import Link from "next/link"
import React, { PropsWithChildren, ReactElement } from "react"
import { styled } from "src/stitches.config"

import { Button } from "../../button"
import { PageWidthContainer } from "../../page-width-container"
import { H1, P } from "../../typography"
import { HomepagePageHeaderVisual } from "../homepage-page-header-visual"

const HomepagePageHeaderWrapper = styled("header", {
  position: "relative", // need this for positioning the HomepagePageHeaderVisual component
  display: "flex",
  alignItems: "center",
  marginBottom: "$40",
  backgroundColor: "$tertiary",

  "@mdDown": {
    marginBottom: "$20",
  },

  "@mdUp": {
    minHeight: "498px",
  },
})

const HomepagePageHeaderBody = styled("div", {
  position: "relative",
  padding: "$40 0",

  [H1.toString()]: {
    marginBottom: "$20",
    color: "$white",
    lineHeight: "1.15",
    textShadow:
      "-1px 0 $colors$blue, 0 1px $colors$blue, 1px 0 $colors$blue, 0 -1px $colors$blue",
  },

  [P.toString()]: {
    maxWidth: "55%",
    marginBottom: "$10",
    color: "$white",

    "@mdUp": {
      paddingLeft: "$40",
    },
  },

  "@mdDown": {
    padding: "$20 0",
  },
})

const CTAHeaderContainer = styled("div", {
  position: "relative",
  zIndex: 99,
  "@mdUp": {
    paddingLeft: "$40",
  },
  "@mdDown": {
    paddingBottom: "$20",
  },
})

export const HomepagePageHeader = (
  props: PropsWithChildren<{}>,
): ReactElement => (
  <HomepagePageHeaderWrapper>
    <HomepagePageHeaderVisual />

    <PageWidthContainer>
      <HomepagePageHeaderBody>{props.children}</HomepagePageHeaderBody>

      {/*
        This block is here temporarily until NOC has reached an X amount of new users.
        Story: NOC-3737 - https://app.clickup.com/t/24307181/NOC-3737
      */}
      <CTAHeaderContainer>
        <P css={{ color: "white" }}>Nog geen account?</P>
        <Link href={"https://fresk.forms.app/aanvraagaccount"}>
          <Button as="span" variant="lightInverse" size="large">
            Meld u hier aan
          </Button>
        </Link>
      </CTAHeaderContainer>
    </PageWidthContainer>
  </HomepagePageHeaderWrapper>
)
