import { IIcon } from "@types"
import { ReactElement, ReactSVG } from "react"

export const WhatsAppIcon = ({
  fill = "#FFFFFF",
}: IIcon): ReactElement<ReactSVG> => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 30 30"
  >
    <g fill="none">
      <path d="M-666-940h992V463h-992z" />
      <path d="M-666-170h992v275h-992z" />
      <path stroke="#FFF" d="M-24.5-34.5h289v99h-289z" />
      <g transform="translate(-5 -5)">
        <rect width="40" height="40" rx="20" />
        <path
          d="M19.597 11.76c4.829-.229 8.8 3.662 8.649 8.435-.14 4.452-3.884 7.989-8.374 7.92a8.28 8.28 0 0 1-3.545-.853l-4.166.98a.32.32 0 0 1-.389-.375l.876-4.218a8.096 8.096 0 0 1-.898-3.691c-.013-4.362 3.454-7.992 7.846-8.2l.001.002zm-2.698 4.37c-.445.374-.975.942-1.04 1.572-.113 1.11.367 2.51 2.183 4.192 2.098 1.941 3.778 2.199 4.872 1.935.62-.148 1.116-.746 1.429-1.235a.59.59 0 0 0-.338-.886l-1.598-.455a.6.6 0 0 0-.59.154l-.39.395a.585.585 0 0 1-.633.133c-.756-.304-2.346-1.706-2.752-2.408a.575.575 0 0 1 .046-.64l.341-.438a.586.586 0 0 0 .073-.6l-.672-1.508a.598.598 0 0 0-.93-.212z"
          fill={fill}
        />
        <path d="M5 5h30v30H5V20z" />
      </g>
    </g>
  </svg>
)
