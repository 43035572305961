import { useFieldArray } from "react-hook-form"
import { useIntl } from "react-intl"
import { styled } from "src/stitches.config"

import { CreateRule, EditableRule } from "../../edit-forms"
import { FormInput, RemoveWithConfirmationButton } from "../../forms"
import { H4 } from "../../typography"

const motionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}

const Wrapper = styled("div", {
  marginRight: "0",
  variants: {
    isDeleteVisible: {
      true: {
        marginRight: "24px",
      },
    },
  },
})

const ButtonWrapper = styled("div", {
  position: "absolute",
  top: "19px",
  right: "11px",
})

export const EditableSubsection = ({
  path,
  subsection,
  remove,
  index,
}: any) => {
  const { formatMessage } = useIntl()

  const rules = useFieldArray({ name: `${path}.rules`, keyName: "_id" })
  const hasContent = !!rules.fields.length

  function handleRemoveSubsectionClick() {
    remove(index)
  }

  return (
    <Wrapper data-cy="subsection" isDeleteVisible={!hasContent}>
      <FormInput
        name={`${path}.title`}
        defaultValue={subsection.title}
        placeholder={formatMessage({
          id: "admin.editRule.sectionTitle.placeholder",
        })}
        displayAs={H4}
        trimLineBreaks
        italic
        bigPadding
      />
      <CreateRule
        insert={rules.insert}
        insertAtIndex={0}
        forceVisibility={!hasContent}
      />

      {rules.fields.map((rule, index) => {
        return (
          <div key={rule._id} {...motionProps}>
            <EditableRule
              path={`${path}.rules.${index}`}
              rule={rule}
              remove={rules.remove}
              removeFromIndex={index}
            />
            <CreateRule insert={rules.insert} insertAtIndex={index + 1} />
          </div>
        )
      })}

      {!hasContent && (
        <div {...motionProps}>
          <ButtonWrapper>
            <RemoveWithConfirmationButton
              onConfirm={handleRemoveSubsectionClick}
            />
          </ButtonWrapper>
        </div>
      )}
    </Wrapper>
  )
}
