import { styled } from "src/stitches.config"

export const TypeButtonsContainer = styled("div", {
  display: "inline-flex",
  justifyContent: "center",
  margin: "$10 0 $20",
  borderRadius: "4px",
  backgroundColor: "$white",
  boxShadow: "$default",
})
