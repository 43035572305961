import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from "@tanstack/react-query"

import { sportProductService } from "../.."
import { SportProductFilters } from "../sport-product.service"
import { SportProduct } from "../types"

export function useSportProducts(
  filters: SportProductFilters,
  options?: UseQueryOptions,
): UseQueryResult<SportProduct[]> {
  const queryFn = () => sportProductService.getAll(filters)
  return useQuery(["sport-products", filters], queryFn, {
    enabled: options?.enabled,
  })
}
