import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const ChevronDownIcon = ({
  color = "currentColor",
  size = iconSizes.regular,
  width,
  height,
  ...rest
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
    version="1.1"
    {...rest}
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(1.000000, 6.000000)">
          <path
            d="M5.20654839,-2.28721831 L12.3643548,4.62883921 C12.7832285,5.03849207 12.9926653,5.49565938 12.9926653,6.00034115 C12.9926653,6.50502292 12.7832285,6.96213417 12.3643548,7.37167491 L5.20654839,14.2877324 C4.65158065,14.8239568 4.65158065,15.6920643 5.20654839,16.2269173 C5.76151613,16.7617702 6.65996774,16.7631416 7.21351613,16.2269173 L16.7941613,6.96984948 C16.9247419,6.84367903 17.0297742,6.69282307 17.1021613,6.52551009 C17.2455161,6.18951272 17.2455161,5.8110014 17.1021613,5.47500402 C17.0326129,5.31317672 16.9304194,5.16232075 16.7941613,5.03066464 L7.21351613,-4.22640314 C6.65854839,-4.76262752 5.76009677,-4.76262752 5.20654839,-4.22640314 C4.653,-3.69017877 4.65158065,-2.82207127 5.20654839,-2.28721831 Z"
            fill={color}
            fillRule="nonzero"
            transform="translate(11.000000, 6.000000) rotate(90.000000) translate(-11.000000, -6.000000) "
          ></path>
        </g>
      </g>
    </g>
  </svg>
)
