import React, { MouseEventHandler, ReactNode } from "react"
import { styled } from "src/stitches.config"

import { PlusIcon } from "../../assets-icons"
import { P } from "../../typography"

interface Props {
  datacy?: string
  onClick: MouseEventHandler<HTMLButtonElement>
  label?: string
  align?: "left" | "center" | "right"
  noScale?: boolean
  icon?: ReactNode
  isDisabled?: boolean
}

const CreateSectionWrapper = styled("div", {
  paddingTop: "$10",
  marginBottom: "$20",
  textAlign: "center",

  variants: {
    align: {
      left: {
        textAlign: "left",
      },
      right: {
        textAlignt: "right",
      },
      center: {
        textAlignt: "center",
      },
    },
  },
})

const CreateSectionButton = styled("button", {
  fontSize: "$sizes$20",
  lineHeight: "0",

  backgroundColor: "transparent",
  border: "none",
  borderRadius: "6px",
  appearance: "none",
  transition: "all .25s ease",
  opacity: "0.5",

  "&:hover": {
    opacity: "1",
    transform: "scale(1.1)",
  },

  variants: {
    noScale: {
      true: {
        "&:hover": {
          opacity: "1",
          transform: "none",
        },
      },
    },
  },
})

const IconWrapper = styled("div", {
  width: "$40",
  height: "$40",

  color: "$white",
  backgroundColor: "$tertiary",
  border: "none",
  borderRadius: "6px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
})

const ButtonContentWrapper = styled("div", {
  display: "flex",
  alignItems: "center",

  "& > *": {
    marginRight: "$10",
  },
})

export const CreateSection = ({
  datacy,
  onClick,
  label,
  align = "center",
  noScale = false,
  icon = <PlusIcon />,
  isDisabled = false,
}: Props) => {
  return (
    <CreateSectionWrapper align={align}>
      <CreateSectionButton
        type="button"
        onClick={onClick}
        data-cy={datacy}
        noScale={noScale}
        aria-label="nieuwe sectie"
        disabled={isDisabled}
      >
        <ButtonContentWrapper>
          <IconWrapper>{icon}</IconWrapper>
          {label && <P noMargin>{label}</P>}
        </ButtonContentWrapper>
      </CreateSectionButton>
    </CreateSectionWrapper>
  )
}
