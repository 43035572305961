import { styled } from "src/stitches.config"

import { NavItemLink } from "./nav-item-link"

export const NavSubmenuItemLink = styled(NavItemLink, {
  fontSize: "18px",
  fontWeight: "bold",
  fontFamily: "$grotesk",
  textAlign: "inherit",
  textDecoration: "none",

  display: "flex",
  alignItems: "flex-end",
  padding: "$10 $30 $10 $10",
  clear: "both",
  border: 0,
  color: "$orange",
  backgroundColor: "transparent",
  whiteSpace: "nowrap",
  cursor: "pointer",

  "&:hover": {
    color: "$orange",
    opacity: 0.7,
    textDecoration: "none",
  },
})
