import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const TrashIcon = ({
  color = "currentColor",
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 20 22"
    version="1.1"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-2.000000, -1.000000)">
        <g transform="translate(2.000000, 1.000000)">
          <path
            d="M1,6 C0.44771525,6 0,5.55228475 0,5 C0,4.44771525 0.44771525,4 1,4 L19,4 C19.5522847,4 20,4.44771525 20,5 C20,5.55228475 19.5522847,6 19,6 L1,6 Z"
            fill={color}
          ></path>
          <path
            d="M16,5 C16,4.44771525 16.4477153,4 17,4 C17.5522847,4 18,4.44771525 18,5 L18,19 C18,20.6568542 16.6568542,22 15,22 L5,22 C3.34314575,22 2,20.6568542 2,19 L2,5 C2,4.44771525 2.44771525,4 3,4 C3.55228475,4 4,4.44771525 4,5 C4,6.27647138 4,7.23382491 4,7.8720606 C4,10.344936 4,14.0542492 4,19 C4,19.5522847 4.44771525,20 5,20 L15,20 C15.5522847,20 16,19.5522847 16,19 L16,5 Z M7,5 C7,5.55228475 6.55228475,6 6,6 C5.44771525,6 5,5.55228475 5,5 L5,3 C5,1.34314575 6.34314575,0 8,0 L12,0 C13.6568542,0 15,1.34314575 15,3 L15,5 C15,5.55228475 14.5522847,6 14,6 C13.4477153,6 13,5.55228475 13,5 L13,3 C13,2.44771525 12.5522847,2 12,2 L8,2 C7.44771525,2 7,2.44771525 7,3 L7,5 Z"
            fill={color}
          ></path>
        </g>
      </g>
    </g>
  </svg>
)
