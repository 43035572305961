export const RecommendationIcon = () => (
  <svg width="0.8947368421em" height="1em" viewBox="0 0 68 76" version="1.1">
    <title>Aanbeveling</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-6.000000, -2.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <g transform="translate(6.000000, 2.000000)">
          <path d="M34,15 C20.441072,15 15,25.7334471 15,33.2820294 C15,44.308269 23.4312031,52.4467043 25.3637879,61 L42.6362121,61 C44.5696302,52.4467043 53,44.308269 53,33.2820294 C53,25.7334471 47.5597614,15 34,15 Z"></path>
          <path d="M26,69.3237757 C26,71.8796309 26.9678664,72.884599 29.4293641,72.884599 L29.7361285,72.884599 L29.7787803,72.884599 L30.1150729,73.9330021 C30.6268938,75.530731 31.2535463,76 32.8751327,76 L36.1256875,76 C37.7480942,76 38.3731062,75.530731 38.8857474,73.9330021 L39.2212197,72.884599 L39.2638715,72.884599 L39.5714561,72.884599 C42.033774,72.884599 43,71.8796309 43,69.3237757 L43,64 L26,64 L26,69.3237757 Z"></path>
          <path d="M34.5,11 C35.4121622,11 36,10.3249906 36,9.28096495 L36,1.7182058 C36,0.674180173 35.4121622,0 34.5,0 C33.5885618,0 33,0.674180173 33,1.71903505 L33,9.2817942 C33,10.3258198 33.5885618,11 34.5,11 Z"></path>
          <path d="M49.6917328,15.5663011 C50.49692,16.2424361 51.4211841,16.1201378 52.0489709,15.2544403 L56.5965775,8.97616811 C57.2251744,8.10959708 57.1117677,7.11111856 56.3082006,6.4349835 C55.5046335,5.75710132 54.5787492,5.87939965 53.9509624,6.74684424 L49.4041659,13.0242429 C48.773949,13.8916875 48.8889757,14.8892924 49.6917328,15.5663011 Z"></path>
          <path d="M67.9332622,26.2440129 C67.6749504,25.2873593 66.8434567,24.8231239 65.811898,25.0618959 L58.341117,26.7896656 C57.3095583,27.0284376 56.8098179,27.7995536 57.0664414,28.7562073 C57.323909,29.7136438 58.1562468,30.1763134 59.1878055,29.9383243 L66.6577424,28.2113375 C67.6901453,27.9725655 68.1907298,27.2006665 67.9332622,26.2440129 Z"></path>
          <path d="M63.9902608,46.4650162 L57.3526666,43.1912614 C56.4364221,42.7393437 55.5799326,43.1038788 55.1728013,44.1198109 C54.7648733,45.1357429 55.0931279,46.0828281 56.0101692,46.5356283 L62.6469666,49.8076179 C63.5632111,50.2613008 64.4197006,49.8967657 64.8268319,48.8808337 C65.2355566,47.866667 64.9065053,46.9186992 63.9902608,46.4650162 Z"></path>
          <path d="M15.9512669,15.2541369 C16.5791269,16.1207397 17.504309,16.2421689 18.3079699,15.5660091 C19.1116307,14.8881021 19.2250506,13.8913342 18.5971905,13.0238578 L14.0482435,6.74622928 C13.4203835,5.87962651 12.4952014,5.7573237 11.6915406,6.43435711 C10.8878797,7.11139053 10.77527,8.10903202 11.40313,8.97563479 L15.9512669,15.2541369 Z"></path>
          <path d="M10.9332508,28.7568327 C11.1907452,27.7992782 10.6901085,27.028067 9.65844247,26.7892656 L2.1877281,25.0612828 C1.15606204,24.8240473 0.323637542,25.287557 0.0661431493,26.2443286 C-0.189662756,27.2011002 0.309285396,27.9723113 1.3417957,28.2111127 L8.81166582,29.9383125 C9.84333188,30.176331 10.6757564,29.7136043 10.9332508,28.7568327 Z"></path>
          <path d="M10.6469793,43.1903543 L4.00981322,46.4661864 C3.09283109,46.9172643 2.7645977,47.8662051 3.17329607,48.8804684 C3.58040109,49.8964971 4.4368353,50.2601842 5.35302075,49.8091063 L11.9901868,46.5332743 C12.9071689,46.0821963 13.2354023,45.1332555 12.8267039,44.1189923 C12.4188022,43.1038463 11.5639614,42.7401592 10.6469793,43.1903543 Z"></path>
        </g>
      </g>
    </g>
  </svg>
)
