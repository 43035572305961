import { useIntl } from "react-intl"
import { ImplementedSportProduct } from "src/services"
import { styled } from "src/stitches.config"

import { OrganizationsList } from "./fragments/organizations-list"

interface Props {
  isp: ImplementedSportProduct
  actions?: React.ReactElement | null
}

const V2ImplementedSportProductCardWrapper = styled("div", {
  display: "flex",
  padding: "$20",
  backgroundColor: "$grayLight",
  borderRadius: "4px",

  variants: {
    isRetired: {
      true: {
        opacity: 0.5,
      },
    },
  },
})

const V2ImplementedSportProductBody = styled("div", {
  width: "100%",
})

const V2ImplementedSportProductHeader = styled("div", {
  display: "flex",
  width: "100%",
})

const SportProductName = styled("div", {
  fontFamily: "$nova",
  fontWeight: "$bold",
})

export const V2ImplementedSportProductCard = ({ isp, actions }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <V2ImplementedSportProductCardWrapper isRetired={isp.isRetired}>
      <V2ImplementedSportProductBody>
        <V2ImplementedSportProductHeader>
          <div>
            {formatMessage({
              id: `sportsProductType.${isp.sportProduct.sportProductType.name}`,
            })}
            <SportProductName>{isp?.sportProduct.name}</SportProductName>
          </div>

          {!isp.isRetired && actions}
        </V2ImplementedSportProductHeader>

        <OrganizationsList isp={isp} />
      </V2ImplementedSportProductBody>
    </V2ImplementedSportProductCardWrapper>
  )
}
