import { Header, flexRender } from "@tanstack/react-table"
import { styled } from "src/stitches.config"

const Th = styled("th", {
  padding: "$15",
  whiteSpace: "nowrap",
  borderBottom: "1px solid #f1f5f9",
  backgroundColor: "#f8f9fd",

  variants: {
    canSort: {
      true: {
        cursor: "pointer",
        userSelect: "none",
      },
    },
  },
})

export function TableHeader(header: Header<any, unknown>) {
  const isSorted = header.column.getIsSorted() as string

  return (
    <Th key={header.id} canSort={header.column.getCanSort()}>
      {header.isPlaceholder ? null : (
        <div onClick={header.column.getToggleSortingHandler()}>
          {flexRender(header.column.columnDef.header, header.getContext())}
          {{ asc: " 🔼", desc: " 🔽" }[isSorted] ?? null}
        </div>
      )}
    </Th>
  )
}
