import { parseISO } from "date-fns"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useFieldArray, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import { useAdminContext } from "src/context/admin-context"
import { styled } from "src/stitches.config"
import alphabeticalSort from "src/utils/alphabetical-sort"
import formatDate, { isISODate } from "src/utils/format-date"
import { filters, transformers } from "src/utils/mappers"
import { v4 as uuid } from "uuid"

import { ContentBoxItem } from "../../content-box-item"
import { WrappedDropdownSelect } from "../../dropdown-select"
import {
  FormInput,
  FormItem,
  FormItemInput,
  FormItemLabel,
  RemoveWithConfirmationButton,
  SportClubDisciplineRelation,
  UploadInput,
} from "../../forms"
import { P } from "../../typography"

interface Props {
  remove: Function
  index: number
  path: string
  hasCertificate?: boolean
}

const ProductWrapper = styled("div", {
  display: "flex",
  alignItems: "flex-start",
  padding: "$20 $10 $20 $20",
  margin: "$10 0",
  borderLeft: "1px solid $bodyGreen",
  backgroundColor: "$grayLight",
})

const DropdownWrapper = styled("div", {
  flexGrow: 2,
  marginRight: "$10",
})

const RemoveWrapper = styled("div", {
  opacity: "0",
  transition: ".2s opacity linear",
  pointerEvents: "none",

  variants: {
    isVisible: {
      true: {
        opacity: "1",
        pointerEvents: "auto",
      },
    },
  },
})

function createEmptySportClubDisciplineRelation() {
  return {
    id: uuid(),
    discipline: null,
    sportClubs: [],
  }
}

function useRelations(path: string) {
  const relations = useFieldArray({
    name: `${path}.sportClubDisciplineRelations`,
    keyName: "_id",
  })

  useEffect(() => {
    if (relations.fields.length === 0) {
      relations.append(createEmptySportClubDisciplineRelation())
    }
  }, [relations.fields])

  return relations
}

export const ImplementedSportProduct = ({
  remove,
  index,
  path,
  hasCertificate = false,
}: Props) => {
  const [isDeleteVisible, setDeleteVisible] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const { formatMessage } = useIntl()
  const adminContext = useAdminContext()

  const form = useFormContext()
  const id = form.watch(`${path}.id`)
  const sportProductTypeValue = form.watch(`${path}.sportProductType`)
  const sportProductValue = form.watch(`${path}.sportProduct`)
  const sportDisciplineValue = form.watch(`${path}.disciplines`)
  const contractorValue = form.watch(`${path}.contractor`)
  const consultantValue = form.watch(`${path}.consultant`)
  const inspectionDate = form.watch(`${path}.inspectionReport.inspectionDate`)
  const sportClubDisciplineRelations = useRelations(path)

  const mappedSportProductTypes = useMemo(() => {
    return (
      adminContext.sportProductTypes
        .map(transformers.sportProductType)
        .sort(alphabeticalSort) || []
    )
  }, [])

  const mappedSportProducts = useMemo(() => {
    if (!sportProductTypeValue) return []
    return (
      adminContext.sportProducts
        .filter(({ type }) => type.id === sportProductTypeValue.id)
        .map(transformers.sportProduct)
        .sort(alphabeticalSort) || []
    )
  }, [sportProductTypeValue])

  const mappedDisciplines = useMemo(() => {
    if (!sportProductValue) return []
    return (
      adminContext.sportProducts
        .find(filters.sportProduct(sportProductValue))
        .disciplines.map(transformers.discipline)
        .sort(alphabeticalSort) || []
    )
  }, [sportProductValue])

  const mappedContractors = useMemo(() => {
    if (!sportProductValue) return []

    return (
      adminContext.sportProducts
        .find(filters.sportProduct(sportProductValue))
        .contractors.map(transformers.contractor)
        .sort(alphabeticalSort) || []
    )
  }, [sportProductValue])

  function handleSportProductTypeChange() {
    form.setValue(`${path}.sportProduct`, undefined)
    handleSportProductChange()
  }

  function handleSportProductChange() {
    // when user changes the sport product value we'll reset all the
    // selected sportClubDiscipline relations to prevent unwanted combinations
    sportClubDisciplineRelations.remove()
  }

  useEffect(() => {
    if (sportDisciplineValue?.length) {
      // mapped current disciplines to check if our current selection is still valid
      // within the new values after selecting a new sport product
      const result = sportDisciplineValue.filter((disciplineValue: any) => {
        return mappedDisciplines.find((mappedDiscipline: any) => {
          return mappedDiscipline.id === disciplineValue.id
        })
      })

      if (result.length !== sportDisciplineValue.length) {
        // discipline values have differ, we need to reset the input values
        form.setValue(`${path}.disciplines`, [], {
          shouldValidate: true,
          shouldDirty: true,
        })
      }
    }

    if (contractorValue) {
      // checked if current selection is still a valid option from the available ones
      const selectedContractor = mappedContractors.find((contractor: any) => {
        return contractorValue.id === contractor.id
      })

      // selection was not found, we need to reset the input value
      if (!selectedContractor) {
        form.setValue(`${path}.contractor`, null, {
          shouldValidate: true,
          shouldDirty: true,
        })
      }
    }
  }, [
    sportProductValue,
    sportDisciplineValue,
    mappedDisciplines,
    contractorValue,
    consultantValue,
    mappedContractors,
  ])

  useEffect(() => {
    if (isISODate(inspectionDate)) {
      const formattedInspectionDate = formatDate(
        parseISO(inspectionDate),
        "dd-MM-yyyy",
      )
      form.setValue(
        `${path}.inspectionReport.inspectionDate`,
        formattedInspectionDate,
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      )
    }
  }, [inspectionDate])

  const handleCreateSportClubDisciplineRelations = useCallback(() => {
    sportClubDisciplineRelations.append(
      createEmptySportClubDisciplineRelation(),
    )
  }, [index, remove])

  const handleRemoveProductClick = useCallback(() => {
    remove(index)
  }, [index, remove])

  const handleMouseEnter = useCallback(() => setDeleteVisible(true), [])
  const handleMouseLeave = useCallback(
    () => setDeleteVisible(isFocused),
    [isFocused],
  )
  const handleFocus = useCallback(() => {
    setIsFocused(true)
    setDeleteVisible(true)
  }, [])
  const handleBlur = useCallback(() => {
    setIsFocused(false)
    setDeleteVisible(false)
  }, [])

  return (
    <ProductWrapper
      key={id}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      <DropdownWrapper>
        <FormItem horizontal>
          <FormItemLabel htmlFor={`${path}.sportProductType`}>
            Product type
          </FormItemLabel>
          <FormItemInput data-cy="form-input-type">
            <WrappedDropdownSelect
              name={`${path}.sportProductType`}
              options={mappedSportProductTypes}
              placeholder={formatMessage({ id: "form.select.placeholder" })}
              noDefault
              onChange={handleSportProductTypeChange}
              isDisabled={hasCertificate}
            />
          </FormItemInput>
        </FormItem>
        <FormItem horizontal>
          <FormItemLabel htmlFor={`${path}.sportProduct`}>
            Sportproduct
          </FormItemLabel>
          <FormItemInput data-cy="form-input-sportproduct">
            <WrappedDropdownSelect
              name={`${path}.sportProduct`}
              options={mappedSportProducts}
              placeholder={formatMessage({ id: "form.select.placeholder" })}
              onChange={handleSportProductChange}
              noDefault
              isDisabled={hasCertificate}
            />
          </FormItemInput>
        </FormItem>

        <FormItem>
          <FormItemInput>
            {sportClubDisciplineRelations.fields.map(
              (sportClubDisciplineRelation, index) => (
                <SportClubDisciplineRelation
                  key={sportClubDisciplineRelation._id}
                  remove={sportClubDisciplineRelations.remove}
                  index={index}
                  path={`${path}.sportClubDisciplineRelations.${index}`}
                  sportProductValue={sportProductValue}
                  hasBackground
                  isDisciplineEditable={!hasCertificate}
                  isSportClubsEditable={!hasCertificate}
                />
              ),
            )}
          </FormItemInput>

          {!hasCertificate && (
            <ContentBoxItem
              buttonLabel="nieuwe discipline"
              onClick={handleCreateSportClubDisciplineRelations}
              alwaysVisible
              datacy="new-discipline"
            />
          )}
        </FormItem>

        <FormItem
          horizontal
          key={`${sportProductTypeValue?.id}_${sportProductValue?.id}_contractor`}
        >
          <FormItemLabel htmlFor={`${path}.contractor`}>
            Aannemer / Leverancier
          </FormItemLabel>
          <FormItemInput data-cy="form-input-contractor">
            <WrappedDropdownSelect
              name={`${path}.contractor`}
              placeholder={formatMessage({ id: "form.select.placeholder" })}
              options={mappedContractors}
              noDefault
              isDisabled={hasCertificate}
            />
          </FormItemInput>
        </FormItem>

        <FormItem horizontal>
          <FormItemLabel htmlFor={`${path}.inspectionReport.inspectionDate`}>
            Datum van keuring
          </FormItemLabel>
          <FormItemInput noMargin>
            <FormInput
              data-cy="form-input-inspectionDate"
              displayAs={P}
              name={`${path}.inspectionReport.inspectionDate`}
              placeholder={"dd-mm-jjjj"}
              trimLineBreaks
              italic
              onDarkBackground={true}
              isDisabled={hasCertificate}
            />
          </FormItemInput>
        </FormItem>
        <FormItem horizontal>
          <FormItemLabel htmlFor={`${path}.inspectionReport`}>
            Keuringsrapport
          </FormItemLabel>
          <FormItemInput data-cy="upload-file-input">
            <UploadInput
              name={`${path}.inspectionReport.url`}
              placeholder={formatMessage({
                id: `form.report.attachment.placeholder`,
              })}
              endpoint="/upload/inspection-reports"
              buttonLabel="Upload rapport"
              acceptTypes="application/pdf"
              onDarkBackground
              isDisabled={hasCertificate}
            />
          </FormItemInput>
        </FormItem>
      </DropdownWrapper>
      <RemoveWrapper isVisible={isDeleteVisible && !hasCertificate}>
        <RemoveWithConfirmationButton onConfirm={handleRemoveProductClick} />
      </RemoveWrapper>
    </ProductWrapper>
  )
}
