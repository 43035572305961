import { useSession } from "next-auth/react"
import { useIntl } from "react-intl"
import { getAuthorizeAdminMenu } from "src/utils"

export function useAuthAdminMenu() {
  const { formatMessage } = useIntl()
  const { data: session } = useSession()

  return getAuthorizeAdminMenu(session, formatMessage)
}
