import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from "@tanstack/react-query"

import { inspectionReportService } from "../.."
import { InspectionReport } from "../types"

export function useInspectionReport(
  cpId: string,
  ispId: string,
  options?: UseQueryOptions,
): UseQueryResult<InspectionReport> {
  const queryFn = () => inspectionReportService.getReport(cpId, ispId)

  return useQuery(["inspection-report", cpId, ispId], queryFn, {
    refetchOnMount: true,
    initialData: options?.initialData,
    placeholderData: options?.initialData,
  })
}
