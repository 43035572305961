import { useRouter } from "next/router"
import { useCallback, useEffect, useRef, useState } from "react"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import api from "src/services/api"
import { IFileContent } from "src/services/types"
import { styled } from "src/stitches.config"

import { PdfColoredDarkIcon } from "../../assets-icons"
import { Button } from "../../button"
import {
  EditableRuleAttachmentWrapper,
  FormIconWrapper,
  HiddenFileInput,
  InputWrapper,
} from "../../edit-forms"
import { FormInput, HiddenInput } from "../../forms"
import { Message } from "../../message"
import { P } from "../../typography"
import { LoadingOverlay } from "../loading-overlay"

interface PdfAttachmentInputProps {
  path: string
  url?: string
  name?: string
}

const ToggleButton = styled("button", {
  display: "flex",
  alignItems: "center",
  whiteSpace: "nowrap",
  border: "none",
  background: "none",
  appearance: "none",

  "> span": {
    marginLeft: "$10",
    fontSize: "$bodySmall",
    color: "#4a4a4a",
  },
})

export const PdfAttachmentInput = ({
  path,
  url,
  name,
}: PdfAttachmentInputProps) => {
  const router = useRouter()
  const { formatMessage } = useIntl()
  const { setValue, watch } = useFormContext()
  const [uploading, setUploading] = useState(false)
  const fileInput = useRef<HTMLInputElement>(null)
  const [fileInputContent, setFileInputContent] =
    useState<IFileContent | null>()

  const fileNameValue = watch(`${path}.content`)
  const fileUrlValue = watch(`${path}.url`)

  const openFile = useCallback(() => {
    if (fileInput.current !== null) {
      fileInput.current.click()
    }
  }, [fileInput.current])

  const onFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const filesContent = event.target.files
      if (filesContent?.length) {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(filesContent[0])
        fileReader.onload = (_e: ProgressEvent<FileReader>) => {
          if (_e?.target) {
            setFileInputContent({
              name: filesContent[0]?.name,
              content: _e.target.result as string,
            })
          }
        }
      }
    },
    [],
  )

  useEffect(() => {
    if (fileInputContent?.content && fileInputContent?.name) {
      setUploading(true)

      const isProcedures = router.route.includes("handbook/process")

      const uploadResponse = !isProcedures
        ? api.admin.createDocumentFile(
            fileInputContent.name,
            fileInputContent.content,
          )
        : api.knowledgeBase.createFileByCode(
            router.query?.id as string,
            fileInputContent.name,
            fileInputContent.content,
          )

      uploadResponse
        .then(({ url, name: fileName }) => {
          setValue(`${path}.url`, url, {
            shouldValidate: true,
            shouldDirty: true,
          })
          setValue(`${path}.content`, fileName, {
            shouldValidate: true,
            shouldDirty: true,
          })
          setValue(`${path}.title`, fileName, {
            shouldValidate: true,
            shouldDirty: true,
          })
          setFileInputContent(null)
        })
        .finally(() => setUploading(false))
    }
  }, [fileInputContent])

  useEffect(() => {
    if (!fileUrlValue) {
      openFile()
    }
  }, [])

  return (
    <EditableRuleAttachmentWrapper style={{ position: "relative" }}>
      <FormIconWrapper css={{ backgroundColor: "$white" }}>
        <PdfColoredDarkIcon size="large" />
      </FormIconWrapper>

      <HiddenInput name={`${path}.url`} />
      <InputWrapper>
        <HiddenFileInput
          ref={fileInput}
          type="file"
          accept="application/pdf"
          onChange={onFileChange}
        />
        <FormInput
          name={`${path}.title`}
          displayAs={P}
          italic
          trimLineBreaks
          placeholder={formatMessage({
            id: "admin.editRule.pdfText.placeholder",
          })}
        />
        <ToggleButton
          type="button"
          onClick={openFile}
          data-cy="open-pdf-file-picker"
        >
          <Button size="xsmall" as="div" icon="upload" variant="secondary" />
          <span>
            {fileNameValue || <Message id="attachmentTypes.pdf.uploadLabel" />}
          </span>
        </ToggleButton>
      </InputWrapper>
      <LoadingOverlay showText visible={uploading} />
    </EditableRuleAttachmentWrapper>
  )
}
