import React, { ReactElement } from "react"
import { IQuestion } from "src/services"
import { keyframes, styled } from "src/stitches.config"

import { Button } from "../button"
import { IconWrapper } from "../icon-wrapper"
import { Markdown } from "../markdown"

interface Props extends IQuestion {
  isOpen: boolean
  onClick(): void
}

const CardWrapper = styled("div", {
  marginBottom: "$10",
  backgroundColor: "$white",
})

const QuestionWrapper = styled("div", {
  padding: "$40 $80",
  font: "$grotesk",
  fontWeight: "$bold",
  fontSize: "18px",
  color: "$black",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
  backgroundColor: "white",

  "@mdDown": {
    padding: "$20",
  },
})

const QuestionStyled = styled("div", {
  maxWidth: "90%",
})

const openUp = keyframes({
  "0%": { height: "0px", opacity: 0 },
  "25%": { height: "100px", opacity: 0 },
  "35%": { height: "120px", opacity: 0 },
  "45%": { height: "130px", opacity: 0 },
  "100%": { height: "100%", opacity: 1 },
})

const AnswerWrapper = styled("div", {
  padding: "0 160px $40 160px",
  font: "$grotesk",
  fontSize: "1rem",
  color: "$black",

  variants: {
    isSelected: {
      true: {
        animation: `${openUp} .4s cubic-bezier(0.865, 0.140, 0.095, 0.870)`,
      },
    },
  },

  "@mdDown": {
    padding: "0 $20 $20 $20",
  },
})

/** Card for FAG (question and answer)
 * @param {string} question - question
 * @param {string} answer - answer
 * @param {boolean} isOpen - detects if card is open
 * @param {Function} onClick - click handler
 */
export const QuestionCard = ({
  question,
  answer,
  isOpen,
  onClick,
}: Props): ReactElement => (
  <CardWrapper data-cy="faq-card">
    <QuestionWrapper onClick={onClick}>
      <QuestionStyled data-cy="faq-question">{question}</QuestionStyled>
      <Button
        variant="light"
        size="large"
        aria-label={isOpen ? "sluiten" : "open"}
      >
        <IconWrapper name={isOpen ? "arrowUp" : "arrowDown"} fill="#eb5e00" />
      </Button>
    </QuestionWrapper>

    {isOpen && (
      <AnswerWrapper isSelected={isOpen}>
        <Markdown>{answer}</Markdown>
      </AnswerWrapper>
    )}
  </CardWrapper>
)
