import Link from "next/link"
import { ImplementedSportProduct } from "src/services"
import { styled } from "src/stitches.config"

const OrganizationsListWrapper = styled("div", {
  fontSize: "$body",
})

const OrganizationsListItem = styled("div", {
  display: "inline-block",

  "&:not(:last-child)::after": {
    content: ", ",
    whiteSpace: "pre",
  },
})

interface Props {
  isp: ImplementedSportProduct
}

export function OrganizationsList({ isp }: Props) {
  const { mainContractor, contractor, consultant } = isp

  return (
    <OrganizationsListWrapper>
      {mainContractor && (
        <OrganizationsListItem>
          Hoofdaannemer:&nbsp;
          <Link href={`/organisaties/${mainContractor.id}`}>
            {mainContractor.name}
          </Link>
        </OrganizationsListItem>
      )}
      {contractor && (
        <OrganizationsListItem>
          Aannemer/leverancier:&nbsp;
          <Link href={`/organisaties/${contractor.id}`}>{contractor.name}</Link>
        </OrganizationsListItem>
      )}
      {consultant && (
        <OrganizationsListItem>
          Consultant:&nbsp;
          <Link href={`/organisaties/${consultant.id}`}>{consultant.name}</Link>
        </OrganizationsListItem>
      )}
    </OrganizationsListWrapper>
  )
}
