export const AvatarPlaceholderGlyph = ({ size = 140 }) => (
  <svg
    width="100%"
    height="100%"
    viewBox={`0 0 ${size} ${size}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g opacity="1">
        <path
          d="M70+0C108.66-7.10171e-15+140+31.3401+140+70L140+140L140+140L70+140C31.3401+140+4.73448e-15+108.66+0+70C-4.73448e-15+31.3401+31.3401+7.10171e-15+70+0Z"
          opacity="1"
          fill="#ffffff"
        />
        <path
          d="M70+0C108.66-7.10171e-15+140+31.3401+140+70L140+140L140+140L70+140C31.3401+140+4.73448e-15+108.66+0+70C-4.73448e-15+31.3401+31.3401+7.10171e-15+70+0Z"
          opacity="1"
          fill="#ffffff"
        />
        <path
          d="M70+0C108.66-7.10171e-15+140+31.3401+140+70L140+140L140+140L70+140C31.3401+140+4.73448e-15+108.66+0+70C-4.73448e-15+31.3401+31.3401+7.10171e-15+70+0Z"
          opacity="1"
          fill="#ffffff"
        />
        <path
          d="M0+0L140+0L140+0L140+140L140+140L0+140L0+140L0+0L0+0Z"
          opacity="1"
          fill="#4a4a4a"
        />
        <g opacity="1">
          <path
            d="M69.3827+33C82.3621+33+92.8395+43.2661+92.8395+55.9838C92.8395+68.7016+82.3621+78.9677+69.3827+78.9677C56.4033+78.9677+45.9259+68.7016+45.9259+55.9838C45.9259+43.2661+56.4033+33+69.3827+33ZM69.3827+42.4639C61.7478+42.4639+55.5846+48.5028+55.5846+55.9838C55.5846+63.4649+61.7478+69.5038+69.3827+69.5038C77.0177+69.5038+83.1808+63.4649+83.1808+55.9838C83.1808+48.5028+77.0177+42.4639+69.3827+42.4639Z"
            fillOpacity="0.6"
            fill="#ffffff"
            opacity="1"
          />
          <path
            d="M70+87.4354C86.5833+87.4354+119.75+95.1943+120+107.347C119.8+107.567+119.598+107.785+119.393+108.001C98.7622+101.366+82.2978+98.0494+70+98.0494C57.7022+98.0494+41.2377+101.366+20.6065+108C20.4018+107.785+20.1996+107.567+20+107.347C20.25+95.1943+53.3333+87.4354+70+87.4354Z"
            fillOpacity="0.6"
            fill="#ffffff"
            opacity="1"
          />
        </g>
      </g>
    </g>
  </svg>
)
