import type * as Stitches from "@stitches/react"
import React from "react"
import { keyframes, styled } from "src/stitches.config"

import { P } from "../typography"

interface Props {
  loadingText?: string
  css?: Stitches.CSS
}

const loaderAnimation = keyframes({
  "0%,100%": {
    boxShadow:
      "0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0",
  },
  "12.5%": {
    boxShadow:
      "0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em",
  },
  "25%": {
    boxShadow:
      "0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em",
  },
  "37.5%": {
    boxShadow:
      "0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em",
  },
  "50%": {
    boxShadow:
      "0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em",
  },
  "62.5%": {
    boxShadow:
      "0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em",
  },
  "75%": {
    boxShadow:
      "0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0",
  },
  "87.5%": {
    boxShadow:
      "0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em",
  },
})

const Wrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "80px auto",
  "@smDown": {
    margin: "40px auto",
  },
})

const Text = styled(P, {
  color: "$gray",
  marginTop: "20px",
})

export const LoaderContent = styled("div", {
  color: "$orangeHighlight",
  fontSize: "6px",
  margin: "20px auto",
  width: "8px",
  height: "8px",
  borderRadius: "50%",
  position: "relative",
  textIndent: "-9999rem",
  animation: `${loaderAnimation} 1.8s infinite linear`,
  "-webkit-animation": `${loaderAnimation} 1.8s infinite linear`,
  transform: "translateZ(0)",
  "-webkit-transform": "translateZ(0)",
  "-ms-transform": "translateZ(0)",
})

export const Loader = ({ loadingText = "Aan het laden...", css }: Props) => (
  <Wrapper data-cy="loader" css={css}>
    <LoaderContent />
    <Text>{loadingText}</Text>
  </Wrapper>
)
