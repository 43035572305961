import axios, { AxiosRequestConfig } from "axios"
import { IncomingMessage } from "http"
import https from "https"
import { getSession } from "next-auth/react"
import appConfig from "src/config"
import { ExtendedSession } from "src/pages/api/auth/[...nextauth]"

const isServerSide = typeof window === "undefined"
const isClientSide = typeof window !== "undefined"

function getPrefix() {
  return isClientSide ? "/api/v2" : `${appConfig.handbook.urlPrefix}/v2`
}

interface APIConfig {
  req?: IncomingMessage
}

export abstract class V2Api {
  private prefix = getPrefix()
  private instance = axios.create({
    timeout: 30000,
    httpsAgent: new https.Agent({ keepAlive: true }),
    baseURL: this.prefix,
  })

  constructor(apiConfig?: APIConfig) {
    if (isServerSide) {
      // Configure interceptor that adds the user session and Azure subscription
      // key to the request. Only done in a server-side context.
      // Needs the `req` in order to get the session token.
      this.instance.interceptors.request.use(async (config) => {
        if (!apiConfig?.req) {
          throw new Error("Missing `req` in server-side V2Api class")
        }

        const session = (await getSession({
          req: apiConfig?.req,
        })) as ExtendedSession

        return {
          ...config,
          headers: {
            ...config.headers,
            "Ocp-Apim-Subscription-Key": appConfig.handbook.apiKey,
            "X-Custom-Authorization": session?.accessToken
              ? `Bearer ${session.accessToken}`
              : false,
          },
        }
      })
    }
  }

  public async get(path: string, config?: AxiosRequestConfig) {
    return (await this.instance.get(path, config))["data"]
  }

  public async post(path: string, config?: AxiosRequestConfig) {
    return (await this.instance.post(path, config))["data"]
  }

  public async put(path: string, config?: AxiosRequestConfig) {
    return (await this.instance.put(path, config))["data"]
  }

  public async delete(path: string, config?: AxiosRequestConfig) {
    return (await this.instance.delete(path, config))["data"]
  }
}
