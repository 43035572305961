export const AccommodationHeader = () => (
  <svg
    preserveAspectRatio="xMidYMax slice"
    width="1280px"
    height="455px"
    viewBox="0 0 1280 455"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Graphics/Accommodaties</title>
    <defs>
      <rect id="path-1" x="0" y="0" width="1280" height="455"></rect>
      <polygon
        id="path-3"
        points="1193.68403 0 858.527089 334.967603 1534 1010.05616 1534 334.967603"
      ></polygon>
      <path
        d="M869.460076,369.089078 L1534,1033.24819 L1534,1033.24819 L1534,1151.80993 L810.164296,428.35285 C804.117924,422.321261 804.115363,412.530136 810.152765,406.489574 C810.154702,406.487636 810.15664,406.485698 810.164388,406.489574 L847.584901,369.08926 C853.629223,363.060373 863.415655,363.060292 869.460076,369.089078 Z"
        id="path-5"
      ></path>
    </defs>
    <g
      id="Graphics/Accommodaties"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group">
        <mask id="mask-2" fill="white">
          <use href="#path-1"></use>
        </mask>
        <use id="Mask" fill="#FFFFFF" href="#path-1"></use>
        <g mask="url(#mask-2)">
          <polygon
            id="Path-20"
            fill="#00378A"
            points="1193.68403 0 0 1193 0 0"
          ></polygon>
          <mask id="mask-4" fill="white">
            <use href="#path-3"></use>
          </mask>
          <use id="Mask" fill="#5076AF" href="#path-3"></use>
          <mask id="mask-6" fill="white">
            <use href="#path-5"></use>
          </mask>
          <use id="Mask" fill="#FFB280" href="#path-5"></use>
          <path
            d="M1186.93502,228.36181 L1239.77337,228.376953 C1248.31031,228.388266 1255.23027,235.30822 1255.24158,243.845167 L1255.54445,1300.58571 C1255.55575,1309.12611 1248.63437,1316.05147 1240.09396,1316.05392 C1240.091,1316.05392 1240.08805,1316.05392 1240.0851,1316.04505 L1187.24675,1316.02991 C1178.7098,1316.0186 1171.78985,1309.09864 1171.77854,1300.5617 L1171.47567,243.821159 C1171.46436,235.280751 1178.38575,228.355396 1186.92616,228.352948 C1186.92911,228.352947 1186.93207,228.352947 1186.93502,228.36181 Z"
            id="Rectangle-Copy"
            fill="#FFB280"
            mask="url(#mask-6)"
            transform="translate(1213.510058, 772.203432) rotate(-45.000000) translate(-1213.510058, -772.203432) "
          ></path>
        </g>
      </g>
    </g>
  </svg>
)
