import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const AdditionalInformationIcon = ({
  color = "currentColor",
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
    version="1.1"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <circle
        stroke={color}
        strokeWidth="1.66666667"
        cx="12"
        cy="12"
        r="10"
      ></circle>
      <g transform="translate(11.000000, 7.000000)" fill={color}>
        <circle cx="1" cy="1" r="1"></circle>
        <path d="M2,9 C2,9.5522847 1.5522847,10 1,10 C0.4477153,10 0,9.5522847 0,9 L0,5 C0,4.4477153 0.4477153,4 1,4 C1.5522847,4 2,4.4477153 2,5 L2,9 Z"></path>
      </g>
    </g>
  </svg>
)
