import {
  UseQueryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import { toast } from "react-hot-toast"

import { certificationProcessService } from "../.."
import { CertificationProcessUpdate } from "../types"

export function useUpdateCertificationProcess(options: UseQueryOptions) {
  const queryClient = useQueryClient()

  async function mutateFn(data: CertificationProcessUpdate) {
    const result = await certificationProcessService.update(data.id, data)
    await queryClient.refetchQueries(["certification-processes", data.id])
    return result
  }

  return useMutation(mutateFn, {
    onSuccess: (...args) => {
      toast.success("Certificeringsproject geupdate")
      options.onSuccess?.(args)
    },
  })
}
