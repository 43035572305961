import Link from "next/link"
import React, { ReactElement } from "react"
import { ILink } from "src/services"
import { styled } from "src/stitches.config"

interface Props {
  links: ILink[]
}

const LinkWrapper = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "$20",
  maxWidth: "600px",
  margin: "0 auto",

  "@mdDown": {
    flexDirection: "column",
    alignItems: "flex-start",
    margin: 0,
  },

  a: {
    color: "$blue",
    textDecoration: "underline",
    lineHeight: 2,
    flexGrow: 1,

    "&:hover": {
      color: "$blue",
      opacity: 0.75,
    },
  },
})

const Container = styled("div", {
  padding: "15px 0",
  fontFamily: "$grotesk",
  textAlign: "center",
  backgroundColor: "$white",
})

export const FooterLinks = (props: Props): ReactElement => (
  <Container>
    <LinkWrapper data-cy="footer-links">
      {props.links.map(({ text, href }, index) => (
        <Link key={`${text}-${index}`} href={href}>
          {text}
        </Link>
      ))}
    </LinkWrapper>
  </Container>
)
