import { merge } from "lodash"
import { IImage } from "src/services"

const defaults: IImage = {
  id: 1,
  width: 600,
  height: 400,
  size: 600,
  url: "/uploads/image.jpg",
  ext: ".jpg",
  mime: "",
  alternativeText: "",
  caption: "",
  formats: {},
}

export const createImage = (override?: Partial<IImage>): IImage =>
  merge({}, defaults, override)
