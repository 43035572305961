import { styled } from "src/stitches.config"

export const NavItem = styled("div", {
  color: "#FFFFFF",
  fontFamily: "$grotesk",
  fontSize: "18px",
  fontWeight: "$bold",
  position: "relative",
  display: "flex",
  alignItems: "flex-end",
  paddingBottom: "22px",
  height: "$40",
  lineHeight: "12px",
  cursor: "pointer",
  whiteSpace: "nowrap",

  "&:hover": {
    opacity: 0.8,
    textDecoration: "none",

    "&::before": {
      transformOrigin: "0% 50%",
      transform: "scale3d(1, 1, 1)",
    },
  },

  "&::before": {
    position: "absolute",
    width: "100%",
    height: "3px",
    background: "white",
    bottom: "0",
    left: "0",
    pointerEvents: "none",

    content: `''`,
    transformOrigin: "100% 50%",
    transform: "scale3d(0, 1, 1)",
    transition: "transform 0.3s ease-in-out",
  },

  variants: {
    slowAnimation: {
      true: {
        "&::before": {
          transition: "transform 0.5s ease-in-out",
        },
      },
    },
    isActive: {
      true: {
        "&::before": {
          transformOrigin: "0% 50%",
          transform: "scale3d(1, 1, 1)",
        },
      },
    },
  },
})

export type NavItemProps = React.ComponentProps<typeof NavItem>
