import { FormProvider, UseFormReturn } from "react-hook-form"
import { useIntl } from "react-intl"
import { useWindowSize } from "src/hooks/useWindowSize"
import { screenSizes } from "src/stitches.config"

import { FilterContainer } from "../filter-container"
import { FiltersContainer } from "../filters-container"
import { WrappedMultiselectDropdown } from "../multiselect-dropdown"
import { H3 } from "../typography"

interface Props {
  form: UseFormReturn
  options: { [key: string]: any }
}

const filters = [
  {
    id: "disciplines",
    name: "discipline",
    option: "disciplines",
  },
  {
    id: "sportclubs",
    name: "sportClubs",
    option: "sportClubs",
  },
]

export const AccommodationFilters = ({ form, options }: Props) => {
  const { formatMessage } = useIntl()
  const { windowSize } = useWindowSize()

  return (
    <FormProvider {...form}>
      <form data-cy="accommodation-filter">
        <FiltersContainer css={{ marginTop: "0", position: "relative" }}>
          {filters.map(({ id, name, option }) => (
            <FilterContainer data-cy={`filter-${id}`} key={id}>
              <H3 as="p" css={{ color: "$white !important" }}>
                {formatMessage({ id: `filters.${id}.label` })}
              </H3>
              <WrappedMultiselectDropdown
                minWidth={
                  windowSize.width && windowSize.width < screenSizes.s
                    ? "small"
                    : "large"
                }
                name={name}
                options={options[option] || undefined}
                placeholder={formatMessage({
                  id: `filters.${id}.placeholder`,
                })}
              />
            </FilterContainer>
          ))}
        </FiltersContainer>
      </form>
    </FormProvider>
  )
}
