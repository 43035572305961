import { FoundationIcon, SportfloorIcon, SubstructureIcon } from "../../glyphs"

const glyphs = {
  foundation: FoundationIcon,
  substructure: SubstructureIcon,
  sportvloer: SportfloorIcon,
}

interface Props {
  name: keyof typeof glyphs
}

export const SportProductIcon = ({ name }: Props) => {
  if (!name) return null

  const SelectedGlyph = glyphs[name]

  return <SelectedGlyph />
}
