import axios from "axios"
import { useSession } from "next-auth/react"
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useFieldArray, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import { useAdminContext } from "src/context/admin-context"
import { styled } from "src/stitches.config"
import alphabeticalSort from "src/utils/alphabetical-sort"
import { getMapCity } from "src/utils/get-map-city"
import hasPermissions, { useIsAdmin } from "src/utils/has-permissions"
import { transformers } from "src/utils/mappers"
import { v4 as uuidv4 } from "uuid"

import { ContentBox } from "../../context-box"
import { WrappedDropdownSelect } from "../../dropdown-select"
import { CreateSubsection } from "../../edit-forms"
import {
  FormInput,
  FormItem,
  FormItemInput,
  FormItemLabel,
  ImplementedSportProduct,
  LocationType,
  MapModal,
  RemoveWithConfirmationButton,
} from "../../forms"
import { Grid } from "../../grid"
import { GridItem } from "../../grid-item"
import { H1, H3, P } from "../../typography"
import { LocationButton } from "../location-button"

interface Props {
  remove: Function
  index: number
  path: string
}

const TitleWrapper = styled("div", {
  display: "flex",
  alignItems: "flex-start",
})

export const EditableAccommodation = ({ remove, index, path }: Props) => {
  const { data: session } = useSession()
  const { formatMessage } = useIntl()
  const { setValue, watch } = useFormContext()
  const implementedSportProducts = useFieldArray({
    name: `${path}.implementedSportProducts`,
    keyName: "_id",
  })
  const [isMapOpen, setIsMapOpen] = useState(false)
  const selectedLatitute = watch(`${path}.latitude`)
  const selectedLongitude = watch(`${path}.longitude`)
  const dispensation = watch(`${path}.dispensation`)
  const id = watch(`${path}.id`)

  const handleRemoveAccommodationClick = useCallback(() => {
    remove(index)
  }, [index, remove])

  const handleAddProductClick = (index: number) => {
    implementedSportProducts.insert(index, {
      id: uuidv4(),
      sportProductType: null,
      sportProduct: null,
      disciplines: null,
      contractor: null,
      inspectionReport: {
        inspectionDate: null,
      },
    })
  }

  const handleLocationClick = () => {
    setIsMapOpen(true)
  }

  const handleMapClose = () => {
    setIsMapOpen(false)
  }

  const saveLocationClick = async (location: LocationType) => {
    setValue(`${path}.location`, `${location.lat},${location.lng}`, {
      shouldValidate: true,
      shouldDirty: true,
    })
    setValue(`${path}.latitude`, location.lat, {
      shouldValidate: true,
      shouldDirty: true,
    })
    setValue(`${path}.longitude`, location.lng, {
      shouldValidate: true,
      shouldDirty: true,
    })

    const city = await getMapCity(location.lat, location.lng)

    if (city) {
      handleMapClose()
    }

    setValue(`${path}.city`, city, { shouldValidate: true, shouldDirty: true })
  }

  useEffect(() => {
    if (!implementedSportProducts.fields.length) {
      handleAddProductClick(0)
    }
  }, [implementedSportProducts.fields])

  useEffect(() => {
    if (selectedLatitute && selectedLongitude) {
      setValue(`${path}.location`, `${selectedLatitute},${selectedLongitude}`)
    }
  }, [selectedLongitude, selectedLatitute])

  const hasCertificate = useMemo(() => {
    if (!implementedSportProducts.fields.length) return false
    return (
      implementedSportProducts.fields
        .map((sportProduct: any) => {
          if (!sportProduct.disciplineCertificates) return []
          return sportProduct.disciplineCertificates.filter(
            (certificate: any) => certificate.state !== "CREATED",
          )
        })
        .flat().length > 0
    )
  }, [implementedSportProducts.fields])

  return (
    <Fragment>
      <Grid>
        <div
          data-cy={"accommodation-id-" + (index + 1)}
          style={{ display: "none" }}
        >
          {id}
        </div>
        <GridItem col={[1, 12]}>
          <ContentBox>
            <TitleWrapper>
              <FormInput
                displayAs={H1}
                name={`${path}.name`}
                trimLineBreaks
                bigPadding
                css={{ marginBottom: "$20", flexGrow: 2, marginRight: "$10" }}
                placeholder={formatMessage({
                  id: "form.accommodation.name.placeholder",
                })}
                maxLength={25}
                data-cy="form-input-title"
                isDisabled={hasCertificate}
              />
              {!hasCertificate && (
                <RemoveWithConfirmationButton
                  onConfirm={handleRemoveAccommodationClick}
                />
              )}
            </TitleWrapper>
            <FormItem horizontal>
              <FormItemLabel htmlFor={`${path}.location`}>
                Locatie
              </FormItemLabel>
              <FormItemInput
                data-cy="form-input-location"
                css={{
                  "@mdUp": { maxWidth: "600px" },
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <FormInput
                  displayAs={P}
                  name={`${path}.location`}
                  trimLineBreaks
                  italic
                  placeholder={formatMessage({
                    id: "admin.createProject.location.placeholder",
                  })}
                  isDisabled
                  css={{ flexGrow: 2, marginRight: "$10" }}
                />
                <LocationButton
                  onClick={handleLocationClick}
                  isDisabled={hasCertificate}
                />
                <MapModal
                  isOpen={isMapOpen}
                  onCancelClick={handleMapClose}
                  onLocationSave={saveLocationClick}
                  defaultLocation={
                    selectedLatitute && selectedLongitude
                      ? { lat: selectedLatitute, lng: selectedLongitude }
                      : null
                  }
                />
              </FormItemInput>
            </FormItem>

            {useIsAdmin() && (
              <FormItem horizontal>
                <FormItemLabel htmlFor={`${path}.dispensation`}>
                  Dispensatie
                </FormItemLabel>
                <FormItemInput css={{ "@mdUp": { maxWidth: "600px" } }}>
                  <FormInput
                    displayAs={P}
                    name={`${path}.dispensation`}
                    trimLineBreaks
                    italic
                    placeholder={formatMessage({
                      id: "admin.createProject.dispensation.placeholder",
                    })}
                    maxLength={255}
                    showMaxLengthCounter={!!dispensation.length}
                    isDisabled={
                      !hasPermissions(session, ["createOrganization"]) ||
                      hasCertificate
                    }
                    data-cy="form-input-dispensation"
                  />
                </FormItemInput>
              </FormItem>
            )}
          </ContentBox>
        </GridItem>
      </Grid>
      <Grid>
        <GridItem col={[1, 3]} medium={[1, 12]}>
          <H3 css={{ "@mdUp": { textAlign: "right" } }} noMargin>
            Producten
          </H3>
        </GridItem>
        <GridItem col={[4, 9]} medium={[1, 12]}>
          <ContentBox css={{ marginBottom: "$20" }}>
            {!hasCertificate && (
              <CreateSubsection
                buttonLabel="nieuw product"
                onClick={() => handleAddProductClick(0)}
              />
            )}

            {implementedSportProducts.fields.map(
              (implementedSportProduct, _index) => {
                function handleClick() {
                  handleAddProductClick(_index + 1)
                }
                return (
                  <Fragment key={implementedSportProduct._id}>
                    <ImplementedSportProduct
                      remove={implementedSportProducts.remove}
                      index={_index}
                      path={`${path}.implementedSportProducts.${_index}`}
                      hasCertificate={hasCertificate}
                    />
                    {!hasCertificate && (
                      <CreateSubsection
                        buttonLabel="nieuw product"
                        onClick={handleClick}
                      />
                    )}
                  </Fragment>
                )
              },
            )}
          </ContentBox>
        </GridItem>
      </Grid>
    </Fragment>
  )
}
