import React from "react"
import { useIntl } from "react-intl"

import { LinkIcon } from "../../../assets-icons"
import {
  EditableRuleAttachmentWrapper,
  FormIconWrapper,
  InputOptions,
  InputWrapper,
} from "../../../edit-forms"
import {
  FormInput,
  FormItemInput,
  RemoveWithConfirmationButton,
} from "../../../forms"
import { P } from "../../../typography"

interface FieldArrayItemProps {
  path: string
  remove: Function
  removeFromIndex: number
}

export const LinkFormItem = ({
  path,
  remove,
  removeFromIndex,
}: FieldArrayItemProps) => {
  const { formatMessage } = useIntl()

  function handleRemoveClick() {
    remove(removeFromIndex)
  }

  return (
    <FormItemInput>
      <EditableRuleAttachmentWrapper>
        <FormIconWrapper>
          <LinkIcon />
        </FormIconWrapper>
        <InputWrapper>
          <FormInput
            name={`${path}.text`}
            placeholder={formatMessage({ id: `form.link.text.placeholder` })}
            displayAs={P}
            italic
            trimLineBreaks
            data-cy="sport-product-form-link-title"
          />
          <FormInput
            name={`${path}.url`}
            placeholder={formatMessage({ id: `form.link.url.placeholder` })}
            displayAs={P}
            italic
            trimLineBreaks
            data-cy="sport-product-form-link-url"
          />
        </InputWrapper>
        <InputOptions>
          <RemoveWithConfirmationButton onConfirm={handleRemoveClick} />
        </InputOptions>
      </EditableRuleAttachmentWrapper>
    </FormItemInput>
  )
}
