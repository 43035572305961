import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { disciplinesService } from "src/services"

import { DisciplineGetAll } from "../types"

export function useDisciplines(): UseQueryResult<DisciplineGetAll> {
  const queryFn = () => disciplinesService.getAll()

  return useQuery(["disciplines"], queryFn)
}
