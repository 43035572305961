import { useIntl } from "react-intl"
import { P } from "src/components"
import { styled } from "src/stitches.config"

const Text = styled(P, {
  color: "$body !important",
  fontWeight: "$regular !important",
  fontFamily: "$grotesk !important",
})

export const SubscriptionText = () => {
  const { formatMessage } = useIntl()

  return (
    <Text noMargin>{formatMessage({ id: "subscriptionToggle.text" })}</Text>
  )
}
