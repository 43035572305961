import { TProductSystemStatus } from "src/services/types"

export const DATE_FORMAT = "d MMMM yyyy"
export const RULE_TYPES = {
  R: "guideline", // (R)ichtlijn
  A: "recommendation", // (A)anbeveling
  V: "precept", // (V)oorschrift
  W: "legislation", // (W)etgeving
}
export const OVERLAY_ZINDEX = 1000
export const PAGINATION_SIZE = "25"
export const PAGE_NUMBER = "0"

export enum ORGANIZATION_TYPES {
  CONTRACTORS = "contractors",
  MANUFACTURERS = "manufacturers",
  INSTITUTES = "institutes",
  CONSULTANTS = "consultants",
  MUNICIPALITIES = "municipalities",
  SPORTCLUBS = "sport-clubs",
  MISC = "misc",
}

export enum KNOWLEDGE_BASE_TYPES {
  PROCEDURES = "procedures",
  EXAMPLES = "examples",
}

export const PRODUCT_STATUS: {
  [key in TProductSystemStatus]: { name: TProductSystemStatus; sort: number }
} = {
  new: { name: "new", sort: -1 }, // prepend
  active: { name: "active", sort: 0 },
  deleted: { name: "active", sort: 0 },
  inactive: { name: "inactive", sort: 1 }, // append example
}

export const KVK_API_RATE_LIMIT_INTERVAL = 1000 // ms
export const KVK_API_RATE_LIMIT_REQUESTS = 100 // requests

export const TOKEN_REFRESH_INTERVAL = 600
