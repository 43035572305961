import { IDropdownOption } from "@types"
import { ChangeEventHandler } from "react"
import { styled } from "src/stitches.config"
import { uniqString } from "src/utils/mappers"

interface IDefaultItemRendererProps {
  checked: boolean
  option: IDropdownOption
  disabled?: boolean
  onClick?: ChangeEventHandler<HTMLInputElement>
  isChild?: boolean
}

const isChildOption = (option: IDefaultItemRendererProps["option"]) =>
  !option.main

export const DropdownItemRenderer = ({
  checked,
  option,
  onClick,
  disabled,
  isChild,
  ...rest
}: IDefaultItemRendererProps) => (
  <DropdownItemRendererWrapper
    selected={checked}
    extraPadding={isChild || isChildOption(option)}
    {...rest}
  >
    <div className={``}>
      <input
        type="checkbox"
        onChange={onClick}
        checked={checked}
        tabIndex={-1}
        disabled={disabled}
        hidden
      />
      {option.twoLines ? (
        option.label
          .split(uniqString)
          .map((item) => <div key={item}>{item}</div>)
      ) : (
        <span>{option.label}</span>
      )}
    </div>
  </DropdownItemRendererWrapper>
)

const DropdownItemRendererWrapper = styled("div", {
  color: "$inputText",
  paddingLeft: "$10",

  variants: {
    extraPadding: {
      true: {
        paddingLeft: "40px",
      },
    },
    selected: {
      true: {
        color: "$gray",
      },
    },
  },
})
