import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const ThreeDotsIcon = ({
  color = "currentColor",
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || size}
    height={width || size}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.222 6.444a2.222 2.222 0 1 1 0-4.444 2.222 2.222 0 0 1 0 4.444Zm0 7.778a2.222 2.222 0 1 1 0-4.444 2.222 2.222 0 0 1 0 4.444ZM10 19.778a2.222 2.222 0 1 0 4.444 0 2.222 2.222 0 0 0-4.444 0Z"
    />
  </svg>
)
