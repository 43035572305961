import React, { PropsWithChildren } from "react"
import { styled } from "src/stitches.config"

interface Props {
  action?: Function
}

const OverflowMenuItemWrapper = styled("button", {
  display: "block",
  width: "100%",
  padding: "$5",
  margin: "0",
  textAlign: "left",
  border: "none",
  borderRadius: "6px",
  background: "none",

  variants: {
    hasHoverState: {
      true: {
        "&:hover": {
          backgroundColor: "$grayLight",
        },
      },
    },
  },
})

export function OverflowMenuItem({
  action,
  children,
}: PropsWithChildren<Props>) {
  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()

    if (action) {
      action()
    }
  }

  return (
    <OverflowMenuItemWrapper
      as={!!action ? "button" : "div"}
      hasHoverState={!!action}
      onClick={handleClick}
    >
      {children}
    </OverflowMenuItemWrapper>
  )
}
