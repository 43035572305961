import Link from "next/link"
import React, { ReactElement } from "react"
import { IParentLink } from "src/services"
import { adminTheme, styled } from "src/stitches.config"

import { IconShadower } from "../icon-shadower"

const ParentLinkWrapper = styled("div", {
  marginBottom: "$40",

  "@mdDown": {
    marginBottom: "$20",
  },

  a: {
    display: "inline-flex",
    alignItems: "center",

    span: {
      marginLeft: "$20",
    },
  },

  "a:hover, a:focus": {
    textDecoration: "underline",

    svg: {
      opacity: 0.75,
    },
  },

  variants: {
    light: {
      true: {
        color: "$white",

        "&:hover, &:focus": {
          a: {
            color: "$white",
          },
        },
      },
      false: {
        color: "$blue",
      },
    },
    green: {
      true: {
        color: "$bodyGreen",

        "&:hover, &:focus": {
          a: {
            color: "$bodyGreen",
          },
        },
      },
    },
  },
})

export const ParentLink = (props: IParentLink): ReactElement => (
  <ParentLinkWrapper
    light={props.light || false}
    green={props.green || false}
    data-cy="parent-link"
  >
    <Link href={props.route}>
      <IconShadower
        name="arrowDiagonal"
        width="1.5em"
        height="1.5em"
        fill={
          props.green ? adminTheme.colors.mediumGreen.toString() : undefined
        }
      />
      <span data-cy="parent-link-title">{props.title}</span>
    </Link>
  </ParentLinkWrapper>
)
