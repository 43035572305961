import { merge } from "lodash"
import { IHomepage } from "src/services"

import { createImage } from "./image"
import { createLinkCard } from "./linkCard"

const defaults: IHomepage = {
  title: "Welcome to our website",
  subtitle: "Hope you enjoy",
  body: "This is the body",
  image: createImage(),
  linkCards: [createLinkCard()],
  quote: {
    title: "A quote",
    content: "The author",
  },
  seo: {
    title: "The seo page title",
    description: "The seo page description",
    noIndex: false,
  },
}

export const createHomePage = (override?: Partial<IHomepage>): IHomepage =>
  merge({}, defaults, override)
