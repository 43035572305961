import React, { ReactElement } from "react"
import { IFooterContactItem } from "src/services"
import { styled } from "src/stitches.config"

import { PageWidthContainer } from "../../page-width-container"
import { H2, P } from "../../typography"
import { FooterContactItem } from "./footer-contact-item"

interface Props {
  title: string
  description: string
  items: IFooterContactItem[]
}

const FooterContactWrapper = styled("div", {
  padding: "$md 0",
  backgroundColor: "$white",

  "@mdUp": {
    padding: "$lg 0",
  },
})

const FooterContactItems = styled("div", {
  width: "calc(100% + 2px)",
  marginLeft: "-1px",

  "@mdUp": {
    display: "flex",
    alignItems: "stretch",
  },
})

export const FooterContact = (props: Props): ReactElement => (
  <FooterContactWrapper data-cy="footer-contact">
    <PageWidthContainer>
      <H2 as="p">{props.title}</H2>

      <FooterContactItems>
        {props.items.map((item) => {
          return <FooterContactItem key={item.id.toString()} {...item} />
        })}
      </FooterContactItems>
    </PageWidthContainer>
  </FooterContactWrapper>
)
