import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const DrawingVariantFlatIcon = ({
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Icon/PDF</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(3.000000, 1.400000)">
        <path
          d="M0.736214605,9.34285714 L11.4113264,9.34285714 C11.8179265,9.34285714 12.147541,9.67544561 12.147541,10.0857143 L12.147541,20.8571429 C12.147541,21.2674115 11.8179265,21.6 11.4113264,21.6 L0.736214605,21.6 C0.329614506,21.6 0,21.2674115 0,20.8571429 L0,10.0857143 C0,9.67544561 0.329614506,9.34285714 0.736214605,9.34285714 Z"
          fill="#5076AF"
        ></path>
        <path
          d="M11.9992805,0.0463401969 C12.1980538,0.120612132 12.3548377,0.277792788 12.4289221,0.477069116 L18.5439407,16.9255763 C18.6849244,17.3048018 18.4925673,17.7268039 18.1142991,17.8681443 C18.0326324,17.8986593 17.9461802,17.9142857 17.8590256,17.9142857 L12.8907194,17.9142857 L12.8908076,9.43893793 C12.8908076,9.05262512 12.5926288,8.73613196 12.2144185,8.70815802 L12.1598676,8.70614807 L8,8.70590989 L11.0590919,0.477069116 C11.2000756,0.097843627 11.6210124,-0.0950002788 11.9992805,0.0463401969 Z"
          fill="#00378A"
        ></path>
      </g>
    </g>
  </svg>
)
