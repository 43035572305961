import appConfig from "src/config"

// Default Netherlands location
export const DEFAULT_MAP_CENTER = {
  lat: 52.2129919,
  lng: 5.2793703,
} as const

export const GOOGLE_MAPS_KEY = appConfig.googleApiKey

type Libraries = (
  | "drawing"
  | "geometry"
  | "localContext"
  | "places"
  | "visualization"
)[]

export const GOOGLE_LIBS: Libraries = ["places"]
