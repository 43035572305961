import { IIcon } from "@types"
import { ReactElement, ReactSVG } from "react"

export const UserIcon = ({
  fill = "#FFFFFF",
}: IIcon): ReactElement<ReactSVG> => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15">
    <g fill={fill}>
      <path d="M10 .75A4.244 4.244 0 0 1 14.25 5 4.244 4.244 0 0 1 10 9.25 4.244 4.244 0 0 1 5.75 5 4.244 4.244 0 0 1 10 .75zm0 1.75A2.497 2.497 0 0 0 7.5 5c0 1.383 1.117 2.5 2.5 2.5s2.5-1.117 2.5-2.5-1.117-2.5-2.5-2.5zM10 11c3.068 0 9.204 1.461 9.25 3.75l-.114.122C15.32 13.623 12.275 13 10 13c-2.275 0-5.32.624-9.136 1.873a2.66 2.66 0 0 1-.114-.122C.796 12.461 6.917 11 10 11z" />
    </g>
  </svg>
)
