import { IAssetsIconProps } from "@types"
import { ReactElement, ReactSVG } from "react"
import { iconSizes } from "src/constants"

export const LocationIcon = ({
  color = "currentColor",
  size = iconSizes.regular,
  width,
  height,
  backgroundColor = "#FFFFFF",
}: IAssetsIconProps): ReactElement<ReactSVG> => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M12.91891,21.7954467 C17.712419,18.1096267 20.2637577,14.6329 20.5729259,11.3652666 C21.0559993,6.25961048 17.4440758,2 12.335847,2 C7.22761824,2 3.52379135,6.2464801 4.04975232,11.3652666 C4.38630013,14.640636 6.93578032,18.1169493 11.6981929,21.7942067 L11.6981885,21.7942124 C12.0576172,22.0717424 12.5589208,22.0722492 12.91891,21.7954467 Z"
        fill={color}
      ></path>
      <circle fill={backgroundColor} cx="12.25" cy="10.25" r="3.5"></circle>
    </g>
  </svg>
)
