import React from "react"
import { IImage } from "src/services"
import { styled } from "src/stitches.config"
import getAbsoluteSrc from "src/utils/get-absolute-src"

const ImageWrapper = styled("div", {
  "@mdDown": {
    "&:not(:last-child)": {
      marginBottom: "$20",
    },
  },

  "@mdUp": {
    flex: "1 1 auto",
    padding: "0 $20",
  },

  img: {
    maxWidth: "100%",
  },

  figure: {
    margin: 0,
  },

  figcaption: {
    paddingTop: "$10",
    fontFamily: "$grotesk",
    fontSize: "$bodySmall",
    lineHeight: "1.25",
  },
})

const Img = styled("div", {
  display: "block",
  position: "relative",

  img: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },

  variants: {
    orientation: {
      landscape: {
        width: "100%",
        paddingBottom: "75%",
      },
      portrait: {
        width: "100%",
        paddingBottom: "133.33333333%",
      },
    },
  },
})

export const Image = (image: IImage) => {
  let src: string = image.url
  if (!image.url) return null

  const orientation = image.width >= image.height ? "landscape" : "portrait"
  const css = {
    flex: `1 1 ${orientation === "landscape" ? "62.225%" : "36.775%"}`,
  }

  return (
    <ImageWrapper css={css}>
      <figure>
        <Img orientation={orientation}>
          <img src={getAbsoluteSrc(src)} alt={image.alternativeText} />
        </Img>

        {image.caption && image.caption.length !== 0 ? (
          <figcaption>{image.caption}</figcaption>
        ) : null}
      </figure>
    </ImageWrapper>
  )
}
