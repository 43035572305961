import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from "@tanstack/react-query"
import { accommodationService } from "src/services"
import { AccommodationPassport } from "src/services/v2/accommodation"

export function useAccommodationPassport(
  id: string,
  options?: UseQueryOptions,
): UseQueryResult<AccommodationPassport> {
  const queryFn = () => accommodationService.getPassportById(id)

  return useQuery(["accommodation-passport", id], queryFn, {
    initialData: options?.initialData,
  })
}
