import { ReactElement } from "react"
import { styled } from "src/stitches.config"

import { IconWrapper, iconNameType } from "../icon-wrapper"

interface Props {
  fill?: string
  height?: string
  width?: string
}

const StyledIcon = styled("div", {
  borderRadius: "6px",
  boxShadow: "0 2px 8px 0 rgba(74, 74, 74, 0.1)",
  display: "inline-flex",
  justifyContent: "center",
  backgroundColor: "white",
  alignItems: "center",
  padding: "4px",
  cursor: "pointer",
  height: "$40",
  width: "$40",

  "@mdDown": {
    height: "$30",
    width: "$30",
  },
})

interface IIconShadower extends Props {
  name: iconNameType
}

/** Component which shadows the icon
 * @param {string} name
 * @param {unknown} rest - rest props
 */
export const IconShadower = ({
  name,
  ...rest
}: IIconShadower): ReactElement => (
  <StyledIcon className="shadow-icon">
    <IconWrapper name={name} {...rest} />
  </StyledIcon>
)
