import { merge } from "lodash"
import { IFooterSponsorItem } from "src/services"

const defaults: IFooterSponsorItem = {
  id: "1",
  icon: "chat",
  title: "Sponsor us",
  url: "",
}

export const createFooterSponsorItem = (
  override?: Partial<IFooterSponsorItem>,
): IFooterSponsorItem => merge({}, defaults, override)
