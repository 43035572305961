import React, { PropsWithChildren } from "react"
import { useFormContext } from "react-hook-form"
import { styled } from "src/stitches.config"

interface CheckboxProps {
  label: string
  name: string
}

const StyledCheckboxContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  marginLeft: "$20",
  textAlign: "center",
  alignSelf: "center",
})

const StyledCheckbox = styled("input", {
  marginRight: "$10",
  marginTop: "4px",
})

const StyledCheckboxLabel = styled("label", {
  margin: 0,
})

export const Checkbox = (props: PropsWithChildren<CheckboxProps>) => {
  const { register } = useFormContext()
  const { label, name } = props

  return (
    <StyledCheckboxContainer>
      <StyledCheckbox
        data-cy="form-input-draft"
        type="checkbox"
        {...register(name)}
        id={name}
      />
      <StyledCheckboxLabel htmlFor={name}>{label}</StyledCheckboxLabel>
    </StyledCheckboxContainer>
  )
}
