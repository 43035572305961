import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const PlusIcon = ({
  color = "currentColor",
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
    version="1.1"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(1.000000, 1.000000)">
          <path
            d="M9,2.2 C9,0.98497355 9.8954306,0 11,0 C12.1045694,0 13,0.98497355 13,2.2 L13,19.8 C13,21.0150263 12.1045694,22 11,22 C9.8954306,22 9,21.0150263 9,19.8 L9,2.2 Z"
            fill={color}
          />
          <path
            d="M2.2,13 C0.98497355,13 0,12.1045694 0,11 C0,9.8954306 0.98497355,9 2.2,9 L19.8,9 C21.0150263,9 22,9.8954306 22,11 C22,12.1045694 21.0150263,13 19.8,13 L2.2,13 Z"
            fill={color}
          />
        </g>
      </g>
    </g>
  </svg>
)
