import uniqBy from "lodash.uniqby"
import { KvKMappedSearchResult } from "src/pages/api/kvk/search"
import {
  AccommodationData,
  AccommodationDataMapped,
  DisciplineCertificates,
  IImplementedSportProduct,
} from "src/services"
import { v4 as uuid } from "uuid"

import { isUnionUser } from "./has-permissions"

const getUniqueSports = (
  implementedSportProduct: IImplementedSportProduct,
  externalId?: string,
) => {
  const sports = [
    ...new Map(
      implementedSportProduct.disciplineCertificates.map((certificate) => [
        certificate.discipline.sport.code,
        certificate.discipline.sport,
      ]),
    ).values(),
  ]
  if (externalId) {
    return sports
      .filter((sport) => isUnionUser(externalId, sport.association.users))
      .map((sport) => sport)
  }
  return sports
}

export const uniqString = " <wELeAz,69eXW#hN,r@ "
/**
 *
 *
 * @param {{ displayName: string; email: string; id: string }} item
 * @return {*}  {string} returns the user label that cointains the displayName and email as well as uniq string to separate the users
 */
const createLable = (item: {
  displayName: string
  email: string
  id: string
}): string => {
  return `${item.displayName ?? item.id}${uniqString}${item.email}`
}
const transformers = {
  manufacturer: (item: any) => {
    return {
      id: item?.id.toString(),
      value: item?.id.toString(),
      label: item?.name,
      main: true,
    }
  },
  contractor: (item: any) => {
    return {
      id: item?.id.toString(),
      value: item?.id.toString(),
      label: item?.name,
      main: true,
    }
  },
  consultant: (item: any) => {
    return {
      id: item?.id.toString(),
      value: item?.id.toString(),
      label: item?.name,
      main: true,
    }
  },
  institute: (item: any) => {
    return {
      id: item?.id.toString(),
      value: item?.id.toString(),
      label: item?.name,
      main: true,
    }
  },
  discipline: (item: any) => {
    return { id: item?.code, value: item?.code, label: item?.name, main: true }
  },
  location: (item: any) => {
    return {
      id: item?.label,
      value: item?.label,
      label: item?.label,
      main: true,
    }
  },
  municipality: (item: any) => {
    return {
      id: item?.id,
      label: item?.name,
      value: JSON.stringify(item),
      main: true,
    }
  },
  sportProduct: (item: any) => {
    return { id: item?.id, value: item?.id, label: item?.name, main: true }
  },
  sportProductType: (item: any) => {
    return { id: item?.id, value: item?.id, label: item?.name, main: true }
  },
  sportProductSubtype: (item: any) => {
    return { id: item?.id, value: item?.id, label: item?.name, main: true }
  },
  productStatus: (item: any) => {
    return {
      id: item?.id || null,
      value: item?.id || null,
      label: item?.name || null,
      main: true,
    }
  },
  systemStatus: (item: any) => {
    return { id: item?.id, value: item?.id, label: item?.name, main: true }
  },
  sustainabilityLabel: (item: any) => {
    return { id: item?.id, value: item?.id, label: item?.label, main: true }
  },
  notes: (item: any) => {
    return { id: item?.id, content: item?.content, createdAt: item?.createdAt }
  },
  sportClubs: (item: any) => {
    return {
      id: item?.id,
      value: item?.id,
      label: `${item?.name}-${item.city}`,
      main: true,
    }
  },
  kvkResult: (item: KvKMappedSearchResult) => {
    if (!item.address) {
      console.log("No address found for", item)
    }

    const newItem = {
      ...item,
      address: item.address?.domestic ||
        item.address?.foreign || {
          street: "",
          city: "",
        },
    }

    const city =
      item.address?.domestic?.city || item.address?.foreign?.city || ""

    return {
      id: uuid(),
      value: JSON.stringify(newItem),
      label: `${item.name}${city && ", " + city}`,
      main: true,
    }
  },
  misc: (item: any) => {
    return [
      {
        id: item.id || uuid(),
        value: JSON.stringify(item),
        label: `${item.name}, ${item.city}`,
        main: true,
      },
    ]
  },
  user: (item: any) => {
    return {
      id: item?.id,
      value: item?.id,
      label: item?.displayName || item?.id,
      main: true,
    }
  },
  users: (item: any) => {
    return {
      id: item?.id,
      value: item?.id,
      label: createLable(item),
      main: true,
    }
  },
  organizationType: (item: any) => {
    return { id: item, label: item, value: item }
  },
  accommodation: (
    accommodation: AccommodationData,
    externalId?: string,
  ): AccommodationDataMapped => {
    return {
      ...accommodation,
      implementedSportProducts: accommodation.implementedSportProducts
        .map((implementedSportProduct) => {
          const sports = getUniqueSports(
            implementedSportProduct,
            externalId,
          ).map((s) => {
            return {
              ...s,
              disciplineCertificates: uniqBy(
                implementedSportProduct.disciplineCertificates.map(
                  (disciplineCertificate) => {
                    const { sport, sportClubs, ...rest } =
                      disciplineCertificate.discipline
                    const sportClubsInDiscipline = sportClubs.map(
                      (sportClub) => sportClub.name,
                    )

                    const sportClubsInImplementedSportProduct =
                      implementedSportProduct.sportClubs.map(
                        (sportClub) => sportClub.name,
                      )
                    const sportClubsStrings =
                      sportClubsInImplementedSportProduct
                        .filter((sportClub) =>
                          sportClubsInDiscipline.includes(sportClub),
                        )
                        .map((sportClub) => sportClub)

                    const disciplineCertificateCopy = {
                      ...disciplineCertificate,
                      discipline: { ...rest, sportClubsStrings },
                    }
                    if (disciplineCertificate.discipline.sport.code === s.code)
                      return { ...disciplineCertificateCopy }
                  },
                ),
                (d) => d?.discipline.code,
              ).filter(Boolean) as DisciplineCertificates,
            }
          })
          return {
            sportProductName: implementedSportProduct.sportProduct.name,
            name: accommodation.name,
            id: implementedSportProduct.id,
            inspectionReportUrl: implementedSportProduct.inspectionReport.url,
            sports: sports,
          }
        })
        .filter(Boolean),
    }
  },
}

const filters = {
  manufacturer: (transformed: any) => (original: any) => {
    if (!transformed || !original) return
    return transformed?.id.toString() === original.id.toString()
  },
  contractor: (transformed: any) => (original: any) => {
    if (!transformed || !original) return
    return transformed?.id.toString() === original.id.toString()
  },
  consultant: (transformed: any) => (original: any) => {
    if (!transformed || !original) return
    return transformed?.id.toString() === original.id.toString()
  },
  testInstitute: (transformed: any) => (original: any) => {
    if (!transformed || !original) return
    return transformed?.id.toString() === original.id.toString()
  },
  discipline: (transformed: any) => (original: any) => {
    if (!transformed || !original) return
    return transformed?.id === original.code
  },
  location: (transformed: any) => (original: any) => {
    if (!transformed || !original) return
    return transformed?.id === original.label
  },
  sportClubs: (transformed: any) => (original: any) => {
    if (!transformed || !original) return
    return transformed?.id === original.id
  },
  sportProduct: (transformed: any) => (original: any) => {
    if (!transformed || !original) return
    return transformed?.id === original.id
  },
  sportProductType: (transformed: any) => (original: any) => {
    if (!transformed || !original) return
    return transformed?.id === original.id
  },
  sportProductSubtype: (transformed: any) => (original: any) => {
    if (!transformed || !original) return
    return transformed?.id === original.id
  },
  productStatus: (transformed: any) => (original: any) => {
    if (!transformed || !original) return
    return transformed?.id === original.id
  },
  systemStatus: (transformed: any) => (original: any) => {
    if (!transformed || !original) return
    return transformed?.id === original.id
  },
  sustainabilityLabel: (transformed: any) => (original: any) => {
    if (!transformed || !original) return
    return transformed?.id === original.id
  },
  kvkResult: (transformed: any) => (original: any) => {
    if (!transformed || !original) return
    return transformed?.id === original.id
  },
  user: (transformed: any) => (original: any) => {
    if (!transformed || !original) return
    return transformed?.id === original.id
  },
  organizationType: (transformed: any) => (original: any) => {
    if (!transformed || !original) return
    return transformed?.id === original.label
  },
}

export { transformers, filters }
