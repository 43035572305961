import { styled } from "src/stitches.config"

export const NavigationWrap = styled("div", {
  display: "flex",
  alignItems: "center",
  alignSelf: "flex-end",

  [`& > *:not(:first-child)`]: {
    marginLeft: "$40",
  },
})

export const MobileNavigationWrap = styled(NavigationWrap, {
  [`& > *:last-child`]: {
    marginRight: "$20",
  },

  "@mdUp": {
    display: "none",
  },
})

export const NonMobileNavigationWrap = styled(NavigationWrap, {
  "@mdDown": {
    display: "none",
  },
})
