import Head from "next/head"
import { ReactElement } from "react"
import { useIntl } from "react-intl"
import {
  ErrorMessage,
  H1,
  PageWidthContainer,
  PublicLayout,
  StyledPageHeader,
} from "src/components"
import { createError } from "src/factories"
import { getTitleForStatusCode } from "src/utils/get-title-for-status-code"

interface ErrorPageProps {
  statusCode: number
  errorMessage: string
}

export default function Error(props: ErrorPageProps): ReactElement {
  const { statusCode, errorMessage } = props
  const { formatMessage } = useIntl()

  const error = createError(statusCode, {
    title: formatMessage({
      id: `errorPage.${statusCode}.title`,
      defaultMessage: getTitleForStatusCode(statusCode),
    }),
    description: formatMessage({
      id: `errorPage.${statusCode}.message`,
      defaultMessage: JSON.stringify(errorMessage, null, 2),
    }),
  })

  return (
    <PublicLayout>
      <Head>
        <title>{`${statusCode ? statusCode : ""} ${error.title}`}</title>
      </Head>

      <PageWidthContainer>
        <StyledPageHeader>
          <H1 data-cy="err-code">{error.title}</H1>
        </StyledPageHeader>
      </PageWidthContainer>

      <PageWidthContainer>
        <ErrorMessage {...error} />
      </PageWidthContainer>
    </PublicLayout>
  )
}
