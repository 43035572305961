import React from "react"
import { styled } from "src/stitches.config"

import { Button, icons } from "../../button"
import { HorizontalFlex, VerticalFlex } from "../../flex"
import { Grid } from "../../grid"
import { GridItem } from "../../grid-item"
import { PageWidthContainer } from "../../page-width-container"
import { H2, P } from "../../typography"

interface InfrastructurePageHeaderInactiveComponentProps {
  hideRestore?: boolean
  onConfirm: () => void
  title: string
  subtitle: string
  confirmButtonLabel: string
  isLoading?: boolean
  iconType?: keyof typeof icons
  isSecondary?: boolean
}

const Container = styled("div", {
  height: "190px",
  position: "relative",
  backgroundColor: "transparent",
  marginBottom: "-1.5%",

  "@mdDown": {
    height: "145px",
  },
})

const Background = styled("span", {
  position: "absolute",
  width: "100%",
  height: "100%",
  background: "radial-gradient(circle, #FFFFFF 0%, #F0F0F0 100%)",
  boxShadow: "0 0 8px 0 rgba(74,74,74,0.1)",
  transform: "rotate(.7deg)",
  transformOrigin: "bottom right",
  zIndex: 1,
  variants: {
    isSecondary: {
      true: {
        background: "linear-gradient(to right, #FAC9A9 0%, #F0F0F0 100%)",
      },
    },
  },
})

const Content = styled("div", {
  position: "absolute",
  width: "100%",
  height: "100%",
  zIndex: 2,
  paddingTop: "50px",
  paddingBottom: "60px",

  "@mdDown": {
    paddingTop: "20px",
    paddingBottom: "20px",
  },

  [H2.toString()]: {
    color: "$gray",
  },

  [`${HorizontalFlex} > ${VerticalFlex}`]: {
    paddingRight: "20px",
  },
})

const RestoreButtonContainer = styled("div", {
  textAlign: "right",

  "@lgDown": {
    display: "none",
  },
})

export const InfrastructurePageHeaderInactiveComponent = ({
  hideRestore,
  onConfirm,
  title,
  subtitle,
  confirmButtonLabel,
  isLoading,
  iconType,
  isSecondary,
}: InfrastructurePageHeaderInactiveComponentProps) => {
  return (
    <Container>
      <Background isSecondary={isSecondary} />

      <Content>
        <PageWidthContainer>
          <Grid>
            <GridItem col={[1, 9]} medium={[1, 12]}>
              <VerticalFlex>
                <H2>{title}</H2>
                <P css={{ color: "$colors$gray" }}>{subtitle}</P>
              </VerticalFlex>
            </GridItem>

            <GridItem col={[11, 2]}>
              <RestoreButtonContainer>
                {!hideRestore && (
                  <Button
                    variant="secondary"
                    icon={iconType}
                    iconColor="white"
                    isLoading={isLoading}
                    onClick={onConfirm}
                    data-cy="approve-button"
                  >
                    {confirmButtonLabel}
                  </Button>
                )}
              </RestoreButtonContainer>
            </GridItem>
          </Grid>
        </PageWidthContainer>
      </Content>
    </Container>
  )
}
