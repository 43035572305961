import { z } from "zod"

export const certificationProcessCreateNoteSchema = z.object({
  content: z.string().min(1).max(255),
})

export type CertificationProcessCreateNote = z.infer<
  typeof certificationProcessCreateNoteSchema
>

export type CertificationProcessNote = {
  id: string
  content: string
  createdAt: string
}

export type CertificationProcessNoteList = Array<CertificationProcessNote>
