import { styled } from "src/stitches.config"

export const TypeButton = styled("button", {
  width: "72px",
  height: "52px",
  margin: "$10",
  padding: "0",
  textAlign: "center",
  color: "$body",
  fontSize: "$bodySmall",
  border: "none",
  borderRadius: "4px",
  backgroundColor: "$lightestGreen",
  appearance: "none",

  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  "&:not(:last-child)": {
    marginRight: "0",
  },
})
