import React, { PropsWithChildren } from "react"
import ReactModal from "react-modal"
import { OVERLAY_ZINDEX } from "src/constants"
import { globalCss, styled } from "src/stitches.config"

import { OVERLAY_BG_COLOR } from "../../overlay"
import { H4 } from "../../typography"

interface ModalProps {
  title?: string
  isOpen: boolean
  onCancelClick?: () => void
  okDisabled?: boolean
  headerContent?: React.ReactNode
  footerContent?: React.ReactNode
  ref?: any
  paddingSm?: boolean
}

// forwardRef has trouble finding out the component also accepted children without this
interface ModalPropsWithChildren extends PropsWithChildren<ModalProps> {}

const Title = styled(H4, {
  color: "$darkGreen",
  padding: "$40 $40 $20",
})

const ModalContent = styled("div", {
  padding: "$80",

  variants: {
    paddingSm: {
      true: {
        padding: "$40",
      },
    },
  },

  "@mdDown": {
    padding: "$40",
  },

  "@smDown": {
    padding: "$20 15px",
  },
})

// required to override content styling with media query
const overrideModalContentClass = globalCss({
  ".ReactModal__Content": {
    inset: "10% 0 0 0 !important",
    padding: "0 !important",
    width: "100%",
    height: "85vh",
    maxWidth: "$pageContainer",

    "@mdUp": {
      margin: "0 auto !important",
      width: "100%",
    },
  },
})()

export const ModalBase = ({
  title,
  isOpen,
  onCancelClick,
  headerContent,
  footerContent,
  paddingSm,
  children,
  ...rest
}: ModalPropsWithChildren) => (
  <ReactModal
    {...rest}
    isOpen={isOpen}
    onRequestClose={onCancelClick}
    shouldCloseOnOverlayClick
    style={{
      overlay: { backgroundColor: OVERLAY_BG_COLOR, zIndex: OVERLAY_ZINDEX },
    }}
    contentLabel={title}
  >
    {headerContent ? headerContent : <Title as="p">{title}</Title>}
    <ModalContent paddingSm={paddingSm}>{children}</ModalContent>
    {footerContent}
  </ReactModal>
)
