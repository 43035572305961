import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const CheckmarkIcon = ({
  color = "currentColor",
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
    version="1.1"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(1.000000, 1.500000)">
          <path
            d="M20.9952785,0.325067768 C21.9640509,0.929870014 22.2801217,2.18544775 21.7323007,3.17528963 L21.6746311,3.27335409 L11.2464891,19.9940268 C11.092831,20.240405 10.8985073,20.443481 10.6764848,20.6024514 C10.2151517,20.9309799 9.64078056,21.0638394 9.08239671,20.9711848 C8.84377532,20.9303758 8.61538258,20.8500894 8.40659438,20.7350722 L8.3038774,20.6746913 L0.706539703,14.0498933 C-0.171005531,13.2602616 -0.239628698,11.9074426 0.548484358,11.0282064 C1.30056939,10.1891639 2.56443921,10.0862487 3.44113247,10.7666642 L7.03362255,14.0083237 C7.24013009,14.1946222 7.48301723,14.3404392 7.74892787,14.4357618 C8.28277025,14.624158 8.87144187,14.5932475 9.38270491,14.3499742 C9.6289062,14.2320469 9.85547362,14.0658651 10.0494982,13.8499638 L18.1460564,0.867686675 C18.8007911,-0.0238958403 20.0417188,-0.270224736 20.9952785,0.325067768 Z"
            fill={color}
          ></path>
        </g>
      </g>
    </g>
  </svg>
)
