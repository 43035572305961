import { IAgendaItem } from "src/services"
import { styled } from "src/stitches.config"
import formatDate from "src/utils/format-date"
import getAbsoluteSrc from "src/utils/get-absolute-src"

import { Button } from "../button"
import { Calendar } from "../calendar"
import { Grid } from "../grid"
import { GridItem } from "../grid-item"
import { Markdown } from "../markdown"
import { H4, P } from "../typography"

interface Props {
  agendaItem: IAgendaItem
}

const AgendaItemWrapper = styled("div", {
  marginBottom: "$40",
  padding: "$30",
  backgroundColor: "$white",

  "@mdDown": {
    width: "calc(100% + $30)",
    marginLeft: "-$15",
    padding: "$15",
  },
})

const CalendarWrapper = styled("div", {
  maxWidth: "$80",
})

const ContentRow = styled("div", {
  display: "flex",
  margin: "0 0 $10",
})

const ContentRowLabel = styled("div", {
  flex: "0 0 85px",
})

const ContentRowValue = styled("div", {
  flex: "1 1 auto",

  "ul li, ol li": {
    marginBottom: "$10",
  },
})

const ContentAttachments = styled("div", {
  paddingTop: "$20",
})

const ContentAttachment = styled("div", {
  paddingTop: "$10",

  "a, a:hover, a:focus": {
    textDecoration: "none",
  },
})

export const AgendaItem = ({ agendaItem }: Props) => (
  <AgendaItemWrapper>
    <Grid columns={6} gutter="$sizes$15">
      <GridItem col={[1, 1]}>
        <CalendarWrapper>
          <Calendar date={agendaItem.dateFrom} />
        </CalendarWrapper>
      </GridItem>

      <GridItem data-cy="agenda-item" col={[2, 5]}>
        <H4 data-cy="agenda-item-topic">{agendaItem.topic}</H4>

        <P>
          {formatDate(agendaItem.dateFrom, "HH:mm")}
          {agendaItem.dateTo &&
            ` tot ${formatDate(agendaItem.dateTo, "HH:mm")}`}{" "}
          uur
          {agendaItem.location && ` - ${agendaItem.location}`}
        </P>

        {agendaItem.theme && (
          <ContentRow data-cy="agenda-item-theme">
            <ContentRowLabel>
              <P noMargin>Thema:</P>
            </ContentRowLabel>
            <ContentRowValue>
              <P noMargin bold size="large">
                {agendaItem.theme}
              </P>
            </ContentRowValue>
          </ContentRow>
        )}

        <ContentRow data-cy="agenda-item-agenda">
          <ContentRowLabel>
            <P>Agenda:</P>
          </ContentRowLabel>
          <ContentRowValue>
            <Markdown>{agendaItem.content}</Markdown>
          </ContentRowValue>
        </ContentRow>

        {agendaItem.attachments && (
          <ContentAttachments>
            {agendaItem.attachments.map((attachment) => {
              return (
                <ContentAttachment key={attachment.id}>
                  <a
                    href={getAbsoluteSrc(attachment.file.url)}
                    target="_blank"
                    download
                  >
                    <Button
                      icon="download"
                      variant="secondary"
                      size="large"
                      outline
                    >
                      {attachment.buttonText}
                    </Button>
                  </a>
                </ContentAttachment>
              )
            })}
          </ContentAttachments>
        )}
      </GridItem>
    </Grid>
  </AgendaItemWrapper>
)
