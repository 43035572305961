import { useMutation, useQueryClient } from "@tanstack/react-query"
import { toast } from "react-hot-toast"
import { implementedSportProductService } from "src/services"
import { ImplementedSportProduct } from "src/services/v2/implemented-sport-product"

export function useDeleteImplementedSportProduct(id: string) {
  const queryClient = useQueryClient()

  async function mutateFn() {
    return await implementedSportProductService.delete(id)
  }

  return useMutation(mutateFn, {
    onSuccess: (data: ImplementedSportProduct) => {
      toast.success(`${data.sportProduct.name} verwijderd`)

      queryClient.refetchQueries([
        "accommodation",
        data.accommodationId,
        "implemented-sport-products",
      ])
    },
  })
}
