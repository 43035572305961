import { useMemo } from "react"
import { Dropdown } from "src/components"
import { useIspAvailableDisciplines } from "src/services/v2/disciplines"

interface Props {
  name: string
  ispId: string
}

export function DisciplinesDropdown({ name, ispId }: Props) {
  const { data: availableDisciplines = [], isLoading } =
    useIspAvailableDisciplines(ispId)

  const options = useMemo(() => {
    return availableDisciplines.map((availableDiscipline) => ({
      value: availableDiscipline.code,
      label: availableDiscipline.name,
    }))
  }, [availableDisciplines])

  return <Dropdown name={name} isLoading={isLoading} options={options} />
}
