import React, { ReactNode } from "react"

import { ContentBoxItem } from "../../content-box-item"
import { Message } from "../../message"

interface Props {
  buttonLabel?: ReactNode | string
  onClick?: Function
}

export const CreateSubsection = ({
  onClick,
  buttonLabel = <Message id="admin.createSubsection.button.add" />,
}: Props) => {
  return (
    <ContentBoxItem
      buttonLabel={buttonLabel}
      onClick={onClick}
      datacy="create-subsection"
    />
  )
}
