import {
  UseQueryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import { toast } from "react-hot-toast"
import { implementedSportProductService } from "src/services"
import {
  ImplementedSportProduct,
  ImplementedSportProductCreate,
} from "src/services/v2/implemented-sport-product"

export function useCreateImplementedSportProduct(
  accId: string,
  options?: UseQueryOptions,
) {
  const queryClient = useQueryClient()

  function mutateFn(data: ImplementedSportProductCreate) {
    return implementedSportProductService.create(accId, data)
  }

  return useMutation(mutateFn, {
    onSuccess: (data: ImplementedSportProduct) => {
      toast.success(`Sportproduct "${data.sportProduct.name}" toegevoegd`)

      if (options?.onSuccess) {
        options?.onSuccess(data)
      }

      queryClient.refetchQueries([
        "accommodation",
        data.accommodationId,
        "implemented-sport-products",
      ])
    },
  })
}
