import * as Popover from "@radix-ui/react-popover"
import React, { PropsWithChildren } from "react"
import { styled } from "src/stitches.config"

export type PopOverSide = "top" | "right" | "left" | "bottom"

interface PopOverProps {
  trigger: React.ReactNode
  close: React.ReactNode
  side?: PopOverSide
  hideArrow?: boolean
  disabled?: boolean
}

const StyledContent = styled(Popover.Content, {
  padding: "20px",
  fontSize: 14,
  backgroundColor: "white",
  boxShadow: "$default",
  maxWidth: "250px",
  overflowWrap: "break-word",
  zIndex: 10,
})

const StyledArrow = styled(Popover.Arrow, {
  fill: "white",
})

const StyledClose = styled(Popover.Close, {
  border: "none",
  backgroundColor: "transparent",
  outline: "none",
  display: "flex",
  padding: "0",

  "& > div": {
    marginRight: "$10",
  },
})

const StyleTooltipTrigger = styled(Popover.Trigger, {
  border: "none",
  backgroundColor: "transparent",
  padding: 0,
})

export const PopOver = (props: PropsWithChildren<PopOverProps>) => {
  const {
    trigger,
    children,
    side = "right",
    close,
    hideArrow,
    disabled,
  } = props

  return (
    <Popover.Root>
      <StyleTooltipTrigger disabled={disabled}>{trigger}</StyleTooltipTrigger>
      <StyledContent side={side} sideOffset={10}>
        {children}
        <StyledClose>{close}</StyledClose>
        {!hideArrow && <StyledArrow />}
      </StyledContent>
    </Popover.Root>
  )
}
