import { ReactElement, ReactSVG } from "react"

export const NOCNSFIcon = (): ReactElement<ReactSVG> => (
  <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60">
    <title>NOCNSF/Atom/Logo/NOCNSF</title>
    <defs>
      <filter id="a">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g transform="translate(1.32 9.442)" filter="url(#a)" fill="none">
      <path
        d="M51.46 0c4.022 0 5.531 2.85 5.895 4.988l.042.28.029.27.017.259.005.244V44.5c0 4.06-2.824 5.582-4.944 5.949l-.279.042-.135.016-.262.023a5.865 5.865 0 0 1-.127.007l-.242.006H5.99c-4.023 0-5.532-2.85-5.896-4.988l-.042-.281a6.683 6.683 0 0 1-.016-.137l-.023-.264-.01-.252L0 6.041C0 2.928 1.662 1.436 3.056.733a7.044 7.044 0 0 1 2.095-.66l.26-.034.115-.013.197-.016.203-.01h45.533zm0 1.17-45.544.002-.198.013-.22.023-.173.024-.195.033-.21.043a6.293 6.293 0 0 0-.112.025l-.231.06-.243.074-.25.089c-1.308.496-2.825 1.63-2.918 4.229l-.005.256.002 38.534.012.2.023.22.024.177.033.196.042.213.025.112.06.234.073.244c.44 1.37 1.538 3.098 4.28 3.198l.253.005 45.543-.002.199-.013.219-.022.174-.025.298-.053.219-.048.231-.06.12-.035.247-.081c1.335-.47 2.949-1.594 3.046-4.277l.004-.256-.002-38.534-.012-.2-.023-.221-.024-.176-.032-.196-.043-.213a6.444 6.444 0 0 0-.025-.112l-.06-.234a6.181 6.181 0 0 0-.035-.12l-.08-.25c-.48-1.388-1.65-3.075-4.493-3.075z"
        fill="#ED6B06"
      />
      <path
        d="M21.863 14.142c0-3.306-2.653-5.983-5.93-5.983-3.275 0-5.928 2.677-5.928 5.983 0 3.305 2.653 5.985 5.927 5.985.178 0 .347-.009.515-.024a6.957 6.957 0 0 1 .004-1.003 4.852 4.852 0 0 1-.519.028c-2.73 0-4.942-2.23-4.942-4.986 0-2.753 2.213-4.984 4.942-4.984 2.732 0 4.946 2.231 4.946 4.984a4.985 4.985 0 0 1-3.408 4.741 5.33 5.33 0 0 0-.05.743c0 .104.002.205.006.309 2.554-.669 4.437-3.009 4.437-5.793"
        fill="#0079C1"
      />
      <path
        d="M47.561 14.142c0 3.305-2.65 5.985-5.929 5.985-3.096 0-5.642-2.4-5.908-5.459.354.038.696.111 1.02.22.358 2.4 2.41 4.24 4.888 4.24 2.729 0 4.941-2.23 4.941-4.986 0-2.753-2.212-4.984-4.94-4.984-2.627 0-4.776 2.064-4.93 4.676a6.127 6.127 0 0 0-.983-.168c.244-3.08 2.8-5.507 5.912-5.507 3.279 0 5.93 2.677 5.93 5.983"
        fill="#E52643"
      />
      <path
        d="M40.143 19.935c-.16 2.61-2.308 4.68-4.936 4.68-2.727 0-4.943-2.234-4.943-4.988a4.985 4.985 0 0 1 3.406-4.74c.037-.244.054-.49.054-.745a8.74 8.74 0 0 0-.007-.307c-2.554.668-4.437 3.008-4.437 5.792 0 3.304 2.656 5.984 5.927 5.984 3.116 0 5.672-2.425 5.914-5.508a5.6 5.6 0 0 1-.978-.168"
        fill="#009540"
      />
      <path
        d="M35.207 14.642c2.478 0 4.532 1.838 4.889 4.242.325.108.669.181 1.02.216-.263-3.058-2.81-5.455-5.91-5.455a6.48 6.48 0 0 0-.513.02c.016.159.023.317.023.477 0 .177-.011.354-.023.527.168-.019.342-.027.514-.027"
        fill="#009540"
      />
      <path
        d="M28.27 20.103c-.242 3.083-2.793 5.508-5.912 5.508-3.272 0-5.927-2.68-5.927-5.984 0-2.784 1.886-5.124 4.437-5.792a4.996 4.996 0 0 1-.046 1.053 4.98 4.98 0 0 0-3.402 4.739c0 2.754 2.211 4.987 4.938 4.987 2.628 0 4.775-2.07 4.935-4.68.316.085.646.141.977.169"
        fill="#F9B21E"
      />
      <path
        d="M21.843 14.669a5.4 5.4 0 0 0 .02-.528c0-.159-.003-.316-.016-.476a6 6 0 0 1 .51-.02c3.104 0 5.645 2.397 5.911 5.455a4.736 4.736 0 0 1-1.021-.216c-.352-2.403-2.408-4.242-4.89-4.242-.171 0-.346.008-.514.027"
        fill="#F9B21E"
      />
      <path
        d="M28.786 19.13c-2.481 0-4.532-1.843-4.89-4.242a4.903 4.903 0 0 0-1.022-.219c.266 3.057 2.809 5.457 5.912 5.457.17 0 .339-.009.51-.023A6.842 6.842 0 0 1 29.3 19.1c-.168.018-.34.03-.514.03"
        fill="#1D1D1B"
      />
      <path
        d="M28.786 8.159c-3.117 0-5.672 2.427-5.913 5.506.335.03.66.087.977.17.16-2.612 2.306-4.678 4.936-4.678 2.727 0 4.938 2.232 4.938 4.985a4.983 4.983 0 0 1-3.403 4.741c-.035.243-.057.49-.057.743 0 .103.003.205.01.309a5.977 5.977 0 0 0 4.442-5.793c0-3.306-2.655-5.983-5.93-5.983"
        fill="#1D1D1B"
      />
      <path fill="#E1001A" d="M9.876 30.361h37.697v-1.434H9.876z" />
      <path fill="#00378A" d="M9.876 33.233h37.697v-1.435H9.876z" />
      <path
        d="M9.898 36.426h1.504l1.952 3.494h.015v-3.494h1.377v5.67h-1.504l-1.95-3.534h-.017v3.533h-.504a.876.876 0 0 1-.873-.878v-4.791zM18.304 36.307c1.772 0 2.772 1.31 2.772 2.954 0 1.644-1 2.954-2.772 2.954-1.77 0-2.77-1.31-2.77-2.954 0-1.645 1-2.954 2.77-2.954zm0 1.27c-.943 0-1.306.842-1.306 1.684s.363 1.684 1.306 1.684c.945 0 1.306-.842 1.306-1.684s-.361-1.684-1.306-1.684zM25.602 38.451c-.08-.572-.52-.873-1.135-.873-.943 0-1.306.841-1.306 1.682 0 .843.363 1.685 1.306 1.685.685 0 1.08-.398 1.135-1.048h1.069c.217 0 .386.199.343.416-.24 1.206-1.231 1.902-2.514 1.902-1.7 0-2.803-1.31-2.803-2.955 0-1.644 1.103-2.953 2.803-2.953 1.075 0 2.23.612 2.505 1.704a.351.351 0 0 1-.339.44h-1.064zM39.388 40.19c.032.667.44.882 1.063.882.442 0 .897-.16.897-.58 0-.501-.803-.595-1.613-.826-.804-.23-1.645-.596-1.645-1.637 0-1.238 1.236-1.722 2.29-1.722.984 0 1.973.423 2.201 1.397a.35.35 0 0 1-.34.43h-1.074c.024-.517-.456-.684-.905-.684-.315 0-.708.111-.708.485 0 .436.81.516 1.63.746.809.23 1.628.612 1.628 1.636 0 1.437-1.212 1.898-2.447 1.898-1.162 0-2.203-.463-2.406-1.615a.35.35 0 0 1 .345-.41h1.084zM43.497 36.426h4.14v.302c0 .486-.39.881-.873.881H44.96v1.16h2.314v1.143H44.96v2.183h-.591a.876.876 0 0 1-.873-.878v-4.791zM32.398 36.426h1.504l1.952 3.494h.016v-3.494h1.377v5.67h-1.503l-1.952-3.534h-.016v3.533h-.505a.876.876 0 0 1-.873-.878v-4.791zM29.684 37.25l.45 1.398h1.456l-1.18.862.45 1.399-1.176-.865-1.178.865.45-1.399-1.178-.862h1.455z"
        fill="#1D1D1B"
      />
    </g>
  </svg>
)
