import AsyncSelect from "react-select/async"
import { TKVKData } from "src/hooks/useKVKSearch"

interface Props {
  name: string
  placeholder: string
  loadOptions: (query: string) => Promise<any>
  handleChange: (value: any) => void
  defaultValues?: TKVKData
  isMulti?: boolean
  value?: { label: string; value: string }
}

export const AsyncDropdown = ({
  name,
  placeholder,
  defaultValues = [],
  handleChange,
  loadOptions,
  isMulti = false,
  value,
}: Props) => {
  return (
    <AsyncSelect
      isMulti={isMulti}
      cacheOptions
      defaultOptions
      loadOptions={loadOptions}
      name={name}
      onChange={handleChange}
      placeholder={placeholder}
      defaultValue={defaultValues}
      value={value}
      menuPortalTarget={document.querySelector("body")}
      noOptionsMessage={() => "Geen opties"}
      loadingMessage={() => "Laden..."}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          minHeight: "44px",
        }),
        menuPortal: (baseStyles) => ({
          ...baseStyles,
          zIndex: 10,
        }),
      }}
    />
  )
}
