import { useCallback, useContext, useMemo, useState } from "react"
import { iconSizes } from "src/constants"
import { EditHandbookContext } from "src/context/edit-handbook-context"
import useTimeout from "src/hooks/useTimeout"
import { styled } from "src/stitches.config"
import { getRuleTemplate } from "src/templates"

import { RuleIcon, TextIcon } from "../../assets-icons"
import { Button } from "../../button"
import { TypeButton, TypeButtonsContainer } from "../../forms"
import { Message } from "../../message"

interface Props {
  insert: Function
  insertAtIndex: number
  forceVisibility?: boolean
}

const CreateRuleWrapper = styled("div", {
  position: "relative",
  textAlign: "center",
})

const CreateRuleToggle = styled("div", {
  display: "flex",
  alignItems: "center",
  width: "100%",
  margin: "$10 0",
  textAlign: "center",
  transition: "opacity .25s ease",

  variants: {
    visible: {
      false: {
        opacity: "0",

        "&:hover": {
          opacity: "1",
        },
      },
    },
  },
})

const ToggleButton = styled("button", {
  display: "flex",
  alignItems: "center",
  whiteSpace: "nowrap",
  border: "none",
  background: "none",
  appearance: "none",

  "> span": {
    marginLeft: "$10",
    fontSize: "$bodySmall",
  },
})

const DashedLine = styled("div", {
  flex: "1 1 50%",
  borderTop: "1px dashed $colors$secondary",

  "&:first-child": {
    marginRight: "$10",
  },

  "&:last-child": {
    marginLeft: "$10",
  },
})

export const CreateRule = ({
  insert,
  insertAtIndex,
  forceVisibility = false,
}: Props) => {
  const { handbookType } = useContext(EditHandbookContext)
  const [isTypeButtonsContainerVisible, setIsTypeButtonsContainerVisible] =
    useState(false)
  const [hideTypeButtons, stopHideTypeButtons] = useTimeout(() => {
    setIsTypeButtonsContainerVisible(false)
  }, 500)

  const isPrecept = useMemo(() => handbookType === "precept", [handbookType])

  const handleToggleClick = useCallback(() => {
    setIsTypeButtonsContainerVisible(!isTypeButtonsContainerVisible)
  }, [isTypeButtonsContainerVisible])

  const handleAddRuleClick = useCallback(() => {
    insert(insertAtIndex, { ...getRuleTemplate() })
    setIsTypeButtonsContainerVisible(false)
  }, [insertAtIndex, insert, isPrecept])

  const handleAddTextClick = useCallback(() => {
    insert(insertAtIndex, {
      ...getRuleTemplate(),
      ruleType: isPrecept ? null : "S",
    })
    setIsTypeButtonsContainerVisible(false)
  }, [insertAtIndex, insert])

  return (
    <CreateRuleWrapper>
      <CreateRuleToggle
        visible={isTypeButtonsContainerVisible || forceVisibility}
      >
        <DashedLine />
        <ToggleButton
          type="button"
          onClick={handleToggleClick}
          data-cy="create-rule-toggle"
        >
          <Button size="xsmall" as="div" icon="plus" variant="secondary" />
          <span>
            <Message id="admin.createRule.button.add" />
          </span>
        </ToggleButton>
        <DashedLine />
      </CreateRuleToggle>

      {(isTypeButtonsContainerVisible || forceVisibility) && (
        <TypeButtonsContainer
          onMouseEnter={stopHideTypeButtons}
          onMouseLeave={hideTypeButtons}
        >
          {isPrecept && (
            <TypeButton
              type="button"
              onClick={handleAddRuleClick}
              data-cy="create-rule"
            >
              <RuleIcon size={iconSizes.large} />
              <Message id={`admin.createRule.button.rule.label`} />
            </TypeButton>
          )}

          <TypeButton
            type="button"
            onClick={handleAddTextClick}
            data-cy="create-text-rule"
          >
            <TextIcon size={iconSizes.large} />
            <Message id="admin.createRule.button.text.label" />
          </TypeButton>
        </TypeButtonsContainer>
      )}
    </CreateRuleWrapper>
  )
}
