import { merge } from "lodash"
import { ILinkCard } from "src/services"

const defaults: ILinkCard = {
  id: 123,
  title: "Card title",
  description: "Card description with something amazing to tell",
  link: {
    href: "#",
    text: "Linking somewhere over the rainbows",
  },
}

export const createLinkCard = (override?: Partial<ILinkCard>): ILinkCard =>
  merge({}, defaults, override)
