import {
  UseQueryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import { useCallback } from "react"
import { toast } from "react-hot-toast"
import api, { SubscriptionArgs } from "src/services/api"

export const useSubscribe = (
  subscribed: boolean,
  options?: Partial<UseQueryOptions>,
) => {
  const queryClient = useQueryClient()

  const mutate = useCallback(
    (subscription: SubscriptionArgs) => {
      return subscribed
        ? api.subscription.unsubscribe(subscription)
        : api.subscription.subscribe(subscription)
    },
    [subscribed],
  )

  return useMutation(mutate, {
    onSuccess: () => {
      if (options?.onSuccess) {
        options.onSuccess({})
      }

      queryClient.invalidateQueries(["userSubscriptions"])
    },
    onError: (error) => {
      toast.error(String(error))
    },
  })
}
