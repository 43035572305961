import { useCallback, useState } from "react"
import { styled } from "src/stitches.config"

import {
  Button,
  TButtonIconColors,
  TButtonSizes,
  TButtonVariants,
  icons,
} from "../../button"
import { PopoverConfirmation } from "../../pop-over-confirmation"

const ConfirmationButtonWrapper = styled("div", {
  marginLeft: "$20",
  flexDirection: "column",
  justifyContent: "center",
})

interface Props {
  onClick: () => void
  onFail?: () => void
  onSuccess?: () => void
  isDisabled?: boolean
  messageTitle: string
  confirmationMessage: string
  confirmationButtonLabel: string
  confirmationButtonIconColor?: TButtonIconColors
  confirmationButtonVariant?: TButtonVariants
  buttonIcon: keyof typeof icons
  buttonVariant: TButtonVariants
  buttonSize?: TButtonSizes
}

export const ConfirmationButton = ({
  onClick,
  onSuccess,
  onFail,
  isDisabled,
  messageTitle,
  confirmationMessage,
  confirmationButtonLabel,
  confirmationButtonIconColor = "variant",
  confirmationButtonVariant = "primary",
  buttonIcon,
  buttonVariant,
  buttonSize = "regular",
}: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = useCallback(async () => {
    try {
      setIsLoading(true)
      onClick()
      onSuccess?.()
    } catch (err) {
      console.error(err)
      onFail?.()
    } finally {
      setIsLoading(false)
    }
  }, [])

  if (isDisabled) {
    return null
  }

  return (
    <ConfirmationButtonWrapper>
      <PopoverConfirmation
        onConfirm={handleClick}
        title={messageTitle}
        message={confirmationMessage}
        confirmIcon={buttonIcon}
        confirmButtonLabel={confirmationButtonLabel}
        confirmIconColor={confirmationButtonIconColor}
        confirmButtonVariant={confirmationButtonVariant}
      >
        <Button
          as="div"
          data-cy="confirmation-button"
          aria-label={messageTitle}
          icon={buttonIcon}
          variant={buttonVariant}
          size={buttonSize}
          isLoading={isLoading}
        />
      </PopoverConfirmation>
    </ConfirmationButtonWrapper>
  )
}
