import { ChangeEvent } from "react"
import { useIntl } from "react-intl"
import { styled } from "src/stitches.config"

const StyledInput = styled("input", {
  width: "100%",
  height: "44px",
  margin: "0",
  backgroundColor: "$white",
  borderRadius: "4px",
  borderTopStyle: "hidden",
  borderRightStyle: "hidden",
  borderLeftStyle: "hidden",
  borderBottomStyle: "hidden",
  borderWidth: "2px",
  resize: "none",
})

export const DefaultColumnFilter = ({
  column: { filterValue, setFilter },
}: any) => {
  const { formatMessage } = useIntl()

  return (
    <StyledInput
      data-cy="filter-input"
      value={filterValue || ""}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={formatMessage({ id: `defaultColumnFIlter.placeholder` })}
    />
  )
}
