import React, { Fragment, useCallback, useContext, useState } from "react"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import { EditHandbookContext } from "src/context/edit-handbook-context"
import api from "src/services/api"
import { styled } from "src/stitches.config"
import { TemplateType } from "src/templates"

import { AdditionalInformationIcon, LinkIcon } from "../../assets-icons"
import {
  EditableRuleAttachmentTextWrapper,
  EditableRuleAttachmentWrapper,
  FormIconWrapper,
  ImageAttachmentInput,
  InputWrapper,
  PdfAttachmentInput,
} from "../../edit-forms"
import {
  FormInput,
  HiddenInput,
  RemoveWithConfirmationButton,
} from "../../forms"
import { ListSelector } from "../../list-selector"
import { Modal } from "../../modal"
import { ModalButtons } from "../../modal-buttons"
import { H4, P } from "../../typography"

interface Props {
  path: string
  attachment: TemplateType
}

const Wrapper = styled("div", {
  display: "flex",
  paddingTop: "$10",
})

const AttachmentTypesWrapper = styled("div", {
  flex: "1 1 auto",
})

const RemoveButtonWrapper = styled("div", {
  flex: "0 0 $30",
  marginLeft: "$10",
  opacity: "0",
  transition: ".2s opacity linear",
  variants: {
    isVisible: {
      true: {
        opacity: "1",
      },
    },
  },
})

const EditableRuleAttachmentTableWrapper = styled("div", {
  padding: "$10",
  borderRadius: "4px",
  backgroundColor: "$grayLight",

  "& > *:not(:first-child)": {
    marginTop: 10,
  },

  textarea: {
    backgroundColor: "$white",
  },
})

const MonospaceP = styled(P, {
  fontFamily: "monospace",
  fontSize: "$bodySmall",
})

const DrawingWrap = styled("div", {
  width: "100%",
  maxWidth: "100%",
  textAlign: "center",
})

export const EditableRuleAttachment = ({ path, attachment }: Props) => {
  const { formatMessage } = useIntl()
  const editContext = useContext(EditHandbookContext)
  const { setValue, unregister } = useFormContext()
  const [showDelete, setShowDelete] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [drawingModalIsOpen, setDrawingModalIsOpen] = useState(
    attachment.type === "DRAWING" && !attachment.url,
  )
  const [selectedDrawing, setSelectedDrawing] = useState<number | undefined>()

  const handleMouseEnter = useCallback(() => setShowDelete(true), [])
  const handleMouseLeave = useCallback(
    () => setShowDelete(isFocused),
    [isFocused],
  )
  const handleFocus = useCallback(() => {
    setIsFocused(true)
    setShowDelete(true)
  }, [])
  const handleBlur = useCallback(() => {
    setIsFocused(false)
    setShowDelete(false)
  }, [])

  const handleRemoveRuleAttachmentClick = useCallback(() => {
    unregister(path)
    setSelectedDrawing(undefined)
  }, [setValue, path, setSelectedDrawing])

  const getDrawingFromSelectName = useCallback(async () => {
    if (selectedDrawing !== undefined) {
      const documentId = editContext.code
      const drawingId =
        editContext.drawings && editContext.drawings[selectedDrawing]
      const variants = drawingId
        ? await api.admin.getDrawingVariants(
            documentId,
            editContext.version,
            drawingId,
          )
        : []

      setValue(`${path}.content`, drawingId, {
        shouldValidate: true,
        shouldDirty: true,
      })
      setValue(`${path}.url`, variants, {
        shouldValidate: true,
        shouldDirty: true,
      })
      setSelectedDrawing(undefined)
    }
  }, [selectedDrawing, setValue, path, editContext])

  const handleCancelClick = useCallback(() => {
    setDrawingModalIsOpen(false)
    setSelectedDrawing(undefined)
    handleRemoveRuleAttachmentClick()
  }, [
    setDrawingModalIsOpen,
    setSelectedDrawing,
    handleRemoveRuleAttachmentClick,
  ])

  return (
    <Wrapper
      data-cy="rule-attachment"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      <AttachmentTypesWrapper>
        {attachment.type === "TEXT" && (
          <EditableRuleAttachmentTextWrapper>
            <FormIconWrapper>
              <AdditionalInformationIcon />
            </FormIconWrapper>

            {/* prettier-ignore */}
            <InputWrapper>
              <FormInput name={`${path}.content`} defaultValue={attachment.content} displayAs={P} italic trimLineBreaks placeholder={formatMessage({id: 'admin.editRule.additionalInfo.placeholder'})} />
            </InputWrapper>
          </EditableRuleAttachmentTextWrapper>
        )}

        {attachment.type === "LINK" && (
          <EditableRuleAttachmentWrapper>
            <FormIconWrapper>
              <LinkIcon />
            </FormIconWrapper>

            {/* prettier-ignore */}
            <InputWrapper>
              <FormInput name={`${path}.title`} defaultValue={attachment.title} displayAs={P} italic trimLineBreaks placeholder={formatMessage({id: 'admin.editRule.linkText.placeholder'})} />
              <FormInput name={`${path}.url`} defaultValue={attachment.url} displayAs={P} italic trimLineBreaks placeholder='https://' />
            </InputWrapper>
          </EditableRuleAttachmentWrapper>
        )}

        {attachment.type === "TABLE" && (
          <Fragment>
            {/* prettier-ignore */}
            <EditableRuleAttachmentTableWrapper>
              <FormInput name={`${path}.title`} defaultValue={attachment.title} displayAs={H4} italic trimLineBreaks placeholder={formatMessage({id: 'admin.editRule.tableTitle.placeholder'})} />
              <FormInput name={`${path}.content`} defaultValue={attachment.content} displayAs={MonospaceP} italic helpText="Voor het starten van een titel kolom: [Titel]" />
              <FormInput name={`${path}.caption`} defaultValue={attachment.caption} displayAs={P} italic placeholder={formatMessage({id: 'admin.editRule.tableDescription.placeholder'})} />
            </EditableRuleAttachmentTableWrapper>
          </Fragment>
        )}

        {attachment.type === "IMAGE" && <ImageAttachmentInput path={path} />}

        {attachment.type === "PDF" && <PdfAttachmentInput path={path} />}

        {attachment.type === "DRAWING" &&
          (drawingModalIsOpen ? (
            <Modal
              isOpen={drawingModalIsOpen}
              title={formatMessage({ id: "admin.editRule.addDrawing" })}
              onCancelClick={handleCancelClick}
              footerContent={
                <ModalButtons
                  onOkClick={() => {
                    getDrawingFromSelectName()
                    setDrawingModalIsOpen(false)
                  }}
                  onCancelClick={handleCancelClick}
                  okButtonLabel={formatMessage({
                    id: "admin.editRule.addDrawing",
                  })}
                  okDisabled={selectedDrawing === undefined}
                />
              }
            >
              <ListSelector
                title={`${editContext.code}: ${formatMessage({
                  id: "admin.editRule.selectDrawing",
                })}`}
                items={editContext.drawings || []}
                onChange={setSelectedDrawing}
                selected={selectedDrawing}
              />
            </Modal>
          ) : (
            <EditableRuleAttachmentWrapper>
              <HiddenInput
                name={`${path}.content`}
                defaultValue={attachment.content}
              />
              <InputWrapper>
                <DrawingWrap>
                  <img
                    src={attachment.url?.regular}
                    width="100%"
                    height="100%"
                    alt={attachment.title}
                  />
                </DrawingWrap>
                <FormInput
                  name={`${path}.title`}
                  defaultValue={attachment.title}
                  displayAs={P}
                  italic
                  trimLineBreaks
                  placeholder={formatMessage({
                    id: "admin.editRule.drawingTitle.placeholder",
                  })}
                  autoFocus
                />
              </InputWrapper>
            </EditableRuleAttachmentWrapper>
          ))}
      </AttachmentTypesWrapper>
      <RemoveButtonWrapper
        isVisible={showDelete}
        data-cy="remove-attachment-button"
      >
        <RemoveWithConfirmationButton
          onConfirm={handleRemoveRuleAttachmentClick}
        />
      </RemoveButtonWrapper>
    </Wrapper>
  )
}
