import { IDropdownOption } from "@types"
import { useCallback } from "react"
import { useIntl } from "react-intl"

export default function useIntlOption() {
  const { formatMessage } = useIntl()

  const intlOption = useCallback(
    (option: string | IDropdownOption): string | IDropdownOption => {
      if (typeof option === "string") {
        return formatMessage({
          id: `select.option.${option}.label`,
          defaultMessage: option,
        })
      }

      return {
        ...option,
        label: !!option?.label
          ? formatMessage({
              id: `select.option.${option.label}.label`,
              defaultMessage: option.label,
            })
          : "",
      }
    },
    [],
  )

  return intlOption
}
