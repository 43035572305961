import { createContext, useContext } from "react"
import { IDisciplineData, IOrganization, TUser } from "src/services"

const AdminContext = createContext({
  manufacturers: [],
  contractors: [],
  consultants: [],
  institutes: [],
  disciplines: [],
  locations: [],
  municipalities: [],
  sportProducts: [],
  sportProductTypes: [],
  sportProductProductStatuses: [],
  sportProductSystemStatuses: [],
  systemStatuses: [],
  sustainabilityLabels: [],
  sportProductSubtypes: [],
  users: [],
})

export interface IAdminContext {
  manufacturers: IOrganization[]
  contractors: IOrganization[]
  consultants: IOrganization[]
  institutes: IOrganization[]
  disciplines: IDisciplineData[]
  locations: any[]
  municipalities: any[]
  sportProducts: any[]
  sportProductTypes: any[]
  sportProductProductStatuses: any[]
  sportProductSystemStatuses: any[]
  systemStatuses: any[]
  sustainabilityLabels: any[]
  sportProductSubtypes: any[]
  users: TUser[]
}

export function AdminContextProvider(props: any) {
  return <AdminContext.Provider {...props} />
}

export function useAdminContext(): IAdminContext {
  const context = useContext(AdminContext)
  return context
}
