import React, { ReactNode } from "react"
import { styled } from "src/stitches.config"

import { Button } from "../button"

interface Props {
  buttonLabel: ReactNode | string
  onClick?: Function
  alwaysVisible?: boolean
  datacy?: string
}

const CreateWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  width: "100%",
  margin: "0 0 $10",
  textAlign: "center",

  variants: {
    alwaysVisible: {
      false: {
        transition: "opacity .25s ease",
        opacity: "0",

        "&:hover": {
          opacity: "1",
        },
      },
    },
  },
})

const CreateButton = styled("button", {
  display: "flex",
  alignItems: "center",
  whiteSpace: "nowrap",
  border: "none",
  background: "none",
  appearance: "none",

  "> span": {
    marginLeft: "$10",
    fontSize: "$bodySmall",
  },
})

const DashedLine = styled("div", {
  flex: "1 1 50%",
  borderTop: "1px dashed $colors$secondary",

  "&:first-child": {
    marginRight: "$10",
  },

  "&:last-child": {
    marginLeft: "$10",
  },
})

export const ContentBoxItem = ({
  onClick,
  buttonLabel,
  alwaysVisible = false,
  datacy,
  ...rest
}: Props) => {
  function handleClick() {
    if (onClick) {
      onClick()
    }
  }

  return (
    <CreateWrapper alwaysVisible={alwaysVisible}>
      <DashedLine />
      <CreateButton
        type="button"
        onClick={handleClick}
        data-cy={datacy}
        {...rest}
      >
        <Button as="div" icon="plus" variant="secondary">
          {buttonLabel}
        </Button>
      </CreateButton>
      <DashedLine />
    </CreateWrapper>
  )
}
