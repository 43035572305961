import { createStitches } from "@stitches/react"

const screenSizes = {
  // all in px
  xs: 360,
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
}

const { globalCss, styled, keyframes, getCssText, createTheme } =
  createStitches({
    theme: {
      colors: {
        black: "#4a4a4a",
        white: "#ffffff",
        fadedWhite: "#f0f1f7",
        orange: "#eb5e00",
        orangeLight: "#FFB280",
        orangeHighlight: "#FF6600",
        blueLight: "#809bc4",
        blueHighlight: "#5076AF",
        blueHover: "#f1f3f5",
        purpleLight: "#d8e1ed",
        blue: "#00378a",
        headerBlue: "#5076af",
        gray: "#919291",
        grayLight: "#f0f0f0",
        grayLightest: "#f9f9fb",
        red: "#e1001a",
        redCherry: "#9D0012",
        shadow: "#4a4a4a",
        disabled: "rgba(74,74,74,0.3)",

        // admin
        bodyGreen: "#31493d",
        lightGreen: "#cbd4c2",
        lightestGreen: "#f1f4ef",
        mediumGreen: "#94a286",
        darkGreen: "#31493d",

        body: "$black",
        heading: "$blue",
        primary: "$orange",
        secondary: "$blue",
        tertiary: "$headerBlue",
        background: "$fadedWhite",

        // forms
        inputText: "$blue",
        inputBorder: "$blue",
        inputHover: "$blueHover",
      },
      shadows: {
        default: "0 2px 8px rgba(74, 74, 74, 0.1)",
        inset: "inset 0 2px 8px rgba(74, 74, 74, 0.1)",
      },
      space: {
        sm: "10px",
        md: "20px",
        lg: "40px",
        xl: "80px",
        xxl: "160px",
        5: "5px",
        10: "10px",
        15: "15px",
        20: "20px",
        30: "30px",
        40: "40px",
        60: "60px",
        70: "70px",
        80: "80px",
        95: "95px",
      },
      sizes: {
        sm: "10px",
        md: "20px",
        lg: "40px",
        xl: "80px",
        xxl: "160px",
        5: "5px",
        10: "10px",
        15: "15px",
        20: "20px",
        30: "30px",
        40: "40px",
        60: "60px",
        70: "70px",
        80: "80px",
        95: "95px",
        pageContainer: "1150px",
        pageContainerWide: "1400px",
      },
      fonts: {
        grotesk: "HK Grotesk",
        nova: "HK Nova",
      },
      fontSizes: {
        body: ".875rem",
        bodyExtraLarge: "1.25rem",
        bodyLarge: "1rem",
        bodySmall: ".75rem",
        bodyExtraSmall: ".625rem",
      },
      fontWeights: {
        light: 300,
        regular: 400,
        medium: 500,
        bold: 700,
      },
      lineHeights: {
        body: ".875rem",
        bodyExtraLarge: "1.25rem",
        bodyLarge: "1rem",
        bodySmall: ".75rem",
        bodyExtraSmall: ".625rem",
      },
    },
    media: {
      xsDown: `(max-width: ${screenSizes.xs}px)`,
      smDown: `(max-width: ${screenSizes.s}px)`,
      smUp: `(min-width: ${screenSizes.s + 1}px)`,
      mdDown: `(max-width: ${screenSizes.m}px)`,
      mdUp: `(min-width: ${screenSizes.m + 1}px)`,
      lgDown: `(max-width: ${screenSizes.l}px)`,
      lgUp: `(min-width: ${screenSizes.l + 1}px)`,
      xlDown: `(max-width: ${screenSizes.xl}px)`,
      xlUp: `(min-width: ${screenSizes.xl + 1}px)`,
    },
  })

const adminTheme = createTheme({
  colors: {
    bodyGreen: "#31493d",
    lightGreen: "#cbd4c2",
    lightestGreen: "#f1f4ef",
    mediumGreen: "#94a286",
    darkGreen: "#31493d",

    body: "$bodyGreen",
    heading: "$darkGreen",
    secondary: "$darkGreen",
    tertiary: "$mediumGreen",
    background: "$lightGreen",

    // forms
    inputText: "$darkGreen",
    inputBorder: "$darkGreen",
    inputHover: "$lightestGreen",
  },
})

const lightOnDark = createTheme({
  colors: {
    body: "$white",
    heading: "$white",
  },
})

export {
  globalCss,
  styled,
  keyframes,
  getCssText,
  adminTheme,
  lightOnDark,
  screenSizes,
}
