import * as Popover from "@radix-ui/react-popover"
import React from "react"
import { iconSizes } from "src/constants"
import { IInfrastructureRuleAttachment } from "src/services"
import { styled } from "src/stitches.config"

import { AdditionalInformationIcon } from "../../assets-icons"

const RuleAttachmentTextWrapper = styled("div", {
  position: "absolute",
  top: "0",
  right: "0",
  transform: "translateX(100%)",
  zIndex: "2",
})

const RuleAttachmentTextIcon = styled("div", {
  position: "relative",
  width: "$30",
  height: "$30",
  marginTop: "-2px",
  textAlign: "center",
  color: "$blueLight",
  cursor: "pointer",

  borderRadius: "0 4px 4px 0",
  backgroundColor: "$white",
  boxShadow: "$default",
  transition: "color .25s ease",

  "&:hover": {
    color: "$blue",
  },

  // Hides shadow on the left side
  "&::after": {
    position: "absolute",
    top: "-5px",
    bottom: "-5px",
    left: "-5px",
    width: "5px",
    content: "''",
    backgroundColor: "$white",
  },
})

const RuleAttachmentTextContent = styled("div", {
  position: "absolute",
  top: "-$40",
  left: "$30",
  width: "240px",
  padding: "$10",
  fontSize: "12px",
  color: "$blueHighlight",

  backgroundColor: "$background",
  transition: "opacity .25s ease",
  pointerEvents: "none",

  "@lgDown": {
    left: "auto",
    right: "$30",
    backgroundColor: "$white",
  },
})

const StyledTooltipTrigger = styled(Popover.Trigger, {
  border: "none",
  backgroundColor: "transparent",
  padding: 0,
})

const StyledContent = styled(Popover.Content, {
  padding: "10px",
  fontSize: "12px",
  backgroundColor: "transparent",
  maxWidth: "250px",
})

const StyledRoot = styled(Popover.Root, {
  position: "relative",
  zIndex: 2,
})

export const RuleAttachmentText = (props: IInfrastructureRuleAttachment) => (
  <RuleAttachmentTextWrapper data-cy="text-attachment">
    <StyledRoot>
      <StyledTooltipTrigger aria-label="tooltip">
        <RuleAttachmentTextIcon data-cy="toggle-button">
          <AdditionalInformationIcon size={iconSizes.xlarge} />
        </RuleAttachmentTextIcon>
      </StyledTooltipTrigger>
      <StyledContent>
        <RuleAttachmentTextContent data-cy="content">
          {props.content}
        </RuleAttachmentTextContent>
      </StyledContent>
    </StyledRoot>
  </RuleAttachmentTextWrapper>
)
