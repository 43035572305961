import getConfig from "next/config"

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig() || {}

const appConfig = {
  ...serverRuntimeConfig,
  ...publicRuntimeConfig,
}

export default appConfig
