import { ReactElement, useCallback } from "react"
import { styled } from "src/stitches.config"

import { AlertIcon } from "../icons"

interface Props {
  checked: boolean
  onChange: Function
  small?: boolean
  positive?: boolean
  disabled?: boolean
  subscriptionStyle?: boolean
}

const ToggleWrapper = styled("div", {
  display: "block",
  height: "30px",

  label: {
    margin: "0",
  },

  input: {
    display: "none",
  },

  variants: {
    small: {
      true: {
        height: "20px",
      },
    },
  },
})

const ToggleContainer = styled("div", {
  display: "inline-block",
  position: "relative",
  height: "30px",
  width: "60px",
  cursor: "pointer",

  borderRadius: "15px",
  backgroundColor: "$gray",
  transition: "background-color 0.15s ease",
  boxShadow: "$inset",

  "input:disabled ~ &": {
    opacity: "0.5",
    pointerEvents: "none",
  },

  variants: {
    isChecked: {
      true: {
        backgroundColor: "$red",
      },
    },
    small: {
      true: {
        height: "20px",
        width: "30px",
      },
    },
    positive: {
      true: {
        backgroundColor: "$lightestGreen",
      },
    },
    subscriptionStyle: {
      true: {},
    },
  },
  compoundVariants: [
    {
      isChecked: true,
      positive: true,
      css: {
        backgroundColor: "$mediumGreen",
      },
    },
    {
      isChecked: true,
      subscriptionStyle: true,
      css: {
        backgroundColor: "$blueHighlight",
      },
    },
  ],
})

const ToggleIndicator = styled("div", {
  position: "absolute",
  top: "5px",
  left: "5px",
  width: "20px",
  height: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  backgroundColor: "#fff",
  transition: "transform 0.15s ease",
  boxShadow: "$default",

  variants: {
    isChecked: {
      true: {
        transform: "translateX(30px)",
      },
    },
    small: {
      true: {
        height: "10px",
        width: "10px",
      },
    },
  },
  compoundVariants: [
    {
      isChecked: true,
      small: true,
      css: {
        transform: "translateX(10px)",
      },
    },
  ],
})

/** Component toggle
 * @param {boolean} checked
 * @param {Function} onChange
 * @param {boolean} small
 * @param {boolean} positive
 * @param {boolean} disabled
 * @param {boolean} subscriptionStyle - indicates if component should be styled for notifications
 */
export const Toggle = ({
  checked,
  onChange,
  small = false,
  positive = false,
  disabled = false,
  subscriptionStyle = false,
}: Props): ReactElement => {
  const handleChange = useCallback(() => {
    onChange(!checked)
  }, [onChange, checked])

  return (
    <ToggleWrapper data-cy="toggle" small={small}>
      <label>
        <input
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
        />

        <ToggleContainer
          isChecked={checked}
          small={small}
          positive={positive}
          subscriptionStyle={subscriptionStyle}
        >
          <ToggleIndicator isChecked={checked} small={small}>
            <AlertIcon fill={checked ? "#5076AF" : "#919291"} />
          </ToggleIndicator>
        </ToggleContainer>
      </label>
    </ToggleWrapper>
  )
}
