import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const ArrowRightSmallOrangeIcon = ({
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 12 12"
    version="1.1"
  >
    <defs>
      <linearGradient
        y1="4.8"
        id="LinearGradient"
        x1="0"
        y2="4.8"
        x2="8.62142"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(1 0 0 1 -807 -29)"
      >
        <stop stopColor="#9d0012" offset="0" />
        <stop stopColor="#e1001a" offset="1" />
      </linearGradient>
    </defs>
    <g>
      <g opacity="1">
        <path
          d="M-1177-881L103-881L103-881L103+800L103+800L-1177+800L-1177+800L-1177-881L-1177-881Z"
          opacity="1"
          fill="#f0f1f7"
        />
        <g opacity="1">
          <path
            d="M-807-29L103-29L103-29L103+41L103+41L-807+41L-807+41L-807-29L-807-29Z"
            opacity="1"
            fill="url(#LinearGradient)"
          />
          <g opacity="1">
            <g opacity="1">
              <path
                d="M-2-6L14-6C16.2091-6+18-4.20914+18-2L18+14C18+16.2091+16.2091+18+14+18L-2+18C-4.20914+18-6+16.2091-6+14L-6-2C-6-4.20914-4.20914-6-2-6Z"
                opacity="1"
                fill="#000000"
              />
              <path
                d="M-2-6L14-6C16.2091-6+18-4.20914+18-2L18+14C18+16.2091+16.2091+18+14+18L-2+18C-4.20914+18-6+16.2091-6+14L-6-2C-6-4.20914-4.20914-6-2-6Z"
                opacity="1"
                fill="#ffffff"
              />
            </g>
            <path
              d="M6.28611+8.6296C6.62468+8.29103+6.90361+8.01209+7.1229+7.79281C7.34219+7.57352+7.67112+7.24459+8.10969+6.80601L2.30626+6.80601C1.944+6.80601+1.65+6.51201+1.65+6.14975C1.65+5.7875+1.944+5.49349+2.30626+5.49349L8.10969+5.49349L6.28611+3.66991C6.02951+3.41331+6.02951+2.9979+6.28611+2.74196C6.5427+2.48601+6.95812+2.48536+7.21406+2.74196L10.1579+5.68578C10.2183+5.74615+10.2668+5.81834+10.3003+5.89841C10.3666+6.05919+10.3666+6.24032+10.3003+6.4011C10.2681+6.47854+10.2209+6.55073+10.1579+6.61373L7.21406+9.55755C6.95746+9.81415+6.54205+9.81415+6.28611+9.55755C6.03016+9.30095+6.02951+8.88554+6.28611+8.6296Z"
              opacity="1"
              fill="#ff6600"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
