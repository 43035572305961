import { iconSizes } from "src/constants"

import { ChevronDownIcon, ChevronUpIcon } from "../assets-icons"

export const DropdownArrow = ({
  expanded,
  size = iconSizes.bigger,
}: {
  expanded?: boolean
  size?: string
}) => {
  const ArrowIcon = expanded ? ChevronUpIcon : ChevronDownIcon

  return <ArrowIcon size={size} data-cy="filterDropdownArrow" />
}
