import React from "react"
import { styled } from "src/stitches.config"

import {
  BackgroundSVGNotTech,
  BackgroundSVGTech,
  ForegroundSVGNotTech,
  ForegroundSVGTech,
} from "./fragments"

const InfrastructurePageHeaderVisualWrapper = styled("div", {
  position: "absolute",
  top: "0",
  right: "0",
  bottom: "0",
  left: "0",
  pointerEvents: "none",
})

const InfrastructurePageHeaderVisualLayer = styled("div", {
  position: "absolute",
  top: "0",
  right: "0",
  bottom: "0",
  left: "0",

  svg: {
    width: "100%",
    height: "calc(100% + $sizes$10)",
  },
})

interface Props {
  isSportTechnisch?: boolean
}

export const InfrastructurePageHeaderVisual = ({ isSportTechnisch }: Props) => (
  <InfrastructurePageHeaderVisualWrapper>
    <InfrastructurePageHeaderVisualLayer>
      {isSportTechnisch ? <ForegroundSVGTech /> : <ForegroundSVGNotTech />}
    </InfrastructurePageHeaderVisualLayer>

    <InfrastructurePageHeaderVisualLayer>
      {isSportTechnisch ? <BackgroundSVGTech /> : <BackgroundSVGNotTech />}
    </InfrastructurePageHeaderVisualLayer>
  </InfrastructurePageHeaderVisualWrapper>
)
