import Head from "next/head"
import Link from "next/link"
import { ReactElement } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import {
  Button,
  Grid,
  GridItem,
  H1,
  IconWrapper,
  Markdown,
  Message,
  P,
  PageWidthContainer,
  PublicLayout,
  StyledPageContent,
  StyledPageHeader,
  StyledPageLead,
} from "src/components"
import { IErrorPage } from "src/services"
import { styled } from "src/stitches.config"

const GoToLink = styled("div", {
  a: {
    color: "$blue",
    display: "inline-flex",
    alignItems: "center",

    span: {
      marginLeft: "0",
    },

    [Button.toString()]: {
      width: "40px",
      height: "40px",
      marginLeft: "$20",
      svg: {
        width: "30px",
      },
    },
  },

  "&:hover, &:focus": {
    svg: {
      opacity: 0.75,
    },
  },
})

const SignupButton = styled(Button, {
  marginTop: "$md",
})

export default function PaymentRequired(props: IErrorPage): ReactElement {
  const intl = useIntl()
  const signupUrl = intl.formatMessage({ id: "navigation.signupLink.url" })

  return (
    <PublicLayout>
      <Head>
        <title>{props.title}</title>
      </Head>
      <PageWidthContainer>
        <StyledPageHeader>
          <H1>{props.title}</H1>
        </StyledPageHeader>
      </PageWidthContainer>
      <PageWidthContainer>
        <Grid>
          <GridItem col={[2, 10]} medium={[1, 12]}>
            <StyledPageContent
              noPadding
              data-cy="page-content"
              css={{ "@mdUp": { marginBottom: "$40" } }}
            >
              <Grid columns={10}>
                <GridItem col={[2, 7]} small={[1, 10]}>
                  <StyledPageLead>
                    <Markdown>{props.body}</Markdown>

                    <Link href={signupUrl} target="_blank" passHref>
                      <SignupButton
                        variant="primary"
                        size="large"
                        css={{
                          "@mdUp": { marginTop: "$lg" },
                        }}
                      >
                        <Message id="navigation.signupLink.label" />
                      </SignupButton>
                    </Link>
                  </StyledPageLead>
                </GridItem>
              </Grid>
            </StyledPageContent>

            {props.goto.map((goto) => {
              return (
                <GoToLink key={goto.id}>
                  <Link href={goto.href} passHref>
                    {goto.text}
                    <Button variant="light" size="large" aria-label={goto.text}>
                      <IconWrapper name="arrowTailed" fill="#eb5e00" />
                    </Button>
                  </Link>
                </GoToLink>
              )
            })}
          </GridItem>
        </Grid>
      </PageWidthContainer>
    </PublicLayout>
  )
}
