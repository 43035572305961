import { useCallback, useContext, useMemo } from "react"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import { EditHandbookContext } from "src/context/edit-handbook-context"
import { styled } from "src/stitches.config"

import { DropdownSelect } from "../../dropdown-select"
import { Grid } from "../../grid"
import { GridItem } from "../../grid-item"
import { MultiselectDropdown } from "../../multiselect-dropdown"
import { H2, H3, P } from "../../typography"

const EditableTagsWrapper = styled("div", {
  marginBottom: "$40",
})

const EditableTagsHeader = styled("div", {
  marginBottom: "$40",

  [H2.toString()]: {
    marginBottom: "$10",
  },
})

const MultiselectDropdownWrapper = styled("div", {
  "> div": {
    minWidth: "auto",
  },
})

const LabelWrapper = styled("label", {
  width: "100%",
})

function mapDisciplinesValuesToActiveOptions(disciplinesValues: any[] = []) {
  return disciplinesValues.map((disciplinesValue) => {
    return {
      value: disciplinesValue.code,
      label: disciplinesValue.name,
    }
  })
}

function getSelectedOptionByValue(options: any[], value: string) {
  return options.find((option) => option.value === value)
}

export const EditableTags = () => {
  const { formatMessage } = useIntl()
  const { watch, setValue, formState } = useFormContext()
  const { tagOptions } = useContext(EditHandbookContext)

  const disciplinesValues = watch("disciplines")
  const locationValue = watch("location")
  const categoryValue = watch("category")

  // Map discipline options for dropdown
  const disciplinesOptions = useMemo(
    () =>
      tagOptions.disciplines.map((discipline) => ({
        value: discipline.code,
        label: discipline.name,
        main: true,
      })),
    [tagOptions.disciplines],
  )

  // Map location options for dropdown
  const locationOptions = useMemo(
    () => [
      {
        value: "",
        label: formatMessage({ id: "filters.locationFilter.indoorOutdoor" }),
      },
      ...tagOptions.locations.map((label) => ({
        value: label,
        label: formatMessage({ id: `filters.locationFilter.${label}` }),
      })),
    ],
    [tagOptions.locations],
  )

  // Map category options for dropdown
  const categoryOptions = useMemo(
    () =>
      tagOptions.categories.map((label) => ({
        value: label,
        label: formatMessage({ id: `filters.categoryFilter.${label}` }),
      })),
    [tagOptions.categories],
  )

  function handleDisciplinesChange(values: Array<{ value: string }>) {
    const mapped = values.map(({ value }) => {
      return tagOptions.disciplines.find(
        (discipline) => discipline.code === value,
      )
    })

    setValue("disciplines", mapped, { shouldValidate: true, shouldDirty: true })
  }

  const handleLocationChange = useCallback((value: { value: string }) => {
    setValue("location", value.value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }, [])

  const handleCategoryChange = useCallback((value: { value: string }) => {
    setValue("category", value.value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }, [])

  function getFieldError(name: string) {
    return (
      (formState.isSubmitted || formState.touchedFields[name]) &&
      formState.errors[name]?.message
    )
  }

  return (
    <EditableTagsWrapper>
      <EditableTagsHeader>
        <H2>{formatMessage({ id: "edit.editableTags.title" })}</H2>
        <P size="extraLarge" light noMargin>
          {formatMessage({ id: "edit.editableTags.subtitle" })}
        </P>
      </EditableTagsHeader>

      <Grid>
        <GridItem col={[1, 4]} data-cy="drowpdown-disciplines">
          <H3 as="p" italic>
            {formatMessage({ id: "tags.disciplines.label" })}
          </H3>

          <MultiselectDropdownWrapper>
            <MultiselectDropdown
              onChange={handleDisciplinesChange}
              activeOptions={mapDisciplinesValuesToActiveOptions(
                disciplinesValues,
              )}
              options={disciplinesOptions}
              placeholder={formatMessage({
                id: "tags.disciplines.placeholder",
              })}
              error={getFieldError("disciplines")}
            />
          </MultiselectDropdownWrapper>
        </GridItem>

        <GridItem col={[5, 4]} data-cy="drowpdown-locations">
          <LabelWrapper>
            <H3 as="p" italic>
              {formatMessage({ id: "tags.locations.label" })}
            </H3>

            <DropdownSelect
              instanceId="location-tag-dropdown"
              onChange={handleLocationChange}
              options={locationOptions}
              selectedOption={getSelectedOptionByValue(
                locationOptions,
                locationValue,
              )}
              error={getFieldError("location")}
            />
          </LabelWrapper>
        </GridItem>

        <GridItem col={[9, 4]} data-cy="drowpdown-categories">
          <LabelWrapper>
            <H3 as="p" italic>
              {formatMessage({ id: "tags.categories.label" })}
            </H3>

            <DropdownSelect
              instanceId="category-tag-dropdown"
              onChange={handleCategoryChange}
              options={categoryOptions}
              selectedOption={getSelectedOptionByValue(
                categoryOptions,
                categoryValue,
              )}
              error={getFieldError("category")}
              placeholder={formatMessage({
                id: "filters.categoryFilter.placeholder",
              })}
              noDefault
            />
          </LabelWrapper>
        </GridItem>
      </Grid>
    </EditableTagsWrapper>
  )
}
