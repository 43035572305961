import React, { useMemo, useState } from "react"
import {
  IInfrastructureDrawingVariants,
  IInfrastructureRule,
} from "src/services"
import { styled } from "src/stitches.config"

import { Markdown } from "../../markdown"
import { RuleAttachmentDrawing } from "../rule-attachment-drawing"
import { RuleAttachmentImage } from "../rule-attachment-image"
import { RuleAttachmentLink } from "../rule-attachment-link"
import { RuleAttachmentPdf } from "../rule-attachment-pdf"
import { RuleAttachmentTable } from "../rule-attachment-table"
import { RuleAttachmentText } from "../rule-attachment-text"
import { RuleIndex } from "../rule-index"
import { RuleType } from "../rule-type"

interface IRuleProps extends IInfrastructureRule {
  isHidden?: boolean
}

const RuleWrapper = styled("div", {
  position: "relative",
  display: "flex",
  padding: "0 $60",
  marginBottom: "$40",

  "@lgDown": {
    padding: "0 $15",
    marginBottom: "$20",
  },

  "&:last-child": {
    marginBottom: "0",
  },

  variants: {
    onTop: {
      true: {
        zIndex: "2",
      },
    },
    isHidden: {
      true: {
        height: "0",
        margin: "0",
        overflow: "hidden",
      },
    },
  },
})

const RuleIconsContainer = styled("div", {
  flex: "0 0 1.75rem",
  marginRight: "$30",
  zIndex: 1,

  "@mdDown": {
    marginRight: "$15",
  },

  variants: {
    elevate: {
      true: {
        zIndex: 1002,
      },
    },
  },
})

const RuleContentContainer = styled("div", {
  flex: "1 1 auto",

  p: {
    marginBottom: 0,
  },

  "p:not(:last-child)": {
    marginBottom: "$20",

    "@mdDown": {
      marginBottom: "$10",
    },
  },
})

const RuleTypeTooltipOverlay = styled("div", {
  position: "fixed",
  top: "0",
  right: "0",
  bottom: "0",
  left: "0",
  zIndex: "1000",
  backgroundColor: "rgba(0, 0, 0, .25)",
  opacity: "0",
  pointerEvents: "none",

  variants: {
    visible: {
      true: {
        opacity: "1",
        transition: "opacity .15s ease",
        pointerEvents: "auto",
      },
    },
  },
})

export const Rule = ({ isHidden, ...props }: IRuleProps) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const isTextOnlyRule = !props.ruleType

  function handleRuleTypeClick() {
    setShowTooltip(!showTooltip)
  }

  const markdownCSS = useMemo(() => {
    // Adding extra CSS to the Markdown component when we're dealing with
    // a Link attachment, because we want this to be hidden on mobile. The
    // RuleAttachmentLink component will take care of it's mobile state.
    if (props.attachment?.type === "LINK") {
      return {
        "@lgDown": {
          display: "none",
        },
      }
    }

    return {}
  }, [])

  return (
    <RuleWrapper
      onTop={showTooltip}
      isHidden={isHidden}
      aria-hidden={isHidden}
      data-cy={`rule type-${props.ruleType}`}
    >
      {!isTextOnlyRule && (
        <>
          <RuleIconsContainer elevate={showTooltip}>
            <RuleIndex />
            <RuleType
              ruleType={props.ruleType}
              showTooltip={showTooltip}
              onClick={handleRuleTypeClick}
            />
          </RuleIconsContainer>
          <RuleTypeTooltipOverlay
            visible={showTooltip}
            onClick={handleRuleTypeClick}
          />
        </>
      )}

      <RuleContentContainer>
        <Markdown css={markdownCSS}>{props.content}</Markdown>

        {props.attachment?.type === "LINK" && (
          <RuleAttachmentLink
            {...props.attachment}
            content={props.content}
            url={props.attachment.url as string}
          />
        )}

        {props.attachment?.type === "TEXT" && (
          <RuleAttachmentText {...props.attachment} />
        )}

        {props.attachment?.type === "TABLE" && (
          <RuleAttachmentTable {...props.attachment} />
        )}

        {props.attachment?.type === "PDF" && (
          <RuleAttachmentPdf
            {...props.attachment}
            content={props.content}
            url={props.attachment.url as string}
          />
        )}

        {props.attachment?.type === "IMAGE" && (
          <RuleAttachmentImage
            {...props.attachment}
            url={props.attachment.url as string}
            isTextOnlyRule={isTextOnlyRule}
          />
        )}

        {props.attachment?.type === "DRAWING" && (
          <RuleAttachmentDrawing
            {...props.attachment}
            url={props.attachment.url as IInfrastructureDrawingVariants}
            isTextOnlyRule={isTextOnlyRule}
          />
        )}
      </RuleContentContainer>
    </RuleWrapper>
  )
}
