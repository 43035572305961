import { ReactElement } from "react"
import { iconNameType } from "src/components"
import { ORGANIZATION_TYPES } from "src/constants"

import { EventType } from "./api"

export interface IFileContent {
  name: string
  content: string
}

export interface IOrganizationListItem {
  title: string
  description: string
  href: string
  disabled?: boolean
}

export interface IOrganization {
  id: number
  type: ORGANIZATION_TYPES
  name: string
  address?: {
    domestic?: {
      street?: string
      city?: string
    }
    foreign?: {
      street?: string
      city?: string
    }
  }
  postalCode: string
  city: string
  country: string
  emailAddress: string
  phoneNumber: string
  website: string
  isActive: boolean
  sportProducts?: any[]
  users: TUser[]
  kvkNumber?: string
  kvkEstablishmentNumber?: string
}

export interface ILink {
  id?: string
  text: string
  href: string
}

export interface IParentLink {
  title: string | ReactElement
  route: string
  light?: boolean
  green?: boolean
}

export interface ILinkCard {
  id: number
  title: string
  description: string
  link: ILink
  order?: number
}

export interface IPageContent {
  id: number
  __component: string
  markdown: string
  images: IImage[]
  provider: "Youtube|Vimeo"
  url: string
  caption: string
}

export interface IImage {
  id: number
  width: number
  height: number
  size: number
  url: string
  ext: string
  mime: string
  alternativeText: string
  caption: string
  formats: object
}

export interface IVideo {
  url: string
  provider: string
  caption: string
}

export interface IPage {
  title: string
  subtitle: string
  lead: string
  parentLink: IParentLink
  seo: {
    title: string
    description: string
    noIndex: boolean
    ogImage: IImage
  }
  content: IPageContent[]
  linkCards: ILinkCard[]
}

export interface INewsTag {
  id: number
  label: string
}

export interface IAttachment {
  id: number
  title?: string
  description?: string
  buttonText: string
  file: any
}

export interface INewsItem {
  id: string
  title: string
  lead: string
  published_at: Date
  content: IPageContent[]
  linkCards: ILinkCard[]
  tags: INewsTag[]
  attachments: IAttachment[]
}

export interface IQuote {
  title: string
  content: string
}

export interface IHomepage {
  title: string
  subtitle: string
  body: string
  image: IImage
  linkCardsTitle?: string
  linkCards: ILinkCard[]
  quote: IQuote
  seo: {
    title: string
    description: string
    noIndex: boolean
  }
}

export interface IAgendaItem {
  id: string
  topic: string
  dateFrom: Date
  dateTo?: Date
  location?: string
  theme?: string
  content?: string
  attachments?: IAttachment[]
}

export interface IMinutesItemSubject {
  id: string
  title: string
  description: string
  linkHref: string
  linkText: string
}

export interface IMinutesItem {
  id: string
  title: string
  intro: string
  date: Date
  attachment: IAttachment
  subjects: IMinutesItemSubject[]
  closingTitle: string
  closingDescription: string
  closingLinkHref: string
  closingLinkText: string
}

export interface IProgramBoardPage {
  title: string
  subtitle?: string
  agendaSectionTitle: string
  agendaItems: IAgendaItem[]
  minutesSectionTitle: string
  minutesItems: IMinutesItem[]
}

export interface IErrorPage {
  title: string
  description: string
  body?: string
  goto: ILink[]
  errorCode?: number
}

export interface IFooterContactItem {
  id: number
  title: string
  icon: iconNameType
  description: string
  meta: string
  href: string
}

export interface IFooterSponsorItem {
  id: string
  icon: iconNameType
  title: string
  url: string
}

export interface IFooterData {
  contact: {
    title: string
    description: string
    items: IFooterContactItem[]
  }
  sponsors: {
    title: string
    items: IFooterSponsorItem[]
  }
  links: ILink[]
}

export interface IDictionaryItem {
  key: string
  value: string
}

export interface IDictionary {
  dictionaryItems: IDictionaryItem[]
}

export type RuleType = "R" | "A" | "V" | "W"
export type AttachmentType =
  | "TEXT"
  | "TABLE"
  | "LINK"
  | "DRAWING"
  | "PDF"
  | "IMAGE"
export interface IInfrastructureRuleAttachment {
  id: string
  type: AttachmentType
  content?: string
  caption?: string
  url?: string | IInfrastructureDrawingVariants
  title?: string
}

export interface IInfrastructureRule {
  id: string
  ruleType: RuleType
  content: string
  meta: null
  attachment?: IInfrastructureRuleAttachment
}

export interface IInfrastructureSubsection {
  id: string
  title: string
  rules: IInfrastructureRule[]
}

export interface IKnowledgeBaseSubSection {
  id: string
  title: string
  knowledgeBaseRules: IInfrastructureRule[]
}

export interface IInfrastructureSection extends IInfrastructureSubsection {
  sections: Array<IInfrastructureSubsection>
}

export interface IHandbookData {
  id: string
  code: string
  title: string
  updatedAt: string
  createdAt: string
  isDraft: boolean
  isLatest: boolean
  isActive: boolean
  version: number
}

export interface IInfrastructureData extends IHandbookData {
  disciplines: Array<{
    label: string
    code: string
    sportCode: string
  }>
  location: string
  category: string
  sections: IInfrastructureSection[]
  drawings: string[]
}

export interface IKnowledgeBaseSection extends IKnowledgeBaseSubSection {
  knowledgeBaseSections: Array<IKnowledgeBaseSubSection>
}

export interface IKnowledgeBaseData extends IHandbookData {
  knowledgeBaseSections: IKnowledgeBaseSection[]
  version: number
}

export interface ISportsUnion {
  id: string
  name: string
  address: string
  phoneNumber: string
  website: string
  logo: IImage
}

export interface IDisciplineData {
  id: string
  code: string
  title: string
  lead: string
  sports_union: ISportsUnion
  updatedAt: string
  createdAt: string
}

export interface IInfrastructureUpdateData {
  code: string
  title: string
  sections: IInfrastructureSection[]
}

export interface IInfrastructureDrawingVariants {
  regular: string
  large: string
  cad: string
  pdf: string
}

export interface IQuestion {
  question: string
  answer: string
}

export interface IMember {
  id: number
  name: string
  position?: string
  avatar?: IImage
  published_at?: string
  created_at?: string
  updated_at?: string
}
export interface ITagOptions {
  disciplines: Array<{ code: string; name: string; sportCode: string }>
  locations: Array<string>
  categories: Array<string>
}

export type TDiscipline = {
  id: string
  code: string
  name: string
  handbooks?: THandbook[]
  sport: TSport
  sportCode: string
}

export type TSport = {
  code: string
  name: string

  disciplines: TDiscipline[]
  association: TAssociation
  associationCode: string
}

export type TAssociation = {
  code: string
  name: string
  sports?: TSport[]
}

export type TUserOrganizations = {
  id: string
  email: string
  externalId: string
  association: TAssociation | null
  organization: IOrganization | null
  certificationInstitute: IOrganization | null
  testInstitute: IOrganization | null
  contractor: IOrganization | null
  manufacturer: IOrganization | null
  consultant: IOrganization | null
  misc: IOrganization | null
  municipality: IOrganization | null
  sportClub: IOrganization | null
}

export type THandbook = {
  id: string
  isLatest: boolean
  isDraft: boolean
  createdAt: string
  updatedAt: string
  title: string
  code: string
  category: string
  location: string
  version: number
  sections?: IInfrastructureSection[]
  disciplines?: TDiscipline[]
}

export type TProductSystemStatus = "active" | "new" | "inactive" | "deleted"
export type TProductStatus = "approved" | "test"
export type TProductType = "sportvloer"

export type TProduct = {
  id: string
  name: string
  description: string
  type: {
    name: TProductType
  }
  subtype: {
    name: string
    description: string
  }
  location: string
  productStatus: {
    name: TProductStatus
  }
  systemStatus: {
    name: TProductSystemStatus
  }
  date: string
  sustainabilityLabel: string
  normLinks: {
    id: string
    url: string
    text: string
  }[]
  reports: {
    id: string
    url: string
    text: string
  }[]
  disciplines: {
    code: string
    name: string
  }[]
  contractors: IOrganization[]
  testInstitute: IOrganization
  manufacturer: IOrganization
  updatedAt: Date
}

export type TProjectOwner = {
  id: number
  name: string
  type?: string | null
  address?: string | null
  city?: string | null
  kvkEstablishmentNumber?: string | null
  kvkNumber?: string | null
}

export type TMunicipality = {
  id: number
  name: string
  address: string
  city: string
  kvkNumber: string
}

export type TProjectContact = {
  id: number
  name: string
}

export type TCertificationInstitute = {
  name: string
  address?: string
  postalCode?: string
  city?: string
  country?: string
  emailAddress?: string
  phoneNumber?: string
  website?: string
  logo?: string
  description?: string
  isActive?: boolean
}

export type TSportClub = {
  id: number
  kvkNumber: string
  name: string
  address: string
  city: string
}

export interface IAccommodation {
  id: string
  code: string
  updatedAt: string
  name: string
  state: string
  implementedSportProducts: {
    approvedSportClubs: {
      name: string
    }[]
    sportClubs: {
      name: string
    }[]
    sportDisciplines: {
      name: string
    }[]
  }[]
}
export type SportFilters = Array<{
  label: string
  value: string
  main?: boolean
}>

export type TAccommodation = {
  id: string
  name: string
  latitude: string
  longitude: string
  city?: string
  dispensation: string
  projectId: string
  project: {
    owner: TProjectOwner
  }
  owner: TProjectOwner
  implementedSportProducts: {
    id: string
    createdAt: string
    updatedAt: string
    contractor: IOrganization
    sportProduct: TProduct
    contractorId: string
    sportProductTypeId: string
    sportProductId: string
    accommodationId: string
    certificationId: string
    disciplines: TDiscipline[]
    sportClubDisciplineRelations: TSportClubDisciplineRelation[]
    certification: {
      id: string
      title: string | null | undefined
      url: string
      expirationDate: Date | null | undefined
      validFrom: Date | null | undefined
      validForYears?: number
    }
  }[]
}

export type TProjectNote = {
  id?: string
  content: string
  createdAt: string
}

export interface IReason {
  id: string
  name: string
}

export type TProductSystemStatusUpdate = {
  [status in TProductSystemStatus]?: boolean
}

export type TUser = {
  id: string
  displayName: string
  givenName: string
  surName: string
  email: string
  jobTitle: string
  externalId: string
  appRoleId: string

  association: IOrganization
  contractor: IOrganization
  manufacturer: IOrganization
  testInstitute: IOrganization
  certificationInstitute: IOrganization
}

export type TPermission = {
  id: string
  name: string
  isActive: boolean
}

export type TRoleWithPermissions = {
  id: string
  name: string
  permissions: TPermission[]
}

export type TKnowledgeBase = Partial<THandbook>

export type TSportClubDisciplineRelation = {
  id: string
  discipline: TDiscipline
  sportClubs: TSportClub[]
}

export interface AccommodationApproval {
  data: {
    accommodation: AccommodationData
    token: {
      inspection: string
      certificate: string
    }
  }
}

export interface AccommodationData {
  id: string
  code: string
  name: string
  city: string
  project: Project
  implementedSportProducts: IImplementedSportProduct[]
  latitude: string
  longitude: string
  dispensation: string
  owner: TProjectOwner
}

export interface IImplementedSportProduct {
  id: string
  contractor: Partial<IOrganization>
  mainContractor: Partial<IOrganization>
  consultant?: Partial<IOrganization>
  sportProduct: SportProduct
  sportClubs: SportClub[]
  inspectionReport: InspectionReport
  disciplineCertificates: IDisciplineCertificate[]
  disciplines: Discipline[]
  state: string
  isApproved: boolean
}

export interface IDisciplineCertificate {
  id: string
  state: string
  discipline: Discipline
  sportClubs: SportClub[]
  certificate?: {
    id: string
    accommodationName: string
    note: string
    createdAt: string
    url: string
    validForYears?: number
  } | null
}

export interface Discipline {
  code: string
  name: string
  sportCode: string
  sport: Sport
  sportClubs: SportClub[]
}

export interface SportClub {
  id: number
  name: string
  disciplines: Discipline[]
}

export interface IAssociation {
  name: string
  code: string
  users: {
    email: string
    externalId: string
  }[]
}

export interface Sport {
  name: string
  code: string
  association: IAssociation
}

interface InspectionReport {
  url: string
  inspectionDate: string
}

interface SportProduct {
  id: string
  name: string
  description?: any
  manufacturer: Partial<IOrganization>
  type: SportProductType
  subtype: SportProductSubtype
  isActive: boolean
  systemStatus: {
    id: string
    name: string
  }
  consultant?: IOrganization
}

interface SportProductType {
  id: string
  name: string
}

interface SportProductSubtype {
  id: string
  name: string
}

interface Owner {
  name: string
}

export interface Project {
  title: string
  owner?: Owner
  reason: {
    id: string
    name: string
  }
  consultant: {
    id: number
    name: string
  }
}

export type DisciplineCertificates = {
  discipline: {
    code: string
    name: string
    sportCode: string
    sportClubsStrings: string[]
  }
  id: string
  state: Status
  sportClubs: SportClub[]
  certificate?: {
    accommodationName: string
    note: string
    createdAt: string
    url: string
    validForYears?: number
  } | null
}[]
export type MappedSport = {
  disciplineCertificates: DisciplineCertificates
  name: string
  code: string
  association: IAssociation
}
export type AccommodationDataMapped = {
  id: string
  name: string
  project: Project
  dispensation: string

  implementedSportProducts: {
    sportProductName: string
    name: string
    id: string
    inspectionReportUrl: string
    sports: MappedSport[]
  }[]
}

export enum Status {
  CREATED = "CREATED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export interface ISubscription {
  id: string
  type: EventType
  sportCode: null | string
}

export interface IDiscipline {
  code: string
  name: string
}

export interface ISportWithDisciplines {
  code: string
  name: string
  disciplines: IDiscipline[]
}
