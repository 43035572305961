import Link from "next/link"
import { IMinutesItem } from "src/services"
import { styled } from "src/stitches.config"
import getAbsoluteSrc from "src/utils/get-absolute-src"

import { Button } from "../button"
import { Calendar } from "../calendar"
import { Grid } from "../grid"
import { GridItem } from "../grid-item"
import { H2, H4, P } from "../typography"

interface Props {
  minutesItem: IMinutesItem
}

const MinutesItemWrapper = styled("div", {
  marginBottom: "$40",
  padding: "$30",
  backgroundColor: "$white",

  "@mdDown": {
    width: "calc(100% + $30)",
    marginLeft: "-$15",
    marginBottom: "0",
    padding: "$15",
  },
})

const CalendarWrapper = styled("div", {
  maxWidth: "$80",
})

const MinutesHeaderWrapper = styled("div", {
  marginBottom: "$40",
})

const MinutesHeaderButtonWrapper = styled("div", {
  "@lgDown": {
    paddingTop: "$20",
  },

  "@lgUp": {
    textAlign: "right",
  },

  "a, a:hover, a:focus": {
    textDecoration: "none",
  },
})

const MinutesItemSubjects = styled("div", {
  paddingTop: "$20",

  "@mdUp": {
    marginBottom: "$40",
  },
})

const MinutesItemSubject = styled("div", {
  marginBottom: "$40",
})

const LinkButtonWrapper = styled("div", {
  "@lgUp": {
    textAlign: "right",

    [`${MinutesItemSubject.toString()} &`]: {
      marginTop: "$5",
    },
  },

  "a, a:hover, a:focus": {
    textDecoration: "none",
  },
})

const MinutesFooterWrapper = styled("div", {
  width: "calc(100% + $60)",
  marginLeft: "-$30",
  padding: "$40 $30 0",
  borderTop: "1px solid #ddd",

  "@mdDown": {
    width: "calc(100% + $30)",
    marginLeft: "-$15",
    padding: "$20 $15 $15",
  },
})

export const MinutesItem = ({ minutesItem }: Props) => (
  <MinutesItemWrapper data-cy="minutes-wrapper">
    <MinutesHeaderWrapper>
      <Grid columns={12} gutter="$sizes$15">
        <GridItem col={[2, 1]} medium={[1, 2]}>
          <CalendarWrapper>
            <Calendar date={minutesItem.date} />
          </CalendarWrapper>
        </GridItem>

        <GridItem col={[3, 7]} medium={[3, 12]}>
          <H4 data-cy="minutes-title">{minutesItem.title}</H4>
        </GridItem>

        {!!minutesItem.attachment && (
          <GridItem col={[10, 3]} medium={[1, 12]}>
            <MinutesHeaderButtonWrapper>
              <a
                href={getAbsoluteSrc(minutesItem.attachment.file.url)}
                target="_blank"
                download
              >
                <Button
                  icon="download"
                  variant="secondary"
                  size="large"
                  outline
                >
                  {minutesItem.attachment.buttonText}
                </Button>
              </a>
            </MinutesHeaderButtonWrapper>
          </GridItem>
        )}
      </Grid>
    </MinutesHeaderWrapper>

    <Grid columns={12} gutter="$sizes$15">
      <GridItem col={[3, 10]} medium={[1, 12]}>
        <P bold>{minutesItem.intro}</P>

        <MinutesItemSubjects data-cy="minutes-subjects">
          {minutesItem.subjects.map((subject) => (
            <MinutesItemSubject key={subject.id}>
              <Grid columns={10} gutter={0}>
                <GridItem col={[1, 7]} medium={[1, 10]}>
                  <H2 data-cy="minutes-subject-title">{subject.title}</H2>
                  <P data-cy="minutes-subject-description">
                    {subject.description}
                  </P>
                </GridItem>

                {!!subject.linkHref && !!subject.linkText && (
                  <GridItem col={[8, 3]} medium={[1, 10]}>
                    <LinkButtonWrapper data-cy="minutes-link">
                      <Link href={subject.linkHref}>
                        <Button
                          variant="secondary"
                          outline
                          size="large"
                          icon="arrowRight"
                          iconColor="primary"
                        >
                          {subject.linkText}
                        </Button>
                      </Link>
                    </LinkButtonWrapper>
                  </GridItem>
                )}
              </Grid>
            </MinutesItemSubject>
          ))}
        </MinutesItemSubjects>
      </GridItem>
    </Grid>

    {!!minutesItem.closingTitle &&
      !!minutesItem.closingDescription &&
      !!minutesItem.closingLinkHref &&
      !!minutesItem.closingLinkText && (
        <MinutesFooterWrapper>
          <Grid columns={12} gutter="$sizes$15">
            <GridItem col={[2, 6]} medium={[1, 12]}>
              <P bold noMargin size="large">
                {minutesItem.closingTitle}
              </P>
              <P>{minutesItem.closingDescription}</P>
            </GridItem>

            <GridItem col={[9, 4]} medium={[1, 12]}>
              <LinkButtonWrapper>
                <Link href={minutesItem.closingLinkHref}>
                  <Button
                    variant="secondary"
                    outline
                    size="large"
                    icon="externalLink"
                  >
                    {minutesItem.closingLinkText}
                  </Button>
                </Link>
              </LinkButtonWrapper>
            </GridItem>
          </Grid>
        </MinutesFooterWrapper>
      )}
  </MinutesItemWrapper>
)
