import { IIcon } from "@types"
import { ReactElement, ReactSVG } from "react"

export const GolazoIcon = ({
  fill = "#FFFFFF",
}: IIcon): ReactElement<ReactSVG> => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    height="60"
    viewBox="0 0 592 347.6"
    fill="none"
  >
    <path
      fill={fill}
      d="M544.9.39c-25.97,0-47.11,21.14-47.11,47.11v150.79c0,25.97,21.14,47.11,47.11,47.11s47.11-21.14,47.11-47.11V47.5c0-25.97-21.14-47.11-47.11-47.11M560.6,198.29c0,8.66-7.04,15.7-15.7,15.7s-15.71-7.04-15.71-15.7V47.5c0-8.66,7.04-15.7,15.71-15.7s15.7,7.04,15.7,15.7v150.79Z"
    />
    <path
      fill={fill}
      d="M411.46,148.69c.85,0,1.59-.57,1.81-1.38l7.78-28.33c.24-.85-.41-1.69-1.29-1.69h-29.39V47.11C390.38,20.89,368.85-.39,342.54,0c-25.85.39-46.38,21.96-46.38,47.81v69.46h-20.99c-.85,0-1.59.57-1.81,1.38l-7.78,28.34c-.24.85.41,1.69,1.29,1.69h29.29v91.6c0,.86.71,1.57,1.57,1.57h28.27c.86,0,1.57-.71,1.57-1.57v-91.6h31.41v91.6c0,.86.71,1.57,1.57,1.57h28.27c.86,0,1.57-.71,1.57-1.57v-91.6h21.08ZM327.57,117.28V47.11c0-8.86,7.37-16.03,16.31-15.7,8.49.32,15.1,7.58,15.1,16.08v69.79h-31.41Z"
    />
    <path
      fill={fill}
      d="M152.71,0C126.74,0,105.6,21.14,105.6,47.07l-.42,150.83c0,25.97,21.14,47.11,47.11,47.11s47.11-21.13,47.11-47.06l.43-150.83C199.82,21.15,178.68,0,152.71,0M167.99,197.89c0,8.66-7.04,15.7-15.7,15.7s-15.71-7.04-15.71-15.65l.43-150.83c0-8.66,7.04-15.7,15.7-15.7s15.7,7.04,15.7,15.66l-.42,150.83Z"
    />
    <path
      fill={fill}
      d="M283.45,210.46h-41.54V4.7c0-.86-.71-1.57-1.57-1.57h-28.27c-.87,0-1.57.71-1.57,1.57v206.59c0,16.89,13.69,30.57,30.57,30.57h42.37c.86,0,1.57-.71,1.57-1.57v-28.27c0-.87-.71-1.57-1.57-1.57"
    />
    <path
      fill={fill}
      d="M47.53,0C21.55,0,.42,21.14.42,47.06L0,197.89C0,224.1,21.53,245.39,47.84,244.99c25.85-.39,46.38-21.97,46.38-47.82v-78.33c0-.86-.71-1.57-1.57-1.57h-39.79c-.85,0-1.59.57-1.81,1.38l-7.78,28.34c-.24.85.41,1.69,1.29,1.69h18.27v48.83c0,8.5-6.61,15.76-15.1,16.08-8.94.33-16.31-6.83-16.31-15.65l.43-150.83c0-8.66,7.04-15.7,15.7-15.7s15.68,7.02,15.71,15.65l-.27,38.77h31.4l.27-38.61v-.11c0-25.97-21.14-47.11-47.12-47.11"
    />
    <path
      fill={fill}
      d="M495.93,3.13h-94.67c-.86,0-1.57.71-1.57,1.57v28.27c0,.86.71,1.57,1.57,1.57h55.22l-56.49,205.63c-.24.85.41,1.69,1.29,1.69h85.23c.87,0,1.57-.71,1.57-1.57v-28.26c0-.87-.71-1.57-1.57-1.57h-45.79L497.22,4.82c.24-.85-.41-1.69-1.29-1.69"
    />
    <rect fill={fill} y="312.27" width="285.02" height="4.23" />
    <g>
      <path
        fill={fill}
        d="M393.12,347.6h-7.61l-4.39-43.23h-.18l.09,12.62v30.61h-8.59v-64.27h12.71l4.03,43.05h.18l4.12-43.05h12.71v64.27h-8.59v-30.61l.09-12.62h-.18l-4.39,43.23Z"
      />
      <path
        fill={fill}
        d="M432.59,283.33h20.05v8.5h-10.74v18.17h9.22v8.5h-9.22v20.59h10.74v8.5h-20.05v-64.27Z"
      />
      <path
        fill={fill}
        d="M491.75,283.33c3.67,0,6.62.98,8.68,3.04s3.13,4.83,3.13,8.32v41.53c0,3.49-1.07,6.27-3.13,8.32s-5.01,3.04-8.68,3.04h-13.34v-64.27h13.34ZM487.81,291.57v47.8h3.22c.98,0,1.79-.36,2.33-.99.54-.63.81-1.61.81-2.86v-40.1c0-1.25-.27-2.24-.81-2.86-.54-.63-1.34-.98-2.33-.98h-3.22Z"
      />
      <path fill={fill} d="M539.1,283.33v64.27h-9.49v-64.27h9.49Z" />
    </g>
    <path
      fill={fill}
      d="M571.5,283.33h12.17l7.97,64.27h-9.13l-1.52-15.04h-7.16l-1.52,15.04h-8.77l7.97-64.27ZM576.42,307.77l-1.7,16.83h5.37l-1.7-16.83-.89-9.67h-.18l-.89,9.67Z"
    />
  </svg>
)
