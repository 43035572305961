import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { notesService } from "src/services"

import { CertificationProcessNoteList } from "../types"

export function useCertificationProcessNotes(
  id: string,
): UseQueryResult<CertificationProcessNoteList> {
  const queryFn = () => {
    return notesService.getAll(id)
  }

  return useQuery(["certification-process", id, "notes"], queryFn)
}
