import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const NotificationIcon = ({
  color = "currentColor",
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.909457 15.455C0.40673 15.455 0.000366211 15.0486 0.000366211 14.5459C0.000366211 14.0432 0.40673 13.6368 0.909457 13.6368C1.91218 13.6368 2.72764 12.8214 2.72764 11.8186V7.27319C2.72764 3.26228 5.98946 0.000457764 10.0004 0.000457764C14.0104 0.000457764 17.2731 3.26228 17.2731 7.27319V11.8186C17.2731 12.8214 18.0885 13.6368 19.0913 13.6368C19.5931 13.6368 20.0004 14.0432 20.0004 14.5459C20.0004 15.0486 19.5931 15.455 19.0913 15.455H0.909457ZM15.9431 13.6368C15.6322 13.1014 15.4549 12.4805 15.4549 11.8186V7.27319C15.4549 4.265 13.0076 1.81864 10.0004 1.81864C6.99218 1.81864 4.54582 4.265 4.54582 7.27319V11.8186C4.54582 12.4805 4.36764 13.1014 4.05764 13.6368H15.9431ZM8.63218 19.6295C9.06128 19.8777 9.53128 19.9959 9.99491 19.9959C10.9367 19.9959 11.854 19.5095 12.3585 18.6386C12.6122 18.2041 12.464 17.6486 12.0295 17.3968C11.5958 17.1441 11.0395 17.2923 10.7867 17.7259C10.5349 18.1595 9.97764 18.3095 9.544 18.0559C9.40764 17.9768 9.29309 17.8623 9.21309 17.725C8.96037 17.2914 8.40491 17.1441 7.97037 17.3968C7.53673 17.6486 7.38946 18.205 7.64128 18.6395C7.87946 19.0486 8.22218 19.3914 8.63218 19.6295Z"
      fill={color}
    />
  </svg>
)
