import React, { Fragment } from "react"
import { IInfrastructureRuleAttachment } from "src/services"
import { styled } from "src/stitches.config"

interface Props extends IInfrastructureRuleAttachment {
  url: string
  isTextOnlyRule: boolean // if true, we need to tweak the positioning a bit
}

const RuleAttachmentImageWrapper = styled("div", {
  marginTop: "$30",

  img: {
    width: "calc(100% + $sizes$30)",
    backgroundColor: "$background",
    "@mdUp": {
      width: "calc(110%)",
    },

    "@lgUp": {
      width: "calc(180%)",
    },

    "@xlUp": {
      width: "calc(186%)",
    },
  },

  variants: {
    isTextOnlyRule: {
      true: {
        img: {
          "@mdUp": {
            width: "calc(110% - 51px)",
          },

          "@lgUp": {
            width: "calc(180% - 51px)",
          },

          "@xlUp": {
            width: "calc(186% - 51px)",
          },
        },
      },
    },
  },
})

const ImageCaption = styled("div", {
  paddingTop: "$10",
  fontFamily: "$grotesk",
  fontSize: "$bodySmall",
  lineHeight: "1.25",
})

export const RuleAttachmentImage = ({ url, title, isTextOnlyRule }: Props) => (
  <Fragment>
    <RuleAttachmentImageWrapper
      data-cy="drawing-attachment"
      isTextOnlyRule={isTextOnlyRule}
    >
      {url && url && (
        <img src={url} width="100%" height="100%" alt={title || "afbeelding"} />
      )}
      {title && <ImageCaption>{title}</ImageCaption>}
    </RuleAttachmentImageWrapper>
  </Fragment>
)
