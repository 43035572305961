import React, { useCallback, useMemo } from "react"
import { useFormContext } from "react-hook-form"
import { iconSizes } from "src/constants"
import {
  AttachmentType,
  RULE_RULE,
  TEXT_EXT_RULE,
  TEXT_RULE,
  attachmentTypes,
  getRuleAttachmentTemplate,
} from "src/templates"

import { TypeButton, TypeButtonsContainer } from "../../forms"
import { Message } from "../../message"

interface Props {
  path: string
  attachmentsFor: typeof RULE_RULE | typeof TEXT_RULE | typeof TEXT_EXT_RULE
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>
}

export const CreateRuleAttachment = ({
  path,
  attachmentsFor,
  ...rest
}: Props) => {
  const { setValue } = useFormContext()

  const handleCreateAttachmentClick = useCallback(
    (type: AttachmentType) => {
      setValue(path, getRuleAttachmentTemplate(type), {
        shouldValidate: true,
        shouldDirty: true,
      })
    },
    [setValue, path],
  )

  const attachments = useMemo(
    () =>
      Object.entries(attachmentTypes).filter(([name, props]) =>
        props.for.includes(attachmentsFor),
      ),
    [],
  )

  return (
    <TypeButtonsContainer {...rest}>
      {attachments.map(([type, details]) => (
        <TypeButton
          key={type}
          type="button"
          onClick={handleCreateAttachmentClick.bind(
            null,
            type as AttachmentType,
          )}
          data-cy={`create-${type}-attachment`}
        >
          <details.icon size={iconSizes.large} />
          <Message id={details.label} />
        </TypeButton>
      ))}
    </TypeButtonsContainer>
  )
}
