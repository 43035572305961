import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const DrawingColoredIcon = ({
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(1.000000, 2.000000)">
        <path
          d="M0,2.85714286 C0,1.27918643 1.23121694,0 2.75,0 L19.25,0 C20.7687831,0 22,1.27918643 22,2.85714286 L22,17.1428571 C22,18.7208136 20.7687831,20 19.25,20 L2.75,20 C1.23121694,20 0,18.7208136 0,17.1428571 L0,2.85714286 Z"
          fill="#31493C"
        ></path>
        <path
          d="M1.21916705,15.6732566 L1.21916705,17.2117259 C1.21916705,18.0613991 1.84454708,18.7501953 2.61599245,18.7501953 L19.3778972,18.7501953 C20.1493426,18.7501953 20.7747226,18.0613991 20.7747226,17.2117259 L20.7747226,11.8270832 L15.4989131,8.83168339 C15.2302818,8.68347148 14.9056922,8.7410935 14.6929448,8.97476104 L9.5107226,14.6824823 L5.79516705,11.9563146 C5.51858018,11.7534985 5.15055138,11.7933899 4.91516705,12.0516997 L1.21916705,15.6732566 Z"
          fill="#94A286"
        ></path>
        <path
          d="M7.18055556,5.78125 C7.18055556,4.91830508 6.49654615,4.21875 5.65277778,4.21875 C4.80900941,4.21875 4.125,4.91830508 4.125,5.78125 C4.125,6.64419492 4.80900941,7.34375 5.65277778,7.34375 C6.49654615,7.34375 7.18055556,6.64419492 7.18055556,5.78125 Z"
          fill="#94A286"
        ></path>
      </g>
    </g>
  </svg>
)
