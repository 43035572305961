import Link from "next/link"
import { INewsTag } from "src/services"
import { styled } from "src/stitches.config"

interface Props {
  newsTags: INewsTag[]
}

const NewsTagsWrapper = styled("div", {
  display: "flex",
  flexWrap: "wrap",
})

const NewsTagItem = styled("div", {
  marginTop: "5px",
  marginLeft: "$10",
  padding: "2px 10px 4px 5px",
  color: "$blueLight",
  lineHeight: "1",
  border: "1px solid $colors$purpleLight",
  borderRadius: "4px",
})

export const NewsTags = (props: Props) => (
  <NewsTagsWrapper>
    {props.newsTags.map((newsTag) => (
      <NewsTagItem key={newsTag.id}>
        <Link href={`/nieuws/tag/${newsTag.id}`} data-cy="news-tag">
          {newsTag.label}
        </Link>
      </NewsTagItem>
    ))}
  </NewsTagsWrapper>
)
