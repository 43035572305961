import { OVERLAY_ZINDEX } from "src/constants"
import { styled } from "src/stitches.config"

export const OVERLAY_BG_COLOR = "rgba(74,74,74,0.1)"

export const Overlay = styled("span", {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: OVERLAY_BG_COLOR,
  zIndex: OVERLAY_ZINDEX,
  pointerEvents: "none",
})
