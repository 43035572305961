import { Status } from "src/services"
import { z } from "zod"

const certification = z.object({
  disciplineCertificateId: z.string(),
  state: z.enum([Status.APPROVED, Status.REJECTED]),
})

export const v2CertificationSchema = z.object({
  accommodationId: z.string().optional(),
  sports: z.array(certification).min(1),
  accommodationName: z.string().refine((value) => value.trim().length > 0, {
    message: "Dit is een verplicht veld",
  }),
  implementedSportProductId: z.string(),
  note: z.string().max(255, { message: "Max 255 tekens" }).optional(),
  validForYears: z
    .number()
    .int("Geen decimale getallen toegestaan")
    .min(0, { message: "Geen negatieve getallen" })
    .max(255, { message: "Max 255 jaar" }),
  certificationProcessId: z.string().optional(),
})

export type CertificationManage = z.infer<typeof v2CertificationSchema>
