import { styled } from "src/stitches.config"

export const P = styled("p", {
  margin: "0 0 1em",
  padding: "0",
  fontSize: "1rem",
  lineHeight: "24px",
  color: "$body",

  "@mdDown": {
    lineHeight: "22px",
  },

  a: {
    color: "$body",
  },

  variants: {
    coloredAnchors: {
      true: {
        a: {
          color: "$secondary",
        },
      },
    },
    extraLineHeight: {
      true: {
        lineHeight: "36px",

        "@mdDown": {
          lineHeight: "32px",
        },
      },
    },
    noMargin: {
      true: {
        margin: "0 !important",
      },
    },
    bold: {
      true: {
        fontWeight: "$bold",
      },
    },
    italic: {
      true: {
        fontStyle: "italic",
      },
    },
    light: {
      true: {
        opacity: "0.5",
      },
    },
    size: {
      extraSmall: {
        fontSize: "0.75rem",
      },
      small: {
        fontSize: "0.875rem",
      },
      large: {
        fontSize: "18px",
        lineHeight: "25px",

        "@mdDown": {
          fontSize: "16px",
          lineHeight: "24px",
        },
      },
      extraLarge: {
        marginBottom: "40px",
        fontSize: "20px",
        lineHeight: "30px",

        "@mdDown": {
          marginBottom: "20px",
          fontSize: "18px",
          lineHeight: "25px",
        },
      },
    },
    textAlign: {
      left: {
        textAlign: "left",
      },
      right: {
        textAlign: "right",
      },
      center: {
        textAlign: "center",
      },
    },
  },
})
