import React, { PropsWithChildren } from "react"
import { forwardRef } from "react"

import { ModalBase } from "./fragments"

interface ModalProps {
  title?: string
  isOpen: boolean
  onCancelClick?: () => void
  okDisabled?: boolean
  headerContent?: React.ReactNode
  footerContent?: React.ReactNode
  ref?: any
  paddingSm?: boolean
}

// forwardRef has trouble finding out the component also accepted children without this
interface ModalPropsWithChildren extends PropsWithChildren<ModalProps> {}

export const Modal = forwardRef((props: ModalPropsWithChildren, ref: any) => {
  return <ModalBase {...props} ref={ref} />
})

Modal.displayName = "Modal"
