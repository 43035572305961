import { V2Api } from "../api.v2"
import {
  CertificationProcessCreateNote,
  CertificationProcessNoteList,
} from "./types"

export class NotesService extends V2Api {
  public async getAll(
    certificationProcessId: string,
  ): Promise<CertificationProcessNoteList> {
    return super.get(`/certification-process/${certificationProcessId}/notes`)
  }

  public async create(
    certificationProcessId: string,
    data: CertificationProcessCreateNote,
  ) {
    return super.post(
      `/certification-process/${certificationProcessId}/notes`,
      {
        data,
      },
    )
  }

  public async delete(id: string) {
    return super.delete(`/note/${id}`)
  }
}
