import Link from "next/link"
import React, { PropsWithChildren } from "react"
import { styled } from "src/stitches.config"

interface NavItemLinkProps {
  href: string
  label?: string
  target?: "_blank" | "_self" | "_parent" | "_top"
  slowAnimation?: boolean
}

const StyledA = styled("a", {
  "&:hover": {
    color: "$white",
    opacity: 0.8,
    textDecoration: "none",
  },
})

export const NavItemLink = ({
  href,
  label,
  target = "_self",
  children,
  ...rest
}: PropsWithChildren<NavItemLinkProps>) => (
  <Link href={href} passHref target={target} legacyBehavior>
    <StyledA aria-label={label} {...rest}>
      {children}
    </StyledA>
  </Link>
)

NavItemLink.toString = () => ".NavItemLink"
