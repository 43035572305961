import { IIcon } from "@types"
import { ReactElement, ReactSVG } from "react"

export const ArrowRightIcon = ({
  fill = "#EB5E00",
  height = "20px",
  width = "20px",
}: IIcon): ReactElement<ReactSVG> => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 80 80"
    version="1.1"
  >
    <title>NOCNSF/Atom/Icon/*/Chevron</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(20.000000, 5.000000)"
        fill={fill}
        fillRule="nonzero"
      >
        <g>
          <path d="M1.90662549,1.28187041 C3.61286215,-0.428741214 6.38221549,-0.428741214 8.09282711,1.28187041 L8.09282711,1.28187041 L38.7175876,31.9066309 C39.1375843,32.3266276 39.4525819,32.8078739 39.6669552,33.3241198 C40.1088267,34.3959865 40.1088267,35.603477 39.6669552,36.6753436 C39.4438319,37.2090895 39.1200845,37.6903357 38.7175876,38.0928325 L38.7175876,38.0928325 L8.09282711,68.7175931 C6.38659046,70.4282047 3.61723711,70.4238297 1.90662549,68.7175931 C0.274213256,67.0893558 0.199588696,64.4929794 1.68275181,62.7724246 L26.3639209,38.0928325 C26.7664177,37.6903357 27.0901652,37.2090895 27.3132884,36.6753436 C27.75516,35.603477 27.75516,34.3959865 27.3132884,33.3241198 C27.0989151,32.8078739 26.7839176,32.3266276 26.3639209,31.9066309 L1.67465532,7.21818027 C0.200262524,5.50135235 0.281367065,2.91129616 1.90662549,1.28187041 Z" />
        </g>
      </g>
    </g>
  </svg>
)
