import { IAssetsIconProps } from "@types"
import { ReactElement, ReactSVG } from "react"

export const ArrowUpTailedIcon = ({
  color = "#00378A",
  height = "24",
  width = "24",
}: IAssetsIconProps): ReactElement<ReactSVG> => (
  <svg width={width} height={height} viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(12.000000, 12.000000) scale(1, -1) rotate(270.000000) translate(-12.000000, -12.000000) translate(1.000000, 4.000000)"
        fill={color}
      >
        <g transform="translate(11.000000, 8.000000) scale(-1, 1) translate(-11.000000, -8.000000) ">
          <path d="M13.015783,2.48969336 C13.7642836,3.24205555 14.3809277,3.86188085 14.8657151,4.34916925 C15.3505024,4.83645766 16.0776835,5.56739027 17.0472583,6.54196709 L11.2026344,6.54196709 C10.401781,6.54196709 9.75181297,7.19528829 9.75181297,8.00027334 C9.75181297,8.8052584 10.401781,9.4585796 11.2026344,9.4585796 L17.0472583,9.4585796 L13.015783,13.5108533 C12.4485118,14.0810511 12.4485118,15.0041589 13.015783,15.5728984 C13.5830542,16.1416378 14.5014241,16.1430961 15.0672445,15.5728984 L21.575272,9.03129587 C21.7087476,8.89713169 21.8161084,8.73671801 21.8901003,8.55880464 C22.0366332,8.20151961 22.0366332,7.79902708 21.8901003,7.44174205 C21.81901,7.26966191 21.7145509,7.10924822 21.575272,6.96925082 L15.0672445,0.42764831 C14.4999733,-0.142549437 13.5816033,-0.142549437 13.015783,0.42764831 C12.4499626,0.997846058 12.4485118,1.92095392 13.015783,2.48969336 Z"></path>
          <path d="M1.45082145,9.4585796 C0.64996801,9.4585796 0,8.8052584 0,8.00027334 C0,7.19528829 0.64996801,6.54196709 1.45082145,6.54196709 L1.65805575,6.54196709 C2.45890919,6.54196709 3.1088772,7.19528829 3.1088772,8.00027334 C3.1088772,8.8052584 2.45890919,9.4585796 1.65805575,9.4585796 L1.45082145,9.4585796 Z"></path>
          <path d="M5.49770006,9.4585796 C4.69684662,9.4585796 4.04687861,8.8052584 4.04687861,8.00027334 C4.04687861,7.19528829 4.69684662,6.54196709 5.49770006,6.54196709 L7.36299011,6.54196709 C8.16384355,6.54196709 8.81381156,7.19528829 8.81381156,8.00027334 C8.81381156,8.8052584 8.16384355,9.4585796 7.36299011,9.4585796 L5.49770006,9.4585796 Z"></path>
        </g>
      </g>
    </g>
  </svg>
)
