import { styled } from "src/stitches.config"

import { HDefault } from "./h-default"

export const H4 = styled("h4", {
  ...HDefault,

  marginBottom: "0",
  fontSize: "1.25rem",
  lineHeight: "1.5",

  "@mdDown": {
    fontSize: "1rem",
  },
})
