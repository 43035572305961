import { styled } from "src/stitches.config"

import { DocumentVersionRow } from "../document-version-row"
import { Grid } from "../grid"
import { GridItem } from "../grid-item"
import { Message } from "../message"
import { P } from "../typography"

const ListContainer = styled("div", {
  display: "flex",
  flexDirection: "column",

  "& > *:not(:last-child)": {
    marginBottom: "$40",

    "@mdDown": {
      marginBottom: "$20",
    },
  },
})

type Version = {
  date: string
  url: string
}

interface IDocumentVersionListProps {
  versions: Version[]
}

export const DocumentVersionList = ({
  versions,
}: IDocumentVersionListProps) => {
  const [current, ...older] = versions
  return (
    <div>
      <DocumentVersionRow
        css={{ marginBottom: 40 }}
        date={current.date}
        url={current.url}
        variant="current"
      />

      <Grid columns={10}>
        <GridItem col={[2, 10]}>
          <P
            noMargin
            size="extraSmall"
            css={{ marginBottom: "$10", color: "black" }}
          >
            <Message
              id="infrastructureDetails.archive.olderVersions.title"
              values={{ versionsLength: older.length }}
            />
          </P>

          <ListContainer>
            {older.map((version) => (
              <DocumentVersionRow
                date={version.date}
                url={version.url}
                key={version.url}
                variant="old"
              />
            ))}
          </ListContainer>
        </GridItem>
      </Grid>
    </div>
  )
}
