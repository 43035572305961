import { useMutation, useQueryClient } from "@tanstack/react-query"
import { toast } from "react-hot-toast"
import { certificationProcessService } from "src/services"
import { ImplementedSportProduct } from "src/services/v2/implemented-sport-product"

export function useUnlinkImplementedSportProduct(id: string) {
  const queryClient = useQueryClient()

  async function mutateFn(ispId: string) {
    const response = await certificationProcessService.unlinkImplementedSportProduct(id, ispId) // prettier-ignore
    await queryClient.refetchQueries(["certification-processes", id])
    return response.data
  }

  return useMutation(mutateFn, {
    onSuccess: (data: ImplementedSportProduct) => {
      toast.success(`${data.sportProduct.name} verwijderd`)
    },
  })
}
