import React, { ReactElement } from "react"
import { ILinkCard } from "src/services"
import { styled } from "src/stitches.config"

import { LinkCard } from "./fragments"

interface Props {
  linkCards: ILinkCard[]
}

const LinkCardsWrapper = styled("div", {
  width: "calc(100% + 30px)",
  marginLeft: "-15px",
  marginBottom: "-15px",

  "@mdUp": {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "stretch",
  },
})

const PageLinkCardItem = styled("div", {
  padding: "15px",

  "@mdUp": {
    flex: "0 0 calc(100% / 2)",
  },

  "@lgUp": {
    flex: "0 0 calc(100% / 3)",
  },
})

export const LinkCards = (props: Props): ReactElement => (
  <LinkCardsWrapper data-cy="cards-wrapper">
    {props.linkCards.map((linkCard) => (
      <PageLinkCardItem data-cy="card-item" key={linkCard.id.toString()}>
        <LinkCard {...linkCard} />
      </PageLinkCardItem>
    ))}
  </LinkCardsWrapper>
)
