import { styled } from "src/stitches.config"

export const FormItemInput = styled("div", {
  "&:not(last-child)": {
    marginBottom: "$10",
  },
  variants: {
    noMargin: {
      true: {
        marginBottom: "0 !important",
      },
    },
  },
})
