import { IIcon } from "@types"
import { ReactElement, ReactSVG } from "react"

export const EmailIcon = ({
  fill = "#FFFFFF",
}: IIcon): ReactElement<ReactSVG> => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
    <path
      d="M29.818 9c2.028 0 3.682 1.648 3.682 3.667v14.666c0 2.02-1.654 3.667-3.682 3.667H10.182C8.154 31 6.5 29.352 6.5 27.333V12.667C6.5 10.647 8.154 9 10.182 9h19.636zm1.227 6.013-10.342 7.21a1.232 1.232 0 0 1-1.407 0L8.953 15.015v12.318c0 .67.555 1.223 1.228 1.223h19.636c.673 0 1.227-.553 1.227-1.223v-12.32h.001zm-1.227-3.569H10.182c-.476 0-.894.278-1.096.678L20 19.73l10.914-7.608c-.202-.4-.62-.678-1.096-.678z"
      fill={fill}
    />
  </svg>
)
