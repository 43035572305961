import { IIcon } from "@types"
import { ReactElement, ReactSVG } from "react"

export const PlayVideoIcon = ({
  fill = "#00378A",
  height = "80",
  width = "80",
}: IIcon): ReactElement<ReactSVG> => (
  <svg width={width} height={height} viewBox="0 0 80 80" version="1.1">
    <title>NOCNSF/Atom/Icon/*/Play</title>
    <g
      id="NOCNSF/Atom/Icon/*/Play"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Icon"
        transform="translate(20.000000, 5.000000)"
        fill={fill}
        fillRule="nonzero"
      >
        <path
          d="M7.30038338,1.12275576 L7.46916034,1.28295872 L38.0939209,31.9077192 C38.5139176,32.3277159 38.8289151,32.8089622 39.0432884,33.3252081 C39.48516,34.3970748 39.48516,35.6045653 39.0432884,36.6764319 C38.8520399,37.1339284 38.5868634,37.5528537 38.2615343,37.9166773 L38.0939209,38.0939209 L7.46916034,68.7186814 C5.76292368,70.429293 2.99357034,70.424918 1.28295872,68.7186814 C0.333400615,67.7715518 -0.0890591226,66.4968394 0.0155795055,65.2574773 C0.00576173675,65.0995396 0,64.9364005 0,64.7686077 L0,4.98152898 L0.00404465789,4.74253407 L0.00961839172,4.64469546 C-0.0631558617,3.43811035 0.361978943,2.20629998 1.28295872,1.28295872 C2.93415548,-0.372471886 5.58098702,-0.425872873 7.30038338,1.12275576 Z M12.3725939,18.6527575 C11.5429104,17.9054769 10.2656992,17.9312452 9.46892498,18.7300624 C9.06895349,19.1310594 8.86278807,19.6526248 8.8511548,20.1773734 L8.85448294,20.3522464 L8.85179337,20.3994578 L8.84984165,20.5147833 L8.84984165,49.3646469 C8.84984165,49.4456141 8.85262193,49.5243358 8.85735943,49.6005477 C8.80686675,50.1985937 9.01072193,50.8136979 9.46892498,51.270729 C10.2530972,52.0528957 11.4983466,52.0939093 12.3268166,51.3883398 L12.4540361,51.270729 L27.2318139,36.4929513 L27.3126947,36.4074236 C27.4696801,36.2318628 27.5976393,36.0297132 27.689925,35.8089513 C27.9031472,35.291729 27.9031472,34.7090624 27.689925,34.1918402 C27.6071694,33.9925513 27.4933383,33.8040713 27.3473508,33.6328855 L27.2318139,33.5078402 L12.4540361,18.7300624 L12.3725939,18.6527575 Z"
          id="Shape"
        ></path>
      </g>
    </g>
  </svg>
)
