import React, { Fragment } from "react"
import {
  IInfrastructureSection,
  IInfrastructureSubsection,
  IKnowledgeBaseSection,
  IKnowledgeBaseSubSection,
} from "src/services"
import { styled } from "src/stitches.config"
import hasMandatoryRules from "src/utils/has-mandatory-rules"
import isMandatoryRule from "src/utils/is-mandatory-rule"

import { Grid } from "../../grid"
import { GridItem } from "../../grid-item"
import { H3, H4 } from "../../typography"
import { Rule } from "../rule"

interface Props {
  section: IInfrastructureSection | IKnowledgeBaseSection
  index: number
  hideOptionalRules: boolean
}

const SectionWrapper = styled("div", {
  marginBottom: "$80",
  counterReset: "ruleIndex",
  counterIncrement: "sectionIndex",

  "@lgDown": {
    marginBottom: "$40",
  },

  variants: {
    isHidden: {
      true: {
        height: "0",
        margin: "0",
        overflow: "hidden",
      },
    },
  },
})

const SectionIndex = styled("div", {
  fontFamily: "$nova",
  fontSize: "$bodyLarge",
  color: "$tertiary",
  textAlign: "right",

  "@lgDown": {
    fontSize: "$bodySmall",
    textAlign: "left",
  },
})

const SectionTitle = styled(H3, {
  marginBottom: "$20",
  color: "$blue",

  "@lgUp": {
    textAlign: "right",
    float: "right",
  },
})

const SectionBody = styled("div", {
  padding: "$40 0",
  backgroundColor: "$white",

  "@lgDown": {
    padding: "$20 0",
    marginLeft: "-$15",
    marginRight: "$30",
  },
})

const SubsectionTitle = styled(H4, {
  padding: "0 $60",
  marginBottom: "$20",
  color: "$tertiary",

  "@lgDown": {
    padding: "0 $15",
  },

  variants: {
    isHidden: {
      true: {
        height: "0",
        margin: "0",
        overflow: "hidden",
      },
    },
  },
})

export const Section = ({ index, hideOptionalRules, section }: Props) => {
  const isHidden = hideOptionalRules && !hasMandatoryRules(section)
  const subsections = (
    "sections" in section ? section.sections : section.knowledgeBaseSections
  ) as Array<IInfrastructureSubsection | IKnowledgeBaseSubSection>
  return (
    <SectionWrapper
      data-id={section.id}
      isHidden={isHidden}
      aria-hidden={isHidden}
      data-cy="section"
    >
      <Grid>
        <GridItem col={[1, 3]} medium={[1, 12]}>
          <SectionIndex>{index + 1}</SectionIndex>
          <SectionTitle data-cy="section-title">{section.title}</SectionTitle>
        </GridItem>

        <GridItem col={[4, 6]} medium={[1, 12]}>
          <SectionBody>
            {/* Iterate over the rules in top level section */}

            {("knowledgeBaseRules" in section
              ? section.knowledgeBaseRules
              : section.rules
            )?.map((rule) => {
              const isHidden = hideOptionalRules && !isMandatoryRule(rule)
              return <Rule key={rule.id} isHidden={isHidden} {...rule} />
            })}

            {/* Iterate over the the subsections */}
            {subsections.map((subsection) => {
              const isHidden =
                hideOptionalRules && !hasMandatoryRules(subsection)

              return (
                <Fragment key={subsection.id}>
                  <SubsectionTitle
                    isHidden={isHidden}
                    aria-hidden={isHidden}
                    data-cy="subsection-title"
                  >
                    {subsection.title}
                  </SubsectionTitle>

                  {/* Iterate over the rules in this subsection */}
                  {("knowledgeBaseRules" in subsection
                    ? subsection.knowledgeBaseRules
                    : subsection.rules
                  )?.map((rule) => {
                    const isHidden = hideOptionalRules && !isMandatoryRule(rule)
                    return <Rule key={rule.id} isHidden={isHidden} {...rule} />
                  })}
                </Fragment>
              )
            })}
          </SectionBody>
        </GridItem>
      </Grid>
    </SectionWrapper>
  )
}
