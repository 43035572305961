import { merge } from "lodash"
import { ILink } from "src/services"

const defaults: ILink = {
  id: "1",
  text: "Click here",
  href: "#",
}

export const createLink = (override?: Partial<ILink>): ILink =>
  merge({}, defaults, override)
