import { useMemo } from "react"
import { useIntl } from "react-intl"
import { useUserSubscriptions } from "src/hooks/subscription"
import { useUnsubscribeFromAll } from "src/hooks/subscription/useUnsubscribeFromAll"

import { Button } from "../button"
import { Message } from "../message"
import { PopoverConfirmation } from "../pop-over-confirmation"

export function UnsubscribeButton() {
  const { formatMessage } = useIntl()
  const { data, isLoading } = useUserSubscriptions()

  const { mutateAsync, isLoading: isMutating } = useUnsubscribeFromAll()

  const disabled = useMemo(() => {
    return !data || data?.length === 0 || isLoading || isMutating
  }, [data, isLoading, isMutating])

  return (
    <PopoverConfirmation
      message={formatMessage({
        id: "subscriptions.page.confirmation.popover.message",
      })}
      confirmButtonLabel={formatMessage({
        id: "subscriptions.page.confirm.label",
      })}
      onConfirm={mutateAsync}
      confirmIcon="checkmark"
      confirmButtonVariant="secondary"
      confirmIconColor="white"
      side="bottom"
      disabled={disabled}
    >
      <Button
        as="div"
        icon="notification"
        variant="secondary"
        size="large"
        outline
        disabled={disabled}
        isLoading={isLoading || isMutating}
      >
        <Message id="subscriptions.page.unsubscribeFromAll" />
      </Button>
    </PopoverConfirmation>
  )
}
