import { PropsWithChildren } from "react"
import { styled } from "src/stitches.config"

const ItemLabel = styled("label", {
  marginBottom: "$5",
  paddingTop: "5px",
  fontFamily: "$nova",
  fontWeight: "bold",
  fontSize: "$bodyLarge",
})

interface ItemLabelProps {
  as?: any
  htmlFor?: string
}

export const FormItemLabel = ({
  children,
  ...rest
}: PropsWithChildren<ItemLabelProps>) => (
  <ItemLabel {...rest}>{children}</ItemLabel>
)
