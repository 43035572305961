import Link from "next/link"
import { DATE_FORMAT } from "src/constants"
import { INewsItem } from "src/services"
import { styled } from "src/stitches.config"
import formatDate from "src/utils/format-date"

import { IconWrapper } from "../icon-wrapper"
import { H4, P } from "../typography"

const NewsItemTitleText = styled("span", {
  wordBreak: "break-word",
})

export const NewsItemGridCard = ({ newsItem }: { newsItem: INewsItem }) => (
  <NewsItemCard>
    <P data-cy="news-item-date" noMargin size="small">
      {formatDate(newsItem.published_at, DATE_FORMAT)}
    </P>

    <Link href={`/nieuws/${newsItem.id}`} data-cy="news-item-link">
      <NewsItemTitle>
        <H4 data-cy="news-item-link-title">
          <NewsItemTitleText>{newsItem.title}</NewsItemTitleText>
        </H4>
      </NewsItemTitle>
    </Link>

    <NewsItemLead data-cy="news-item-lead">
      <P noMargin>{newsItem.lead}</P>
    </NewsItemLead>

    <IconWrapperStyled data-cy="news-item-link-icon">
      <IconWrapper name="arrowRight" />
    </IconWrapperStyled>
  </NewsItemCard>
)

const NewsItemCard = styled("div", {
  position: "relative",
  padding: "$20",
  borderRadius: "4px",
  backgroundColor: "#fff",
  boxShadow: "$default",
  transition: ".2s transform",
  height: "100%",
  width: "100%",

  "a, a:hover, a:focus": {
    textDecoration: "none",
  },

  "&:hover, &:focus-within": {
    transform: "translateY(-3px)",
  },

  "a::before": {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    content: "''",
  },
})

const NewsItemTitle = styled("div", {
  position: "relative",
  margin: "$10 0",

  "&::before": {
    position: "absolute",
    top: "-3px",
    bottom: "-5px",
    left: "-24px",
    width: "30px",
    backgroundColor: "$tertiary",
    content: "",
  },

  "&::after": {
    position: "absolute",
    bottom: "-9px",
    left: "-24px",
    width: "0",
    height: "0",
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: "#215198 #215198 transparent transparent",
    content: "",
  },

  [H4.toString()]: {
    position: "relative",

    span: {
      padding: "2px 10px 5px 0",
      textTransform: "uppercase",
      color: "$white",
      backgroundColor: "$tertiary",
      boxDecorationBreak: "clone",
    },
  },

  "@mdDown": {
    "&::before": {
      top: "-2px",
    },
  },
})

const NewsItemLead = styled("div", {
  marginRight: "$30",
  display: "-webkit-box",
  "-webkit-line-clamp": 8,
  "-webkit-box-orient": "vertical",
  overflow: "hidden",
  wordBreak: "break-word",
})

const IconWrapperStyled = styled("span", {
  position: "absolute",
  right: "$20",
  bottom: "$20",
  transition: ".2s transform",

  [`${NewsItemCard}:hover &, ${NewsItemCard}:focus-within &`]: {
    transform: "translateX(4px)",
  },
})
