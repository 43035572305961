import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const DuplicateIcon = ({
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
    version="1.1"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(9.000000, 1.000000)">
        <path
          d="M1.08333333,0 C0.4875,0 0,0.50625 0,1.125 L0,16.875 C0,17.49375 0.4875,18 1.08333333,18 L11.9166667,18 C12.5125,18 13,17.49375 13,16.875 L13,4.5 L8.66666667,0 L1.08333333,0 Z"
          fill="#CBD4C2"
        ></path>
        <path
          d="M9.75,4.5 L13,4.5 L8.66666667,0 L8.66666667,3.375 C8.66666667,3.99375 9.15416667,4.5 9.75,4.5 Z"
          fill="#94A286"
        ></path>
        <polygon
          fillOpacity="0.1"
          fill="#4A4A4A"
          fillRule="nonzero"
          points="13 7.875 9.75 4.5 13 4.5"
        ></polygon>
      </g>
      <g transform="translate(2.000000, 5.000000)">
        <path
          d="M1.08333333,0 C0.4875,0 0,0.50625 0,1.125 L0,16.875 C0,17.49375 0.4875,18 1.08333333,18 L11.9166667,18 C12.5125,18 13,17.49375 13,16.875 L13,4.5 L8.66666667,0 L1.08333333,0 Z"
          fill="#CBD4C2"
        ></path>
        <path
          d="M9.75,4.5 L13,4.5 L8.66666667,0 L8.66666667,3.375 C8.66666667,3.99375 9.15416667,4.5 9.75,4.5 Z"
          fill="#94A286"
        ></path>
        <polygon
          fillOpacity="0.1"
          fill="#4A4A4A"
          fillRule="nonzero"
          points="13 7.875 9.75 4.5 13 4.5"
        ></polygon>
      </g>
      <g transform="translate(5.500000, 12.500000)" fill="#94A286">
        <g transform="translate(0.250000, 0.250000)">
          <path d="M2.25,0.55 C2.25,0.246243387 2.47385765,0 2.75,0 C3.02614235,0 3.25,0.246243387 3.25,0.55 L3.25,4.95 C3.25,5.25375659 3.02614235,5.5 2.75,5.5 C2.47385765,5.5 2.25,5.25375659 2.25,4.95 L2.25,0.55 Z"></path>
          <path d="M0.55,3.25 C0.246243387,3.25 0,3.02614235 0,2.75 C0,2.47385765 0.246243387,2.25 0.55,2.25 L4.95,2.25 C5.25375659,2.25 5.5,2.47385765 5.5,2.75 C5.5,3.02614235 5.25375659,3.25 4.95,3.25 L0.55,3.25 Z"></path>
        </g>
      </g>
    </g>
  </svg>
)
