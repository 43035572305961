import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock"
import { signIn, signOut, useSession } from "next-auth/react"
import { useRouter } from "next/router"
import {
  Fragment,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useMeasure } from "react-use"
import useKeypress from "src/hooks/useKeypress"

import { IconWrapper, iconNameType } from "../icon-wrapper"
import {
  AccountNavButtonWithButtons,
  MobileNavigation,
  MobileSlideNavigation,
  NavBar,
  NavItemLink,
  NavMenu,
  NavMenuItemLink,
  NavSubmenu,
  NavSubmenuItemLink,
  NonMobileNavigationWrap,
  PublicLayoutHeader,
  TabletMenuItems,
} from "./fragments"

export type MenuItemList = {
  href: string
  label?: string
  icon?: iconNameType
  items?: MenuItemList[]
  tabletMenu?: boolean
  mobileLabel?: string
}
interface NavigationProps {
  items: MenuItemList[]
}

export const Navigation = ({ items }: NavigationProps) => {
  const { asPath } = useRouter()

  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const [ref, { width }] = useMeasure<HTMLDivElement>()
  const mobileSliderRef: MutableRefObject<HTMLDivElement | null> = useRef(null)
  const [mobileSliderElement, setMobileSliderElement] =
    useState<HTMLDivElement | null>()

  const handleLoginClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault()
      signIn("azure-ad")
    },
    [],
  )

  const handleLogoutClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault()
      signOut()
    },
    [],
  )

  const regularMenus = useMemo(
    () => items.filter((item) => !item.tabletMenu),
    [items],
  )
  const tabletMenus = useMemo(
    () => items.filter((item) => item.tabletMenu),
    [items],
  )
  const toggleMobileMenu = useCallback(
    () => setMobileNavOpen(!mobileNavOpen),
    [mobileNavOpen],
  )

  useEffect(() => {
    if (mobileSliderElement) {
      mobileNavOpen
        ? disableBodyScroll(mobileSliderElement)
        : enableBodyScroll(mobileSliderElement)
    }
  }, [mobileNavOpen])

  useEffect(() => {
    setMobileSliderElement(mobileSliderRef.current)
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [])

  useEffect(() => {
    if (width > 0) setMobileNavOpen(false)
  }, [width])

  useKeypress("Escape", () => {
    setMobileNavOpen(false)
  })

  return (
    <Fragment>
      <PublicLayoutHeader>
        <NavBar role="navigation" data-cy="navigation">
          <NavItemLink href="/" label="Home" data-cy="logo">
            <IconWrapper name="logo" />
          </NavItemLink>
          <NavMenu>
            <MobileNavigation
              onClick={toggleMobileMenu}
              onLogoutClick={handleLogoutClick}
              onLoginClick={handleLoginClick}
            />

            <NonMobileNavigationWrap ref={ref}>
              {regularMenus.map((item) => {
                if (item.items?.length) {
                  return (
                    <NavSubmenu
                      label={item.label}
                      slowAnimation
                      key={`${item.href}-${item.label}`}
                    >
                      {item.items.map((childItem) => (
                        <NavSubmenuItemLink
                          label={childItem.label}
                          key={childItem.href}
                          href={childItem.href}
                          data-cy="navigation-subitem"
                        >
                          {childItem.label}
                        </NavSubmenuItemLink>
                      ))}
                    </NavSubmenu>
                  )
                } else {
                  return (
                    <NavMenuItemLink
                      href={item.href!}
                      label={item.label}
                      key={`${item.href}-${item.label}`}
                      isActive={asPath === "/"}
                      data-cy="navigation-item"
                    >
                      {item.icon ? (
                        <IconWrapper name={item.icon} />
                      ) : (
                        item.label
                      )}
                    </NavMenuItemLink>
                  )
                }
              })}

              <TabletMenuItems
                items={tabletMenus.map(({ href, label }) => ({
                  href,
                  label: label!,
                }))}
              />

              <AccountNavButtonWithButtons
                onLogoutClick={handleLogoutClick}
                onLoginClick={handleLoginClick}
              />
            </NonMobileNavigationWrap>
          </NavMenu>
        </NavBar>
      </PublicLayoutHeader>

      <MobileSlideNavigation
        open={mobileNavOpen}
        onCloseClick={toggleMobileMenu}
        items={[...regularMenus, ...tabletMenus]}
        ref={mobileSliderRef}
      />
    </Fragment>
  )
}
