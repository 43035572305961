import React, { MouseEvent } from "react"
import { styled } from "src/stitches.config"

import { LocationIcon } from "../../assets-icons"
import { Button } from "../../button"

const LocationIconWrapper = styled("div", {
  width: "16px",
  height: "16px",
})

const ButtonContentWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  "& > *": {
    marginRight: "5px",
  },
})

interface LocationProps {
  onClick: (event: MouseEvent<HTMLAnchorElement>) => void
  size?: "xsmall" | "small" | "regular" | "large"
  isDisabled?: boolean
}

export const LocationButton = ({
  onClick,
  size = "regular",
  isDisabled = false,
}: LocationProps) => {
  return (
    <Button
      data-cy="form-input-location-button"
      size={size}
      variant="secondary"
      onClick={onClick}
      disabled={isDisabled}
    >
      <ButtonContentWrapper>
        <LocationIconWrapper>
          <LocationIcon backgroundColor="#31493d" />
        </LocationIconWrapper>
        Locatie toevoegen
      </ButtonContentWrapper>
    </Button>
  )
}
