import { INewsItem } from "src/services"
import { styled } from "src/stitches.config"

import { NewsItemGridCard } from "../news-item-grid-card"

interface Props {
  newsItems: INewsItem[]
}

const NewsItemsGridWrapper = styled("div", {
  display: "flex",
  flexWrap: "wrap",
  width: "calc(100% + $sizes$30)",
  margin: "-$15 -$15 $80",
  alignItems: "stretch",

  "@mdDown": {
    marginBottom: "$40",
  },
})

const NewsItemsGridItem = styled("div", {
  flex: "0 0 100%",
  padding: "$15",

  "@mdUp": {
    flex: "0 0 calc(100% / 2)",
  },

  "@lgUp": {
    flex: "0 0 calc(100% / 3)",
  },
})

export const NewsItemsGrid = ({ newsItems }: Props) => {
  return (
    <NewsItemsGridWrapper data-cy="news-items">
      {newsItems.map((newsItem) => (
        <NewsItemsGridItem data-cy="news-item" key={newsItem.id}>
          <NewsItemGridCard newsItem={newsItem} />
        </NewsItemsGridItem>
      ))}
    </NewsItemsGridWrapper>
  )
}
