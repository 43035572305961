import { Dialog, Transition } from "@headlessui/react"
import { FC, Fragment, useCallback, useMemo } from "react"
import { Button } from "src/components/button"
import { H3, P } from "src/components/typography"
import { styled } from "src/stitches.config"

import { useModal } from "./use-modal"

const ModalOverlay = styled("div", {
  position: "fixed",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: "rgba(0, 0, 0, 0.25)",
})

const ModalWrapper = styled("div", {
  position: "fixed",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  overflowY: "auto",
})

const ModalDescription = styled(Dialog.Description, {
  marginTop: "0.5rem",
})

const DialogPanel = styled(Dialog.Panel, {
  width: "100%",
  maxWidth: "50rem",
  transform: "none",
  overflow: "hidden",
  borderRadius: "8px",
  backgroundColor: "white",
  textAlign: "left",
  verticalAlign: "middle",

  boxShadow:
    "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
  transitionProperty: "all",
  transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
  transitionDuration: "150ms",
})

const Modal = styled("div", {
  display: "flex",
  minHeight: "100vh",
  alignItems: "center",
  justifyContent: "center",
  padding: "4px",
  textAlign: "center",
})

const TitleSection = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  background: "$grayLight",
  padding: "2rem",
})

const ContentSection = styled("section", {
  padding: "2rem",
})

const CloseButtonWrappeer = styled("div", {
  display: "flex",
  height: "40px",
  alignItems: "center",
  gap: "10px",
  color: "$bodyGreen",
})

export const V2Modal: FC<{ onClose?: Function }> = ({ onClose }) => {
  const { stack, close } = useModal()

  const closeModal = useCallback(async () => {
    close()
    await onClose?.()
  }, [close, onClose])

  // TODO: fix the messed up animations between stacked modals
  const activeModal = stack[stack.length - 1]?.id
  const isStacked = stack.length > 1

  const description = useMemo(() => {
    const description = stack[stack.length - 1]?.description

    if (!description) return null

    if (typeof description === "string") return description

    return description()
  }, [stack])

  return (
    <>
      {stack.map((modal) => (
        <Transition
          appear={modal.id === activeModal}
          show={modal.id === activeModal}
          as={Fragment}
          key={modal.id}
        >
          <Dialog
            as="div"
            style={{
              position: "relative",
              zIndex: 10,
            }}
            onClose={closeModal}
          >
            {modal.id === activeModal && <ModalOverlay />}

            <ModalWrapper>
              <Modal>
                <DialogPanel>
                  <TitleSection>
                    <div>
                      <H3 noMargin>{modal.title}</H3>
                      {modal.subtitle && (
                        <P noMargin light size="extraLarge">
                          {modal.subtitle}
                        </P>
                      )}
                    </div>
                    <CloseButtonWrappeer>
                      <P size="small" noMargin css={{ fontWeight: 400 }}>
                        Annuleren
                      </P>
                      <Button
                        variant="light"
                        as="div"
                        size="large"
                        icon="delete"
                        onClick={closeModal}
                      />
                    </CloseButtonWrappeer>
                  </TitleSection>

                  <ContentSection>
                    {description && (
                      <ModalDescription as="div">
                        <P>{description}</P>
                      </ModalDescription>
                    )}
                    {modal.jsx}
                  </ContentSection>
                </DialogPanel>
              </Modal>
            </ModalWrapper>
          </Dialog>
        </Transition>
      ))}
    </>
  )
}
