import { AxiosResponse } from "axios"

import { V2Api } from "../api.v2"
import { SportProductSubtype } from "./types"

export interface SportProductSubtypeFilters {
  typeId?: string
}

export class SportProductSubtypeService extends V2Api {
  public async getAll(
    filters?: SportProductSubtypeFilters,
  ): Promise<AxiosResponse<SportProductSubtype[]>> {
    return super.get("/sport-product-subtypes", {
      params: filters,
    })
  }
}
