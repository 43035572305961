import React, { ReactElement } from "react"
import { IImage } from "src/services"
import { styled } from "src/stitches.config"

import { Image } from "../image"

interface Props {
  images: IImage[]
}

// It's not possible to limit the amount of images in the CMS, so we're setting a
// limit here. The ImageGallery component should not display more than this amount.
const MAX_IMAGES = 2

const ImageGalleryWrapper = styled("div", {
  "@mdUp": {
    display: "flex",
    width: "calc(100% + $40)",
    marginLeft: "-$20",
  },
})

export const ImageGallery = (props: Props): ReactElement => (
  <ImageGalleryWrapper data-cy="image-gallery">
    {[...props.images].splice(0, MAX_IMAGES).map((image) => (
      <Image {...image} key={image.id} />
    ))}
  </ImageGalleryWrapper>
)
