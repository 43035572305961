import axios from "axios"
import https from "https"
import qs from "query-string"
import { ReactText } from "react"
import { THandbookVersion } from "src/components"
import appConfig from "src/config"
import {
  IInfrastructureData,
  IOrganization,
  THandbook,
  TKnowledgeBase,
  TProduct,
  TProductSystemStatusUpdate,
  TRoleWithPermissions,
  TUser,
} from "src/services"
import { certificationSchema } from "src/utils/form-schemas/certification"
import setSubscriptionKeyHeader from "src/utils/set-subscription-header"
import * as yup from "yup"

import { AccommodationApproval } from "./types"

type RequestMethod = "get" | "post" | "put" | "delete"

type RequestOptions = {
  skipAuthorization?: boolean
  server?: {
    urlPrefix: string
    apiKey?: string
    apiToken?: string
  }
  client?: {
    urlPrefix: string
    apiKey?: string
  }
}

type Sport = {
  code: string
  name: string
  disciplines: Array<{
    code: string
    name: string
    sportCode: string
  }>
}
export enum EventType {
  NEW_PROCEDURE = "new-procedure",
  NEW_NEWS = "new-news",
  SPORT_REGULATION_CHANGE = "sport-regulation-change",
  NEW_KNOWLEDGE_BASE = "new-knowledge-base",
}

type Subscription = {
  id: string
  sportCode: string | null
  type: EventType
}

export type SubscriptionArgs = {
  sportCodes?: string[]
  type: EventType
}

type Filters = {
  sports: Array<Sport>
  locations: string[]
  categories: string[]
}

interface GetDisciplinesByTitle {
  id: number
  code: string
  title: string
  lead: string
  sports_union: Sportsunion
  published_at: string
  created_at: string
  updated_at: string
}

interface Sportsunion {
  id: number
  name: string
  address: string
  phoneNumber: string
  website: string
  published_at: string
  created_at: string
  updated_at: string
  emailAddress: string
  street: string
  postalCode: string
  contactName: string
  city: string
  logo: Logo
}
interface Logo {
  id: number
  name: string
  alternativeText: string
  caption: string
  width: number
  height: number
  formats?: any
  hash: string
  ext: string
  mime: string
  size: number
  url: string
  previewUrl?: any
  provider: string
  provider_metadata?: any
  created_at: string
  updated_at: string
}

export interface ErrorWithStatus extends Error {
  statusCode: number
}

const instance = axios.create({
  timeout: 30000,
  httpsAgent: new https.Agent({ keepAlive: true }),
})

function createRequest(method: RequestMethod, options: RequestOptions = {}) {
  return async (url: string, data?: any, headers: any = {}, params?: any) => {
    const isClientSide = process.browser
    const isServerSide = !process.browser

    if (isClientSide) {
      if (!options.client) {
        throw Error("Method has no client side options")
      } else {
        url = `${options.client.urlPrefix}${url}`

        if (options.client?.apiKey) {
          headers = {
            ...headers,
            ...setSubscriptionKeyHeader(options.client.apiKey),
          }
        }
      }
    }

    if (isServerSide) {
      if (!options.server) {
        throw Error("Method has no server side options")
      } else {
        url = `${options.server.urlPrefix}${url}`

        if (options.server?.apiKey) {
          headers = {
            ...headers,
            ...setSubscriptionKeyHeader(options.server.apiKey),
          }
        }
      }
    }

    if (!options.skipAuthorization && api.accessToken) {
      headers["X-Custom-Authorization"] = `Bearer ${api.accessToken}`
    }

    if (options.server?.apiToken) {
      headers["Authorization"] = `Bearer ${options.server.apiToken}`
    }

    try {
      const response = await instance.request({
        method,
        url,
        data,
        headers,
        params,
      })
      console.debug(`[${method.toUpperCase()}] ${url} - OK`)

      return response.data
    } catch (error) {
      let errorMessage = (error as any).response?.data?.message
      if (errorMessage) {
        console.error(errorMessage)
      }

      console.debug(
        `[${method.toUpperCase()}] ${url} - ${
          (error as any).response?.status || 500
        } ${(error as any).response?.statusText || "Unknown error"}`,
      )

      let err = new Error(
        errorMessage ||
          (error as any).response?.statusText ||
          JSON.stringify(error),
      ) as ErrorWithStatus
      err.statusCode = (error as any).response?.status || 500
      throw err
    }
  }
}

export const internal = {
  admin: {
    get: createRequest("get", {
      server: {
        urlPrefix: appConfig.admin?.urlPrefix,
        apiKey: appConfig.admin?.apiKey,
      },
      client: { urlPrefix: "/api/admin" },
    }),
    put: createRequest("put", {
      server: {
        urlPrefix: appConfig.admin?.urlPrefix,
        apiKey: appConfig.admin?.apiKey,
      },
      client: { urlPrefix: "/api/admin" },
    }),
    post: createRequest("post", {
      server: {
        urlPrefix: appConfig.admin?.urlPrefix,
        apiKey: appConfig.admin?.apiKey,
      },
      client: { urlPrefix: "/api/admin" },
    }),
    delete: createRequest("delete", {
      server: {
        urlPrefix: appConfig.admin?.urlPrefix,
        apiKey: appConfig.admin?.apiKey,
      },
      client: { urlPrefix: "/api/admin" },
    }),
  },
  handbook: {
    get: createRequest("get", {
      server: {
        urlPrefix: appConfig.handbook?.urlPrefix,
        apiKey: appConfig.handbook?.apiKey,
      },
    }),
  },
  strapi: {
    get: createRequest("get", {
      skipAuthorization: true,
      server: {
        urlPrefix: appConfig.strapi?.urlPrefix,
        apiKey: appConfig.strapi?.apiKey,
        apiToken: appConfig.strapi?.apiToken,
      },
      client: { urlPrefix: "/api/strapi" },
    }),
  },
  search: {
    handbooks: {
      post: createRequest("post", {
        server: {
          urlPrefix: `${appConfig.search?.searchServiceUrl}:9000/indexes/${appConfig.search?.serviceHandbookIndex}/docs/search`,
        },
      }),
    },
    sportProducts: {
      post: createRequest("post", {
        server: {
          urlPrefix: `${appConfig.search?.searchServiceUrl}:9000/indexes/${appConfig.search?.serviceSportProductsIndex}/docs/search`,
        },
      }),
    },
    accommodations: {
      post: createRequest("post", {
        server: {
          urlPrefix: `${appConfig.search?.searchServiceUrl}:9000/indexes/${appConfig.search?.serviceAccommodationsIndex}/docs/search`,
        },
      }),
    },
  } as const,
}

type HandbookGetDocumentVersionsByIdResponse = THandbookVersion[]

type CreateDocumentFileResponse = {
  name: string
  url: string
}

const api = {
  admin: {
    async getDocumentById(documentId: ReactText): Promise<THandbook> {
      return await internal.admin.get(`/document/${documentId}`)
    },
    async getDrawingsByDocumentId(documentId: ReactText, version: ReactText) {
      return await internal.admin.get(
        `/document/${documentId}/versions/${version}/drawings`,
      )
    },
    async getDrawingVariants(
      documentId: ReactText,
      version: ReactText,
      drawingId: ReactText,
    ) {
      return await internal.admin.get(
        `/document/${documentId}/versions/${version}/drawings/${drawingId}/variants`,
      )
    },
    async updateDocumentById(documentId: ReactText, handbook: any) {
      return await internal.admin.put(`/document/${documentId}`, { handbook })
    },
    async createDocumentVersionById(documentId: ReactText, handbook: any) {
      return await internal.admin.post(`/document/${documentId}`, { handbook })
    },
    async createDocument(handbook: any) {
      return await internal.admin.post(`/document/`, { handbook })
    },
    async createOrganization(values: any) {
      return await internal.admin.post(`/organizations`, values)
    },
    async getOrganizationsByType(type: string): Promise<IOrganization[]> {
      return await internal.admin.get(`/organizations?type=${type}`)
    },
    async getOrganizationById(id: string) {
      return await internal.admin.get(`/organizations/${id}`)
    },
    async updateOrganizationById(id: string, values: any) {
      return await internal.admin.put(`/organizations/${id}`, values)
    },
    async getUserPermissions() {
      return await internal.admin.get(`/user/permissions`)
    },
    async getUserOrganizations() {
      return await internal.admin.get(`/user/organizations`)
    },
    // dropdown options
    async getSportProductTypes() {
      return await internal.admin.get(`/sport-product-types`)
    },
    async getSportProductSubtypes() {
      return await internal.admin.get(`/sport-product-subtypes`)
    },
    async getSportProductSystemStatuses(): Promise<{
      data: TProduct["systemStatus"][]
    }> {
      return await internal.admin.get(`/sport-product-system-statuses`)
    },
    async getSportProductProductStatuses() {
      return await internal.admin.get(`/sport-product-product-statuses`)
    },
    async getReasons() {
      return await internal.admin.get(`/reasons`)
    },
    async getDisciplines() {
      return await internal.admin.get(`/disciplines`)
    },
    async getLocations() {
      return await internal.admin.get(`/locations`)
    },
    async getMunicipalities(): Promise<any> {
      return await internal.handbook.get("/municipalities")
    },
    async getSustainabilityLabels() {
      return await internal.admin.get(`/sustainability-labels`)
    },
    async updateOrganizationStatus(id: number, active: boolean) {
      return await internal.admin.put(`/organizations/`, {
        status: [{ id, active }],
      })
    },
    async getSportsProducts(): Promise<{ data: TProduct[] }> {
      return await internal.admin.get(`/sport-products`)
    },
    async getSportsProductById(id: string) {
      return await internal.admin.get(`/sport-products/${id}`)
    },
    async createSportsProduct(values: TProduct): Promise<TProduct> {
      return await internal.admin.post(`/sport-products`, values)
    },
    async updateSportsProductById(id: string, values: any) {
      return await internal.admin.put(`/sport-products/${id}`, values)
    },
    async uploadFile(
      endpoint: string,
      name: string,
      content: string,
      extraPostData: any = {},
    ) {
      return await internal.admin.post(endpoint, {
        name,
        content,
        ...extraPostData,
      })
    },
    async createDocumentFile(
      name: string,
      content: string,
    ): Promise<CreateDocumentFileResponse> {
      return await internal.admin.post("/document-file", {
        name: name,
        content: content,
      })
    },
    async updateDocumentStatusById(documentId: string, status: any) {
      return await internal.admin.put(`/document/${documentId}/status`, {
        status,
      })
    },
    async updateSportsProductStatusById(
      id: string,
      status: TProductSystemStatusUpdate,
    ) {
      return await internal.admin.put(`/sport-products/${id}/status/`, {
        status,
      })
    },
    async updateAccommodationCity(id: string, city: string) {
      return await internal.admin.put(`/accommodation-city`, { id, city })
    },
    async getUsers(): Promise<{ data: TUser[] }> {
      return await internal.admin.get("/users")
    },
    async getPermissions(): Promise<{ data: TRoleWithPermissions[] }> {
      return await internal.admin.get("/permissions")
    },
    async getAccommodation(id: string): Promise<AccommodationApproval> {
      return await internal.admin.get(`/accommodation/${id}`)
    },

    async createCertification(
      values: yup.InferType<typeof certificationSchema>,
    ): Promise<void> {
      return internal.admin.post(`/createCertificate`, values)
    },
    async regenerateCertificate(certificateId: string): Promise<void> {
      return internal.admin.post(`/regenerate-certificate`, { certificateId })
    },
    async getAssociations(): Promise<any> {
      return await internal.admin.get("/associations")
    },
  },
  knowledgeBase: {
    async getKnowledgeBaseItemsByType(type: string): Promise<any> {
      return await internal.admin.get(`/knowledge-base?type=${type}`)
    },
    async deleteDocumentById(id: string): Promise<any> {
      return await internal.admin.delete(`/knowledge-base/${id}`)
    },
    async getDocumentById(id: string): Promise<any> {
      return await internal.admin.get(`/knowledge-base/${id}`)
    },

    async createDocument(document: TKnowledgeBase): Promise<TKnowledgeBase> {
      return await internal.admin.post(`/knowledge-base/`, document)
    },
    async updateDocument(
      id: string,
      document: TKnowledgeBase,
    ): Promise<TKnowledgeBase> {
      return await internal.admin.put(`/knowledge-base/${id}`, document)
    },
    async createFileByCode(
      id: ReactText,
      name: string,
      content: string,
    ): Promise<CreateDocumentFileResponse> {
      return await internal.admin.post(`/knowledge-base/${id}/files`, {
        name: name,
        content: content,
      })
    },
  },
  handbook: {
    async status(): Promise<Record<string, string>> {
      return await internal.handbook.get("/status")
    },
    async getDocumentById(id: ReactText): Promise<IInfrastructureData> {
      return await internal.handbook.get(`/document/${id}`)
    },
    async getDocumentArchiveById(
      id: ReactText,
    ): Promise<HandbookGetDocumentVersionsByIdResponse> {
      return await internal.handbook.get(`/document/${id}/versions`)
    },
    async getDocumentVersionById(
      id: ReactText,
      version: ReactText,
    ): Promise<IInfrastructureData> {
      return await internal.handbook.get(`/document/${id}/versions/${version}`)
    },
    async getFilters(): Promise<Filters> {
      return await internal.handbook.get("/filters")
    },
    async getSportsAndDisciplines() {
      return await internal.handbook.get("/sports")
    },
    async getSportClubs() {
      return await internal.handbook.get("/sportClubs")
    },
  },
  strapi: {
    async getSingleTypeByName(name: string) {
      return await internal.strapi.get(`/${name}`)
    },
    async getPagesByRoute(route: string) {
      return await internal.strapi.get(`/pages?route=${route}`)
    },
    async getDisciplinesByTitle(
      titles: string[],
    ): Promise<GetDisciplinesByTitle[]> {
      const query = titles.map((title) => `title_in=${title}`).join("&")
      return await internal.strapi.get(`/disciplines?${query}`)
    },
    async getDisciplinesByCode(code: string) {
      return await internal.strapi.get(`/disciplines?code=${code}`)
    },
    async getNewsItems(start?: number, limit?: number, newsTagId?: number) {
      const query = qs.stringify({
        _start: start,
        _limit: limit,
        _sort: "published_at:DESC",
        "tags.id": newsTagId,
      })
      return await internal.strapi.get(`/news-items?${query}`)
    },
    async getNewsItemsCount(newsTagId?: number) {
      const query = qs.stringify({ "tags.id": newsTagId })
      return await internal.strapi.get(`/news-items/count?${query}`)
    },
    async getNewsItemById(id: string) {
      return await internal.strapi.get(`/news-items?id=${id}`)
    },
    async getNewsTags() {
      return await internal.strapi.get(`/news-tags?news_items_null=false`)
    },
    async getErrorPagesByErrorType(errorType: string) {
      return (await internal.strapi.get(`/error-pages?error=${errorType}`))[0]
    },
    async getBoardMembers() {
      return await internal.strapi.get(`/board-members`)
    },
  },
  subscription: {
    async getUserSubscriptions(): Promise<Subscription[]> {
      return internal.admin.get("/user-subscriptions")
    },
    async subscribe(args: SubscriptionArgs): Promise<Subscription> {
      return internal.admin.post("/subscribe-to-changes", args)
    },
    async unsubscribe(args: SubscriptionArgs): Promise<Subscription> {
      return internal.admin.delete("/unsubscribe-from-changes", args)
    },
    async unsubscribeFromAll(): Promise<void> {
      return internal.admin.delete("/unsubscribe-from-all")
    },
  },

  search: {
    async searchHandbooks(searchQueryData: any, headers?: any, params?: any) {
      const { queryKey, apiVersion } = appConfig.search
      return await internal.search.handbooks.post(
        "/",
        searchQueryData,
        { "api-key": queryKey, ...headers },
        { "api-version": apiVersion, ...params },
      )
    },
    async searchSportProducts(
      searchQueryData: any,
      headers?: any,
      params?: any,
    ) {
      const { queryKey, apiVersion } = appConfig.search
      return await internal.search.sportProducts.post(
        "/",
        searchQueryData,
        { "api-key": queryKey, ...headers },
        { "api-version": apiVersion, ...params },
      )
    },
    async accommodations(searchQueryData: any, headers?: any, params?: any) {
      const { queryKey, apiVersion } = appConfig.search
      return await internal.search.accommodations.post(
        "/",
        searchQueryData,
        { "api-key": queryKey, ...headers },
        { "api-version": apiVersion, ...params },
      )
    },
  },
  accessToken: "",
  setAccessToken(token: string) {
    this.accessToken = token
  },
}

export default api
