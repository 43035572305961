import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const DownloadIcon = ({
  color = "currentColor",
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 80 80"
    version="1.1"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(5.000000, 8.100505)"
        fill={color}
        fillRule="nonzero"
      >
        <g transform="translate(35.000000, 21.000000) scale(-1, 1) rotate(90.000000) translate(-35.000000, -21.000000) translate(14.000000, 0.000000)">
          <path d="M18.0495092,7.61147108 L27.0646104,16.6265723 L4.65933961,16.6265723 C2.24430187,16.6265723 0.284271247,18.5866029 0.284271247,21.0016407 C0.284271247,23.4166784 2.24430187,25.376709 4.65933961,25.376709 L27.0646104,25.376709 L18.0495092,34.3918102 C16.3388574,36.1024619 16.3388574,38.8718802 18.0495092,40.5781569 C19.7601609,42.2844335 22.5295792,42.2888086 24.2358558,40.5781569 L40.7191987,24.094814 C41.121705,23.6923077 41.4454601,23.2110502 41.6685886,22.6772918 C42.1104705,21.6054001 42.1104705,20.3978812 41.6685886,19.3259895 C41.4542102,18.8097314 41.1392053,18.3284739 40.7191987,17.9084673 L24.2358558,1.42512442 C22.5252041,-0.285527309 19.7557858,-0.285527309 18.0495092,1.42512442 C16.3432325,3.13577615 16.3388574,5.90519442 18.0495092,7.61147108 Z" />
        </g>

        <path d="M15.3125,66.0515152 C6.890625,66.0515152 0,59.1608902 0,50.7390152 L0,46.6765152 C0,44.2702652 1.96875,42.3015152 4.375,42.3015152 C6.78125,42.3015152 8.75,44.2702652 8.75,46.6765152 L8.75,50.7390152 C8.75,54.3483902 11.703125,57.3015152 15.3125,57.3015152 L55.054859,57.3015152 C58.664234,57.3015152 61.617359,54.3483902 61.617359,50.7390152 L61.617359,50.7390152 L61.617359,46.6765152 C61.617359,44.2702652 63.586109,42.3015152 65.992359,42.3015152 C68.398609,42.3015152 70.367359,44.2702652 70.367359,46.6765152 L70.367359,46.6765152 L70.367359,50.7390152 C70.367359,59.1608902 63.476734,66.0515152 55.054859,66.0515152 L55.054859,66.0515152 L15.3125,66.0515152 Z" />
      </g>
    </g>
  </svg>
)
