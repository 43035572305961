import Link from "next/link"
import { styled } from "src/stitches.config"

import { SidePanel, SidePanelProps } from "../side-panel"

interface SidePanelNavigationProps extends SidePanelProps {
  links: {
    label: string
    href: string
  }[]
}

export const SidePanelNavigation = ({
  links,
  ...rest
}: SidePanelNavigationProps) => {
  return (
    <SidePanel {...rest}>
      <NavWrap>
        {links &&
          links.map((link) => (
            <Link href={link.href} key={link.href}>
              <NavLink>{link.label}</NavLink>
            </Link>
          ))}
      </NavWrap>
    </SidePanel>
  )
}

const NavWrap = styled("div", {
  padding: "2rem 0",

  a: {
    display: "inline-flex",
    width: "100%",
  },
})

const NavLink = styled("span", {
  padding: ".5rem 2rem",
  width: "100%",
  backgroundColor: "#6d6c6c",
  color: "$grayLightest",

  "&:hover": {
    color: "$orangeLight",
  },
})
