import { PropsWithChildren, ReactElement, useEffect } from "react"
import { adminTheme, styled } from "src/stitches.config"

import { PageWidthContainer } from "../page-width-container"
import { TopScrollButton } from "../top-scroll-button"
import { AdminPageHeader } from "./fragments"

interface IAdminLayoutProps {
  headerButtons?: ReactElement
  backButtonHref?: string
}

const AdminLayoutWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  backgroundColor: "$background",
})

const AdminLayoutHeader = styled("header", {
  height: "5rem",
  width: "100vw",
  position: "fixed",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  zIndex: 10,
  backgroundColor: "$white",
})

const AdminLayoutBody = styled("main", {
  backgroundColor: "$background",
  position: "relative",
  top: "5rem",
  minHeight: "100vh",
  height: "100%",
})

/** Component wrapper for admin pages
 * @param {ReactElement} headerButtons - element of button in header
 * @param {string} backButtonHref - ref by clicking back button
 */
export const AdminLayout = (
  props: PropsWithChildren<IAdminLayoutProps>,
): ReactElement => {
  useEffect(() => {
    // Setting the theme classname on the body element here. This is needed
    // to apply theme styles to components that get rendered inside React
    // Portals, like the confirmation popover. We'll also set the className on
    // the <AdminLayoutWrapper /> to prevent flickering on page load.

    if (typeof window !== "undefined") {
      document.body.classList.add(adminTheme.toString())
      return () => document.body.classList.remove(adminTheme.toString())
    }
  }, [])

  return (
    <AdminLayoutWrapper className={adminTheme}>
      <AdminLayoutHeader>
        <PageWidthContainer>
          <AdminPageHeader
            backButtonHref={props.backButtonHref}
            buttons={props.headerButtons}
          />
        </PageWidthContainer>
      </AdminLayoutHeader>

      <AdminLayoutBody>{props.children}</AdminLayoutBody>
      <TopScrollButton />
    </AdminLayoutWrapper>
  )
}
