import {
  ArrowDiagonalIcon,
  ArrowDownIcon,
  ArrowRightIcon,
  ArrowTailedIcon,
  ArrowUpIcon,
  BSNCIcon,
  BurgerIcon,
  ChatIcon,
  CloseIcon,
  EmailIcon,
  GemeentenIcon,
  GolazoIcon,
  HomeIcon,
  LogoIcon,
  NOCNSFIcon,
  PlayVideoIcon,
  SearchIcon,
  UserIcon,
  WhatsAppIcon,
} from "../icons"

export interface Props {
  fill?: string
  height?: string
  width?: string
}

export const iconsMap = {
  arrowRight: ArrowRightIcon,
  bsnc: BSNCIcon,
  burger: BurgerIcon,
  chat: ChatIcon,
  close: CloseIcon,
  mail: EmailIcon,
  gemeenten: GemeentenIcon,
  home: HomeIcon,
  logo: LogoIcon,
  nocnsf: NOCNSFIcon,
  search: SearchIcon,
  user: UserIcon,
  whatsapp: WhatsAppIcon,
  arrowUp: ArrowUpIcon,
  arrowDown: ArrowDownIcon,
  arrowTailed: ArrowTailedIcon,
  playVideo: PlayVideoIcon,
  arrowDiagonal: ArrowDiagonalIcon,
  golazo: GolazoIcon,
}
