import axios from "axios"

export async function getMapCity(lat: number, lng: number) {
  try {
    const { data: city } = await axios({
      method: "post",
      url: `/api/geocoding/${lat},${lng}`,
    })

    return city
  } catch (error: any) {
    if (error.response?.status === 404) {
      alert("Deze locatie kan niet worden geselecteerd")
    } else {
      console.error(error)
    }
  }
}
