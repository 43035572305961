import { styled } from "src/stitches.config"

export const TBody = styled("tbody", {
  tr: {
    "&:hover": {
      cursor: "pointer",
    },
    "&:hover, &:active": {
      backgroundColor: "#fafafa",
    },
    "&.highlight": {
      backgroundColor: "#f6f7f7",
    },
    "&.highlight:hover, &.highlight:active": {
      backgroundColor: "#fafafa",
    },
  },
  td: {
    padding: "1rem 1.2rem",
    borderBottom: "1px solid #f6f7f7",
  },
})
