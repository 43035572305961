import React, { PropsWithChildren, ReactElement } from "react"
import { styled } from "src/stitches.config"

interface GridProps {
  gutter?: number | string
  columns?: number
  alignItems?: "end" | "start" | "center"
}

const StyledGrid = styled("div", {
  display: "grid",

  // Forcing the gap to be 30px on smaller screens
  // to prevent the gaps from being wider than the viewport
  // '@lgDown': {
  //   'column-gap': '30px !important',
  // },
})

export const Grid = ({
  gutter,
  columns,
  alignItems,
  ...props
}: PropsWithChildren<GridProps>): ReactElement => {
  const css = {
    "column-gap": gutter || 30,
    "grid-template-columns": `repeat(${columns || 12}, 1fr)`,
    "grid-auto-flow": "row",
    "align-items": alignItems || "start",
    "> *": {
      height: !alignItems ? "100%" : "auto",
    },
  }

  return <StyledGrid css={css} {...props} />
}
