import { styled } from "src/stitches.config"

import { Button } from "../button"

export const LinkContainer = styled("span", {
  display: "flex",
  justifyContent: "space-between",
  textDecoration: "none",

  [Button.toString()]: {
    marginLeft: "$10",
  },
})
