import { styled } from "src/stitches.config"

import { HorizontalFlex } from "./horizontal-flex"

export const HorizontalFlexSpaced = styled(HorizontalFlex, {
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "$10",

  "& > *:not(:last-child)": {
    marginRight: "1rem",
  },
})
