import { useRouter } from "next/router"
import React, {
  Fragment,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { SubmitHandler, useFieldArray, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import { EditHandbookContext } from "src/context/edit-handbook-context"
import api from "src/services/api"
import { styled } from "src/stitches.config"
import { v4 as uuidv4 } from "uuid"

import { CloseAssetsIcon, TagsIcon } from "../../assets-icons"
import {
  Checkbox,
  ConfirmationButton,
  CreateSection,
  DocumentCodeInput,
  EditableSection,
  EditableTags,
} from "../../edit-forms"
import { FormInput } from "../../forms"
import { Grid } from "../../grid"
import { GridItem } from "../../grid-item"
import { H1 } from "../../typography"

interface Props {
  onSubmit: SubmitHandler<any>
  disableCustomCode?: boolean
  disableArchive?: boolean
  disableDraft?: boolean
  disableTags?: boolean
  renderHeader?: ReactNode
  enableOrder?: boolean
}

const EditableSectionsWrapper = styled("div", {
  counterReset: "sectionIndex",
})

const EditableTagsContainer = styled("div", {
  variants: {
    isVisible: {
      false: {
        display: "none",
      },
    },
  },
})

const DocumentToolbar = styled("div", {
  display: "flex",
  alignItems: "flex-end",
  marginBottom: "$10",
  height: "2rem",
})

const EditTagsToggleButton = styled("button", {
  display: "inline-flex",
  alignItems: "center",
  appearance: "none",
  marginLeft: "auto",
  padding: "0",
  color: "$darkGreen",
  border: "none",
  background: "none",

  svg: {
    width: "1.6rem",
    height: "1.6rem",
    marginLeft: "$10",
    padding: "6px",
    color: "$white",
    borderRadius: "4px",
    backgroundColor: "$darkGreen",
  },

  variants: {
    isHidden: {
      true: {
        visibility: "hidden",
      },
    },
  },
})

const TitleInputContainer = styled("div", {
  padding: "$20",
  marginBottom: "$10",
  backgroundColor: "$white",

  variants: {
    enableOrder: {
      true: {
        display: "flex",
        "& > *:first-child": {
          width: "150px",
          marginRight: "$10",
          input: {
            height: "120px",
          },
        },
      },
    },
  },
})

export const EditForm = ({
  onSubmit,
  disableCustomCode,
  disableArchive,
  disableDraft,
  disableTags,
  renderHeader,
  enableOrder,
}: Props) => {
  const { formatMessage } = useIntl()
  const router = useRouter()
  const { mode } = useContext(EditHandbookContext)
  const { handleSubmit, formState, trigger, watch } = useFormContext()
  const [isTagsVisible, setIsTagsVisible] = useState(false)
  const code: string = watch("code")

  const isTagsInvalid = !!formState.errors.category
  const sections = useFieldArray({ name: "sections", keyName: "_id" })
  function handleShowTagsClick() {
    setIsTagsVisible(true)
  }

  function handleHideTagsClick() {
    setIsTagsVisible(false)
  }

  const handleOnArchiveClick = useCallback(async () => {
    return api.admin.updateDocumentStatusById(code, { active: false })
  }, [])

  const onArchiveSuccess = useCallback(() => {
    router.push(`/kwaliteitszorgsysteem/voorschriften/${code}`)
  }, [])

  const toolbarDisabled = useMemo(
    () => disableCustomCode && disableArchive && disableDraft && disableTags,
    [disableCustomCode, disableArchive, disableDraft, disableTags],
  )

  const addSection = (index: number) => {
    sections.insert(index, {
      id: uuidv4(),
      title: "",
      rules: [],
      sections: [],
    })
  }

  useEffect(() => {
    trigger("category")

    if (!sections.fields.length) {
      addSection(0)
    }
  }, [])

  useEffect(() => {
    if (!!formState.errors.category) {
      setIsTagsVisible(true)
    }
  }, [formState.errors.category])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!disableTags && (
        <EditableTagsContainer isVisible={isTagsVisible}>
          <Grid>
            <GridItem col={[1, 10]}>
              <DocumentToolbar>
                <EditTagsToggleButton
                  isHidden={isTagsInvalid}
                  type="button"
                  onClick={handleHideTagsClick}
                >
                  {formatMessage({ id: "edit.hideTagsButton.label" })}
                  <CloseAssetsIcon />
                </EditTagsToggleButton>
              </DocumentToolbar>
            </GridItem>
          </Grid>

          <Grid>
            <GridItem col={[1, 10]}>
              <EditableTags />
            </GridItem>
          </Grid>
        </EditableTagsContainer>
      )}

      {!toolbarDisabled && (
        <Grid>
          <GridItem col={[1, 10]}>
            <DocumentToolbar>
              {!disableCustomCode && <DocumentCodeInput />}

              {!disableArchive && (
                <ConfirmationButton
                  isDisabled={mode === "new"}
                  messageTitle={code}
                  onClick={handleOnArchiveClick}
                  onSuccess={onArchiveSuccess}
                  confirmationMessage={formatMessage(
                    { id: "admin.deactivateDoc.confirmationArchiveText" },
                    { code: code },
                  )}
                  confirmationButtonLabel={formatMessage({
                    id: "admin.deactivateDoc.confirmationArchiveButton",
                  })}
                  buttonIcon="archive"
                  buttonVariant="darkGreen"
                />
              )}

              {!disableDraft && (
                <Checkbox
                  name="isDraft"
                  label={formatMessage({ id: "draft" })}
                />
              )}

              {!disableTags && !isTagsVisible && (
                <EditTagsToggleButton
                  data-cy="edit-tags-button"
                  type="button"
                  onClick={handleShowTagsClick}
                >
                  {formatMessage({ id: "edit.showTagsButton.label" })}
                  <TagsIcon />
                </EditTagsToggleButton>
              )}
            </DocumentToolbar>
          </GridItem>
        </Grid>
      )}

      {renderHeader}

      <Grid>
        <GridItem col={[1, 10]}>
          <TitleInputContainer enableOrder={enableOrder}>
            {enableOrder && (
              <FormInput
                data-cy="form-input-order"
                displayAs={H1}
                name="order"
                trimLineBreaks
                bigPadding
                type="number"
              />
            )}
            <FormInput
              data-cy="form-input-title"
              displayAs={H1}
              name="title"
              trimLineBreaks
              bigPadding
            />
          </TitleInputContainer>
        </GridItem>
      </Grid>

      <Grid>
        <GridItem col={[3, 8]}>
          <CreateSection
            datacy="create-section"
            onClick={() => {
              addSection(0)
            }}
          />
        </GridItem>
      </Grid>

      <EditableSectionsWrapper>
        {sections.fields.map((section, index) => {
          return (
            <Fragment key={section._id}>
              <EditableSection
                index={index}
                section={section}
                path={`sections.${index}`}
                remove={sections.remove}
                removeFromIndex={index}
              />

              <Grid>
                <GridItem col={[3, 8]}>
                  <CreateSection
                    datacy="create-section"
                    onClick={() => {
                      addSection(index + 1)
                    }}
                  />
                </GridItem>
              </Grid>
            </Fragment>
          )
        })}
      </EditableSectionsWrapper>
    </form>
  )
}
