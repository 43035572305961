import { styled } from "src/stitches.config"

export const THead = styled("thead", {
  th: {
    padding: "1rem 1.4rem",
    whiteSpace: "nowrap",
    borderBottom: "1px solid #f1f5f9",
    backgroundColor: "#f8f9fd",

    "&>*": {
      color: "#4A4A4A",
    },
  },
})
