import { Library } from "@googlemaps/js-api-loader"
import {
  Autocomplete,
  GoogleMap,
  LoadScript,
  Marker,
} from "@react-google-maps/api"
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock"
import React, {
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react"
import { useIntl } from "react-intl"
import appConfig from "src/config"
import { globalCss, styled } from "src/stitches.config"

import { CloseAssetsIcon, SearchAssetsIcon } from "../../assets-icons"
import { LocationButton } from "../../forms"
import { Modal } from "../../modal"
import { MapHeader } from "./fragments"
import { LocationType } from "./types"

interface Props {
  trigger?: ReactNode
  isOpen: boolean
  onCancelClick: () => void
  onLocationSave: Function
  defaultLocation?: LocationType | null
}

type Libraries = (
  | "drawing"
  | "geometry"
  | "localContext"
  | "places"
  | "visualization"
)[]

const GOOGLE_MAPS_KEY = appConfig.googleApiKey
const GOOGLE_LIBS = ["places"]
const GOOGLE_AUTOCOMPLETE_FIELDS = ["name", "geometry.location"]

globalCss({
  ".ReactModal__Content": {
    height: "100%",
  },

  ".pac-container.pac-logo.hdpi": {
    borderRadius: "4px",
  },

  ".pac-item": {
    height: "$40",
    lineHeight: "40px",
    border: "none",
  },

  ".pac-icon.pac-icon-marker": {
    margin: "$10 $20 0 $20",
  },
})()

const MapWrapper = styled("div", {
  backgroundColor: "$white",
  position: "relative",
  width: "100%",
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "center",
})

const StyledInput = styled("input", {
  height: "$40",
  borderRadius: "4px",
  appearance: "none",
  padding: "$10",
  margin: "$10 0 0 0",
  font: "inherit",
  color: "inherit",
  borderColor: "$grayLight",
  outline: "none",
  border: "none",
  width: "400px",
  textIndent: "40px",
})

const SearchWrapper = styled("div", {
  position: "absolute",
  top: 0,
  left: "200px",
})

const SearchIconWrapper = styled("div", {
  position: "absolute",
  top: "15px",
  left: "15px",
  width: "20px",
  height: "20px",
})

const SearchCancelIconWrapper = styled("div", {
  position: "absolute",
  top: "15px",
  right: "5px",
  width: "22px",
  height: "22px",
  backgroundColor: "$white",
})

const ButtonWrapper = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "$20",

  "& > *": {
    backgroundColor: "$darkGreen",
  },
})

// Default Netherlands location
const DEFAULT_MAP_CENTER = {
  lat: 52.2129919,
  lng: 5.2793703,
}

type SearchPlace = {
  getPlace: Function
}

export const MapModal = ({
  isOpen,
  onCancelClick,
  onLocationSave,
  defaultLocation,
}: Props) => {
  const { formatMessage } = useIntl()
  const [searchBox, setSearchBox] = useState<SearchPlace>()
  const [markerLocation, setMarkerLocation] = useState<LocationType>()
  const [showCancel, setShowCancel] = useState(false)
  const [hasSearched, setHasSearched] = useState(false)
  const searchRef: MutableRefObject<any> = useRef(null)

  const [center, setCenter] = useState(defaultLocation || DEFAULT_MAP_CENTER)
  const initialZoomLevel = defaultLocation || hasSearched ? 16 : 7

  const onMapClick = (e: any) => {
    setCenter({ lat: e.latLng.lat(), lng: e.latLng.lng() })
    setMarkerLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() })

    // Reset search when clicking on the map to move the marker location
    searchRef.current.value = ""
    setShowCancel(false)
  }

  const onLoad = (_searchBox: any) => {
    setSearchBox(_searchBox)
  }

  const onPlacesChanged = () => {
    if (searchBox !== null) {
      const place = searchBox?.getPlace()
      setCenter({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      })
      setHasSearched(true)
    } else {
      console.log("Autocomplete is not loaded yet!")
    }
  }

  // Load the marker with default location
  useEffect(() => {
    if (defaultLocation) {
      const coordinates = {
        lat: Number(defaultLocation.lat),
        lng: Number(defaultLocation.lng),
      }
      setMarkerLocation(coordinates)
    }
  }, [])

  useEffect(() => {
    isOpen ? disableBodyScroll(document.body) : enableBodyScroll(document.body)
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen && defaultLocation) {
      setCenter(defaultLocation)
    }
  }, [isOpen, defaultLocation])

  const onLocationClick = () => {
    if (markerLocation) {
      onLocationSave(markerLocation)
    }
  }

  const onSearchFocus = () => {
    setShowCancel(true)
  }

  const onSearchBlur = () => {
    if (!searchRef.current.value) {
      setShowCancel(false)
    }
  }

  const onSearchCancelClick = () => {
    setShowCancel(false)
    // delete search input value
    searchRef.current.value = ""
  }

  return (
    <Modal
      isOpen={isOpen}
      onCancelClick={onCancelClick}
      headerContent={<MapHeader onCancelClick={onCancelClick} />}
      paddingSm
    >
      <MapWrapper>
        <LoadScript
          googleMapsApiKey={GOOGLE_MAPS_KEY}
          libraries={GOOGLE_LIBS as Library[]}
        >
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: "52vh" }}
            center={{ lat: Number(center.lat), lng: Number(center.lng) }}
            zoom={initialZoomLevel}
            onClick={onMapClick}
            mapTypeId="satellite"
          >
            {markerLocation && <Marker position={markerLocation} />}
            <Autocomplete
              onLoad={onLoad}
              onPlaceChanged={onPlacesChanged}
              fields={GOOGLE_AUTOCOMPLETE_FIELDS}
            >
              <SearchWrapper>
                <SearchIconWrapper>
                  <SearchAssetsIcon />
                </SearchIconWrapper>
                <StyledInput
                  ref={searchRef}
                  type="text"
                  placeholder={formatMessage({
                    id: "admin.createProject.map.search.placeholder",
                  })}
                  onFocus={onSearchFocus}
                  onBlur={onSearchBlur}
                  data-cy="maps-search-input"
                />
                {showCancel && (
                  <SearchCancelIconWrapper onClick={onSearchCancelClick}>
                    <CloseAssetsIcon color="#31493d" />
                  </SearchCancelIconWrapper>
                )}
              </SearchWrapper>
            </Autocomplete>
          </GoogleMap>
        </LoadScript>
      </MapWrapper>
      <ButtonWrapper>
        <LocationButton onClick={onLocationClick} size="large" />
      </ButtonWrapper>
    </Modal>
  )
}
