import { styled } from "src/stitches.config"

export const NavSubmenuItemButton = styled("button", {
  appearance: "none",
  border: "none",
  background: "none",

  fontSize: "18px",
  fontWeight: "bold",
  fontFamily: "$grotesk",
  textAlign: "inherit",
  textDecoration: "none",

  display: "flex",
  alignItems: "flex-end",
  padding: "$10 $40 $10 $10",
  clear: "both",
  color: "$orange",
  backgroundColor: "transparent",
  whiteSpace: "nowrap",
  cursor: "pointer",

  "&:hover": {
    color: "$orange",
    opacity: 0.7,
    textDecoration: "none",
  },
})
