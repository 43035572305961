import { styled } from "src/stitches.config"

import { HDefault } from "./h-default"

export const H1 = styled("h1", {
  ...HDefault,

  fontSize: "64px",
  lineHeight: "1.25",

  "@mdDown": {
    fontSize: "32px",
  },
})
