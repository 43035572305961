import {
  AdditionalInformationIcon,
  DrawingColoredIcon,
  DrawingWithPencilColoredIcon,
  LinkColoredIcon,
  PdfColoredDarkIcon,
  TableColoredIcon,
} from "src/components/assets-icons"
import { v4 as uuidv4 } from "uuid"

export const RULE_RULE = "rule"
export const TEXT_RULE = "text"
export const TEXT_EXT_RULE = `${TEXT_RULE}_ext`

export function getRuleTemplate() {
  return {
    id: uuidv4(),
    ruleType: "R",
    content: "",
  }
}

export type TemplateType = {
  type: string
  content?: string
  title?: string
  url?: any
  caption?: string
}

export const attachmentTypes = {
  table: {
    label: "attachmentTypes.table.label",
    icon: TableColoredIcon,
    for: [RULE_RULE, TEXT_RULE, TEXT_EXT_RULE],
    template: {
      type: "TABLE",
      title: "",
      content: `
|           | Heading1 | Heading2 | Heading3 | Heading4 |
|-----------|----------|----------|----------|----------|
| Row title | Cell1    | Cell2    | Cell3    | Cell4    |
| Row title | Cell1    | Cell2    | Cell3    | Cell4    |
| Row title | Cell1    | Cell2    | Cell3    | Cell4    |
| Row title | Cell1    | Cell2    | Cell3    | Cell4    |
| Row title | Cell1    | Cell2    | Cell3    | Cell4    |
| Row title | Cell1    | Cell2    | Cell3    | Cell4    |`.trim(),
      caption: "",
    },
  },
  drawing: {
    label: "attachmentTypes.drawing.label",
    icon: DrawingWithPencilColoredIcon,
    for: [RULE_RULE, TEXT_RULE],
    template: {
      type: "DRAWING",
      content: "",
      title: "",
      url: null,
    },
  },
  image: {
    label: "attachmentTypes.image.label",
    icon: DrawingColoredIcon,
    for: [RULE_RULE, TEXT_RULE, TEXT_EXT_RULE],
    template: {
      type: "IMAGE",
      content: "",
      title: "",
      url: null,
    },
  },
  pdf: {
    label: "attachmentTypes.pdf.label",
    icon: PdfColoredDarkIcon,
    for: [RULE_RULE, TEXT_RULE, TEXT_EXT_RULE],
    template: {
      type: "PDF",
      content: "",
      title: "",
      url: null,
    },
  },
  link: {
    label: "attachmentTypes.link.label",
    icon: LinkColoredIcon,
    for: [TEXT_RULE, RULE_RULE, TEXT_EXT_RULE],
    template: {
      type: "LINK",
      url: "",
      title: "",
    },
  },
  text: {
    label: "attachmentTypes.text.label",
    icon: AdditionalInformationIcon,
    for: [TEXT_RULE, RULE_RULE, TEXT_EXT_RULE],
    template: {
      type: "TEXT",
      content: "",
    },
  },
}

export type AttachmentType = keyof typeof attachmentTypes

export const getRuleAttachmentTemplate = (type: AttachmentType) => ({
  id: uuidv4(),
  ...attachmentTypes[type].template,
})
