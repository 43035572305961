import { styled } from "src/stitches.config"

import { Button } from "../button"

interface Props {
  onCancelClick: () => void
  onOkClick: () => void
  okDisabled: boolean
  okButtonLabel: string
}

const ButtonWrap = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
  padding: "0 $40 $40",

  "& > *:not(:first-child)": {
    marginLeft: "$10",
  },
})

const PrimaryButton = styled(Button, {
  backgroundColor: "$darkGreen",
  "&:hover, &:focus": {
    backgroundColor: "$lightGreen",
    color: "$darkGreen",
  },
})

const SecondaryButton = styled(Button, {
  backgroundColor: "$lightGreen",
  color: "$darkGreen",
  "&:hover, &:focus": {
    backgroundColor: "$darkGreen",
    color: "$lightGreen",
  },
})

export const ModalButtons = ({
  onCancelClick,
  onOkClick,
  okDisabled,
  okButtonLabel,
}: Props) => (
  <ButtonWrap>
    <SecondaryButton
      size="large"
      variant="secondary"
      onClick={onCancelClick}
      icon="delete"
      aria-label="cancel"
    />
    <PrimaryButton
      data-cy="primary-button"
      size="large"
      variant="secondary"
      onClick={onOkClick}
      disabled={okDisabled}
    >
      {okButtonLabel}
    </PrimaryButton>
  </ButtonWrap>
)
