import { useRouter } from "next/router"
import React, {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from "react"
import { useMeasure } from "react-use"
import { styled } from "src/stitches.config"

import { HoverCard } from "../../hover-card"
import { IconWrapper } from "../../icon-wrapper"
import { iconNameType } from "../../icon-wrapper"
import { NavItem, NavItemProps } from "./nav-item"

interface NavSubmenuProps extends NavItemProps {
  label?: string | ReactElement
  icon?: iconNameType
}

const IconWrapperStyled = styled("span", {
  marginLeft: "12px",
  variants: {
    removeMargin: {
      true: {
        marginLeft: "0px",
      },
    },
  },
})

const ContentWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
})

export const NavSubmenu = ({
  label,
  icon,
  children,
  slowAnimation,
}: PropsWithChildren<NavSubmenuProps>) => {
  const [isOpen, setIsOpen] = useState(false)
  const { asPath } = useRouter()
  const [ref, { width }] = useMeasure<HTMLDivElement>()
  const isActive =
    typeof label === "string" && asPath.includes(label?.toLowerCase())

  useEffect(() => {
    if (width === 0) setIsOpen(false)
  }, [width])

  return (
    <HoverCard
      open={isOpen}
      trigger={
        <NavItem
          slowAnimation={slowAnimation}
          ref={ref}
          isActive={isActive}
          role="button"
          aria-label="submenu item"
          data-cy="navigation-item"
        >
          {label && <span>{label}</span>}
          <IconWrapperStyled removeMargin={!!icon && !label}>
            <IconWrapper name={icon || (!isOpen ? "arrowDown" : "arrowUp")} />
          </IconWrapperStyled>
        </NavItem>
      }
      side="bottom"
      hideArrow
      openToggle={setIsOpen}
      align="end"
    >
      <ContentWrapper css={{ minWidth: width }} data-cy="navigation-submenu">
        {children}
      </ContentWrapper>
    </HoverCard>
  )
}

NavSubmenu.toString = () => ".NavSubmenu"
