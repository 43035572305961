import { AccommodationService } from "./accommodation"
import { CertificationProcessService } from "./certification-process/certification-process.service"
import { CertificationService } from "./certification/certification.service"
import { DisciplinesService } from "./disciplines"
import { ImplementedSportProductService } from "./implemented-sport-product"
import { InspectionReportService } from "./inspection-report"
import { NotesService } from "./notes"
import { OrganizationsService } from "./organizations"
import { SportProductService } from "./sport-product"
import { SportProductSubtypeService } from "./sport-product-subtype"
import { SportProductTypeService } from "./sport-product-type"

export * from "./accommodation"
export * from "./certification-process"
export * from "./implemented-sport-product"
export * from "./organizations"
export * from "./sport-product-subtype"
export * from "./sport-product-type"
export * from "./certification"

export const accommodationService = new AccommodationService()
export const certificationProcessService = new CertificationProcessService()
export const implementedSportProductService = new ImplementedSportProductService() // prettier-ignore
export const organizationsService = new OrganizationsService()
export const sportProductTypeService = new SportProductTypeService()
export const sportProductSubtypeService = new SportProductSubtypeService()
export const sportProductService = new SportProductService()
export const inspectionReportService = new InspectionReportService()
export const disciplinesService = new DisciplinesService()
export const certificationService = new CertificationService()
export const notesService = new NotesService()
