import Link from "next/link"
import React, { ReactElement } from "react"
import { ILinkCard } from "src/services"
import { styled } from "src/stitches.config"

import { IconWrapper } from "../../icon-wrapper"
import { H3, P } from "../../typography"

const IconWrapperStyled = styled("span", {
  transition: ".2s transform",
})

const LinkCardWrapper = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  padding: "$20",
  borderRadius: "4px",
  backgroundColor: "$white",
  boxShadow: "$default",
  transition: ".2s transform",

  p: {
    fontWeight: "$bold",
  },

  "&:hover": {
    transform: "translateY(-3px)",

    [`${IconWrapper}`]: {
      transform: "translateX(4px)",
    },
  },
})

const LinkCardLink = styled("div", {
  display: "inline-flex",
  alignItems: "center",
  margin: "auto 0 0 auto",

  a: {
    color: "$blue",
    marginRight: "$20",
  },

  "a::after": {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    content: "''",
  },
})

export const LinkCard = (props: ILinkCard): ReactElement => (
  <LinkCardWrapper data-cy="link-card">
    <H3>
      {props.order ? `${props.order}.` : ""}
      {props.title}
    </H3>
    <P>{props.description}</P>

    <LinkCardLink>
      <Link href={props.link.href} passHref>
        {props.link.text}
      </Link>

      <IconWrapperStyled>
        <IconWrapper name="arrowRight" />
      </IconWrapperStyled>
    </LinkCardLink>
  </LinkCardWrapper>
)
