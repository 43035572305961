import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const PdfColoredDarkIcon = ({
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
    version="1.1"
  >
    <g>
      <g opacity="1">
        <path
          d="M5.42571+1C4.67929+1+4.06857+1.61875+4.06857+2.375L4.06857+21.625C4.06857+22.3813+4.67929+23+5.42571+23L18.9971+23C19.7436+23+20.3543+22.3813+20.3543+21.625L20.3543+6.5L14.9257+1L5.42571+1Z"
          opacity="1"
          fill="#31493c"
        />
        <path
          d="M16.2829+6.5L20.3543+6.5L14.9257+1L14.9257+5.125C14.9257+5.88125+15.5364+6.5+16.2829+6.5Z"
          opacity="1"
          fill="#94a286"
        />
        <path
          d="M20.3543+10.625L16.2829+6.5L20.3543+6.5L20.3543+10.625Z"
          fillOpacity="0.3"
          fill="#94a286"
          opacity="1"
        />
      </g>
      <path
        d="M7.83+18.8623C7.67923+18.8623+7.52302+18.8319+7.41106+18.7266C7.05702+18.3933+7.05702+18.2323+7.08562+17.9858C7.16447+17.3078+8.05099+16.6197+9.69294+15.8968C10.3445+14.5243+11.0601+12.6809+11.4296+11.268C10.9972+10.3635+10.3874+9.2659+10.6937+8.57757C10.8011+8.33646+10.935+8.15158+11.1849+8.07162C11.2837+8.03998+11.5333+8+11.6251+8C11.8435+8+12.0354+8.27025+12.1714+8.43682C12.2992+8.59339+12.4921+8.924+11.9129+11.268C12.4969+12.4273+13.4123+13.686+14.2051+14.4947C14.773+14.396+15.204+14.4293+15.6022+14.4293C16.2806+14.4293+16.7961+14.5716+16.8685+14.818C16.9409+15.0643+16.9409+15.2509+16.7677+15.5201C16.5944+15.7893+16.1829+15.8968+15.7951+15.8968C15.2683+15.8968+14.4999+15.696+13.8158+15.0643C12.5867+15.3112+11.3375+15.7189+10.1773+16.2065C9.81516+16.9452+9.35931+17.5494+9.03612+17.9858C8.59206+18.5833+8.20908+18.8623+7.83+18.8623ZM9.06123+16.8655C8.13541+17.3656+7.75806+17.7766+7.73077+18.0081C7.72643+18.0464+7.71474+18.1472+7.91749+18.2963C7.98204+18.2767+8.35896+18.1114+9.06123+16.8655ZM14.9692+15.0157C15.3223+15.2768+15.4085+15.4088+15.6395+15.4088C15.7408+15.4088+16.0298+15.4047+16.1637+15.2252C16.2282+15.1382+16.2534+15.0824+16.2633+15.0524C16.21+15.0253+16.1394+14.9704+15.7543+14.9704C15.5355+14.9708+15.2604+14.9799+14.9692+15.0157ZM11.733+12.2749C11.4232+13.3051+11.0143+14.4174+10.5745+15.4247C11.48+15.087+12.4643+14.7921+13.3888+14.5835C12.8039+13.9306+12.2195+13.1152+11.733+12.2749ZM11.47+8.75121C11.4276+8.76495+10.8938+9.48285+11.5116+10.0904C11.9227+9.20968+11.4886+8.74538+11.47+8.75121Z"
        opacity="1"
        fill="#ffffff"
      />
    </g>
  </svg>
)
