import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const ReplaceIcon = ({
  color = "currentColor",
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 14 13"
    version="1.1"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 7.06006L1.5 9.56006M1.5 9.56006L4 12.0601M1.5 9.56006H12.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 1L12.5 3.5M12.5 3.5L10 6M12.5 3.5H1.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
