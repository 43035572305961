import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const SectionIcon = ({
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 18 18"
    version="1.1"
  >
    <g>
      <g opacity="1">
        <path
          stroke="#e1001a"
          strokeWidth="2"
          d="M1.5+7.49999L16.5+7.49999"
          fill="none"
          strokeLinecap="round"
          opacity="1"
          strokeLinejoin="round"
        />
        <path
          stroke="#919291"
          strokeWidth="0.666667"
          d="M1.5+10.5L16.5+10.5"
          fill="none"
          strokeLinecap="round"
          opacity="1"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
)
