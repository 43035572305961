import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const RuleIcon = ({
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
    version="1.1"
  >
    <defs>
      <rect x="0" y="0" width="11" height="22" rx="2"></rect>
      <filter
        x="-118.2%"
        y="-50.0%"
        width="336.4%"
        height="218.2%"
        filterUnits="objectBoundingBox"
        id="filter-2"
      >
        <feOffset
          dx="0"
          dy="2"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          stdDeviation="4"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feColorMatrix
          values="0 0 0 0 0.290196078   0 0 0 0 0.290196078   0 0 0 0 0.290196078  0 0 0 0.1 0"
          type="matrix"
          in="shadowBlurOuter1"
        ></feColorMatrix>
      </filter>
      <rect x="0" y="0" width="11" height="11" rx="2"></rect>
      <filter
        x="-118.2%"
        y="-100.0%"
        width="336.4%"
        height="336.4%"
        filterUnits="objectBoundingBox"
        id="filter-4"
      >
        <feOffset
          dx="0"
          dy="2"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          stdDeviation="4"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feColorMatrix
          values="0 0 0 0 0.290196078   0 0 0 0 0.290196078   0 0 0 0 0.290196078  0 0 0 0.1 0"
          type="matrix"
          in="shadowBlurOuter1"
        ></feColorMatrix>
      </filter>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <g transform="translate(6.500000, 1.000000)">
        <g>
          <g>
            <use
              fill="black"
              fillOpacity="1"
              filter="url(#filter-2)"
              xlinkHref="#path-1"
            ></use>
            <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-1"></use>
          </g>
          <circle fill="#E1001A" cx="5.5" cy="17.5" r="1.83333333"></circle>
        </g>
        <g>
          <use
            fill="black"
            fillOpacity="1"
            filter="url(#filter-4)"
            xlinkHref="#path-3"
          ></use>
          <use fill="#94A286" fillRule="evenodd" xlinkHref="#path-3"></use>
        </g>
      </g>
    </g>
  </svg>
)
