import { AxiosResponse } from "axios"

import { V2Api } from "../api.v2"
import { SportProductType } from "./types"

export class SportProductTypeService extends V2Api {
  public async getAll(): Promise<AxiosResponse<SportProductType[]>> {
    return super.get("/sport-product-types")
  }
}
