import Link from "next/link"
import React, { PropsWithChildren, ReactElement, useState } from "react"
import { useAuthAdminMenu } from "src/components/navigation/hooks/use-auth-admin-menu"
import { iconSizes } from "src/constants"
import { styled } from "src/stitches.config"

import { ArrowLeftIcon } from "../../assets-icons"
import { Button } from "../../button"
import { Logo } from "../../logo"
import { SidePanelNavigation } from "../../side-panel-navigation"

interface Props {
  buttons?: ReactElement
  backButtonHref?: string
}

const AdminPageHeaderWrapper = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
})

const BurgerIcon = styled("span", {
  "&:after": {
    content: "☰",
  },
})

const LogoContainer = styled("div", {
  height: "$40",
  fontSize: "$sizes$40",

  svg: {
    verticalAlign: "top",
  },
})

const HeaderBlock = styled("div", {
  display: "flex",
  flexDirection: "row",

  "& > *:not(:last-child)": {
    marginRight: "1rem",
  },
})

export const AdminPageHeader = ({
  backButtonHref,
  buttons,
}: PropsWithChildren<Props>): ReactElement => {
  const [openPanel, setOpenPanel] = useState(false)

  const authorizedMenu = useAuthAdminMenu()

  return (
    <>
      <AdminPageHeaderWrapper>
        <HeaderBlock>
          <Button
            variant="light"
            size="large"
            onClick={() => {
              setOpenPanel(true)
            }}
            aria-label="Menu"
          >
            <BurgerIcon />
          </Button>

          {backButtonHref && (
            <Link href={backButtonHref} data-cy="back-button">
              <Button
                variant="light"
                size="large"
                icon={<ArrowLeftIcon color="#94A286" size={iconSizes.xlarge} />}
                noPadding
              />
            </Link>
          )}

          <LogoContainer>
            <Logo />
          </LogoContainer>
        </HeaderBlock>

        {buttons && <div>{buttons}</div>}
      </AdminPageHeaderWrapper>
      <SidePanelNavigation
        isOpen={openPanel}
        onClose={() => setOpenPanel(false)}
        links={authorizedMenu}
      />
    </>
  )
}
