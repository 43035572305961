import { useMutation, useQueryClient } from "@tanstack/react-query"
import { toast } from "react-hot-toast"
import api from "src/services/api"

export const useUnsubscribeFromAll = () => {
  const queryClient = useQueryClient()

  const mutate = () => api.subscription.unsubscribeFromAll()

  return useMutation(mutate, {
    onSuccess: () => {
      queryClient.invalidateQueries(["userSubscriptions"])
    },
    onError: (error) => {
      toast.error(String(error))
    },
  })
}
