import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

// TODO: rename this component because there is already ArrowRightIcon in the project
export const ArrowRightAssetsIcon = ({
  color = "currentColor",
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33317 6.66634C0.964981 6.66634 0.666504 6.36786 0.666504 5.99967C0.666504 5.63148 0.964981 5.33301 1.33317 5.33301H10.6665C11.0347 5.33301 11.3332 5.63148 11.3332 5.99967C11.3332 6.36786 11.0347 6.66634 10.6665 6.66634H1.33317Z"
      fill={color}
    />
    <path
      d="M5.52827 1.80458C5.26792 1.54423 5.26792 1.12212 5.52827 0.861766C5.78862 0.601417 6.21073 0.601417 6.47108 0.861766L11.1377 5.52843C11.3981 5.78878 11.3981 6.21089 11.1377 6.47124L6.47108 11.1379C6.21073 11.3983 5.78862 11.3983 5.52827 11.1379C5.26792 10.8776 5.26792 10.4554 5.52827 10.1951L9.72353 5.99984L5.52827 1.80458Z"
      fill={color}
    />
  </svg>
)
