import { useMemo } from "react"
import { useIntl } from "react-intl"
import { Dropdown } from "src/components"
import { ORGANIZATION_TYPES } from "src/constants"
import { useOrganizations } from "src/services"

export const MunicipalitiesDropdown = () => {
  const { data: municipalities = [], isLoading: loadingMunicipalities } =
    useOrganizations({
      type: ORGANIZATION_TYPES.MUNICIPALITIES,
    })

  const { formatMessage } = useIntl()

  const municipalityOptions = useMemo(() => {
    return municipalities.map((municipality) => ({
      value: municipality,
      label: municipality.name,
    }))
  }, [municipalities])

  return (
    <Dropdown
      name="owner"
      isLoading={loadingMunicipalities}
      options={municipalityOptions}
      placeholder={formatMessage({
        id: "form.select.placeholder.municipality",
      })}
      findDropdownValue={(options, val) =>
        options.find((option) => option.value.id === val?.id)
      }
    />
  )
}
