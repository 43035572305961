import { IIcon } from "@types"
import { ReactElement, ReactSVG } from "react"

export const ChatIcon = ({
  fill = "#FFFFFF",
}: IIcon): ReactElement<ReactSVG> => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
    <g fill="none">
      <path d="M-490-812H790V488H-490z" />
      <path d="M-490-185H790v315H-490z" />
      <path stroke="#FFF" d="M-39.5-49.5h379v139h-379z" />
      <g transform="translate(-10 -10)">
        <rect width="60" height="60" rx="30" />
        <path
          d="M23.366 35.364c.235-.235.553-.366.884-.366h15c.69 0 1.25-.56 1.25-1.25V21.25c0-.69-.56-1.25-1.25-1.25h-17.5c-.69 0-1.25.56-1.25 1.25v16.98l2.866-2.865zm1.401 2.134-4.633 4.633c-.788.788-2.134.23-2.134-.883V21.25a3.75 3.75 0 0 1 3.75-3.75h17.5A3.75 3.75 0 0 1 43 21.25v12.498a3.75 3.75 0 0 1-3.75 3.75H24.767z"
          fill={fill}
        />
        <path d="M10 10h40v40H10V30z" />
      </g>
    </g>
  </svg>
)
