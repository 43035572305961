import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const FilterIcon = ({
  color = "currentColor",
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
    version="1.1"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(1.000000, 1.000000)" fill={color}>
        <path d="M21.9004188,0.565999937 C21.7338709,0.22027399 21.3841685,0.000350462474 21.0004174,0 L1.0003863,0 C0.615842295,0 0.265280503,0.220226623 0.0987030261,0.566818064 C-0.0678744509,0.913409505 -0.020954961,1.32481566 0.219385087,1.62499969 L8.00039718,11.3509974 L8.00039718,21 C8.00029541,21.4044241 8.24380682,21.7690986 8.61739814,21.9239949 C8.73873008,21.9745805 8.86894464,22.0004194 9.00039873,22 C9.26559396,22 9.51990681,21.8945443 9.70739983,21.7069949 L13.707406,17.7069959 C13.8949557,17.5195032 14.0003499,17.2651908 14.0004065,17 L14.0004065,11.3509974 L21.7814186,1.62499969 C22.0215383,1.32429874 22.067813,0.912493207 21.9004188,0.565999937 Z" />
      </g>
    </g>
  </svg>
)
