import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const ChevronUpIcon = ({
  color = "currentColor",
  size = iconSizes.regular,
  width,
  height,
  ...rest
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
    version="1.1"
    {...rest}
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(12.000000, 13.000000) scale(1, -1) translate(-12.000000, -13.000000) translate(1.000000, 7.000000)">
          <path
            d="M5.21571875,-4.27566972 C5.75196875,-4.79699898 6.62234375,-4.79699898 7.15996875,-4.27566972 L7.15996875,-4.27566972 L16.7849688,5.05759062 C16.9169688,5.18558962 17.0159688,5.33225514 17.0833438,5.48958724 C17.2222188,5.81625136 17.2222188,6.18424848 17.0833438,6.51091259 C17.0132188,6.67357799 16.9114688,6.82024351 16.7849688,6.94290921 L16.7849688,6.94290921 L7.15996875,16.2761696 C6.62371875,16.7974988 5.75334375,16.7961655 5.21571875,16.2761696 C4.79009896,15.8645061 4.70142817,15.2497818 4.94970638,14.752848 L13.0037188,6.94290921 C13.1302188,6.82024351 13.2319688,6.67357799 13.3020938,6.51091259 C13.4409687,6.18424848 13.4409687,5.81625136 13.3020938,5.48958724 C13.2347188,5.33225514 13.1357188,5.18558962 13.0037188,5.05759062 L4.94929376,-2.75279939 C4.70201515,-3.2489238 4.79150418,-3.8632586 5.21571875,-4.27566972 Z"
            fill={color}
            fillRule="nonzero"
            transform="translate(11.000000, 6.000000) rotate(90.000000) translate(-11.000000, -6.000000) "
          ></path>
        </g>
      </g>
    </g>
  </svg>
)
