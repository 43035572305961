import React, { useEffect, useRef, useState } from "react"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import api from "src/services/api"
import { styled } from "src/stitches.config"

import { IFileContent } from "../../../services/types"
import { Button } from "../../button"
import {
  EditableRuleAttachmentWrapper,
  InputWrapper,
  LoadingOverlay,
} from "../../edit-forms"
import { FormInput, HiddenInput } from "../../forms"
import { Message } from "../../message"
import { P } from "../../typography"

interface ImageAttachmentInputProps {
  path: string
}

export const EmptyImagePlaceholder = styled("div", {
  padding: "10.625rem 0",
  background: "$white",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
})

export const HiddenFileInput = styled("input", {
  display: "none",
})

export const ImageAttachmentInput = ({ path }: ImageAttachmentInputProps) => {
  const { formatMessage } = useIntl()
  const { setValue, watch } = useFormContext()
  const [uploading, setUploading] = useState(false)
  const fileInput = useRef<HTMLInputElement>(null)
  const [fileInputContent, setFileInputContent] =
    useState<IFileContent | null>()

  const fileNameValue = watch(`${path}.content`) || ""
  const fileUrlValue = watch(`${path}.url`)

  const openFile = () => {
    if (fileInput.current !== null) {
      fileInput.current.click()
    }
  }

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filesContent = event.target.files
    if (filesContent?.length) {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(filesContent[0])
      fileReader.onload = (_e: ProgressEvent<FileReader>) => {
        if (_e?.target) {
          setFileInputContent({
            name: filesContent[0]?.name,
            content: _e.target.result as string,
          })
        }
      }
    }
  }

  useEffect(() => {
    if (fileInputContent?.content && fileInputContent.name) {
      setUploading(true)

      api.admin
        .createDocumentFile(fileInputContent.name, fileInputContent.content)
        .then(({ url, name: fileName }) => {
          setValue(`${path}.url`, url, {
            shouldValidate: true,
            shouldDirty: true,
          })
          setValue(`${path}.content`, fileName, {
            shouldValidate: true,
            shouldDirty: true,
          })
          setValue(`${path}.title`, fileName, {
            shouldValidate: true,
            shouldDirty: true,
          })
          setFileInputContent(null)
        })
        .finally(() => setUploading(false))
    }
  }, [fileInputContent])

  useEffect(() => {
    if (!fileUrlValue) {
      openFile()
    }
  }, [])

  return (
    <EditableRuleAttachmentWrapper style={{ position: "relative" }}>
      <HiddenInput name={`${path}.url`} />
      <InputWrapper>
        <HiddenFileInput
          data-cy="image-input"
          ref={fileInput}
          type="file"
          accept="image/*"
          onChange={onFileChange}
        />
        <DrawingWrap>
          {fileUrlValue ? (
            <img src={fileUrlValue} width="100%" height="100%" />
          ) : (
            <EmptyImagePlaceholder>
              <P>
                <Message id="admin.editRule.noImageLabel" />
              </P>
              <Button variant="secondary" onClick={openFile} icon="upload">
                <Message id="admin.editRule.pickImageFileLabel" />
              </Button>
            </EmptyImagePlaceholder>
          )}
        </DrawingWrap>
        <FormInput
          name={`${path}.title`}
          defaultValue={fileNameValue}
          displayAs={P}
          italic
          trimLineBreaks
          placeholder={formatMessage({
            id: "admin.editRule.drawingTitle.placeholder",
          })}
          autoFocus
        />
      </InputWrapper>
      <LoadingOverlay visible={uploading} showText={false} />
    </EditableRuleAttachmentWrapper>
  )
}

export const DrawingWrap = styled("div", {
  width: "100%",
  maxWidth: "100%",
  textAlign: "center",
})
