import { styled } from "src/stitches.config"

export const SportProductCardContainer = styled("div", {
  position: "relative",
  backgroundColor: "$white",
  borderRadius: "6px",
  boxShadow: "0 2px 8px 0 rgba(74,74,74,0.1)",
  overflow: "hidden",

  "a::after": {
    position: "absolute",
    inset: "0",
    content: "",
  },

  variants: {
    systemStatus: {
      inactive: {
        background: "radial-gradient(circle at left, #FFFFFF 0%, #F0F0F0 100%)",
      },
      new: {
        background:
          "radial-gradient(circle at left, #F8CDB1 0%, #F1EAE6 85.15%, #F0F0F0 100%)",
      },
      active: {},
      deleted: {},
    },
  },
})
