import { useSession } from "next-auth/react"
import Link from "next/link"
import { Fragment, PropsWithChildren } from "react"
import { useIntl } from "react-intl"
import { styled } from "src/stitches.config"
import hasPermissions from "src/utils/has-permissions"

import { UserAssetsIcon } from "../../assets-icons"
import { Divider } from "../../divider"
import { Message } from "../../message"
import { useAuthAdminMenu } from "../hooks/use-auth-admin-menu"
import { NavSubmenu } from "./nav-submenu"
import { NavSubmenuItemButton } from "./nav-submenu-item-button"
import { NavSubmenuItemLink } from "./nav-submenu-item-link"
import { NavSubmenuItemText } from "./nav-submenu-item-text"

const NavSubmenuItemButtonStyled = styled(NavSubmenuItemButton, {
  color: "$black",
  "&:hover": {
    color: "$black",
  },
})

interface Props {
  onLogoutClick: (event: any) => void
  onLoginClick: (event: any) => void
}
export const AccountNavButtonWithButtons = ({
  onLogoutClick,
  onLoginClick,
}: PropsWithChildren<Props>) => {
  const authorizedMenu = useAuthAdminMenu()
  const { data: session } = useSession()

  return (
    <AccountNavButton onLogoutClick={onLogoutClick} onLoginClick={onLoginClick}>
      {authorizedMenu.map((item) => (
        <Link key={item.href} href={item.href} legacyBehavior>
          <NavSubmenuItemButton data-cy={item.dataCy}>
            <Message id={item.label} />
          </NavSubmenuItemButton>
        </Link>
      ))}
      {hasPermissions(session, ["getUserSubscriptions"]) && (
        <Link href="/meldingen" legacyBehavior>
          <NavSubmenuItemButton data-cy="nav-subscriptions">
            <Message id="navigation.subscriptions.label" />
          </NavSubmenuItemButton>
        </Link>
      )}
    </AccountNavButton>
  )
}
export const AccountNavButton = ({
  onLogoutClick,
  onLoginClick,
  children,
}: PropsWithChildren<Props>) => {
  const intl = useIntl()
  const { data: session } = useSession()

  const fullName = (session as any)?.fullName as string
  const signupUrl = intl.formatMessage({ id: "navigation.signupLink.url" })

  return (
    <NavSubmenu
      label={<UserAssetsIcon size="18px" aria-label="User menu" />}
      slowAnimation
    >
      {session ? (
        <Fragment>
          <NavSubmenuItemText data-cy="logged-in-user">
            <Message
              id="navigation.loggedIn.welcome"
              values={{ name: fullName }}
            />
          </NavSubmenuItemText>
          {children}
          <Divider />
          <NavSubmenuItemButtonStyled onClick={onLogoutClick} data-cy="logout">
            <Message id="navigation.logoutButton.label" />
          </NavSubmenuItemButtonStyled>
        </Fragment>
      ) : (
        <>
          <NavSubmenuItemButton onClick={onLoginClick} data-cy="login">
            <Message id="navigation.loginButton.label" />
          </NavSubmenuItemButton>
          <NavSubmenuItemLink href={signupUrl} target="_blank">
            <Message id="navigation.signupLink.label" />
          </NavSubmenuItemLink>
        </>
      )}
    </NavSubmenu>
  )
}
