import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { SessionProvider as AuthProvider, getSession } from "next-auth/react"
import App, { AppContext, AppProps } from "next/app"
import Head from "next/head"
import { useRouter } from "next/router"
import Script from "next/script"
import NodeCache from "node-cache"
import { useEffect, useMemo } from "react"
import { ToastPosition, Toaster } from "react-hot-toast"
import { IntlProvider } from "react-intl"
import { useMedia } from "react-use"
import { ModalProvider, V2Modal } from "src/components"
import { TOKEN_REFRESH_INTERVAL } from "src/constants"
import { AdminContextProvider } from "src/context/admin-context"
import { AnalyticsProvider } from "src/context/analytics-context"
import { IDictionary, IDictionaryItem, IFooterData } from "src/services"
import api from "src/services/api"
import "src/styles/globals.scss"
import { ExtendedSession } from "src/types/session"

import appConfig from "../config"
import removeHash from "../utils/remove-hash"
import ErrorComponent from "./_error"
import PaymentRequired from "./_payment-required"

const queryClient = new QueryClient()
//cache for 6 hours
const serversideCache = new NodeCache({ stdTTL: 6 * 60 * 60 })

const getFromCache = async (identifier: string, action: Function) => {
  let val = serversideCache.get(identifier)
  if (!val) {
    console.log(`Refetching for cache item '${identifier}'`)
    val = await action()
    serversideCache.set(identifier, val)
  }
  return val
}

const useToasterPosition = (): ToastPosition => {
  const isMobile = useMedia("(max-width: 640px)")
  return isMobile ? "top-center" : "top-right"
}

export interface MyAppProps extends AppProps {
  footerData: IFooterData
  accessToken: string
  messages: any
  session: ExtendedSession
}

export default function MyApp(props: MyAppProps): React.ReactElement {
  const router = useRouter()
  const { Component, footerData, messages, pageProps } = props

  const isAdminPages = useMemo(() => {
    return router.pathname.includes("/admin")
  }, [router.pathname])

  useEffect(() => {
    if (router.asPath.endsWith("#")) router.push(removeHash(router.asPath))
  }, [router.events])

  useEffect(() => {
    api.setAccessToken(props.accessToken)
  }, [])
  const position = useToasterPosition()
  return (
    <IntlProvider
      messages={messages}
      onError={() => {}}
      locale="nl"
      defaultLocale="nl"
    >
      <Head>
        {!isAdminPages && (
          <script
            id="zsiqchat"
            dangerouslySetInnerHTML={{
              __html: `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "38ba6d4be7fe8bd72f8361e1674ae8a6f298170a8c44be12555015954dacefb5ada2942de56e6f010765d56964cdb0f9", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.eu/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);`,
            }}
          />
        )}
      </Head>
      <Script
        id="gtag-base"
        dangerouslySetInnerHTML={{
          __html: `
						(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
						new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
						j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
						'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
						})(window,document,'script','dataLayer','${appConfig.gtmId}');
					`,
        }}
      />
      <Toaster position={position} />
      <AnalyticsProvider>
        <ModalProvider>
          <QueryClientProvider client={queryClient}>
            <AdminContextProvider value={pageProps?.adminContext}>
              <AuthProvider
                session={props.session}
                refetchInterval={TOKEN_REFRESH_INTERVAL}
                refetchOnWindowFocus={false}
              >
                <V2Modal />
                {pageProps.statusCode === 402 ? (
                  <PaymentRequired {...pageProps} />
                ) : pageProps.statusCode ? (
                  <ErrorComponent {...pageProps} />
                ) : (
                  <Component {...pageProps} footerData={footerData} />
                )}
              </AuthProvider>
            </AdminContextProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ModalProvider>
      </AnalyticsProvider>
    </IntlProvider>
  )
}
/**
 * Remember that getInitialProps gets called on every request - serverside. Make sure to keep memory and cpu footprint of this function low
 */
MyApp.getInitialProps = async (appContext: AppContext) => {
  const session = (await getSession({
    req: appContext.ctx.req,
  })) as ExtendedSession

  api.setAccessToken(session?.accessToken as string)

  const appProps = (await getFromCache("appProps", async () =>
    App.getInitialProps(appContext),
  )) as Object

  const footerData = (await getFromCache("footerData", async () =>
    api.strapi.getSingleTypeByName("footer"),
  )) as IFooterData

  // Place parsed data into cache - we dont want to perform parsing on every request, it is quite expensive
  const messages = await getFromCache("messages", async () => {
    const dictItems = (
      (await api.strapi.getSingleTypeByName("dictionary")) as IDictionary
    ).dictionaryItems
    return dictItems.reduce(
      (out: object, item: IDictionaryItem) => ({
        ...out,
        [item.key]: item.value,
      }),
      {},
    )
  })

  return {
    ...appProps,
    footerData,
    messages,
    session,
    accessToken: session?.accessToken,
  }
}
