import { styled } from "src/stitches.config"

import { HDefault } from "./h-default"

export const H5 = styled("h5", {
  ...HDefault,

  marginBottom: "0",
  fontSize: "1.125rem",
  lineHeight: "1.5",

  "@mdDown": {
    fontSize: ".875rem",
  },
})
