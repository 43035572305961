import { IIcon } from "@types"
import { ReactElement, ReactSVG } from "react"

export const ArrowUpIcon = ({
  fill = "#fff",
  height = "10",
  width = "16",
}: IIcon): ReactElement<ReactSVG> => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 10"
    width={width}
    height={height}
  >
    <path
      d="M15.707 8.957a.999.999 0 0 0 0-1.414l-7-7a.998.998 0 0 0-1.414 0l-7 7a1 1 0 0 0 1.144 1.606l5.856-5.856a1.006 1.006 0 0 1 1.414 0l5.855 5.856c.373.182.835.117 1.145-.192z"
      fill={fill}
      fillRule="nonzero"
    />
  </svg>
)
