import Link from "next/link"
import React from "react"
import { iconSizes } from "src/constants"
import { styled } from "src/stitches.config"

import { ChevronRightIcon } from "../assets-icons"
import { P } from "../typography"

interface Props {
  discipline: {
    code: string
    title: string
  }
}

const DisciplineTileWrapper = styled("div", {
  position: "relative",
  borderRadius: "4px",
  backgroundColor: "$orangeLight",
  overflow: "hidden",
  transition: "background-color .15s ease",

  "&:hover": {
    backgroundColor: "#ffa265",
  },
})

const DisciplineTileBackground = styled("div", {
  position: "absolute",
  top: "0",
  right: "0",
  width: "150%",
  paddingBottom: "150%",

  img: {
    position: "absolute",
    right: "-5%",
    width: "100%",
    height: "100%",
  },
})

const DisciplineTileForeground = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "column-reverse",
  minHeight: "110px",
  padding: "$10",
  color: "$white",

  "a::after": {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    content: "''",
  },

  [P.toString()]: {
    margin: "0",
    color: "$white",

    a: {
      color: "$white",
      textDecoration: "none",
    },

    svg: {
      position: "relative",
      top: "-.1em",
      left: ".1em",
    },
  },
})

export const DisciplineTile = (props: Props) => (
  <DisciplineTileWrapper data-cy="discipline-tile">
    <DisciplineTileBackground>
      <img src="/images/discipline_tile_background.svg" alt="" />
    </DisciplineTileBackground>

    <DisciplineTileForeground>
      <P size="extraSmall" data-cy="code">
        {props.discipline.code}
        <ChevronRightIcon size={iconSizes.xsmall} />
      </P>

      <P bold data-cy="title">
        <Link
          href={`/kwaliteitszorgsysteem/voorschriften/disciplines/${props.discipline.code}`}
        >
          {props.discipline.title}
        </Link>
      </P>
    </DisciplineTileForeground>
  </DisciplineTileWrapper>
)
