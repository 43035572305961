import React from "react"
import { styled } from "src/stitches.config"

import { PageWidthContainer } from "../../page-width-container"
import { OverviewHeader, OverviewHeaderShadow } from "../../visuals"

const OverviewHeaderVisualWrapper = styled("div", {
  position: "absolute",
  top: "0",
  right: "0",
  bottom: "0",
  left: "0",
  pointerEvents: "none",
})

const VisualWrapper = styled("div", {
  position: "absolute",
  bottom: "0",
  left: "50%",
  width: "100%",
  height: "calc(100% + 15px)",
  transform: "translate(-50%, 15px)",

  svg: {
    display: "block",
    width: "100%",
    height: "100%",
    overflow: "visible",
  },
})

export const OverviewHeaderVisual = () => (
  <OverviewHeaderVisualWrapper>
    <PageWidthContainer
      size="wide"
      css={{ position: "relative", height: "100%" }}
    >
      <VisualWrapper>
        <OverviewHeaderShadow />
      </VisualWrapper>

      <VisualWrapper>
        <OverviewHeader />
      </VisualWrapper>
    </PageWidthContainer>
  </OverviewHeaderVisualWrapper>
)
