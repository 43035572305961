const routes = {
  accommodations: {
    overview: {
      route: "/accommodatiepaspoort",
      permissions: ["getAccommodations"],
    },
    editAdmin: {
      route: "/admin/accommodatiepaspoort/:id/edit",
      permissions: ["updateAccommodationById"],
    },
  },
  "sports-products": {
    list: { route: "/sportproductenlijst" },
    create: {
      route: "/admin/sportproducten/nieuw",
      permissions: ["createSportProduct"],
    },
    get: { route: "/sportproducten/:id" },
    edit: {
      route: "/admin/sportproducten/:id",
      permissions: ["updateSportProductById"],
    },
  },
  "handbook/process": {
    list: { route: "/procedures", permissions: ["getKnowledgeBaseItems"] },
    create: {
      route: "/admin/procedures/nieuw",
      permissions: ["createKnowledgeBase"],
    },
    get: { route: "/procedures/:id", permissions: ["getKnowledgeBaseById"] },
    edit: {
      route: "/admin/procedures/:id",
      permissions: ["updateKnowledgeBaseById"],
    },
    archive: {
      route: "/procedures/:id/archief",
      permissions: ["getKnowledgeBaseVersionsById"],
    },
    archive_get: {
      route: "/procedures/:id/archief/:version",
      permissions: ["getKnowledgeBaseVersionsById"],
    },
  },
  "knowledge-base": {
    list: { route: "/knowledge-base", permissions: ["getKnowledgeBaseItems"] },
    create: {
      route: "/admin/knowledge-base/nieuw",
      permissions: ["createKnowledgeBase"],
    },
    get: {
      route: "/knowledge-base/:id",
      permissions: ["getKnowledgeBaseById"],
    },
    edit: {
      route: "/admin/knowledge-base/:id",
      permissions: ["updateKnowledgeBaseById"],
    },
    archive: {
      route: "/knowledge-base/:id/archief",
      permissions: ["getKnowledgeBaseVersionsById"],
    },
    archive_get: {
      route: "/knowledge-base/:id/archief/:version",
      permissions: ["getKnowledgeBaseVersionsById"],
    },
  },
}

module.exports = { routes }
