import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const UserAssetsIcon = ({
  color = "currentColor",
  size = iconSizes.regular,
  width,
  height,
  ...rest
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
    version="1.1"
    {...rest}
  >
    <g fill={color} stroke="none" strokeWidth="1" fillRule="evenodd">
      <path d="M12,6 C14.7666667,6 17,8.23333333 17,11 C17,13.7666667 14.7666667,16 12,16 C9.23333333,16 7,13.7666667 7,11 C7,8.23333333 9.23333333,6 12,6 Z M12,8 C10.34,8 9,9.34 9,11 C9,12.66 10.34,14 12,14 C13.66,14 15,12.66 15,11 C15,9.34 13.66,8 12,8 Z" />
      <path d="M12.25,20.0611653 C16.23,20.0611653 24.19,21.57061 24.25,23.9348015 L24.1018819,24.0611653 C19.1515905,22.7710829 15.2009633,22.1260417 12.25,22.1260417 C9.29911111,22.1260417 5.34860861,22.7710503 0.3984925,24.0610677 C0.346802694,24.0203605 0.298081818,23.9777544 0.25,23.9348015 C0.31,21.57061 8.25,20.0611653 12.25,20.0611653 Z" />
    </g>
  </svg>
)
