import { IAttachment } from "src/services"
import { styled } from "src/stitches.config"
import getAbsoluteSrc from "src/utils/get-absolute-src"

import { Button } from "../button"
import { P } from "../typography"

interface Props {
  attachment: IAttachment
}

const NewsItemAttachmentWrapper = styled("div", {
  paddingTop: "$20",
  "a, a:hover, a:focus": {
    textDecoration: "none",
  },

  "@mdUp": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
})

const NewsItemAttachmentBody = styled("div", {
  "@mdDown": {
    marginBottom: "$20",
  },
})

export const NewsItemAttachment = ({ attachment }: Props) => (
  <NewsItemAttachmentWrapper>
    <NewsItemAttachmentBody>
      <P bold noMargin>
        {attachment.title}
      </P>

      <P noMargin>{attachment.description}</P>
    </NewsItemAttachmentBody>

    <a href={getAbsoluteSrc(attachment.file.url)} target="_blank" download>
      <Button icon="download" size="large" variant="secondary" outline>
        {attachment.buttonText}
      </Button>
    </a>
  </NewsItemAttachmentWrapper>
)
