import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const EditIcon = ({
  color = "currentColor",
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
    version="1.1"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(1.000000, 1.000000)">
          <path
            d="M18.2953262,0 L18.4264568,0.00229243494 L18.5160805,0.0102926421 L18.6038364,0.0232848833 L18.6852246,0.040023305 L18.7783934,0.0649401503 L18.8526948,0.0894295045 L18.9206925,0.115884917 L19.0206936,0.161234631 L19.0645899,0.184423682 C19.1925116,0.253338507 19.3126339,0.342103189 19.4206364,0.450366321 L21.5496687,2.57907813 C21.9716606,3.0020884 22.0974567,3.60933674 21.9254494,4.14098938 L21.9066468,4.19556113 C21.8446222,4.36509527 21.7516725,4.52585295 21.6277438,4.66914158 L21.5496687,4.75294893 L19.183,7.117 L19.1840773,7.11818427 L16.5822033,9.7191985 L16.581,9.718 L19.183,7.117 L14.8801623,2.81560166 L12.2782883,5.41661589 L16.581,9.718 L14.179828,12.120412 L4.76477419,21.5345472 L0.902511656,21.9947288 C0.656994053,22.0228264 0.412048508,21.9375823 0.237303104,21.7632278 C0.0625576999,21.5888734 -0.022877605,21.3444759 0.00528298038,21.0995076 L0.430537634,17.2624591 L17.2457537,0.450366321 L17.3140483,0.386048969 L17.3951053,0.318950672 L17.488334,0.252822856 L17.5588629,0.208575233 L17.637315,0.165788982 L17.7331256,0.121507638 L17.815354,0.0893822672 L17.8534593,0.0761178845 L17.9396034,0.050637551 L18.0264989,0.0303555982 L18.122452,0.0140148196 L18.2024338,0.00509823609 L18.2953262,0 Z"
            fill={color}
          ></path>
        </g>
      </g>
    </g>
  </svg>
)
