import { styled } from "src/stitches.config"

import { HorizontalFlex } from "../../flex"

export const NavMenu = styled(HorizontalFlex, {
  alignItems: "center",
  alignSelf: "flex-end",

  [`& > *:not(:first-child)`]: {
    marginLeft: "$40",
  },
})
