import { IDropdownOption } from "@types"
import { useMemo } from "react"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import { ORGANIZATION_TYPES } from "src/constants"
import useKVKSearch from "src/hooks/useKVKSearch"

import { AsyncDropdown } from "../../async-dropdown"

export const MiscDropdown = () => {
  const { filter: kvkOptionsFilter } = useKVKSearch()
  const { formatMessage } = useIntl()
  const { setValue, getValues } = useFormContext()

  const loadOptions = async (query: string) => {
    if (query.length < 3) return []

    const options = await kvkOptionsFilter(null, query)
    return options
  }

  const handleChange = (option: IDropdownOption | null) => {
    if (!option) return

    const parsedOption = JSON.parse(option.value)

    setValue(
      "owner",
      {
        name: parsedOption.name,
        kvkNumber: parsedOption.kvkNumber,
        kvkEstablishmentNumber: parsedOption.kvkEstablishmentNumber,
        type: ORGANIZATION_TYPES.MISC,
        address: parsedOption.address.street,
        city: parsedOption.address.city,
      },
      {
        shouldValidate: true,
      },
    )
  }
  const value = useMemo(
    () =>
      getValues("owner")
        ? {
            label: getValues("owner")?.name,
            value: getValues("owner"),
          }
        : undefined,
    [getValues("owner")?.name],
  )

  return (
    <AsyncDropdown
      value={value}
      name="owner"
      placeholder={formatMessage({ id: "form.select.placeholder.kvk" })}
      loadOptions={loadOptions}
      handleChange={handleChange}
    />
  )
}
