import { styled } from "src/stitches.config"

export const HorizontalFlex = styled("div", {
  display: "flex",
  flexDirection: "row",

  variants: {
    justifyContent: {
      spaceBetween: {
        justifyContent: "space-between",
      },
    },
    alignItems: {
      center: {
        alignItems: "center",
      },
      start: {
        alignItems: "flex-start",
      },
    },
  },
})
