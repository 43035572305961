import { styled } from "src/stitches.config"

export const FiltersContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  marginTop: "2rem",
  flexFlow: "wrap",

  H3: {
    color: "$headerBlue",
  },

  "@mdDown": {
    flexDirection: "column",
    marginTop: "2rem",
  },

  "@mdUp": {
    "& > *:not(:last-child)": {
      marginRight: "20px",
    },
  },
})
