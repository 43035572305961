import React, { ReactElement, useState } from "react"
import { IInfrastructureSection, IKnowledgeBaseSection } from "src/services"
import { styled } from "src/stitches.config"
import hasMandatoryRules from "src/utils/has-mandatory-rules"

import { Grid } from "../../grid"
import { GridItem } from "../../grid-item"
import { IconWrapper } from "../../icon-wrapper"
import { Message } from "../../message"
import { Toggle } from "../../toggle"
import { P } from "../../typography"

interface Props {
  sections: Array<IInfrastructureSection | IKnowledgeBaseSection>
  hideOptionalRules: boolean
  onOptionalRulesToggle: () => void
  onClick: (index: string) => void
  enableIndexToggle?: boolean
}

const PageIndexWrapper = styled("div", {
  marginBottom: "$80",
  borderRadius: "4px",
  overflow: "hidden",

  "@mdDown": {
    marginBottom: "$40",
  },
})

const PageIndexContent = styled("div", {
  padding: "$40",
  backgroundColor: "$white",

  "@mdDown": {
    padding: "$20",
  },

  "@smDown": {
    padding: "$10 0 $10 $10",
  },
})

const PageIndexFooter = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  padding: "$20 $40",
  backgroundColor: "rgba(255, 255, 255, 0.6)",

  [P.toString()]: {
    marginRight: "$20",
  },

  "@mdDown": {
    padding: "$20",
  },
})

const PageIndexTitle = styled("div", {
  "@lgUp": {
    marginTop: "-.09em",
    textAlign: "right",
  },
})

const PageIndexSections = styled("div", {
  display: "block",
  marginTop: "1em",
  gridColumnGap: "$10",

  "@mdUp": {
    display: "grid",
    gridTemplateColumns: `repeat(3, 1fr)`,
  },
  variants: {
    isHidden: {
      true: {
        "@lgDown": {
          display: "none",
        },
      },
    },
  },
})

const PageIndexSection = styled("div", {
  position: "relative",
  display: "flex",
  color: "$blue",
  marginBottom: "$20",
  transition: "opacity .15s ease",

  "@lgDown": {
    "&:last-child": {
      marginBottom: "0",
    },
  },

  "a::after": {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    content: "''",
  },

  variants: {
    isHidden: {
      true: {
        opacity: ".15",
      },
    },
  },
})

const PageIndexSectionIndex = styled("div", {
  flex: "0 0 $30",
  width: "$30",
  height: "$30",
  marginTop: "-3px",
  marginRight: "$10",
  fontFamily: "$nova",
  textAlign: "center",
  color: "$tertiary",
  lineHeight: "$sizes$30",
  borderRadius: "4px",
  backgroundColor: "$white",
  boxShadow: "$default",
})

const PageIndexSectionTitle = styled("div", {
  flex: "0 0 calc(100% - $sizes$40)",
})

const StyledP = styled(P, {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  pointerEvents: "none",
  marginBottom: "0",
  "@lgDown": {
    cursor: "pointer",
    pointerEvents: "auto",
  },
})

const StyledIcon = styled("span", {
  fontSize: "1em",
  visibility: "hidden",
  "@lgDown": {
    visibility: "visible",
  },

  "@smDown": {
    marginRight: "1.5em",
  },
})

const StyledPFooter = styled(P, {
  margin: "0 $10 0 0",
})

export const PageIndex = ({
  sections,
  hideOptionalRules,
  onClick,
  enableIndexToggle,
  onOptionalRulesToggle,
}: Props): ReactElement => {
  const [isOpen, setIsOpen] = useState(false)
  const rowsPerColumn = Math.ceil(sections.length / 3)
  let gridColumnStart = 1
  let gridRowStart = 0

  const openClickHandler = () => {
    setIsOpen(!isOpen)
  }

  return (
    <PageIndexWrapper data-cy="page-index">
      <PageIndexContent>
        <Grid>
          <GridItem col={[1, 3]} medium={[1, 12]}>
            <PageIndexTitle>
              <StyledP size="large" bold onClick={openClickHandler}>
                <Message id="infrastructureDetail.page.index.title" />
                <StyledIcon>
                  <IconWrapper
                    name={isOpen ? "arrowUp" : "arrowDown"}
                    fill="#00378a"
                  />
                </StyledIcon>
              </StyledP>
            </PageIndexTitle>
          </GridItem>

          <GridItem col={[4, 9]} medium={[1, 12]}>
            <PageIndexSections isHidden={!isOpen}>
              {sections.map((section, index) => {
                const isHidden =
                  hideOptionalRules && !hasMandatoryRules(section)

                if (gridRowStart >= rowsPerColumn) {
                  gridColumnStart++
                  gridRowStart = 0
                }

                gridRowStart++

                function handleSectionClick(event: React.MouseEvent): void {
                  event.preventDefault()
                  onClick(section.id)
                }

                return (
                  <PageIndexSection
                    key={section.id}
                    css={{ gridColumnStart, gridRowStart }}
                    isHidden={isHidden}
                  >
                    <PageIndexSectionIndex>{index + 1}</PageIndexSectionIndex>
                    <PageIndexSectionTitle>
                      {isHidden ? (
                        section.title
                      ) : (
                        <a href="#" onClick={handleSectionClick}>
                          {section.title}
                        </a>
                      )}
                    </PageIndexSectionTitle>
                  </PageIndexSection>
                )
              })}
            </PageIndexSections>
          </GridItem>
        </Grid>
      </PageIndexContent>

      {enableIndexToggle && (
        <PageIndexFooter>
          <StyledPFooter>
            <Message id="infrastructureDetail.page.index.optionalRules" />
          </StyledPFooter>
          <Toggle
            checked={hideOptionalRules}
            onChange={onOptionalRulesToggle}
          />
        </PageIndexFooter>
      )}
    </PageIndexWrapper>
  )
}
