import { Session } from "next-auth"
import hasPermissions from "src/utils/has-permissions"

import { v2Routes } from "../services"

export const adminMenu = [
  {
    label: "certificationProcess",
    href: v2Routes.certificationProcess.all.source,
    permissions: v2Routes.certificationProcess.all.permissions,
    dataCy: null,
  },
  {
    label: "accommodations",
    href: v2Routes.accommodations.all.source,
    permissions: v2Routes.accommodations.all.permissions,
    dataCy: null,
  },
]

export const getAuthorizeAdminMenu = (
  session: Session | null,
  formatMessage: Function,
) => {
  const authorizedMenu = adminMenu
    .filter((item) => hasPermissions(session, item.permissions))
    .map((item) => ({
      label: formatMessage({ id: `adminPanel.item.${item.label}` }),
      href: item.href,
      dataCy: item.dataCy,
    }))

  return authorizedMenu
}
