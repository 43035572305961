import { useCallback, useState } from "react"

const useTimeout = (callback: (...args: any[]) => void, ms?: number) => {
  const [textTimeout, setTextTimeout] = useState<NodeJS.Timeout>()

  const startTimeout = useCallback(() => {
    setTextTimeout(setTimeout(callback, ms))
  }, [])

  const stopTimeout = useCallback(() => {
    textTimeout && clearTimeout(textTimeout)
  }, [textTimeout])

  return [startTimeout, stopTimeout]
}

export default useTimeout
