import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const LinkIcon = ({
  color = "currentColor",
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
    version="1.1"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g transform="translate(2.000000, 2.000000)">
        <g>
          <g transform="translate(0.000000, 6.842220)">
            <path
              d="M12.6536425,3.16669846 C12.4568294,2.47018135 12.1505162,1.88897406 11.7347031,1.42307657 C9.83647824,-0.474358856 6.75968491,-0.474358856 4.86146009,1.42307657 L1.42372571,4.85969805 C-0.474575238,6.75799901 -0.474575238,9.83575296 1.42372571,11.7340539 C3.32202667,13.6323549 6.39978062,13.6323549 8.29808158,11.7340539 L10.9345028,8.87176282"
              stroke={color}
              strokeWidth="1.5"
            ></path>
          </g>
        </g>
        <g>
          <g transform="translate(7.141840, 0.000000)">
            <path
              d="M0,9.83792859 C0.192399956,10.4321766 0.577813925,11.0535065 1.15624191,11.7019184 C3.05459413,13.6000956 6.13224554,13.6000956 8.03059777,11.7019184 L11.4672193,8.26529694 C13.3345357,6.36176691 13.3198788,3.30930192 11.4343686,1.42379171 C9.54885841,-0.461718497 6.49639342,-0.476375417 4.59286339,1.39094108 L2.87455265,3.10925182 L1.75372114,4.24576547"
              stroke={color}
              strokeWidth="1.5"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
