import { styled } from "src/stitches.config"

export const FilterContainer = styled("span", {
  display: "flex",
  flexDirection: "row",
  marginBottom: "2rem",

  "& > *:first-child": {
    marginRight: "$20",
  },

  "&:last-child": {
    marginBottom: "0",
  },

  "@mdDown": {
    flexDirection: "column",
    marginBottom: 0,

    "&:not(:first-child)": {
      marginTop: "$20",
    },

    "& > *:last-child": {
      width: "100%",
    },
  },
})
