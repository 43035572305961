import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const ArrowLeftIcon = ({
  color = "currentColor",
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
    version="1.1"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M12.7234865,5.80090503 C13.5796521,6.64731249 14.2849946,7.34461595 14.8395142,7.89281541 C15.3940338,8.44101487 16.2258131,9.26331406 17.3348522,10.359713 L2.65950869,10.359713 C1.74345989,10.359713 1,11.0946993 1,12.0003075 C1,12.9059157 1.74345989,13.6409021 2.65950869,13.6409021 L17.3348522,13.6409021 L12.7234865,18.19971 C12.0746186,18.8411825 12.0746186,19.8796788 12.7234865,20.5195107 C13.3723544,21.1593425 14.4228234,21.1609831 15.0700318,20.5195107 L22.5141788,13.1602079 C22.6668536,13.0092732 22.7896573,12.8288078 22.8742922,12.6286552 C23.0419026,12.2267096 23.0419026,11.7739055 22.8742922,11.3719598 C22.7929763,11.1783696 22.6734917,10.9979042 22.5141788,10.8404072 L15.0700318,3.48110435 C14.4211639,2.83963188 13.3706949,2.83963188 12.7234865,3.48110435 C12.0762781,4.12257681 12.0746186,5.16107316 12.7234865,5.80090503 Z"
        fill={color}
        transform="translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000) "
      ></path>
    </g>
  </svg>
)
