export function toHumanReadableString<T>(items?: T): string | T {
  if (!items) {
    return "--"
  }

  if (Array.isArray(items)) {
    return items.length ? items.join(", ") : "--"
  }

  return items
}
