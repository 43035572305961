import { merge } from "lodash"
import { IFooterContactItem } from "src/services"

const defaults: IFooterContactItem = {
  id: 1,
  title: "Contact us",
  icon: "chat",
  description: "Contact us for more information",
  meta: "Call us at 555-555-5555",
  href: "tel:555-555-5555",
}

export const createFooterContactItem = (
  override?: Partial<IFooterContactItem>,
): IFooterContactItem => merge({}, defaults, override)
