export const v2AccommodationColumns = [
  {
    accessor: "code" as const,
    Filter: () => null,
    Header: "Code",
  },
  {
    accessor: "city" as const,
    Filter: () => null,
    Header: "Plaats",
  },
  {
    accessor: "sportClubs" as const,
    Filter: () => null,
    Header: "Sportvereniging",
  },
  {
    accessor: "name" as const,
    Filter: () => null,
    Header: "Accommodatienaam",
  },
] as const
