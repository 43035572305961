import Link from "next/link"
import { ReactElement } from "react"
import { IErrorPage } from "src/services"
import { styled } from "src/stitches.config"

import { Button } from "../button"
import { Grid } from "../grid"
import { GridItem } from "../grid-item"
import { IconWrapper } from "../icon-wrapper"
import { StyledPageContent, StyledPageLead } from "../styled-page-content"
import { P } from "../typography"

const GoToLink = styled("div", {
  a: {
    color: "$blue",
    display: "inline-flex",
    alignItems: "center",

    span: {
      marginLeft: "0",
    },
  },

  "&:hover, &:focus": {
    svg: {
      opacity: 0.75,
    },
  },
})

const StyledButton = styled(Button, {
  width: "40px",
  height: "40px",
  marginLeft: "$20",
  svg: {
    width: "30px",
  },
})

export const ErrorMessage = (props: IErrorPage): ReactElement => (
  <Grid>
    <GridItem col={[2, 10]} medium={[1, 12]}>
      <StyledPageContent
        noPadding
        data-cy="page-content"
        css={{ "@mdUp": { marginBottom: "$40" } }}
      >
        <Grid columns={10}>
          <GridItem col={[2, 7]} small={[1, 10]}>
            <StyledPageLead>
              <P size="large" bold data-cy="page-lead">
                {props?.description}
              </P>
            </StyledPageLead>
          </GridItem>
        </Grid>
      </StyledPageContent>

      {props?.goto &&
        props?.goto.map(({ text, href }, index) => {
          return (
            <GoToLink key={`${href}-${index}`}>
              <Link href={href} passHref>
                {text}
                <StyledButton variant="light" size="large" aria-label={text}>
                  <IconWrapper name="arrowTailed" fill="#eb5e00" />
                </StyledButton>
              </Link>
            </GoToLink>
          )
        })}
    </GridItem>
  </Grid>
)
