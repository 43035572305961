import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from "@tanstack/react-query"
import { organizationsService } from "src/services"

import { UserOrganizations } from "../types"

export function useUserOrganizations(
  options?: UseQueryOptions,
): UseQueryResult<UserOrganizations> {
  const queryFn = () => organizationsService.getUserAll()

  return useQuery(["userOrganizations"], queryFn, {
    initialData: options?.initialData,
  })
}
