import { IIcon } from "@types"
import { ReactElement, ReactSVG } from "react"

export const ArrowDownIcon = ({
  fill = "#FFF",
  height = "10",
  width = "16",
}: IIcon): ReactElement<ReactSVG> => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 10"
  >
    <path
      d="M14.043 1.043 9 6.086c-.299.295-.632.443-1 .443s-.702-.148-1-.443L1.957 1.043A.999.999 0 1 0 .543 2.457l6.75 6.75a1.006 1.006 0 0 0 1.414 0l6.75-6.75a.999.999 0 1 0-1.414-1.414z"
      fill={fill}
      fillRule="nonzero"
    />
  </svg>
)
