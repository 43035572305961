import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { useController, useFieldArray, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import { styled } from "src/stitches.config"
import { v4 as uuidv4 } from "uuid"

import { Button } from "../../button"
import {
  CreateRule,
  CreateSubsection,
  EditableRule,
  EditableSubsection,
} from "../../edit-forms"
import { FormInput, RemoveWithConfirmationButton } from "../../forms"
import { Grid } from "../../grid"
import { GridItem } from "../../grid-item"
import { H4 } from "../../typography"

interface Props {
  index: number
  path: string
  section: any
  remove: Function
  removeFromIndex: number
}

const SectionContainer = styled("div", {
  padding: "$20 $20 $10",
  marginBottom: "$10",
  backgroundColor: "$white",
  counterReset: "ruleIndex",
  counterIncrement: "sectionIndex",
})

const SectionIndex = styled("div", {
  textAlign: "right",

  "*": {
    color: "$tertiary",
  },
})

const SectionTitle = styled("div", {
  textAlign: "right",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
})

const SectionTitleButtonGroup = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "$10",

  "> *:not(:last-child)": {
    marginRight: "0.25em",
  },
})

const SubsectionContainer = styled("div", {
  padding: "$20 $20 1px",
  marginBottom: "$10",
  backgroundColor: "#f9f9f9",
  borderLeft: "1px solid $colors$secondary",
  display: "flex",
  flexDirection: "column",
  position: "relative",
})

const SectionTitleInputContainer = styled("div", {
  marginBottom: "$10",
  scrollMarginTop: "calc(5rem + $10)",
  variants: {
    visible: {
      true: {
        display: "block",
      },
      false: {
        display: "none",
      },
    },
  },
})

const motionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}

export const EditableSection = ({
  path,
  index,
  section,
  remove,
  removeFromIndex,
}: Props) => {
  const { formatMessage } = useIntl()
  const { watch } = useFormContext()
  const [isSectionTitleInputVisible, setIsSectionTitleInputVisible] = useState(
    !section.title,
  ) // Initializing to be visible when there's no title yet

  const title = watch(`${path}.title`, section.title)
  const rules = useFieldArray({ name: `${path}.rules`, keyName: "_id" })

  const subsections = useFieldArray({
    name: `${path}.sections`,
    keyName: "_id",
  })

  const hasContent = !!rules.fields.length || !!subsections.fields.length

  const { fieldState: titleState } = useController({
    name: `${path}.title`,
  })

  const handleEditTitleClick = useCallback(() => {
    setIsSectionTitleInputVisible(!title ? true : !isSectionTitleInputVisible) // Forcing this to true when the title value is empty
  }, [isSectionTitleInputVisible, title])

  const handleRemoveSectionClick = useCallback(() => {
    remove(removeFromIndex)
  }, [removeFromIndex, remove])

  const handleAddSubSectionClick = useCallback(
    (index: number) => {
      subsections.insert(index, {
        id: uuidv4(),
        title: "",
        rules: [],
      })
    },
    [index],
  )

  const handleTitleEditBlur = useCallback(() => {
    if (title) {
      // Only blur out when there is title content
      setIsSectionTitleInputVisible(false)
    }
  }, [title])

  const titleInputRef: MutableRefObject<HTMLTextAreaElement | null> =
    useRef(null)
  const titleInputWrapRef: MutableRefObject<HTMLDivElement | null> =
    useRef(null)

  useEffect(() => {
    if (isSectionTitleInputVisible && titleInputRef.current) {
      titleInputRef.current.focus()
      var val = titleInputRef.current.value
      titleInputRef.current.value = ""
      titleInputRef.current.value = val

      // only scroll if this is not the first section, otherwise some elements get hidden at the top
      if (index !== 0) {
        titleInputWrapRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        })
      }
    }
  }, [isSectionTitleInputVisible, index])

  return (
    <div data-cy="section">
      <SectionTitleInputContainer
        visible={isSectionTitleInputVisible}
        ref={titleInputWrapRef}
      >
        <Grid>
          <GridItem col={[3, 8]}>
            <FormInput
              name={`${path}.title`}
              defaultValue={section.title}
              placeholder={formatMessage({
                id: "admin.editRule.chapterTitle.placeholder",
              })}
              trimLineBreaks
              displayAs={H4}
              italic
              inputRef={titleInputRef}
              onBlur={handleTitleEditBlur}
            />
          </GridItem>
        </Grid>
      </SectionTitleInputContainer>

      <Grid>
        <GridItem col={[1, 2]}>
          <SectionIndex>
            <H4 as="p">{index + 1}</H4>
          </SectionIndex>

          <SectionTitle data-cy="section-title">
            <H4>{title}</H4>
            <SectionTitleButtonGroup>
              {!hasContent && (
                <RemoveWithConfirmationButton
                  onConfirm={handleRemoveSectionClick}
                />
              )}
              <Button
                size="small"
                variant="light"
                icon="edit"
                onClick={handleEditTitleClick}
                aria-label="edit"
                data-cy="edit-title"
              />
            </SectionTitleButtonGroup>
          </SectionTitle>
        </GridItem>

        <GridItem col={[3, 8]}>
          <SectionContainer>
            <CreateRule
              insert={rules.insert}
              insertAtIndex={0}
              forceVisibility={!hasContent}
            />

            {rules.fields.map((rule, index) => {
              return (
                rule && (
                  <div key={rule._id} {...motionProps}>
                    <EditableRule
                      path={`${path}.rules.${index}`}
                      rule={rule}
                      remove={rules.remove}
                      removeFromIndex={index}
                    />
                    <CreateRule
                      insert={rules.insert}
                      insertAtIndex={index + 1}
                    />
                  </div>
                )
              )
            })}

            <CreateSubsection onClick={() => handleAddSubSectionClick(0)} />

            {subsections.fields.map((subsection, index) => {
              return (
                subsection && (
                  <div key={subsection._id} {...motionProps}>
                    <SubsectionContainer>
                      <EditableSubsection
                        path={`${path}.sections.${index}`}
                        subsection={subsection}
                        remove={subsections.remove}
                        index={index}
                      />
                    </SubsectionContainer>

                    <CreateSubsection
                      onClick={() => handleAddSubSectionClick(index + 1)}
                    />
                  </div>
                )
              )
            })}
          </SectionContainer>
        </GridItem>
      </Grid>
    </div>
  )
}
