import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const SpinIcon = ({
  color = "currentColor",
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
    version="1.1"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(3.25, 0.75)" fill={color} fillRule="nonzero">
        <path d="M8.7272744,2.56562049 L8.7272744,0.5 C8.7272744,0.223857625 8.50341677,-7.21644966e-15 8.2272744,-6.88338275e-15 C8.09466615,-6.77236045e-15 7.9674892,0.0526784202 7.87372101,0.146446609 L5.07074482,2.9494228 C4.68022052,3.33994709 4.68022052,3.97311207 5.07074482,4.36363636 L7.87372101,7.16661256 C8.06898315,7.3618747 8.38556564,7.3618747 8.58082779,7.16661256 C8.67459598,7.07284437 8.7272744,6.94566741 8.7272744,6.81305916 L8.7272744,4.74743867 L8.7272744,4.74743867 C12.3381835,4.74743867 15.2727289,7.68198413 15.2727289,11.2928932 C15.2727289,12.3947114 15.0000017,13.4419841 14.5090926,14.3474387 C14.0181835,15.2528932 15.2179135,17.3289917 16.1018199,15.9401659 C16.9857262,14.5513402 17.4550052,12.9391396 17.4545481,11.2928932 C17.4545481,6.47107504 13.5490926,2.56562049 8.7272744,2.56562049 Z M2.18181985,11.2928932 C2.18181985,10.191075 2.45454712,9.14380231 2.94545622,8.23834776 C3.43636531,7.33289322 2.23663528,5.25679472 1.35272894,6.64562049 C0.468822604,8.03444627 -0.000456426629,9.64664681 3.33112894e-07,11.2928932 C3.33112894e-07,16.1147114 3.90545622,20.0201659 8.7272744,20.0201659 L8.7272744,22.0857864 C8.7272744,22.3619288 8.95113202,22.5857864 9.2272744,22.5857864 C9.35988264,22.5857864 9.4870596,22.533108 9.58082779,22.4393398 L12.383804,19.6363636 C12.7743283,19.2458393 12.7743283,18.6126744 12.383804,18.2221501 L9.58082779,15.4191739 C9.38556564,15.2239117 9.06898315,15.2239117 8.87372101,15.4191739 C8.77995282,15.5129421 8.7272744,15.640119 8.7272744,15.7727273 L8.7272744,17.8383478 L8.7272744,17.8383478 C5.11636531,17.8383478 2.18181985,14.9038023 2.18181985,11.2928932 Z" />
      </g>
    </g>
  </svg>
)
