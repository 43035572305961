import React, { ReactElement } from "react"
import { IFooterSponsorItem } from "src/services"
import { styled } from "src/stitches.config"

import { IconWrapper } from "../../icon-wrapper"
import { P } from "../../typography"

interface Props {
  title: string
  items: IFooterSponsorItem[]
}

const SponsorFooter = styled("div", {
  textAlign: "center",
  backgroundColor: "$primary",
  color: "$white",
  fontSize: "0.75rem",
  padding: "$40 0",
  fontFamily: "$grotesk",
})

const SponsorWrapper = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "$20",
  maxWidth: "768px",
  margin: "0 auto",
  flexWrap: "nowrap",

  a: {
    flexGrow: 1,
    flexBasis: 0,
    padding: "0 $10",

    "&:hover": {
      color: "inherit",
    },
  },
})

const Sponsor = styled("div", {
  marginTop: "$10",
})

export const FooterSponsors = (props: Props): ReactElement => (
  <SponsorFooter data-cy="footer-sponsors">
    <P size="large" bold css={{ color: "$white" }}>
      {props.title}
    </P>
    <SponsorWrapper>
      {props.items.map(({ id, icon, title, url }) => {
        return (
          <a key={id} href={url}>
            <IconWrapper name={icon} />
            <Sponsor>{title}</Sponsor>
          </a>
        )
      })}
    </SponsorWrapper>
  </SponsorFooter>
)
