import Link from "next/link"
import { styled } from "src/stitches.config"
import { v2Routes } from "v2-routes"

import { ArrowRightAssetsIcon } from "../assets-icons"

interface Props {
  id?: string
}

const ButtonsWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "20px",
  justifyContent: "flex-end",
})

const IconWrapper = styled("div", {
  backgroundColor: "$darkGreen",
  display: "flex",
  padding: "4px",
  borderRadius: "4px",
  alignItems: "center",
})

const LinkWrapper = styled("a", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: "4px",
  cursor: "pointer",
  fontSize: "12px",

  "&:hover, &:focus": {
    [`${IconWrapper}`]: {
      backgroundColor: "$orange",
    },
  },
})

export const AccommodationButtons = ({ id }: Props) => {
  const { source: detailSource } = v2Routes.accommodations.detail
  const { source: passportSource } = v2Routes.accommodations.passport

  if (!id) return null

  return (
    <ButtonsWrapper>
      <Link href={detailSource.replace(":id", id)} legacyBehavior>
        <LinkWrapper>
          <span>Bekijk accommodatie</span>

          <IconWrapper>
            <ArrowRightAssetsIcon color="white" width="10px" height="10px" />
          </IconWrapper>
        </LinkWrapper>
      </Link>

      <Link href={passportSource.replace(":id", id)} legacyBehavior>
        <LinkWrapper>
          <span>Bekijk accommodatiepaspoort</span>

          <IconWrapper>
            <ArrowRightAssetsIcon color="white" width="10px" height="10px" />
          </IconWrapper>
        </LinkWrapper>
      </Link>
    </ButtonsWrapper>
  )
}
