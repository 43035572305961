import { styled } from "src/stitches.config"

import { DisciplineTile } from "../discipline-tile"
import { DocumentTile } from "../document-tile"

interface Props {
  columns?: number
  gridItems: Array<{
    type?: "discipline" | "document"
    code: string
    title: string
    isActive: boolean
    category: string
  }>
}

const PreceptsOverviewGridWrapper = styled("div", {
  display: "flex",
  flexWrap: "wrap",
  width: "calc(100% + $sizes$20)",
  marginLeft: "-$10",
  marginBottom: "$40",
})

const PreceptsOverviewGridItem = styled("div", {
  padding: "$10",
  flex: "0 0 50%",
})

export const PreceptsOverviewGrid = ({ columns = 4, ...props }: Props) => {
  const css = {
    "@lgUp": {
      flex: `0 0 ${100 / columns}%`,
    },
  }

  return (
    <PreceptsOverviewGridWrapper data-cy="precept-tile">
      {props.gridItems.map((gridItem) => {
        const isDiscipline = gridItem.type === "discipline"

        return (
          <PreceptsOverviewGridItem key={gridItem.code} css={css}>
            {isDiscipline ? (
              <DisciplineTile discipline={gridItem} />
            ) : (
              <DocumentTile document={gridItem} />
            )}
          </PreceptsOverviewGridItem>
        )
      })}
    </PreceptsOverviewGridWrapper>
  )
}
