import { useIntl } from "react-intl"
import { CertificationProcessState } from "src/services"
import { styled } from "src/stitches.config"

interface Props {
  state: CertificationProcessState
  color?: "green" | "grey"
}

const CertificationProcessStateWrapper = styled("div", {
  position: "relative",
  top: "-1px",
  display: "inline-flex",
  alignItems: "center",
  height: "26px",
  padding: "3px 4px 5px",
  fontSize: "14px",
  fontWeight: 500,
  border: "1px solid currentColor",
  borderRadius: "4px",
  whiteSpace: "nowrap",

  variants: {
    color: {
      green: {
        color: "$mediumGreen",
      },
      grey: {
        color: "$gray",
      },
    },
  },
  defaultVariants: {
    color: "green",
  },
})

export function CertificationProcessStateBadge({
  state,
  color = "green",
}: Props) {
  const { formatMessage } = useIntl()

  return (
    <CertificationProcessStateWrapper color={color}>
      {formatMessage({ id: `certificationProcess.state.${state}` })}
    </CertificationProcessStateWrapper>
  )
}
