import React, { PropsWithChildren, ReactElement } from "react"
import { styled } from "src/stitches.config"

import { PageWidthContainer } from "../../page-width-container"
import { H1, P } from "../../typography"
import { InfrastructureArchivePageHeaderVisual } from "../infrastructure-archive-page-header-visual"
import { InfrastructurePageHeaderVisual } from "../infrastructure-page-header-visual"

const HandbookPageHeaderWrapper = styled("header", {
  position: "relative", // need this for positioning the InfrastructurePageHeaderVisual component
  marginBottom: "$80",
  backgroundColor: "$tertiary",

  "@mdDown": {
    marginBottom: "$40",
  },

  variants: {
    archive: {
      true: {
        backgroundColor: "$redCherry",
      },
    },
    noBackgrounds: {
      true: {
        backgroundColor: "transparent",
      },
    },
  },

  compoundVariants: [
    {
      archive: true,
      noBackgrounds: true,
      css: {
        backgroundColor: "$redCherry",
      },
    },
  ],
})

const HandbookPageHeaderBody = styled("div", {
  position: "relative",
  padding: "$40 0 $10",

  [H1.toString()]: {
    marginBottom: "$20",
    color: "$white",
    lineHeight: "1.15",
    textShadow:
      "-1px 0 $colors$blue, 0 1px $colors$blue, 1px 0 $colors$blue, 0 -1px $colors$blue",
  },

  [P.toString()]: {
    marginBottom: "0",
    color: "$white",
  },

  "@mdDown": {
    padding: "$20 0 0",
  },

  variants: {
    archive: {
      true: {
        [H1.toString()]: {
          textShadow:
            "-1px 0 $colors$red, 0 1px $colors$red, 1px 0 $colors$red, 0 -1px $colors$red",
        },
      },
    },
    noBackgrounds: {
      true: {
        [H1.toString()]: {
          color: "$blue",
          textShadow: "none",
        },
        [P.toString()]: {
          color: "$black",
        },
      },
    },
  },

  compoundVariants: [
    {
      archive: true,
      noBackgrounds: true,
      css: {
        [H1.toString()]: {
          color: "$white",
        },
        [P.toString()]: {
          color: "$white",
        },
      },
    },
  ],
})

interface HandbookPageHeaderSkeletonProps {
  archive?: boolean
  noBackgrounds?: boolean
  isSportTechnisch?: boolean
}

export const HandbookPageHeaderSkeleton = ({
  archive,
  noBackgrounds = false,
  children,
  isSportTechnisch,
}: PropsWithChildren<HandbookPageHeaderSkeletonProps>): ReactElement => (
  <HandbookPageHeaderWrapper archive={archive} noBackgrounds={noBackgrounds}>
    {archive
      ? !noBackgrounds && <InfrastructureArchivePageHeaderVisual />
      : !noBackgrounds && (
          <InfrastructurePageHeaderVisual isSportTechnisch={isSportTechnisch} />
        )}

    <PageWidthContainer>
      <HandbookPageHeaderBody archive={archive} noBackgrounds={noBackgrounds}>
        {children}
      </HandbookPageHeaderBody>
    </PageWidthContainer>
  </HandbookPageHeaderWrapper>
)
