import { PropsWithChildren, ReactElement } from "react"
import { IFooterData } from "src/services"
import { styled } from "src/stitches.config"

import { routes } from "../../../routes"
import { Footer } from "../footer"
import { MenuItemList, Navigation } from "../navigation"

interface FooterProps {
  footerData?: IFooterData
}

const PublicLayoutWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  overflow: "hidden",
})

const PublicLayoutBody = styled("main", {
  flex: "1 1 auto",
  backgroundColor: "$background",
})

const PublicLayoutFooter = styled("footer", {
  flex: "0 0 fit-content",
})

export const PublicLayout = (
  props: PropsWithChildren<FooterProps>,
): ReactElement => {
  const { footerData, children } = props

  const menuItems: MenuItemList[] = [
    { href: "/", icon: "home", mobileLabel: "Home", label: "Home" },
    {
      href: "#Kwaliteitszorgsysteem",
      label: "Kwaliteitszorgsysteem",
      items: [
        {
          href: "/kwaliteitszorgsysteem/voorschriften",
          label: "Voorschriften",
        },
        { href: routes["handbook/process"].list.route, label: "Procedures" },
        { href: "/programmaraad", label: "Programmaraad" },
        { href: "/betrokken-organisaties", label: "Betrokken organisaties" },
        {
          href: routes["sports-products"].list.route,
          label: "Sportproductenlijst",
        },
      ],
    },
    {
      href: "/accommodatiepaspoort",
      label: "Accommodatiepaspoort",
      tabletMenu: true,
    },
    { href: "/duurzaamheid", label: "Duurzaamheid", tabletMenu: true },
    { href: "/nieuws", label: "Nieuws", tabletMenu: true },
    {
      href: routes["knowledge-base"].list.route,
      label: "Kennisbank",
      tabletMenu: true,
    },
  ]

  return (
    <PublicLayoutWrapper>
      <Navigation items={menuItems} />

      <PublicLayoutBody data-cy="main">{children}</PublicLayoutBody>

      {!!footerData && (
        <PublicLayoutFooter>
          <Footer data={footerData} />
        </PublicLayoutFooter>
      )}
    </PublicLayoutWrapper>
  )
}
