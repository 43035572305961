import Link from "next/link"
import React, { ReactElement } from "react"
import { DATE_FORMAT } from "src/constants"
import { IInfrastructureData } from "src/services"
import { styled } from "src/stitches.config"
import formatDate from "src/utils/format-date"

import { Button } from "../../button"
import { Grid } from "../../grid"
import { GridItem } from "../../grid-item"
import { Message } from "../../message"
import { H1, P } from "../../typography"
import { HandbookPageHeaderSkeleton } from "../handbook-page-header-skeleton"

const HandbookData = styled("div", {
  display: "flex",
  alignItems: "center",
  color: "$fadedWhite",

  "@mdDown": {
    alignItems: "flex-start",
    flexDirection: "column",
  },
})

const HandbookDataItem = styled("div", {
  margin: "0 $20 $20 0",

  "@mdDown": {
    margin: "0",

    "&:last-child": {
      marginBottom: "$20",
    },
  },
})

const ArchiveLink = styled("div", {
  color: "$white",
  textDecoration: "underline",

  "&:hover, &:focus": {
    a: {
      color: "$white",
    },
  },

  variants: {
    isBlue: {
      true: {
        color: "$blue",
        "&:hover, &:focus": {
          a: {
            color: "$blue",
          },
        },
      },
    },
  },
})

const EditButtonContainer = styled("div", {
  textAlign: "right",

  "@lgDown": {
    display: "none",
  },
})

interface HandbookPageHeaderProps {
  title: IInfrastructureData["title"]
  code?: IInfrastructureData["code"]
  updatedAt: IInfrastructureData["updatedAt"]
  isArchive?: boolean
  hasArchive?: boolean
  isActive?: boolean
  backArchiveButton: ReactElement
  backParentButton: ReactElement
  archiveURL: string
  currentVersionURL: string
  editPageURL: string
  isEditEnabled?: boolean
  noBackgrounds?: boolean
  isSportTechnisch?: boolean
}

export const HandbookPageHeader = ({
  title,
  code,
  updatedAt,
  isArchive,
  hasArchive,
  isActive,
  backArchiveButton,
  backParentButton,
  archiveURL,
  currentVersionURL,
  editPageURL,
  noBackgrounds = false,
  isSportTechnisch = false,
  isEditEnabled = false,
}: HandbookPageHeaderProps) => {
  return (
    <HandbookPageHeaderSkeleton
      isSportTechnisch={isSportTechnisch}
      archive={isArchive}
      noBackgrounds={noBackgrounds}
    >
      {isArchive ? backArchiveButton : backParentButton}

      <Grid>
        <GridItem col={[2, 9]} medium={[1, 12]}>
          <H1 data-cy="handbookpage-title">{title}</H1>

          <HandbookData>
            {code && (
              <HandbookDataItem>
                <P bold size="large">
                  {code}
                </P>
              </HandbookDataItem>
            )}

            <HandbookDataItem>
              <P size="small">
                <Message
                  id="infrastructureDetails.updatedAt"
                  values={{ date: formatDate(updatedAt, DATE_FORMAT) }}
                />
              </P>
            </HandbookDataItem>

            {isArchive ? (
              <HandbookDataItem>
                <ArchiveLink isBlue={noBackgrounds}>
                  <Link href={currentVersionURL} data-cy="archive-link">
                    <Message
                      id={
                        "infrastructureDetails.archive.olderVersions.viewCurrent"
                      }
                    />
                  </Link>
                </ArchiveLink>
              </HandbookDataItem>
            ) : (
              hasArchive && (
                <HandbookDataItem>
                  <ArchiveLink isBlue={noBackgrounds}>
                    <Link href={archiveURL} data-cy="archive-link">
                      <Message
                        id={"infrastructureDetails.archiveButton.label"}
                      />
                    </Link>
                  </ArchiveLink>
                </HandbookDataItem>
              )
            )}
          </HandbookData>
        </GridItem>

        <GridItem col={[11, 2]}>
          {isActive && !isArchive && isEditEnabled && (
            <EditButtonContainer>
              <Link href={editPageURL} data-cy="admin-link">
                <Button icon="edit" variant="secondary" size="large" outline>
                  <Message id="infrastructureDetails.editButton.label" />
                </Button>
              </Link>
            </EditButtonContainer>
          )}
        </GridItem>
      </Grid>
    </HandbookPageHeaderSkeleton>
  )
}
