export const NoResultsGlyph = ({ size = 160 }) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        y1="128"
        id="LinearGradient"
        x1="0"
        y2="28.3046"
        x2="134.073"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(1 0 0 1 0 0)"
      >
        <stop stopColor="#00378a" offset="0" />
        <stop stopColor="#5076af" offset="1" />
      </linearGradient>
    </defs>
    <g id="Illustration/no-results">
      <path
        d="M80+0L80+0C124.183+1.19209e-06+160+35.8172+160+80L160+80C160+124.183+124.183+160+80+160L80+160C35.8172+160+7.56548e-06+124.183+8.9407e-07+80L8.9407e-07+80C-7.89762e-06+35.8172+35.8172+2.18184e-07+80+0Z"
        opacity="1"
        fill="none"
      />
      <path
        d="M96.247+96.6529C96.115+96.7538+95.9869+96.8664+95.8627+96.9906C95.7385+97.1148+95.6298+97.239+95.5251+97.3748C90.6424+102.083+84.0016+104.974+76.6815+104.974C69.179+104.974+62.3906+101.939+57.4692+97.0178C52.5477+92.0964+49.5125+85.3082+49.5125+77.8058C49.5125+70.3035+52.5477+63.5152+57.4692+58.5939C62.3906+53.6725+69.179+50.6374+76.6815+50.6374C84.184+50.6374+90.9723+53.6725+95.8938+58.5939C100.815+63.5152+103.85+70.3035+103.85+77.8058C103.85+85.1258+100.959+91.7665+96.247+96.6529L96.247+96.6529ZM118.238+113.874L103.975+99.6104C108.752+93.6334+111.613+86.0534+111.613+77.8058C111.613+68.161+107.701+59.4244+101.382+53.1058C95.0632+46.7873+86.3264+42.875+76.6815+42.875C67.0365+42.875+58.2998+46.7873+51.981+53.1058C45.6623+59.4244+41.75+68.161+41.75+77.8058C41.75+87.4506+45.6623+96.1872+51.981+102.506C58.2998+108.824+67.0365+112.737+76.6815+112.737C84.9292+112.737+92.5093+109.876+98.4865+105.098L112.75+119.362C114.268+120.879+116.725+120.879+118.238+119.362C119.752+117.844+119.756+115.387+118.238+113.874L118.238+113.874Z"
        opacity="1"
        fill="#5076af"
      />
      <path
        d="M80+0C124.183-8.11625e-15+160+35.8172+160+80C160+124.183+124.183+160+80+160C35.8172+160+5.41083e-15+124.183+0+80C-5.41083e-15+35.8172+35.8172+8.11625e-15+80+0ZM135.887+46.7897L34.7341+126.648C46.4377+138.007+62.4022+145+80+145C115.899+145+145+115.899+145+80C145+67.8663+141.675+56.5091+135.887+46.7897ZM80+15C44.1015+15+15+44.1015+15+80C15+93.6193+19.1887+106.26+26.348+116.705L128.15+36.3346C116.258+23.2296+99.0901+15+80+15Z"
        opacity="1"
        fill="url(#LinearGradient)"
      />
    </g>
  </svg>
)
