import React from "react"
import { globalCss, styled } from "src/stitches.config"

import { CloseAssetsIcon } from "../../../assets-icons"
import { Button } from "../../../button"
import { Message } from "../../../message"

globalCss({
  ".ReactModal__Content": {
    height: "100%",
  },

  ".pac-container.pac-logo.hdpi": {
    borderRadius: "4px",
  },

  ".pac-item": {
    height: "$40",
    lineHeight: "40px",
    border: "none",
  },

  ".pac-icon.pac-icon-marker": {
    margin: "$10 $20 0 $20",
  },
})()

const StyledHeader = styled("div", {
  backgroundColor: "$grayLight",
  padding: "$40",
})

const CloseButton = styled("div", {
  position: "absolute",
  top: "$40",
  right: "$40",
  display: "flex",
  alignItems: "center",
  whiteSpace: "nowrap",
  border: "none",
  background: "none",
  appearance: "none",

  "& > span": {
    marginRight: "$10",
    color: "$darkGreen",
  },
})

const StyledTitle = styled("div", {
  fontSize: "32px",
  color: "$black",
  fontWeight: "$medium",
  fontFamily: "$nova",
})

const StyledDescription = styled("div", {
  fontFamily: "$nova",
  color: "$gray",
  fontSize: "18px",
  marginTop: "$10",
})

interface MapHeaderProps {
  onCancelClick: () => void
}

export const MapHeader = ({ onCancelClick }: MapHeaderProps) => (
  <StyledHeader>
    <StyledTitle>
      <Message id="admin.createProject.map.title" />
    </StyledTitle>
    <StyledDescription>
      <Message id="admin.createProject.map.subtitle" />
    </StyledDescription>
    <CloseButton onClick={onCancelClick}>
      <span>
        <Message id="admin.createProject.map.close.label" />
      </span>
      <Button
        data-cy="close-button"
        variant="light"
        as="div"
        size="large"
        icon="delete"
      />
    </CloseButton>
  </StyledHeader>
)
