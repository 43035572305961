import React, { ReactElement, ReactNode } from "react"
import { FormattedMessage } from "react-intl"

interface Props {
  id: string
  values?: Record<string, ReactNode>
}

// This is a wrapping component for React Intl's FormattedMessage. Feel free to use
// FormattedMessage directly. This is only here because the `id` and `defaultMessage`
// props are likely to be the same, so it saves you some repetition.
export const Message = (props: Props): ReactElement => (
  <FormattedMessage
    id={props.id}
    defaultMessage={props.id}
    values={props.values}
  />
)
