import { styled } from "src/stitches.config"

export const OwnerToggleButton = styled("button", {
  display: "inline-flex",
  alignItems: "center",
  appearance: "none",
  marginTop: "10px",
  padding: "0",
  color: "$darkGreen",
  border: "none",
  background: "none",

  svg: {
    width: "18px",
    height: "18px",
    padding: "5px",
    color: "$white",
    borderRadius: "4px",
    backgroundColor: "$darkGreen",
    marginRight: "4px",
  },
  span: {
    fontSize: "12px",
    color: "$black",
  },
})
