import React from "react"

export const BackgroundSVG = () => (
  <svg
    preserveAspectRatio="xMidYMax slice"
    width="12800px"
    height="261px"
    viewBox="0 0 1280 275"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        x1="0%"
        y1="54.1513172%"
        x2="104.744655%"
        y2="47.6846434%"
        id="linearGradient-1"
      >
        <stop stopColor="#EB5E00" offset="0%"></stop>
        <stop stopColor="#FFB280" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="100%"
        y1="50%"
        x2="-2.22044605e-14%"
        y2="50%"
        id="linearGradient-2"
      >
        <stop stopColor="#FFB280" offset="0%"></stop>
        <stop stopColor="#EB5E00" offset="100%"></stop>
        <stop stopColor="#5076AF" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M130.800487,0 L985.800487,0 C633.709074,174 380.375741,261 225.800487,261 C71.2252327,261 39.558566,174 130.800487,0 Z"
        id="Curve-Copy"
        fill="url(#linearGradient-1)"
        opacity="0.4"
        transform="translate(532.900243, 130.500000) scale(-1, 1) translate(-532.900243, -130.500000) "
      ></path>
      <path
        d="M555,-849 C861.518036,-849 1110,-600.518036 1110,-294 C1110,12.5180361 861.518036,261 555,261 C248.481964,261 0,12.5180361 0,-294 C0,-600.518036 248.481964,-849 555,-849 Z M555,-833 C257.31852,-833 16,-591.68148 16,-294 C16,3.68148014 257.31852,245 555,245 C852.68148,245 1094,3.68148014 1094,-294 C1094,-591.68148 852.68148,-833 555,-833 Z"
        id="Ring-Copy"
        fill="url(#linearGradient-2)"
        opacity="0.8"
      ></path>
    </g>
  </svg>
)
