import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const ExternalLinkIcon = ({
  color = "currentColor",
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
    version="1.1"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(3.000000, -1.000000)" fill={color}>
        <g transform="translate(14.227822, 7.772124) scale(-1, 1) rotate(225.000000) translate(-14.227822, -7.772124) translate(8.732171, 2.276332)">
          <path d="M4.68080305,1.96800079 L7.05611114,4.34327586 L1.15274748,4.34327586 C0.516430873,4.34327586 8.52651283e-14,4.85969956 8.52651283e-14,5.49600733 C8.52651283e-14,6.13231509 0.516430873,6.64873879 1.15274748,6.64873879 L7.05611114,6.64873879 L4.68080305,9.02401386 C4.23007879,9.47473186 4.23007879,10.2044109 4.68080305,10.6539762 C5.13152732,11.1035414 5.86121648,11.1046942 6.310788,10.6539762 L10.653836,6.31098847 C10.7598888,6.20493718 10.8451921,6.07813671 10.9039822,5.93750348 C11.0204097,5.65508427 11.0204097,5.33693038 10.9039822,5.05451118 C10.8474976,4.91848886 10.7644998,4.7916884 10.653836,4.68102618 L6.310788,0.338038502 C5.86006373,-0.112679501 5.13037457,-0.112679501 4.68080305,0.338038502 C4.23123153,0.788756504 4.23007879,1.51843552 4.68080305,1.96800079 Z" />
        </g>
        <path d="M0,17.9655569 C0,20.1845303 1.81554892,22.000054 4.03455316,22.000054 L11.7743082,22.000054 C13.9933124,22.000054 15.8088613,20.1845303 15.8088613,17.9655569 L15.8088613,14.2604065 C15.8088613,13.6264141 15.2901331,13.1076931 14.6561319,13.1076931 C14.0221307,13.1076931 13.5034024,13.6264141 13.5034024,14.2604065 L13.5034024,17.9655569 C13.5034024,18.9165455 12.72531,19.6946271 11.7743082,19.6946271 L4.03455316,19.6946271 C3.08355134,19.6946271 2.30545895,18.9165455 2.30545895,17.9655569 L2.30545895,10.2259095 C2.30545895,9.27492086 3.08355134,8.49683928 4.03455316,8.49683928 L7.73975503,8.49683928 C8.37375624,8.49683928 8.89248451,7.97811823 8.89248451,7.34412583 C8.89248451,6.71013343 8.37375624,6.19141238 7.73975503,6.19141238 L4.03455316,6.19141238 C1.81554892,6.19141238 0,8.00693607 0,10.2259095 L0,17.9655569 Z" />
      </g>
    </g>
  </svg>
)
