import { IIcon } from "@types"
import { ReactElement, ReactSVG } from "react"

export const SearchIcon = ({
  fill = "#FFFFFF",
}: IIcon): ReactElement<ReactSVG> => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
    <path
      d="M12.61 12.7a.858.858 0 0 0-.16.161 6.012 6.012 0 0 1-4.187 1.689 6.016 6.016 0 0 1-4.27-1.768 6.016 6.016 0 0 1-1.768-4.27c0-1.667.674-3.175 1.768-4.269a6.016 6.016 0 0 1 4.27-1.768c1.667 0 3.175.674 4.269 1.768a6.016 6.016 0 0 1 1.768 4.27 6.012 6.012 0 0 1-1.69 4.188zm4.887 3.828-3.17-3.17a7.734 7.734 0 0 0 1.698-4.846 7.74 7.74 0 0 0-2.273-5.488A7.74 7.74 0 0 0 8.262.75a7.74 7.74 0 0 0-5.488 2.274A7.74 7.74 0 0 0 .5 8.512a7.74 7.74 0 0 0 2.274 5.49 7.74 7.74 0 0 0 5.489 2.273 7.734 7.734 0 0 0 4.845-1.698l3.17 3.17a.862.862 0 1 0 1.22-1.22z"
      fill={fill}
    />
  </svg>
)
