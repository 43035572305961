import { IIcon } from "@types"
import { ReactElement, ReactSVG } from "react"

export const ArrowDiagonalIcon = ({
  fill = "#EB5E00",
  height = "80px",
  width = "80px",
}: IIcon): ReactElement<ReactSVG> => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 80 80"
    version="1.1"
  >
    <title>NOCNSF/Atom/Icon/*/Arrow diagonal</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(41.287240, 41.287240) rotate(135.000000) translate(-41.287240, -41.287240) translate(19.407739, 12.994346)"
        fill={fill}
        fillRule="nonzero"
      >
        <g transform="translate(21.879500, 28.292893) scale(-1, 1) rotate(90.000000) translate(-21.879500, -28.292893) translate(-6.413393, 6.413393)">
          <path d="M31.6144803,7.46933546 C32.4574305,8.31228564 33.6098632,9.46471833 35.0717784,10.9266335 C36.5336936,12.3885487 38.7265664,14.5814215 41.6503968,17.5052519 L4.37506836,17.5052519 C1.96003063,17.5052519 0,19.4652826 0,21.8803203 C0,24.295358 1.96003063,26.2553887 4.37506836,26.2553887 L41.6503968,26.2553887 L31.6144803,36.2913052 C29.9038286,38.0019569 29.9038286,40.7713752 31.6144803,42.4776518 C33.3251321,44.1839285 36.0945503,44.1883035 37.800827,42.4776518 L55.3049852,24.9734936 C55.7074915,24.5709873 56.0312465,24.0897298 56.254375,23.5559715 C56.6962569,22.4840797 56.6962569,21.2765609 56.254375,20.2046691 C56.0399967,19.6884111 55.7249917,19.2071535 55.3049852,18.787147 L37.800827,1.2829888 C36.0901753,-0.427662932 33.320757,-0.427662932 31.6144803,1.2829888 C29.9082037,2.99364053 29.9038286,5.7630588 31.6144803,7.46933546 Z" />
        </g>
      </g>
    </g>
  </svg>
)
