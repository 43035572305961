import { IIcon } from "@types"
import { ReactElement, ReactSVG } from "react"

export const AlertIcon = ({
  fill = "#FFFFFF",
}: IIcon): ReactElement<ReactSVG> => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="none">
    <path
      fill={fill}
      fillRule="evenodd"
      d="M.45 7.73a.45.45 0 1 1 0-.91c.5 0 .91-.41.91-.91V3.64a3.64 3.64 0 0 1 7.28 0V5.9c0 .5.4.9.9.9a.45.45 0 1 1 0 .92H.46Zm7.52-.91a1.8 1.8 0 0 1-.24-.91V3.64a2.73 2.73 0 0 0-5.46 0V5.9c0 .33-.09.64-.24.9h5.94Zm-3.65 3a1.36 1.36 0 0 0 1.86-.5.45.45 0 0 0-.79-.46.45.45 0 0 1-.78 0 .45.45 0 0 0-.79.46c.12.2.3.38.5.5Z"
      clipRule="evenodd"
    />
  </svg>
)
