import { IDropdownOption } from "@types"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import { ORGANIZATION_TYPES } from "src/constants"
import useKVKSearch, { TKVKData } from "src/hooks/useKVKSearch"

import { AsyncDropdown } from "../async-dropdown"

interface Props {
  name: string
  defaultValues?: TKVKData
}

export const SportClubsDropdown = ({ name, defaultValues = [] }: Props) => {
  const { filter: kvkOptionsFilter } = useKVKSearch()
  const { formatMessage } = useIntl()
  const { setValue } = useFormContext()

  const loadOptions = async (query: string) => {
    if (query.length < 3) return []
    return kvkOptionsFilter(null, query)
  }

  const handleChange = (value: readonly IDropdownOption[] | null) => {
    if (!value) return

    setValue(
      name,
      value.map((option) => {
        const parsedOption = JSON.parse(option.value)

        return {
          name: parsedOption.name,
          kvkNumber: parsedOption.kvkNumber,
          kvkEstablishmentNumber: parsedOption.kvkEstablishmentNumber,
          type: ORGANIZATION_TYPES.SPORTCLUBS,
          address: parsedOption.address.street,
          city: parsedOption.address.city,
        }
      }),
      {
        shouldValidate: true,
      },
    )
  }

  return (
    <AsyncDropdown
      isMulti
      name={name}
      defaultValues={defaultValues}
      placeholder={formatMessage({ id: "form.select.placeholder.kvk" })}
      loadOptions={loadOptions}
      handleChange={handleChange}
    />
  )
}
