import { keyframes, styled } from "src/stitches.config"

import { SpinIcon } from "../../assets-icons"
import { P } from "../../typography"

// TODO: move overlay component below  to a separate file
const LoadingOverlayContainer = styled("div", {
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  pointerEvents: "none",
  opacity: 0,
  transition: "opacity 150ms ease-in-out",

  variants: {
    visible: {
      true: {
        pointerEvents: "auto",
        opacity: 1,
      },
    },
  },
})

const LoadingOverlayBackground = styled("div", {
  position: "absolute",
  width: "100%",
  height: "100%",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "$lightGreen",
  opacity: 0.6,
  zIndex: 0,
})

const rotate = keyframes({
  "0%": {
    transform: "rotate(0)",
  },
  "100%": {
    transform: "rotate(360deg)",
  },
})

const LoadingOverlayMessage = styled("p", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",

  "& *": {
    color: "$black",
    zIndex: 1,
  },

  svg: {
    animation: `${rotate} 1s infinite`,
    marginRight: ".5rem",
  },

  [`${P}`]: {
    margin: 0,
  },
})

export const LoadingOverlay = ({
  visible,
  showText = true,
}: {
  visible: boolean
  showText: boolean
}) => (
  <LoadingOverlayContainer visible={visible}>
    <LoadingOverlayBackground />
    <LoadingOverlayMessage>
      <SpinIcon />
      {showText && <P size="small">PDF wordt geladen ...</P>}
    </LoadingOverlayMessage>
  </LoadingOverlayContainer>
)
