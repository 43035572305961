export const HomepageHeader = () => (
  <svg
    preserveAspectRatio="xMidYMax slice"
    width="1280px"
    height="498px"
    viewBox="0 0 1280 498"
    version="1.1"
  >
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
        <stop stopColor="#5076AF" offset="0%" />
        <stop stopColor="#00378A" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="38.4443763%"
        y1="53.6810207%"
        x2="35.1535068%"
        y2="47.3849087%"
        id="linearGradient-2"
      >
        <stop stopColor="#F0F1F7" offset="0%" />
        <stop stopColor="#FFFFFF" offset="100%" />
      </linearGradient>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M730,-630 C1033.75661,-630 1280,-383.75661 1280,-80 C1280,-66.66778 1279.52563,-53.44636 1278.59284,-40.35169 L1278.58621,-40.39899 C2114.4735,-17.31965 2785,667.53525 2785,1509 C2785,2365.04136 2091.04136,3059 1235,3059 C378.958634,3059 -315,2365.04136 -315,1509 C-315,1001.94655 -71.526573,551.75937 304.888464,268.97027 L304.896474,269.00506 C226.853564,174.05608 180,52.49966 180,-80 C180,-383.75661 426.243384,-630 730,-630 Z M1274.29462,-0.49866 L1274.15387,0.47913 C1235.21033,266.08585 1006.41851,470 730,470 C573.179124,470 431.687604,404.36721 331.495664,299.07186 L331.482014,299.02907 C-36.69224,574.39877 -275,1013.86951 -275,1509 C-275,2342.94997 401.050024,3019 1235,3019 C2068.94997,3019 2745,2342.94997 2745,1509 C2745,688.1881 2090.08321,20.34021 1274.29462,-0.49866 Z M1235,-1 C909.975134,-1 608.935024,101.69091 362.548584,276.39768 L362.548584,276.41015 C455.862574,371.79874 586.017694,431 730.000004,431 C985.957494,431 1198.218,243.91095 1237.49097,-0.97661 L1237.49097,-1 C1236.65772,-1 1235.82894,-1 1235,-1 Z M730.000004,-597 C446.125634,-597 216.000004,-366.87436 216.000004,-83 C216.000004,42.4129 260.915584,157.3352 335.538134,246.55828 L335.528144,246.53148 C589.158124,65.50323 899.641484,-41 1235,-41 L1242.30387,-40.9398 C1243.42732,-54.81152 1244,-68.83888 1244,-83 C1244,-366.87436 1013.87436,-597 730.000004,-597 Z"
        fill="url(#linearGradient-1)"
        fillRule="nonzero"
      />
      <path
        d="M611.386004,-1481.43074 L611.386004,1118.56926 L3211.386,1118.56926 L3211.386,-1481.43074 L3267.95454,-1481.43074 L3267.95454,3798.56926 L507.954544,3798.56926 L507.954544,-1481.43074 L611.386004,-1481.43074 Z M3211.386,1222.00072 L611.386004,1222.00072 L611.386004,3742.00072 L3211.386,3742.00072 L3211.386,1222.00072 Z"
        fill="url(#linearGradient-2)"
        fillRule="nonzero"
        transform="translate(1887.954544, 1158.569260) rotate(45.000000) translate(-1887.954544, -1158.569260) "
      />
    </g>
  </svg>
)
