export const OverviewHeader = () => (
  <svg
    preserveAspectRatio="xMidYMax slice"
    width="1280px"
    height="431px"
    viewBox="0 0 1280 431"
    version="1.1"
  >
    <defs>
      <linearGradient
        x1="0%"
        y1="60.8525145%"
        x2="104.744655%"
        y2="43.9471162%"
        id="linearGradient-1"
      >
        <stop stopColor="#DEDFE4" offset="0%" />
        <stop stopColor="#FFFFFF" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="-2.48949813e-15%"
        x2="50%"
        y2="100%"
        id="linearGradient-2"
      >
        <stop stopColor="#FFFFFF" offset="0%" />
        <stop stopColor="#DEDFE4" offset="100%" />
        <stop stopColor="#5076AF" offset="100%" />
      </linearGradient>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fill="#ffffff" x="0" y="0" width="1280" height="416"></rect>
      <g transform="translate(84.998501, -605.000000)">
        <path
          d="M124.200106,594.050825 L979.198607,594 C1070.44153,875.333333 1038.77536,1016 884.200106,1016 C729.624851,1016 476.291518,875.350275 124.200106,594.050825 Z"
          fill="url(#linearGradient-1)"
          opacity="0.4"
          transform="translate(577.100053, 805.000000) scale(-1, 1) translate(-577.100053, -805.000000) "
        />
        <path
          d="M507.5,0 C787.784511,0 1015,227.215489 1015,507.5 C1015,787.784511 787.784511,1015 507.5,1015 C227.215489,1015 0,787.784511 0,507.5 C0,227.215489 227.215489,0 507.5,0 Z M507.5,15 C235.499761,15 15,235.499761 15,507.5 C15,779.500239 235.499761,1000 507.5,1000 C779.500239,1000 1000,779.500239 1000,507.5 C1000,235.499761 779.500239,15 507.5,15 Z"
          fill="url(#linearGradient-2)"
          opacity="0.8"
        />
      </g>
    </g>
  </svg>
)
