import React from "react"

export const BackgroundSVGTech = () => (
  <svg
    preserveAspectRatio="xMidYMax slice"
    viewBox="0 0 1280 1035"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <ellipse id="path-1" cx="688" cy="975" rx="380" ry="60"></ellipse>
      <feGaussianBlur
        stdDeviation="4"
        in="shadowOffsetOuter1"
        result="shadowBlurOuter1"
      ></feGaussianBlur>
      <feColorMatrix
        values="0 0 0 0 0.290196078   0 0 0 0 0.290196078   0 0 0 0 0.290196078  0 0 0 0.1 0"
        type="matrix"
        in="shadowBlurOuter1"
      ></feColorMatrix>
      <linearGradient
        x1="50%"
        y1="-2.48949813e-15%"
        x2="50%"
        y2="100%"
        id="linearGradient-3"
      >
        <stop stopColor="#00378A" offset="0%"></stop>
        <stop stopColor="#5076AF" offset="100%"></stop>
        <stop stopColor="#5076AF" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="-2.48949813e-15%"
        x2="50%"
        y2="100%"
        id="linearGradient-4"
      >
        <stop stopColor="#00378A" offset="0%"></stop>
        <stop stopColor="#5076AF" offset="100%"></stop>
        <stop stopColor="#5076AF" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g
      id="Graphics/Voorschrift_norms"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <circle
        id="Ring"
        fill="url(#linearGradient-3)"
        opacity="0.8"
        transform="translate(687.498501, 507.500000) rotate(-15.000000) translate(-687.498501, -507.500000) "
        cx="687.498501"
        cy="507.5"
        r="507.5"
      ></circle>
      <path
        d="M378.816108,625.4375 C567.60437,634.770833 812.998002,764.770833 1114.997,1015.4375 L268.168122,1015.4375 C153.145184,746.104167 190.027846,616.104167 378.816108,625.4375 Z M383.929708,638.472989 C202.623518,629.482354 169.451643,754.991358 284.414086,1015 L284.414086,1015 L1089.36263,1015 C800.380205,772.972627 565.235899,647.463624 383.929708,638.472989 Z"
        id="Combined-Shape"
        fill="url(#linearGradient-4)"
        opacity="0.4"
      ></path>
    </g>
  </svg>
)
