import { merge } from "lodash"
import { IErrorPage } from "src/services"
import { getTitleForStatusCode } from "src/utils/get-title-for-status-code"

const getDefaults = (errorCode?: number): IErrorPage => ({
  errorCode,
  title: getTitleForStatusCode(errorCode ?? 0),
  description: "An error occurred",
  goto: [{ text: "home", href: "/" }],
})

export const createError = (
  errorCode?: number,
  override?: Partial<IErrorPage>,
): IErrorPage => {
  const error = getDefaults(errorCode)
  return merge({}, error, override)
}
