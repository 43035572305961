import React from "react"

export const ForegroundSVGNotTech = () => (
  <svg
    preserveAspectRatio="xMidYMax slice"
    viewBox="0 0 1280 442"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <ellipse id="path-1" cx="449.998501" cy="382" rx="380" ry="60"></ellipse>
      <filter
        x="-1.7%"
        y="-9.2%"
        width="103.4%"
        height="121.7%"
        filterUnits="objectBoundingBox"
        id="filter-2"
      >
        <feOffset
          dx="0"
          dy="2"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          stdDeviation="4"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feColorMatrix
          values="0 0 0 0 0.290196078   0 0 0 0 0.290196078   0 0 0 0 0.290196078  0 0 0 0.1 0"
          type="matrix"
          in="shadowBlurOuter1"
        ></feColorMatrix>
      </filter>
    </defs>
    <g
      id="Graphics-elements-bottom"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Oval">
        <use
          fill="black"
          fillOpacity="1"
          filter="url(#filter-2)"
          href="#path-1"
        ></use>
        <use fill="#5076AF" fillRule="evenodd" href="#path-1"></use>
      </g>
    </g>
  </svg>
)
