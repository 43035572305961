import { styled } from "src/stitches.config"

const OuterContainer = styled("div", {
  width: "100%",
  margin: "0 auto",
  padding: "0 $30",

  "@mdDown": {
    padding: "0 $15",
  },

  variants: {
    background: {
      white: {
        backgroundColor: "$white",
      },
    },

    noPadding: {
      true: {
        padding: 0,
      },
    },
  },
})

const InnerContainer = styled("div", {
  width: "100%",
  maxWidth: "$pageContainer",
  margin: "0 auto",

  variants: {
    size: {
      wide: {
        maxWidth: "$pageContainerWide",
      },
    },
    background: {
      white: {},
    },
  },
})

// TODO Julia: rename

export const PageWidthContainer = (props: any) => (
  <OuterContainer {...props}>
    <InnerContainer {...props} />
  </OuterContainer>
)
