import { createContext } from "react"
import { ITagOptions } from "src/services/types"

type TMode = "edit" | "new"

export type TEditHandbook = {
  drawings?: string[]
  code: string
  version: number
  tagOptions: ITagOptions
  mode: TMode
  handbookType: "process" | "precept"
  id?: string
}

export const editHandbookDefault: TEditHandbook = {
  drawings: [],
  code: "",
  version: 1,
  tagOptions: {
    disciplines: [],
    locations: [],
    categories: [],
  },
  mode: "edit",
  handbookType: "precept",
}

export const EditHandbookContext = createContext(editHandbookDefault)
