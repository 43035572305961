import { styled } from "src/stitches.config"

export const RuleIndex = styled("div", {
  position: "relative",
  zIndex: "2",
  width: "$30",
  height: "$30",
  fontFamily: "$nova",
  fontSize: "8px",
  color: "$white",
  textAlign: "center",
  lineHeight: "28px",
  borderRadius: "4px",
  backgroundColor: "$tertiary",
  pointerEvents: "none", // makes the underlying RuleType clickable
  counterIncrement: "ruleIndex",

  "&::after": {
    content: 'counter(sectionIndex)"."counter(ruleIndex)',
  },

  "@mdDown": {
    width: "$20",
    height: "$20",
    fontSize: "7px",
    lineHeight: "19px",
  },
})
