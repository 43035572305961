import {
  UseQueryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import { toast } from "react-hot-toast"
import { V2CreateInspectionReport } from "src/utils/v2/form-schemas/create-inspection-report"

import { inspectionReportService } from "../.."
import { InspectionReport } from "../types"

export function useCreateInspectionReport(
  cpId: string,
  ispId: string,
  options?: UseQueryOptions,
) {
  const queryClient = useQueryClient()

  async function mutationFn(data: V2CreateInspectionReport) {
    const result = await inspectionReportService.create(cpId, ispId, data)
    await queryClient.refetchQueries(["inspection-report", cpId, ispId])
    await queryClient.refetchQueries(["certification-processes", cpId])
    return result
  }

  return useMutation(mutationFn, {
    onSuccess: async (data: InspectionReport) => {
      toast.success("Inspectierapport toegevoegd")
      options?.onSuccess?.(data)
    },
    onError: (error) => {
      toast.error(String(error))
    },
  })
}
