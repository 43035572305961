import { IAssetsIconProps } from "@types"
import { ReactElement, ReactSVG } from "react"
import { iconSizes } from "src/constants"

export const SearchAssetsIcon = ({
  color = "currentColor",
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps): ReactElement<ReactSVG> => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <g
          transform="translate(1.000000, 1.000000)"
          fill={color}
          fillRule="nonzero"
        >
          <path d="M15.4451964,15.2414095 C15.4077962,15.2700091 15.371496,15.3019087 15.3362958,15.3371083 C15.3010955,15.3723078 15.2702953,15.4075074 15.2405952,15.4460069 C13.8567865,16.7802902 11.9746748,17.59978 9.90006182,17.59978 C7.77374854,17.59978 5.84983653,16.7395908 4.45502782,15.3448082 C3.06021911,13.9500256 2.20001374,12.0261497 2.20001374,9.89987625 C2.20001374,7.77360283 3.06021911,5.84972688 4.45502782,4.45494431 C5.84983653,3.06016175 7.77374854,2.1999725 9.90006182,2.1999725 C12.0263751,2.1999725 13.9502871,3.06016175 15.3450958,4.45494431 C16.7399045,5.84972688 17.6001099,7.77360283 17.6001099,9.89987625 C17.6001099,11.9744503 16.7806048,13.8565268 15.4451964,15.2414095 L15.4451964,15.2414095 Z M21.6778354,20.1220485 L17.6353101,16.079599 C18.9894186,14.3856202 19.8001236,12.237347 19.8001236,9.89987625 C19.8001236,7.16641042 18.6913167,4.69034137 16.9005055,2.89956376 C15.1096943,1.10878614 12.6335789,0 9.90006182,0 C7.16654475,0 4.69042929,1.10878614 2.89961811,2.89956376 C1.10880692,4.69034137 0,7.16641042 0,9.89987625 C0,12.6333421 1.10880692,15.1094111 2.89961811,16.9001887 C4.69042929,18.6909664 7.16654475,19.7997525 9.90006182,19.7997525 C12.2375764,19.7997525 14.3858898,18.9890626 16.0799004,17.6349796 L20.1224256,21.677429 C20.5525283,22.1075237 21.2488327,22.1075237 21.6778354,21.677429 C22.106838,21.2473344 22.107938,20.5510431 21.6778354,20.1220485 L21.6778354,20.1220485 Z"></path>
        </g>
      </g>
    </g>
  </svg>
)
