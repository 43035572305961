import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const TagsIcon = ({
  color = "currentColor",
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
    version="1.1"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(0.853788, 2.000000)"
        fill={color}
        fillRule="nonzero"
      >
        <path d="M17.3063933,0 L10.55162,0 C9.93204774,0 9.82014395,0.0704426975 9.41103607,0.480904363 L0.618699121,9.42441246 C-0.27194994,10.3201331 -0.137793437,10.9246808 0.618699121,11.746048 L7.37347248,18.6182206 C8.25356586,19.4215625 8.75786928,19.4215625 9.65464027,18.6182206 L18.4469772,9.67471251 C18.9196662,9.15446478 18.9196662,9.15446478 18.9196662,8.51266375 L18.9196662,1.64131182 C18.9196662,0 18.9196662,0 17.3063933,0 Z M14.4831659,5.74459136 C13.8152709,5.74459136 13.2732112,5.19311059 13.2732112,4.5136075 C13.2732112,3.83410441 13.8152709,3.28262364 14.4831659,3.28262364 C15.1510608,3.28262364 15.6931205,3.83410441 15.6931205,4.5136075 C15.6931205,5.19311059 15.1510608,5.74459136 14.4831659,5.74459136 Z" />
        <path d="M20.532939,1.64131182 L20.5321324,9.28079768 C20.5321324,9.89548471 20.5321324,9.89548471 20.1207478,10.2902044 L11.1864428,19.3797893 L11.323571,19.5193008 C12.2791429,20.3165216 12.7169482,20.3165216 13.6047388,19.5193008 L21.6727163,11.3127417 C22.1462118,10.8322979 22.1462118,10.8206559 22.1462118,10.1523343 L22.1462118,3.28262364 C22.1462118,1.64131182 22.1462118,1.64131182 20.532939,1.64131182 Z" />
      </g>
    </g>
  </svg>
)
