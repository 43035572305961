import { Button } from "src/components"
import { icons } from "src/components"
import { styled } from "src/stitches.config"

interface Props {
  text: string
  onClick: () => void
  dataCy?: string
  icon?: keyof typeof icons
  hideIcon?: boolean
  isLoading?: boolean
  isDisabled?: boolean
}

export const HeaderButtonWrapper = styled("div", {
  marginLeft: "auto",

  "@lgDown": {
    display: "none",
  },
})

export const HeaderButton = ({
  text,
  icon = "plusThin",
  onClick,
  dataCy,
  isLoading = false,
  isDisabled = false,
  hideIcon = false,
}: Props) => {
  return (
    <HeaderButtonWrapper>
      <Button
        data-cy={dataCy}
        variant="secondary"
        size="large"
        outline
        icon={hideIcon ? undefined : icon}
        onClick={onClick}
        isLoading={isLoading}
        disabled={isDisabled}
      >
        {text}
      </Button>
    </HeaderButtonWrapper>
  )
}
