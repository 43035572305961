import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const ResetIcon = ({
  color = "currentColor",
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
    version="1.1"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M2.27521998,2.00000105 C2.97953655,1.99911108 3.55038244,2.56141634 3.55037968,3.25608369 L3.55037968,3.25608369 L3.55060325,7.46019565 C4.34481609,6.59001896 5.16775414,5.75416634 6.0194174,4.95263779 C7.82245186,3.25574224 10.2659321,2.21356884 12.9565222,2.21356884 C18.5033818,2.21356884 23,6.64291153 23,12.1067826 C23,17.5581171 18.5239928,21.9796651 12.9946867,21.9999265 C12.2023231,22.000999 11.5227524,21.3736243 11.521729,20.593009 C11.5207292,19.8123937 12.1630296,19.1733663 12.956516,19.1733663 L12.956516,19.1733663 C16.9185648,19.1733639 20.1304349,16.0095477 20.1304349,12.1067826 C20.1304349,8.20401756 16.9185648,5.04020135 12.9565222,5.04020135 C11.0555839,5.04020135 9.32733227,5.76848395 8.04364993,6.95725621 C7.35689158,7.59323853 6.42516843,8.47046414 5.24848049,9.58893305 L5.35396286,9.59534382 L9.98588695,9.59530705 C10.6902035,9.59441601 11.2601476,10.1576096 11.2610477,10.8513886 C11.2619457,11.5451677 10.6910954,12.1075287 9.98587698,12.1075287 L9.98587698,12.1075287 L2.27516064,12.1075287 C2.1020124,12.1075287 1.93788242,12.0737737 1.78727977,12.0124801 C1.47525271,11.8845631 1.22635308,11.6393873 1.09649311,11.3320286 C1.03246494,11.1783492 1.00000095,11.0148982 1.00000095,10.8514471 L1.00000095,10.8514471 L1.00000095,3.25609351 C0.999151251,2.56231449 1.57090342,2.00089315 2.27521998,2.00000105 Z"
        fill={color}
      ></path>
    </g>
  </svg>
)
