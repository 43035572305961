import React from "react"
import { styled } from "src/stitches.config"

import { PageWidthContainer } from "../../page-width-container"
import { HomepageHeader } from "../../visuals"

const HomepagePageHeaderVisualWrapper = styled("div", {
  position: "absolute",
  top: "0",
  right: "0",
  bottom: "0",
  left: "0",
  overflow: "hidden",
  pointerEvents: "none",
})

const VisualWrapper = styled("div", {
  position: "absolute",
  bottom: "0",
  left: "50%",
  width: "100%",
  height: "100%",
  transform: "translateX(-50%)",

  svg: {
    display: "block",
    width: "100%",
    height: "100%",
    overflow: "visible",
  },
})

export const HomepagePageHeaderVisual = () => (
  <HomepagePageHeaderVisualWrapper>
    <PageWidthContainer
      size="wide"
      css={{ position: "relative", height: "100%" }}
    >
      <VisualWrapper>
        <HomepageHeader />
      </VisualWrapper>
    </PageWidthContainer>
  </HomepagePageHeaderVisualWrapper>
)
