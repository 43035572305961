import { styled } from "src/stitches.config"
import formatDate from "src/utils/format-date"

interface Props {
  date: Date
}

const CalendarWrapper = styled("div", {
  position: "relative",
  width: "100%",
  paddingBottom: "100%",
  borderRadius: "4px",
  backgroundColor: "$tertiary",
  boxShadow: "$default",
})

const CalendarInner = styled("div", {
  position: "absolute",
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  "@smDown": {
    fontSize: "0.85rem",
  },

  "@xsDown": {
    fontSize: "0.75rem",
  },
})

const CalendarDate = styled("div", {
  color: "$white",
  fontSize: "1.25em",
  fontWeight: "$bold",
  textAlign: "center",
  lineHeight: "1.25",
})

const CalendarMonth = styled("div", {
  color: "$white",
  fontSize: "0.625em",
  textAlign: "center",
  textTransform: "capitalize",
  lineHeight: "1.5",
})

const CalendarYear = styled("div", {
  color: "$white",
  fontSize: "0.625em",
  textAlign: "center",
  lineHeight: "1.5",
})

export const Calendar = ({ date }: Props) => (
  <CalendarWrapper>
    <CalendarInner data-cy="calendar">
      <CalendarDate>{formatDate(date, "d")}</CalendarDate>
      <CalendarMonth>{formatDate(date, "MMMM")}</CalendarMonth>
      <CalendarYear>{formatDate(date, "yyyy")}</CalendarYear>
    </CalendarInner>
  </CalendarWrapper>
)
