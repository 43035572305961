import Link from "next/link"
import { useCallback, useState } from "react"
import { FormattedMessage } from "react-intl"
import { IInfrastructureSection, IKnowledgeBaseSection } from "src/services"
import { styled } from "src/stitches.config"
import { useHasPermissions } from "src/utils/has-permissions"

import { Button } from "../button"
import { Grid } from "../grid"
import { GridItem } from "../grid-item"
import { PageIndex, Section } from "../infrastructure-page"
import { PageWidthContainer } from "../page-width-container"
import { TopScrollButton } from "../top-scroll-button"
import { P } from "../typography"

const SectionsWrapper = styled("div", {
  counterReset: "sectionIndex",
})

const SubscriptionsPageLinkWrapper = styled("div", {
  position: "relative",
})

const SubscriptionsPageLink = styled("div", {
  "@mdUp": {
    position: "absolute",
    right: "0",
    transform: "translateY(-100%)",
  },

  a: {
    color: "$blue",
  },
})

interface IHandbookPageContentProps {
  sections: Array<IInfrastructureSection | IKnowledgeBaseSection>
  enableIndexToggle?: boolean
  sportCodes?: string[]
}

export const HandbookPageContent = ({
  sections,
  enableIndexToggle = true,
  sportCodes,
}: IHandbookPageContentProps) => {
  const canSubscribe = useHasPermissions(["getUserSubscriptions"])
  const hasSportCodes = !!sportCodes?.length
  const [hideOptionalRules, setHideOptionalRules] = useState(false)

  const handleIndexClick = useCallback((id: string) => {
    const targetElement = document.querySelector(`[data-id="${id}"]`)

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" })
    }
  }, [])

  const handleOptionalRulesToggleChange = useCallback(() => {
    setHideOptionalRules(!hideOptionalRules)
  }, [setHideOptionalRules, hideOptionalRules])

  return (
    <>
      <PageWidthContainer>
        {hasSportCodes && canSubscribe && (
          <SubscriptionsPageLinkWrapper>
            <SubscriptionsPageLink>
              <P textAlign="right">
                <Link href={`/meldingen?sportCodes=${sportCodes?.join(",")}`}>
                  <FormattedMessage id="precept.toSubscriptionsPageLink.label" />
                  <Button
                    variant="light"
                    icon="arrowRight"
                    size="small"
                    css={{ marginLeft: "$10" }}
                  />
                </Link>
              </P>
            </SubscriptionsPageLink>
          </SubscriptionsPageLinkWrapper>
        )}

        <Grid>
          <GridItem col={[3, 10]} medium={[1, 12]}>
            <PageIndex
              onClick={handleIndexClick}
              hideOptionalRules={hideOptionalRules}
              onOptionalRulesToggle={handleOptionalRulesToggleChange}
              sections={sections}
              enableIndexToggle={enableIndexToggle}
            />
          </GridItem>
        </Grid>

        <SectionsWrapper>
          {sections.map((section, index: number) => (
            <Section
              key={section.id}
              index={index}
              hideOptionalRules={hideOptionalRules}
              section={section}
            />
          ))}
        </SectionsWrapper>
      </PageWidthContainer>

      <TopScrollButton />
    </>
  )
}
