import { styled } from "src/stitches.config"

export const THSorter = styled("span", {
  // float: 'right',
  marginLeft: ".5rem",
  opacity: 0.2,
  lineHeight: "20px",
  display: "inline-block",
  verticalAlign: "text-bottom",

  variants: {
    isSorted: {
      true: {
        opacity: 0.6,
      },
    },
  },
})
