export const GuidelineIcon = () => (
  <svg width="1.0625em" height="1em" viewBox="0 0 68 64" version="1.1">
    <title>Richtlijn</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-6.000000, -8.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <g transform="translate(6.000000, 8.000000)">
          <rect
            x="31.2722034"
            y="0.0842857143"
            width="5.27440678"
            height="57.1842857"
            rx="1.13"
          ></rect>
          <rect
            x="28.6408475"
            y="58.6003571"
            width="10.5605085"
            height="5.31535714"
            rx="1.13"
          ></rect>
          <path d="M61.0123729,18.7057143 L40.5111864,18.7057143 C39.7839752,18.6993134 39.1960126,18.1067858 39.189661,17.3739286 L39.189661,4.06785714 C39.1960126,3.3349999 39.7839752,2.74247229 40.5111864,2.73607143 L61.0123729,2.73607143 C61.4052854,2.73962594 61.7770055,2.91616444 62.0298305,3.21928571 L67.5264407,9.86642857 C67.9369147,10.3609738 67.9369147,11.0808119 67.5264407,11.5753571 L61.9830508,18.2225 C61.7409508,18.513497 61.3888168,18.6887932 61.0123729,18.7057143 L61.0123729,18.7057143 Z"></path>
          <path d="M6.81813559,18.7057143 L27.319322,18.7057143 C28.0446375,18.7057143 28.6344287,19.2947961 28.6408475,20.0257143 L28.6408475,33.3317857 C28.6344959,34.064643 28.0465332,34.6571706 27.319322,34.6635714 L6.81813559,34.6635714 C6.44169163,34.6466504 6.08955769,34.4713542 5.84745763,34.1803571 L0.350847458,27.5332143 C-0.0596265834,27.0386691 -0.0596265834,26.3188309 0.350847458,25.8242857 L5.84745763,19.1771429 C6.090785,18.8893635 6.44316893,18.7182214 6.81813559,18.7057143 L6.81813559,18.7057143 Z"></path>
        </g>
      </g>
    </g>
  </svg>
)
