import { format } from "date-fns"
import nl from "date-fns/locale/nl"

export default function formatDate(date: string | Date, formatString: string) {
  return format(new Date(date), formatString, { locale: nl })
}

export function isISODate(date: string) {
  const isoDateRegExp = new RegExp(
    /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/,
  )
  return isoDateRegExp.test(date)
}
