import { AxiosResponse } from "axios"

import { V2Api } from "../api.v2"
import { SportProduct } from "./types"

export interface SportProductFilters {
  typeId?: string
  subtypeId?: string
}

export class SportProductService extends V2Api {
  public async getAll(
    filters?: SportProductFilters,
  ): Promise<AxiosResponse<SportProduct[]>> {
    return super.get("/sport-products", {
      params: filters,
    })
  }
}
