import React from "react"
import { styled } from "src/stitches.config"

import { BackgroundSVG, ForegroundSVG } from "./fragments"

const DisciplinePageHeaderVisualWrapper = styled("div", {
  position: "absolute",
  top: "0",
  right: "0",
  bottom: "0",
  left: "0",
  pointerEvents: "none",
})

const DisciplinePageHeaderVisualLayer = styled("div", {
  position: "absolute",
  top: "0",
  right: "0",
  bottom: "0",
  left: "0",

  svg: {
    width: "100%",
    height: "calc(100% + $sizes$10)",
  },
})

export const DisciplinePageHeaderVisual = () => {
  return (
    <DisciplinePageHeaderVisualWrapper>
      <DisciplinePageHeaderVisualLayer>
        <ForegroundSVG />
      </DisciplinePageHeaderVisualLayer>

      <DisciplinePageHeaderVisualLayer>
        <BackgroundSVG />
      </DisciplinePageHeaderVisualLayer>
    </DisciplinePageHeaderVisualWrapper>
  )
}
