import { styled } from "src/stitches.config"

import { NavItemLink } from "./nav-item-link"

export const NavMenuItemLink = styled(NavItemLink, {
  color: "#FFFFFF",
  fontFamily: "$grotesk",
  fontSize: "18px",
  textDecoration: "none",
  fontWeight: "bold",
  position: "relative",
  display: "flex",
  alignItems: "flex-end",
  paddingBottom: "22px",
  height: "$40",
  lineHeight: "12px",
  cursor: "pointer",
  whiteSpace: "nowrap",

  "&::before": {
    position: "absolute",
    width: "100%",
    height: "3px",
    background: "white",
    bottom: "0",
    left: "0",
    pointerEvents: "none",

    content: `''`,
    transformOrigin: "100% 50%",
    transform: "scale3d(0, 1, 1)",
    transition: "transform 0.3s ease-in-out",
  },
  "&:hover::before": {
    transformOrigin: "0% 50%",
    transform: "scale3d(1, 1, 1)",
  },

  variants: {
    slowAnimation: {
      true: {
        "&::before": {
          transition: "transform 0.5s ease-in-out",
        },
      },
    },
    isActive: {
      true: {
        "&::before": {
          transformOrigin: "0% 50%",
          transform: "scale3d(1, 1, 1)",
        },
      },
    },
  },
})
