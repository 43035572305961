import { ReactElement, ReactSVG } from "react"

import { iconsMap } from "./constants"
import { iconNameType } from "./types"

export const iconNames = Object.keys(iconsMap)

interface IconProps {
  name: iconNameType
  fill?: string
  rest?: unknown
  disabled?: boolean
}

/** Component wrapper of an icon
 * @param {string} name
 * @param {string} fill - color to fill
 * @param {unknown} rest - rest props
 * @param {boolean} disabled - is disabled
 */
export const IconWrapper = (
  props: IconProps,
): ReactElement<ReactSVG> | null => {
  const { name, fill, disabled, ...rest } = props

  if (!iconNames.includes(name)) {
    if (process.env.NODE_ENV !== "test") {
      console.warn(`Icon not found for ${name}`)
    }

    return null
  }

  const IconComponent = iconsMap[name]

  return IconComponent({
    fill: disabled ? "rgba(74,74,74,0.3)" : fill,
    ...rest,
  })
}
