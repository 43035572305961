import Link from "next/link"
import { Button } from "src/components"
import { icons } from "src/components"
import { styled } from "src/stitches.config"

interface Props {
  text: string
  href: string
  dataCy?: string
  icon?: keyof typeof icons
  hideIcon?: boolean
}

export const HeaderLinkButtonWrapper = styled("div", {
  marginLeft: "auto",

  "@lgDown": {
    display: "none",
  },
})

export const HeaderLinkButton = ({
  text,
  href,
  icon = "plusThin",
  dataCy,
  hideIcon = false,
}: Props) => {
  return (
    <HeaderLinkButtonWrapper>
      <Link href={href}>
        <Button
          data-cy={dataCy}
          variant="secondary"
          size="large"
          outline
          icon={hideIcon ? undefined : icon}
          as="div"
        >
          {text}
        </Button>
      </Link>
    </HeaderLinkButtonWrapper>
  )
}
