import React, { Fragment, PropsWithChildren, ReactElement } from "react"

import { Button, ButtonProps, TButtonIconColors } from "../button"
import { PopOver, PopOverSide } from "../pop-over"
import { P } from "../typography"

interface Props {
  onConfirm: () => Promise<void> | void
  title?: string
  message: string | ReactElement
  confirmIcon: ButtonProps["icon"]
  confirmButtonLabel: string
  confirmButtonVariant?: ButtonProps["variant"]
  confirmIconColor?: TButtonIconColors
  disabled?: boolean
  side?: PopOverSide
}

export const PopoverConfirmation = ({
  onConfirm,
  title,
  message,
  confirmIcon,
  confirmButtonLabel,
  confirmButtonVariant = "primary",
  confirmIconColor = "variant",
  disabled = false,
  side,
  children,
}: PropsWithChildren<Props>) => (
  <PopOver
    disabled={disabled}
    trigger={children}
    side={side}
    close={
      <Fragment>
        <Button
          variant={confirmButtonVariant}
          icon={confirmIcon}
          iconColor={confirmIconColor}
          size="regular"
          onClick={() => onConfirm()}
          as="div"
          data-cy="confirm"
        >
          {confirmButtonLabel}
        </Button>
        <Button
          variant="lightInverse"
          icon="delete"
          size="regular"
          as="div"
          aria-label="cancel"
          data-cy="cancel"
        />
      </Fragment>
    }
  >
    {title && (
      <P size="large" bold css={{ marginBottom: "$10" }}>
        {title}
      </P>
    )}

    {typeof message !== "string" ? (
      message
    ) : (
      <P css={{ marginBottom: "$20" }}>{message}</P>
    )}
  </PopOver>
)
