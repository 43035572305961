import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from "@tanstack/react-query"
import { certificationProcessService } from "src/services"

import { CertifyData } from "../types"

export function useCertificationProcessCertifyData(
  id: string,
  options?: UseQueryOptions,
): UseQueryResult<CertifyData> {
  const queryFn = () => certificationProcessService.getByIdToCertify(id)
  return useQuery(["certification-processes", "certify-data", id], queryFn, {
    initialData: options?.initialData,
  })
}
