import { styled } from "src/stitches.config"

export const PublicLayoutHeader = styled("header", {
  position: "relative",
  backgroundColor: "$primary",
  padding: "0 $30",
  zIndex: 1001,

  "@mdDown": {
    padding: "0 $15",
  },
})
