import { IIcon } from "@types"
import { ReactElement, ReactSVG } from "react"

export const HomeIcon = ({
  fill = "#FFFFFF",
}: IIcon): ReactElement<ReactSVG> => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19">
    <g fill={fill}>
      <path d="m17.473 10.908-6.286-6.175c-.354-.345-.75-.517-1.187-.517-.437 0-.833.172-1.188.517l-6.285 6.175a1.201 1.201 0 0 1-1.68 0 1.152 1.152 0 0 1 0-1.65L9.16 1.092a1.199 1.199 0 0 1 1.68 0l8.312 8.166a1.151 1.151 0 0 1 0 1.65 1.202 1.202 0 0 1-1.68 0z" />
      <path d="M3.005 18.75v-7.126a.75.75 0 0 0-.75-.75h-.753A.582.582 0 0 1 1.11 9.86l8.384-7.65a.75.75 0 0 1 1.012 0l8.384 7.65a.582.582 0 0 1-.392 1.013h-.753a.75.75 0 0 0-.75.75v7.126a.25.25 0 0 1-.25.25h-4.477a.25.25 0 0 1-.25-.25v-5.583a.25.25 0 0 0-.25-.25H8.231a.25.25 0 0 0-.25.25v5.583a.25.25 0 0 1-.25.25H3.255a.25.25 0 0 1-.25-.25z" />
    </g>
  </svg>
)
