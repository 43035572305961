import { ORGANIZATION_TYPES } from "@constants"
import { AxiosResponse } from "axios"

import { V2Api } from "../api.v2"
import { OrganizationGetAll } from "./types"

export interface OrganizationGetAllFilters {
  type: ORGANIZATION_TYPES
  sportProductId?: string
}

export class OrganizationsService extends V2Api {
  public async getAll(
    filters: OrganizationGetAllFilters,
  ): Promise<AxiosResponse<OrganizationGetAll>> {
    return super.get(`/organizations`, { params: filters })
  }
  public async getUserAll(): Promise<AxiosResponse<OrganizationGetAll>> {
    return super.get("/user/organizations")
  }
}
