import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const UploadIcon = ({
  color = "currentColor",
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
    version="1.1"
  >
    <g>
      <g opacity="1">
        <path
          d="M16.2182+9.168L13.3396+6.34947L13.3396+13.3544C13.3396+14.1094+12.7137+14.7222+11.9426+14.7222C11.1714+14.7222+10.5456+14.1094+10.5456+13.3544L10.5456+6.34947L7.66698+9.168C7.12076+9.70283+6.23646+9.70283+5.69163+9.168C5.14681+8.63317+5.14541+7.76733+5.69163+7.23387L10.9549+2.08043C11.0834+1.95459+11.2371+1.85337+11.4075+1.78361C11.7498+1.64546+12.1354+1.64546+12.4776+1.78361C12.6425+1.85064+12.7961+1.94912+12.9302+2.08043L18.1935+7.23387C18.7397+7.7687+18.7397+8.63454+18.1935+9.168C17.6473+9.70146+16.763+9.70283+16.2182+9.168Z"
          opacity="1"
          fill={color}
        />
        <path
          d="M5.78738+22.31C3.15432+22.31+1+20.1098+1+17.4206L1+16.1234C1+15.3551+1.61552+14.7264+2.36782+14.7264C3.12012+14.7264+3.73564+15.3551+3.73564+16.1234L3.73564+17.4206C3.73564+18.5731+4.65892+19.5161+5.78738+19.5161L18.2126+19.5161C19.3411+19.5161+20.2644+18.5731+20.2644+17.4206L20.2644+17.4206L20.2644+16.1234C20.2644+15.3551+20.8799+14.7264+21.6322+14.7264C22.3845+14.7264+23+15.3551+23+16.1234L23+16.1234L23+17.4206C23+20.1098+20.8457+22.31+18.2126+22.31L18.2126+22.31L5.78738+22.31Z"
          opacity="1"
          fill={color}
        />
      </g>
    </g>
  </svg>
)
