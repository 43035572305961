import { IDropdownOption } from "@types"
import { MouseEventHandler } from "react"
import { styled } from "src/stitches.config"

import { Close2Icon } from "../../assets-icons"

export const InputItem = ({ label, onRemove, ...rest }: IInputItem) => (
  <InputItemWrapper {...rest}>
    <InputItemRemoveLabel>{label}</InputItemRemoveLabel>
    <InputItemRemoveIcon onClick={onRemove}>
      <Close2Icon size=".5rem" />
    </InputItemRemoveIcon>
  </InputItemWrapper>
)

const InputItemRemoveLabel = styled("span", {
  paddingTop: "2px",
})

const InputItemRemoveIcon = styled("span", {
  marginLeft: "10px",
  marginTop: "1px",
  padding: "0 $10",
  cursor: "pointer",
})

const InputItemWrapper = styled("span", {
  paddingLeft: "$10",
  backgroundColor: "$white",
  border: "1px solid $fadedWhite",
  borderRadius: "4px",
  boxSizing: "border-box",
  display: "inline-flex",
  fontSize: "1rem",
  lineHeight: "1rem",
  height: "1.5rem",
  color: "$inputText",
  marginRight: "$10",
})

interface IInputItem {
  label: IDropdownOption["label"]
  onRemove?: MouseEventHandler<HTMLSpanElement>
}
