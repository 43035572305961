import { useEffect, useMemo } from "react"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import Select from "react-select"

type OnDropdownChange = (name: string, option: Option | null) => void

type Option = {
  value: any
  label: string
}

export interface DropdownProps {
  name: string
  options: Option[]
  isLoading?: boolean
  isDisabled?: boolean
  placeholder?: string
  onChange?: OnDropdownChange
  findDropdownValue?: (
    options: Option[],
    value: Option["value"],
  ) => Option | undefined
}

export function Dropdown({
  name,
  options,
  isLoading = false,
  isDisabled = false,
  placeholder = "Selecteer...",
  onChange,
  findDropdownValue,
}: DropdownProps) {
  const { formatMessage } = useIntl()
  const form = useFormContext()
  const value = form.watch(name)

  function handleChange(option: Option | null) {
    form.setValue(name, option?.value, { shouldValidate: true })

    if (onChange) {
      onChange(name, option)
    }
  }

  const dropdownOptions = useMemo(() => {
    return options.map((option) => ({
      ...option,
      label: formatMessage({
        id: `select.option.${option.label}.label`,
        defaultMessage: option.label,
      }),
    }))
  }, [options])

  const dropdownValue = useMemo(() => {
    const finder =
      findDropdownValue ||
      ((options, val) => options.find((option) => option.value === val))
    return finder(dropdownOptions, value)
  }, [value, dropdownOptions, findDropdownValue])

  useEffect(() => {
    if (!isLoading) {
      // Explicitly set the value when loading is finished to
      // prevent some weird behaviour where the form thinks the
      // value is undefined after loading the options.
      form.setValue(name, value || form.getValues()[name], {
        shouldValidate: true,
      })
    }
  }, [isLoading])

  return (
    <Select
      key={`select-${name}-${value}`} // force re-render on value change
      isClearable
      options={dropdownOptions}
      value={dropdownValue}
      onChange={handleChange}
      placeholder={placeholder}
      isDisabled={isDisabled}
      isLoading={isLoading}
      menuPortalTarget={document.querySelector("body")}
      noOptionsMessage={() => "Geen opties"}
      loadingMessage={() => "Laden..."}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          minHeight: "44px",
        }),
        menuPortal: (baseStyles) => ({
          ...baseStyles,
          zIndex: 10,
        }),
      }}
    />
  )
}
