import React, { PropsWithChildren, ReactElement } from "react"
import { styled } from "src/stitches.config"

import { PageWidthContainer } from "../../page-width-container"
import { H1, P } from "../../typography"
import { AccommodationPageHeaderVisual } from "../accommodation-page-header-visual"

const AccommodationPageHeaderWrapper = styled("header", {
  position: "relative", // need this for positioning the OverviewPageHeaderVisual component
  display: "flex",
  alignItems: "center",
  marginBottom: "0",

  "@mdDown": {
    marginBottom: "$20",
  },

  "@mdUp": {
    minHeight: "431px",
  },
})

const AccommodationPageHeaderBody = styled("div", {
  position: "relative",
  padding: "$40 0",

  [H1.toString()]: {
    marginBottom: "$20",
    lineHeight: "1.15",
  },

  [P.toString()]: {
    marginBottom: "$10",
  },

  "@mdDown": {
    padding: "$20 0",
  },
})

export const AccommodationPageHeader = (
  props: PropsWithChildren<{}>,
): ReactElement => (
  <AccommodationPageHeaderWrapper data-cy="header-wrapper">
    <AccommodationPageHeaderVisual />
    <PageWidthContainer>
      <AccommodationPageHeaderBody>
        {props.children}
      </AccommodationPageHeaderBody>
    </PageWidthContainer>
  </AccommodationPageHeaderWrapper>
)
