import { useRouter } from "next/router"
import React from "react"
import { styled } from "src/stitches.config"

import { HorizontalFlex } from "../../flex"
import { NavMenu } from "./nav-menu"
import { NavMenuItemLink } from "./nav-menu-item-link"
import { NavSubmenu } from "./nav-submenu"
import { NavSubmenuItemLink } from "./nav-submenu-item-link"

interface TabletMenuItemsProps {
  items: {
    href: string
    label: string
  }[]
}

const TabletMenuItemsBase = ({ items, ...rest }: TabletMenuItemsProps) => {
  const { asPath } = useRouter()
  return (
    <HorizontalFlex {...rest}>
      <NavMenu>
        {items.map((item) => (
          <NavMenuItemLink
            key={item.href}
            href={item.href}
            slowAnimation
            isActive={asPath.includes(item.href)}
            label={item.label}
          >
            {item.label}
          </NavMenuItemLink>
        ))}
      </NavMenu>

      <span>
        <NavSubmenu icon="burger">
          {items.map((item) => (
            <NavSubmenuItemLink
              key={item.href}
              href={item.href}
              label={item.label}
            >
              {item.label}
            </NavSubmenuItemLink>
          ))}
        </NavSubmenu>
      </span>
    </HorizontalFlex>
  )
}
TabletMenuItemsBase.toString = () => ".TabletMenuItemsBase"

export const TabletMenuItems = styled(TabletMenuItemsBase, {
  [`& > span`]: {
    display: "none",
  },

  "@lgDown": {
    [`& > span`]: {
      display: "inherit",
    },
    [`& > ${NavMenu}`]: {
      display: "none",
    },
  },
})
