import { OVERLAY_ZINDEX } from "src/constants"
import { styled } from "src/stitches.config"

export const DropdownFiltersWrapper = styled("div", {
  position: "relative",
  color: "$inputText",
  "@smDown": {
    maxWidth: "$pageContainer",
  },
  "@mdDown": {
    minWidth: "initial",
  },

  "& .rmsc": {
    "--rmsc-main": "$colors$inputBorder",
    "--rmsc-hover": "$colors$inputHover",
    "--rmsc-selected": "$white",
    "--rmsc-border": "$colors$inputBorder",
    "--rmsc-gray": "$colors$inputText",
    "--rmsc-bg": "$white",
    "--rmsc-p": "10px" /* Spacing */,
    "--rmsc-radius": "4px" /* Radius */,
    "--rmsc-h": "initial" /* Height */,

    "& .select-item": {
      marginBottom: "0 !important",
    },

    "& .dropdown-heading": {
      minHeight: "$40",
      paddingTop: "6px",
      paddingBottom: "4px",
      cursor: "pointer",

      "& > svg": {
        alignSelf: "flex-end",
        height: "1.5rem",
        paddingBottom: "6px",
      },

      "& .dropdown-heading-value": {
        whiteSpace: "break-spaces",

        "& > span": {
          "&.gray": {
            marginBottom: "4px",
            display: "block",
          },

          "& > span": {
            marginBottom: "4px",
          },
        },
      },
    },

    "& .dropdown-container:focus-within": {
      boxShadow: "none",
      borderColor: "$inputBorder",
    },

    "& .dropdown-content": {
      backgroundColor: "$colors$inputBorder",
      border: "solid 1px $colors$inputBorder",
      borderRadius: "0 0 var(--rmsc-radius) var(--rmsc-radius)",
      padding: 0,
    },

    "& .search": {
      backgroundColor: "$inputBorder",
      height: "60px",
      display: "flex",
      padding: "0 $20",

      input: {
        height: "$40",
        verticalAlign: "middle",
        alignSelf: "center",
      },

      ".search-clear-button": {
        marginRight: "$20",
        marginBottom: "4px",
        padding: "0 12px",
      },
    },

    "& .select-panel": {
      backgroundColor: "$inputBorder",

      ".options": {
        backgroundColor: "$white",
        padding: "5px 0",
        borderRadius: "var(--rmsc-radius) var(--rmsc-radius) 0 0",
      },
    },

    ".select-item:focus": {
      background: "white",
    },
    ".select-item:hover:focus": {
      background: "var(--rmsc-hover)",
    },
  },

  variants: {
    isActive: {
      true: {
        zIndex: OVERLAY_ZINDEX,
      },
    },
    noSearch: {
      true: {
        ".dropdown-content": {
          backgroundColor: "transparent !important",
          borderRadius: "var(--rmsc-radius) !important",
        },
        "& .select-panel": {
          backgroundColor: "transparent !important",
        },
      },
    },
    isDisabled: {
      true: {
        "& .dropdown-heading": {
          backgroundColor: "hsl(0, 0%, 95%)",
        },
        "& .dropdown-heading-value": {
          "& > span": {
            "& > span": {
              backgroundColor: "hsl(0, 0%, 97%)",
              color: "hsl(0, 0%, 60%)",
            },
          },
        },
      },
    },
    minWidth: {
      xsmall: {
        minWidth: "100px",
      },
      small: {
        minWidth: "150px",
      },
      medium: {
        minWidth: "250px",
      },
      large: {
        minWidth: "350px",
      },
    },
  },
})
