import { PropsWithChildren } from "react"
import { styled } from "src/stitches.config"

const PageContentOuter = styled("div", {
  marginBottom: "$80",
  borderRadius: "4px",
  overflow: "hidden",

  "@mdDown": {
    marginBottom: "$40",
  },
})

const PageContentInner = styled("div", {
  padding: "$40 0",

  "@mdDown": {
    padding: "$20 0",
  },

  "@smDown": {
    padding: "$10 0",
  },
})

// TODO Yulia: there are 3 diff PageContents through the code

export const PageContent = ({ children }: PropsWithChildren<{}>) => (
  <PageContentOuter>
    <PageContentInner>{children}</PageContentInner>
  </PageContentOuter>
)
