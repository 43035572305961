const v2AccommodationRoutes = {
  all: {
    source: "/admin/accommodaties",
    destination: "/v2/admin/accommodations",
    permissions: ["AccommodationGetAll"],
  },
  detail: {
    source: "/admin/accommodaties/:id",
    destination: "/v2/admin/accommodations/:id",
    permissions: ["AccommodationGetById"],
  },
  passport: {
    source: "/accommodatiepaspoort/:id",
    destination: "/v2/accommodations/:id",
    permissions: ["AccommodationPassportGetById"],
  },
}

const v2CertificationProcessRoutes = {
  all: {
    source: "/admin/certificeringsprojecten",
    destination: "/v2/admin/certification-process",
    permissions: ["CertificationProcessGetAll"],
  },
  detail: {
    source: "/admin/certificerings-process/:id",
    destination: "/v2/admin/certification-process/:id",
    permissions: ["CertificationProcessGetById"],
  },
  certify: {
    source: "/admin/certificerings-process/:id/certificeren",
    destination: "/v2/admin/certification-process/:id/certify",
    permissions: ["CertificationProcessGetByIdToCertify"],
  },
}

const v2Routes = {
  accommodations: v2AccommodationRoutes,
  certificationProcess: v2CertificationProcessRoutes,
}

function flattenRoutes(routes) {
  const result = []

  for (const key in routes) {
    if (typeof routes[key] === "object") {
      for (const subKey in routes[key]) {
        const { source, destination } = routes[key][subKey]
        result.push({ source, destination })
      }
    }
  }

  return result
}

module.exports = { v2Routes, flattenRoutes }
