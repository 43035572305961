import React, { Fragment, MouseEvent } from "react"
import { RULE_TYPES } from "src/constants"
import { RuleType as RuleTypeType } from "src/services"
import { styled } from "src/stitches.config"

import {
  GuidelineIcon,
  LegislationIcon,
  PreceptIcon,
  RecommendationIcon,
} from "../../assets-icons"
import { Message } from "../../message"
import { H4, P } from "../../typography"

interface Props {
  ruleType: RuleTypeType
  showTooltip: boolean
  onClick: (event: MouseEvent<HTMLAnchorElement>) => void
  editType?: (key: string) => void
}

const RuleTypeWrapper = styled("div", {
  position: "relative",
  zIndex: "1",
})

const RuleTypeTooltipDescription = styled("div", {
  position: "absolute",
  top: "-$30",
  left: "0",
  width: "360px",
  padding: "$30 $30 $30 $60",
  opacity: "0",
  borderRadius: "4px",
  backgroundColor: "$white",
  boxShadow: "$default",
  pointerEvents: "none",
  transition: "opacity .15s ease",

  "@mdDown": {
    top: "-$20",
    maxWidth: "calc(100vw - $40)",
    padding: "$20 $10 $10 $40",
  },

  [H4.toString()]: {
    color: "$red",
  },

  p: {
    color: "$black",
  },

  variants: {
    visible: {
      true: {
        opacity: "1",
        pointerEvents: "auto",
      },
    },
    edit: {
      true: {
        bottom: "0",
        top: "0",
        left: "28px",
        width: "130px",
        padding: "0",
        paddingLeft: "5px",
        backgroundColor: "$white",
        pointerEvents: "none",
      },
    },
  },
  compoundVariants: [
    {
      visible: true,
      edit: true,
      css: {
        pointerEvents: "auto",
      },
    },
  ],
})

const RuleTypeTooltipButton = styled("button", {
  position: "relative",
  appearance: "none",
  width: "$30",
  height: "$60",
  padding: "$30 0 0",
  marginTop: "-$30",
  textAlign: "center",
  border: "none",
  borderRadius: "4px",
  backgroundColor: "$grayLight",
  boxShadow: "$default",

  svg: {
    position: "relative",
    top: "-.125em",
    fontSize: "18px",
    color: "$red",
    transition: "color .25s ease",
  },

  "@mdDown": {
    width: "$20",
    height: "$40",
    paddingTop: "$20",
    marginTop: "-$20",

    svg: {
      top: "-.2em",
      left: "0",
      fontSize: "12px",
    },
  },

  variants: {
    small: {
      true: {
        background: "$white",
        height: "$30",
        margin: "0",
        padding: "0",
        boxShadow: "none",
      },
    },

    selected: {
      false: {
        svg: {
          color: "$grayLight",
        },

        "&:hover": {
          svg: {
            color: "$tertiary",
          },
        },
      },
    },
  },
})

interface IIcons {
  [key: string]: JSX.Element
}

const icons: IIcons = {
  W: <LegislationIcon />,
  V: <PreceptIcon />,
  R: <GuidelineIcon />,
  A: <RecommendationIcon />,
}

export const RuleType = (props: Props) => {
  const { showTooltip, onClick, editType, ruleType } = props
  const edit = !!editType

  const handleClick =
    (key: string) => (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault()
      if (editType) editType(key)
    }

  return (
    <Fragment>
      <RuleTypeWrapper>
        <RuleTypeTooltipDescription edit={edit} visible={showTooltip}>
          {!!editType ? (
            Object.keys(icons).map((key: string, index) => (
              <RuleTypeTooltipButton
                key={`${key}-${index}`}
                small
                selected={key === ruleType}
                onClick={handleClick(key) as any}
                data-cy={key}
                aria-label={`select-${key}`}
              >
                {icons[key]}
              </RuleTypeTooltipButton>
            ))
          ) : (
            <>
              <H4 as="p">
                <Message
                  id={`ruleType.tooltip.${RULE_TYPES[ruleType]}.title`}
                />
              </H4>
              <P>
                <Message
                  id={`ruleType.tooltip.${RULE_TYPES[ruleType]}.description`}
                />
              </P>
            </>
          )}
        </RuleTypeTooltipDescription>
        <RuleTypeTooltipButton
          onClick={onClick as any}
          data-cy="rule-type"
          aria-label={`${ruleType}-geselecteerd`}
        >
          {icons[ruleType]}
        </RuleTypeTooltipButton>
      </RuleTypeWrapper>
    </Fragment>
  )
}
