import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const ArchiveIcon = ({
  color = "currentColor",
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
    version="1.1"
  >
    <title>Icon/archive</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(1.000001, 1.500001)">
        <path
          d="M21.6765893,3.63852707 L18.3776911,0.323843229 C18.1717245,0.116138432 17.8918021,-0.000424098234 17.5999915,0 L4.39999875,0 C4.10821231,-0.000301704929 3.8283349,0.116242119 3.62229918,0.323843229 L0.323400985,3.63852707 C0.116010882,3.84556721 -0.000424328791,4.12730393 0,4.4210533 L0,18.7894728 C0,20.0085779 0.986700622,21 2.19999996,21 L19.7999903,21 C21.0132897,21 22,20.0085779 22,18.7894728 L22,4.4210533 C22.00066,4.127255 21.8841871,3.84542706 21.6765893,3.63852707 Z"
          fill="#FFFFFF"
        ></path>
        <path
          d="M5.4072816,1.4861064 L16.5881839,1.4861064 C16.853476,1.4861064 17.107896,1.59152314 17.2954449,1.7791539 L18.5036768,2.9879131 C18.6208085,3.10509594 18.6207671,3.29504543 18.5035843,3.41217716 C18.4473271,3.46840976 18.3710408,3.49999885 18.2914985,3.49999885 L3.70503747,3.49999885 C3.53935205,3.49999885 3.40503747,3.36568427 3.40503747,3.19999885 C3.40503747,3.12050265 3.43659007,3.04425663 3.49276518,2.98800716 L4.69970729,1.77946745 C4.88728155,1.5916449 5.14183591,1.4861064 5.4072816,1.4861064 Z"
          fill={color}
        ></path>
        <path
          d="M11.2959706,9.49484553 C11.6462201,9.87102662 11.9347694,10.1809393 12.1616183,10.4245835 C12.3884672,10.6682277 12.7287406,11.033694 13.1824384,11.5209824 L7.17888876,11.5209824 C6.80414153,11.5209824 6.49999884,11.847643 6.49999884,12.2501355 C6.49999884,12.652628 6.80414153,12.9792886 7.17888876,12.9792886 L13.1824384,12.9792886 L11.2959706,15.0054255 C11.0305246,15.2905244 11.0305246,15.7520783 11.2959706,16.036448 C11.5614166,16.3208178 11.9911539,16.3215469 12.2559209,16.036448 L15.3012538,12.7656468 C15.3637117,12.6985647 15.4139495,12.6183578 15.4485729,12.5294012 C15.5171408,12.3507587 15.5171408,12.1495124 15.4485729,11.9708699 C15.4153073,11.8848298 15.3664273,11.804623 15.3012538,11.7346243 L12.2559209,8.463823 C11.990475,8.17872413 11.5607377,8.17872413 11.2959706,8.463823 C11.0312035,8.74892188 11.0305246,9.21047581 11.2959706,9.49484553 Z"
          fill={color}
          transform="translate(10.999999, 12.249999) rotate(-270.000000) translate(-10.999999, -12.249999) "
        ></path>
      </g>
    </g>
  </svg>
)
