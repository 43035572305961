import React from "react"

export const BackgroundSVG = () => (
  <svg
    preserveAspectRatio="xMidYMax slice"
    viewBox="0 0 1280 442"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        x1="0%"
        y1="60.8525145%"
        x2="104.744655%"
        y2="43.9471162%"
        id="linearGradient-1"
      >
        <stop stopColor="#9D0012" offset="0%"></stop>
        <stop stopColor="#e1001a" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="-2.48949813e-15%"
        x2="50%"
        y2="100%"
        id="linearGradient-2"
      >
        <stop stopColor="#9D0012" offset="0%"></stop>
        <stop stopColor="#e1001a" offset="100%"></stop>
        <stop stopColor="#e1001a" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M209.198607,1.050825 L1064.19711,1 C1155.44003,282.333333 1123.77386,423 969.198607,423 C814.623353,423 561.290019,282.350275 209.198607,1.050825 Z"
        id="Curve"
        fill="url(#linearGradient-1)"
        opacity="0.4"
        transform="translate(662.098554, 212.000000) scale(-1, 1) translate(-662.098554, -212.000000) "
      ></path>
      <path
        d="M687.498501,-593 C967.783012,-593 1194.9985,-365.784511 1194.9985,-85.5 C1194.9985,194.784511 967.783012,422 687.498501,422 C407.213991,422 179.998501,194.784511 179.998501,-85.5 C179.998501,-365.784511 407.213991,-593 687.498501,-593 Z M687.498501,-578 C415.498262,-578 194.998501,-357.500239 194.998501,-85.5 C194.998501,186.500239 415.498262,407 687.498501,407 C959.498741,407 1179.9985,186.500239 1179.9985,-85.5 C1179.9985,-357.500239 959.498741,-578 687.498501,-578 Z"
        id="Ring"
        fill="url(#linearGradient-2)"
        opacity="0.8"
      ></path>
    </g>
  </svg>
)
