import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const TableColoredIcon = ({
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(1.000000, 2.500000)">
        <rect
          fill="#31493C"
          x="0"
          y="0"
          width="22"
          height="19"
          rx="2.44444444"
        ></rect>
        <rect
          fill="#94A286"
          x="14.9722222"
          y="5.9375"
          width="5.5"
          height="5.34375"
          rx="1.83333333"
        ></rect>
        <rect
          fill="#94A286"
          x="14.9722222"
          y="12.46875"
          width="5.5"
          height="5.34375"
          rx="1.83333333"
        ></rect>
        <rect
          fill="#94A286"
          x="8.25"
          y="12.46875"
          width="5.5"
          height="5.34375"
          rx="1.83333333"
        ></rect>
        <rect
          fill="#94A286"
          x="8.25"
          y="5.9375"
          width="5.5"
          height="5.34375"
          rx="1.83333333"
        ></rect>
        <rect
          fill="#94A286"
          x="1.52777778"
          y="5.9375"
          width="5.5"
          height="5.34375"
          rx="1.83333333"
        ></rect>
        <rect
          fill="#94A286"
          x="1.52777778"
          y="12.46875"
          width="5.5"
          height="5.34375"
          rx="1.83333333"
        ></rect>
      </g>
    </g>
  </svg>
)
