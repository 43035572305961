import { UseQueryOptions } from "@tanstack/react-query"
import { useCallback, useMemo } from "react"

import { useCreateAccommodation } from "./use-create-accommodation"
import { useUpdateAccommodation } from "./use-update-accommodation"

export function useMutateAccommodation(id?: string, options?: UseQueryOptions) {
  const { mutateAsync: create, isLoading: isLoadingCreate } =
    useCreateAccommodation(options)
  const { mutateAsync: update, isLoading: isLoadingUpdate } =
    useUpdateAccommodation(id, options)

  const mutateAsync = useCallback(
    async (data: any) => {
      if (id) {
        return update(data)
      } else {
        return create(data)
      }
    },
    [id],
  )
  const isLoading = useMemo(() => {
    if (id) {
      return isLoadingUpdate
    } else {
      return isLoadingCreate
    }
  }, [id, isLoadingUpdate])

  return { mutateAsync, isLoading }
}
