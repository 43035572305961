import { Row, Table, flexRender } from "@tanstack/react-table"
import { useRouter } from "next/router"
import { Fragment } from "react"
import { Loader } from "src/components"
import { styled } from "src/stitches.config"
import { v2Routes } from "v2-routes"

import { Pagination } from "./fragments/pagination"
import { TableHeader } from "./fragments/table-header"

interface Props {
  table: Table<any>
  rowBaseUrl: string
  isLoading?: boolean
  tableFilterComponent: React.ReactElement
}

const T = styled("table", {
  width: "100%",
  fontSize: "$body",
})

const Tr = styled("tr", {
  position: "relative",
  cursor: "pointer",
  transition: "background-color .25s",

  "&:hover": {
    backgroundColor: "$grayLight",
  },
})

const Tbody = styled("tbody", {
  backgroundColor: "$white",
})

const Td = styled("td", {
  padding: "$15",
  borderBottom: "1px solid #f1f5f9",
})

export function V2Table({
  table,
  rowBaseUrl,
  isLoading = false,
  tableFilterComponent,
}: Props) {
  const router = useRouter()

  const navigateToRowLink = (row: Row<any>) => {
    const url = rowBaseUrl + row.original.id
    router.push(url)
  }

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLTableRowElement>,
    row: Row<any>,
  ) => {
    if (e.key !== "Enter") {
      return
    }

    navigateToRowLink(row)
  }

  return (
    <Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {tableFilterComponent}
          <T>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableHeader key={header.id} {...header} />
                  ))}
                </Tr>
              ))}
            </thead>

            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <Tr
                  key={row.id}
                  onClick={() => navigateToRowLink(row)}
                  onKeyDown={(e) => handleKeyDown(e, row)}
                  tabIndex={0}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      ) || "--"}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </T>
          <Pagination table={table} />
        </>
      )}
    </Fragment>
  )
}
