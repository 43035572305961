import React from "react"
import { styled } from "src/stitches.config"

import { AccommodationHeader } from "../../visuals"

const VisualWrapper = styled("div", {
  position: "absolute",
  bottom: "0",
  right: "50%",
  transform: "translateX(50%)",
  width: "100%",
  pointerEvents: "none",

  svg: {
    display: "block",
    width: "100%",
    overflow: "visible",
  },
})

export const AccommodationPageHeaderVisual = () => (
  <VisualWrapper>
    <AccommodationHeader />
  </VisualWrapper>
)
