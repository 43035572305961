export const HDefault = {
  margin: "0 0 .5em -0.0825em",
  padding: "0",
  fontFamily: "$nova",
  color: "$heading",

  variants: {
    italic: {
      true: {
        fontStyle: "italic",
      },
    },
    noMargin: {
      true: {
        margin: "0",
      },
    },
    textAlign: {
      left: {
        textAlign: "left",
      },
      right: {
        textAlign: "right",
      },
      center: {
        textAlign: "center",
      },
    },
  },
}
