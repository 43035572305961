import { Fragment, ReactElement } from "react"
import { IFooterData } from "src/services"

import { FooterContact, FooterLinks, FooterSponsors } from "./fragments"

export interface FooterProps {
  data: IFooterData
}

export const Footer = ({ data }: FooterProps): ReactElement => (
  <Fragment>
    <FooterContact {...data.contact} />
    <FooterSponsors {...data.sponsors} />
    <FooterLinks links={data.links} />
  </Fragment>
)
