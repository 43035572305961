import { IMember } from "src/services"
import { styled } from "src/stitches.config"

import { AvatarPlaceholderGlyph } from "../glyphs"
import { H5, P } from "../typography"

const Card = styled("div", {
  height: "245px",
  backgroundColor: "$white",
  overflow: "hidden",
  position: "relative",
  marginBottom: "$30",
  padding: "$20 $10 0",

  "@mdDown": {
    height: "215px",
  },

  "@smDown": {
    height: "215px",
  },
})

const Title = styled(H5, {
  color: "$black",

  "@mdDown": {
    fontSize: "1rem",
  },
})

const Subtitle = styled(P, {
  color: "$black",
  "@mdDown": {
    fontSize: "0.875rem",
  },
})

const AvatarMask = styled("div", {
  overflow: "hidden",
  height: "140px",
  width: "140px",
  borderRadius: "70px 70px 0 70px",
  position: "absolute",
  bottom: -20,
  right: -10,

  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    filter: "grayscale(1)",
  },

  "@lgDown": {
    right: -20,
  },

  "@mdDown": {
    height: "115px",
    width: "115px",
    bottom: -6,
  },
})

interface IMemberCardProps {
  member: IMember
}

export const MemberCard = ({
  member: { name, position, avatar },
}: IMemberCardProps) => (
  <Card data-cy="member-card">
    <Title>{name}</Title>
    <Subtitle>{position}</Subtitle>
    <AvatarMask>
      {avatar?.url ? (
        <img src={avatar.url} alt={name} />
      ) : (
        <AvatarPlaceholderGlyph />
      )}
    </AvatarMask>
  </Card>
)
