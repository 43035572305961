import { styled } from "src/stitches.config"

export const StyledPageHeader = styled("header", {
  padding: "$80 0 $40",

  "@mdDown": {
    padding: "$40 0 0",
  },

  H1: {
    lineHeight: "1.15",
    marginBottom: "$10",
  },

  P: {
    maxWidth: "825px",
    fontFamily: "$nova",
    fontWeight: "$medium",
    color: "$tertiary",
  },

  variants: {
    hasParentLink: {
      true: {
        padding: "$40 0 0",

        "@mdDown": {
          padding: "$20 0 0",
        },
      },
    },
  },
})

export const StyledPageContent = styled("div", {
  paddingBottom: "$40",
  backgroundColor: "$white",

  "@mdDown": {
    width: "calc(100% + $30)",
    marginBottom: "$40",
    marginLeft: "-$15",
  },

  "@mdUp": {
    marginBottom: "$80",
    marginLeft: "0",
  },

  variants: {
    noPadding: {
      true: {
        paddingBottom: "0",
      },
    },
    roundBorders: {
      true: {
        overflow: "hidden",
        borderRadius: "6px",
      },
    },
  },
})

export const StyledPageLead = styled("div", {
  p: {
    margin: 0,
  },

  "@mdDown": {
    padding: "$20 $15",
  },

  "@mdUp": {
    padding: "$40 0",
  },
})

export const StyledPageContentItemContainer = styled("div", {
  padding: "$40 0",

  "@mdDown": {
    padding: "$20 $15",
  },
})

export const StyledLinkCardsComponentContainer = styled("div", {
  marginBottom: "$15",

  "@mdUp": {
    marginBottom: "$80",
  },
})
