import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const DrawingVariantDepthIcon = ({
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Icon/CAD</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(1.000000, 1.500000)">
        <g transform="translate(9.566112, 0.000000)">
          <path
            d="M6.0066522,0.180209391 L12.3600662,12.4044609 C12.5224184,12.7168336 12.4086221,13.0995805 12.1007601,13.2766214 L6.3827338,16.5648623 C6.06219854,16.7491912 5.65047775,16.6429616 5.46312967,16.327592 C5.40296971,16.2263226 5.3712645,16.1111339 5.3712645,15.9938351 L5.3712645,0.33077604 C5.3712645,0.148132175 5.5217518,7.00678257e-05 5.7073873,7.00678257e-05 C5.83360629,7.00678257e-05 5.94918613,0.0696421282 6.0066522,0.180209391 Z"
            fill="#00378A"
          ></path>
          <path
            d="M4.71423539,0.0363339844 C4.82803547,0.0928739451 4.89964171,0.206591151 4.89964171,0.33077604 L4.89964171,15.9938841 C4.89964171,16.3591718 4.59485927,16.655296 4.21889108,16.655296 C4.09816132,16.655296 3.97960334,16.624101 3.87537217,16.5649093 L3.84930443,16.5501804 L3.83467417,11.2187446 L3.83467417,11.2187446 L3.83477456,10.8978606 C3.8297337,10.610726 3.76734267,10.4223642 3.64760147,10.3327753 L3.61647138,10.3124359 L0,8.2669879 L4.2562153,0.180209391 C4.34180231,0.0175935336 4.54686489,-0.04682168 4.71423539,0.0363339844 Z"
            fill="#5076AF"
          ></path>
        </g>
        <g transform="translate(0.000000, 7.360182)">
          <g transform="translate(0.998549, 0.000000)" fill="#00378A">
            <path d="M4.88132821,6.48165918 L0.338638713,3.92569366 C0.0163466224,3.7443545 -0.0955104361,3.34029123 0.0887989804,3.02319308 C0.148628947,2.92025764 0.235484773,2.83504039 0.340273301,2.77646295 L5.15260732,0.0863382293 C5.35912345,-0.029105578 5.61222924,-0.0287570873 5.81841617,0.0872549535 L10.3611057,2.64322047 C10.6833978,2.82455963 10.7952548,3.2286229 10.6109454,3.54572105 C10.5511154,3.64865649 10.4642596,3.73387374 10.3594711,3.79245118 L5.54713707,6.4825759 C5.34062093,6.59801971 5.08751514,6.59767122 4.88132821,6.48165918 Z"></path>
            <path d="M11.7539046,4.8562191 L11.7696251,10.2028823 C11.7703237,10.441508 11.6403281,10.6620046 11.4294487,10.779879 L6.6004339,13.4791365 C6.27762162,13.6595777 5.86725809,13.5483805 5.68386128,13.2307706 C5.62667524,13.1317347 5.59645296,13.0198471 5.59612176,12.905945 L5.5805722,7.55928227 C5.57988113,7.32066759 5.70987187,7.10018353 5.92073961,6.98231134 L10.7495836,4.28305341 C11.0723931,4.10260732 11.4827583,4.21379836 11.6661601,4.53140556 C11.7233458,4.63043739 11.7535698,4.74232067 11.7539046,4.8562191 Z"></path>
          </g>
          <path
            d="M4.96844972,13.5525672 L0.358707999,10.9913476 C0.147320951,10.8738988 0.0166015533,10.6556913 0.0158972434,10.4190998 L0,5.07988274 C-0.00108451259,4.71459654 0.302815121,4.41761708 0.678781739,4.41655778 C0.797992011,4.41622554 0.915202817,4.44631167 1.01868772,4.50380894 L5.62842944,7.0650285 C5.83981649,7.1824773 5.97053589,7.40068481 5.9712402,7.63727626 L5.98713744,12.9764934 C5.98822195,13.3417796 5.68432232,13.638759 5.3083557,13.6398183 C5.18914543,13.6401506 5.07193462,13.6100644 4.96844972,13.5525672 Z"
            fill="#5076AF"
          ></path>
        </g>
      </g>
    </g>
  </svg>
)
