export const OverviewHeaderShadow = () => (
  <svg
    preserveAspectRatio="xMidYMax slice"
    width="1280px"
    height="431px"
    viewBox="0 0 1280 431"
    version="1.1"
  >
    <defs>
      <ellipse id="path-1" cx="555" cy="60" rx="555" ry="60"></ellipse>
      <filter
        x="-1.2%"
        y="-9.2%"
        width="102.3%"
        height="121.7%"
        filterUnits="objectBoundingBox"
        id="filter-2"
      >
        <feOffset
          dx="0"
          dy="2"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          stdDeviation="4"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feColorMatrix
          values="0 0 0 0 0.290196078   0 0 0 0 0.290196078   0 0 0 0 0.290196078  0 0 0 0.1 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(84.998501, 311.000000)">
        <use
          fill="black"
          fillOpacity="1"
          filter="url(#filter-2)"
          xlinkHref="#path-1"
        />
        <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-1" />
      </g>
    </g>
  </svg>
)
