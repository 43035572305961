import { styled } from "src/stitches.config"

export const NavSubmenuItemText = styled("div", {
  width: "100%",
  margin: "$10 $10 $5 $10",
  padding: "0 $30 0 0",

  fontSize: "18px",
  fontFamily: "$grotesk",
  color: "$gray",
  textAlign: "inherit",
  whiteSpace: "nowrap",
})
