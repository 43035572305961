import { AxiosResponse } from "axios"
import { V2IspDisciplines } from "src/utils/v2"

import { V2Api } from "../api.v2"
import { DisciplineGetAll, IspDisciplineGetAllAvailable } from "./types"

export class DisciplinesService extends V2Api {
  public async getImplementedSportProductAllAvailableDisciplines(
    ispId: string,
  ): Promise<AxiosResponse<IspDisciplineGetAllAvailable>> {
    return super.get(
      `/implemented-sport-product/${ispId}/available-disciplines`,
    )
  }

  public async manageImplementedSportProductDisciplines(
    cpId: string,
    ispId: string,
    data: V2IspDisciplines,
  ) {
    return super.post(
      `certification-process/${cpId}/implemented-sport-product/${ispId}/disciplines`,
      { data },
    )
  }

  public async getAll(): Promise<AxiosResponse<DisciplineGetAll>> {
    return super.get(`/disciplines`)
  }
}
