import { styled } from "src/stitches.config"

import { EditableRuleAttachmentTextWrapper } from "./editable-rule-attachment-text-wrapper"

export const EditableRuleAttachmentWrapper = styled(
  EditableRuleAttachmentTextWrapper,
  {
    padding: "$10",
    borderRadius: "4px",
    backgroundColor: "$grayLight",

    textarea: {
      backgroundColor: "$white !important",
    },
  },
)
