import { styled } from "src/stitches.config"

import { HDefault } from "./h-default"

export const H3 = styled("h3", {
  ...HDefault,

  fontSize: "1.5rem",
  lineHeight: "1.5",

  "@mdDown": {
    fontSize: `20px`,
  },
})
