import Link from "next/link"
import React from "react"
import { iconSizes } from "src/constants"
import { styled } from "src/stitches.config"
import { isSportTechnish } from "src/utils/isSportTechnish"

import { ChevronRightIcon } from "../assets-icons"
import { P } from "../typography"

interface Props {
  document: {
    code: string
    title: string
    isActive: boolean
    category: string
  }
}

const DocumentTileBackground = styled("div", {
  position: "absolute",
  top: "0",
  left: "0",
  width: "150%",
  paddingBottom: "150%",

  variants: {
    technisch: {
      true: {
        img: {
          position: "absolute",
          left: "10%",
          width: "120%",
          height: "120%",
        },
      },
      false: {
        img: {
          position: "absolute",
          left: "-10%",
          width: "100%",
          height: "100%",
        },
      },
    },
  },
})

const DocumentTileForeground = styled("div", {
  position: "relative",
  minHeight: "110px",
  padding: "$10",

  "a::after": {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    content: "''",
  },

  [P.toString()]: {
    margin: "0",

    a: {
      textDecoration: "none",
    },

    svg: {
      position: "relative",
      top: "-.1em",
      left: ".1em",
    },
  },
})

const DocumentTileWrapper = styled("div", {
  position: "relative",
  borderRadius: "4px",
  backgroundColor: "$tertiary",
  overflow: "hidden",
  transition: "background-color .15s ease",

  [`${P.toString()}`]: {
    color: "$white",
    a: {
      color: "$white",
    },
  },

  "&:hover": {
    backgroundColor: "#4369a2",
  },

  variants: {
    isActive: {
      false: {
        background: "radial-gradient(circle at left, #FFFFFF 0%, #F0F0F0 100%)",

        [`${P.toString()}`]: {
          color: "$gray",
          a: {
            color: "$gray",
          },
        },

        "&:hover": {
          background: "$grayLight",
        },
      },
    },
  },
})

const image = ({
  category,
  isActive,
}: {
  category: string
  isActive: boolean
}) => {
  if (isSportTechnish(category)) {
    return isActive
      ? "/images/document_tile_background_techish.svg"
      : "/images/document_tile_background_techish_inactive.svg"
  } else {
    return isActive
      ? "/images/document_tile_background.svg"
      : "/images/document_tile_background_inactive.svg"
  }
}

export const DocumentTile = ({ document }: Props) => {
  const { code, title, isActive, category } = document
  return (
    <DocumentTileWrapper data-cy="document-tile" isActive={isActive}>
      <DocumentTileBackground technisch={isSportTechnish(category)}>
        <img src={image({ category: category, isActive: isActive })} alt="" />
      </DocumentTileBackground>

      <DocumentTileForeground>
        <P size="extraSmall" data-cy="code">
          {code}
          <ChevronRightIcon size={iconSizes.xsmall} />
        </P>

        <P bold data-cy="title">
          <Link href={`/kwaliteitszorgsysteem/voorschriften/${code}`}>
            {title}
          </Link>
        </P>
      </DocumentTileForeground>
    </DocumentTileWrapper>
  )
}
