import { PropsWithChildren, ReactElement } from "react"
import ReactPlayer from "react-player"
import { IVideo } from "src/services/types"
import { styled } from "src/stitches.config"

import { IconShadower } from "../icon-shadower"

const PlayerWrapper = styled("div", {
  "& > div": {
    position: "relative",
    paddingTop: "56.35%",
  },

  "& > p": {
    marginTop: "$10",
    fontSize: "$bodySmall",
    lineHeight: "15px",
    marginBottom: "0",
  },

  ".shadow-icon": {
    width: "60px",
    height: "60px",
  },
})

const IconWrapper = styled("div", {
  position: "absolute",
  top: "0",
  bottom: "0",
  left: "0",
  right: "0",
  backgroundColor: "rgba(74, 74, 74, 0.3)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
})

/** Video player
 */
export const VideoPlayer = (props: PropsWithChildren<IVideo>): ReactElement => {
  const { url, caption } = props

  return (
    <PlayerWrapper data-cy="video-player">
      <div>
        <ReactPlayer
          style={{ position: "absolute", top: "0", left: "0" }}
          playIcon={
            <IconWrapper>
              <IconShadower name="playVideo" />
            </IconWrapper>
          }
          light
          playing
          controls
          url={url}
          width="100%"
          height="100%"
        />
      </div>
      {caption && <p>{caption}</p>}
    </PlayerWrapper>
  )
}
