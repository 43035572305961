import { merge } from "lodash"
import { IFooterData } from "src/services"

import { createFooterContactItem } from "./footerContactItem"
import { createFooterSponsorItem } from "./footerSponsorItem"
import { createLink } from "./link"

const defaults: IFooterData = {
  contact: {
    title: "Contact us",
    description: "Contact us for more information",
    items: [createFooterContactItem()],
  },
  sponsors: {
    title: "Sponsor us",
    items: [createFooterSponsorItem()],
  },
  links: [createLink()],
}

export const createFooter = (override?: Partial<IFooterData>): IFooterData =>
  merge({}, defaults, override)
