import { styled } from "src/stitches.config"

interface CheckboxProps {
  checked?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const StyledCheckboxContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  marginLeft: "$20",
  textAlign: "center",
  alignSelf: "center",
})

const StyledCheckbox = styled("input", {
  marginRight: "$10",
  marginTop: "4px",
  width: "24px",
  height: "24px",
  cursor: "pointer",
})

export const V2Checkbox = ({
  checked = false,
  onChange = () => {},
}: CheckboxProps) => {
  return (
    <StyledCheckboxContainer>
      <StyledCheckbox type="checkbox" onChange={onChange} checked={checked} />
    </StyledCheckboxContainer>
  )
}
