import * as Popover from "@radix-ui/react-popover"
import React, { PropsWithChildren, useCallback } from "react"
import { styled } from "src/stitches.config"

interface PopOverProps extends Popover.PopoverProps {
  trigger: React.ReactNode
  side?: "top" | "right" | "left" | "bottom"
  hideArrow?: boolean
  openToggle: (open: boolean) => void
  align?: "start" | "center" | "end"
}

const StyledContent = styled(Popover.Content, {
  padding: "0.5rem 0",
  backgroundColor: "white",
  boxShadow: "$default",
})

const StyledArrow = styled(Popover.Arrow, {
  fill: "white",
})

const StyleTooltipTrigger = styled(Popover.Trigger, {
  border: "none",
  backgroundColor: "transparent",
  padding: 0,
})

export const HoverCard = (props: PropsWithChildren<PopOverProps>) => {
  const {
    trigger,
    children,
    side = "right",
    hideArrow = false,
    openToggle,
    align,
    open,
    ...rest
  } = props
  const togglePopover = useCallback(() => openToggle(!open), [open])

  return (
    <Popover.Root onOpenChange={togglePopover} {...rest}>
      <StyleTooltipTrigger>{trigger}</StyleTooltipTrigger>
      <StyledContent side={side} align={align}>
        {children}
        {!hideArrow && <StyledArrow />}
      </StyledContent>
    </Popover.Root>
  )
}
