import React from "react"

export const ForegroundSVGTech = () => (
  <svg
    preserveAspectRatio="xMidYMax slice"
    viewBox="0 0 1280 1035"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <ellipse id="path-1" cx="688" cy="975" rx="380" ry="60"></ellipse>
      <feGaussianBlur
        stdDeviation="4"
        in="shadowOffsetOuter1"
        result="shadowBlurOuter1"
      ></feGaussianBlur>
      <feColorMatrix
        values="0 0 0 0 0.290196078   0 0 0 0 0.290196078   0 0 0 0 0.290196078  0 0 0 0.1 0"
        type="matrix"
        in="shadowBlurOuter1"
      ></feColorMatrix>
      <linearGradient
        x1="50%"
        y1="-2.48949813e-15%"
        x2="50%"
        y2="100%"
        id="linearGradient-3"
      >
        <stop stopColor="#00378A" offset="0%"></stop>
        <stop stopColor="#5076AF" offset="100%"></stop>
        <stop stopColor="#5076AF" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="-2.48949813e-15%"
        x2="50%"
        y2="100%"
        id="linearGradient-4"
      >
        <stop stopColor="#00378A" offset="0%"></stop>
        <stop stopColor="#5076AF" offset="100%"></stop>
        <stop stopColor="#5076AF" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g
      id="Graphics/Voorschrift_norms"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Oval">
        <use
          fill="black"
          fill-opacity="1"
          filter="url(#filter-2)"
          xlinkHref="#path-1"
        ></use>
        <use fill="#5076AF" fill-rule="evenodd" xlinkHref="#path-1"></use>
      </g>
      <polygon
        id="Rectangle"
        fill="#5076AF"
        transform="translate(640.000000, 507.500000) scale(-1, 1) translate(-640.000000, -507.500000) "
        points="0.00115436816 0 1280 0 1279.99885 1015 0 1015"
      ></polygon>
    </g>
  </svg>
)
