import { useQuery } from "@tanstack/react-query"
import { ISubscription } from "src/services"
import api from "src/services/api"

export const useUserSubscriptions = (initialData?: ISubscription[]) => {
  return useQuery(
    ["userSubscriptions"],
    api.subscription.getUserSubscriptions,
    {
      initialData,
    },
  )
}
