import { styled } from "src/stitches.config"

export const FormIconWrapper = styled("div", {
  flex: "0 0 $sizes$30",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "$30",
  marginRight: "$10",
  padding: "5px",
  color: "$body",
  lineHeight: "1",
  borderRadius: "4px",
  backgroundColor: "$lightestGreen",
})
