import Link from "next/link"
import { ArrowLeftIcon, Button } from "src/components"
import { iconSizes } from "src/constants"
import { styled } from "src/stitches.config"

interface Props {
  text: string
  onClick?: () => void
  href?: string
  dataCy?: string
}

const BackButtonWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  color: "$bodyGreen",

  "& > *": {
    marginRight: "$10",
  },
})

export const BackButton = ({ text, onClick, href = "#", dataCy }: Props) => {
  if (onClick) {
    return (
      <BackButtonWrapper css={{ marginBottom: "$40" }}>
        <Button
          variant="light"
          size="large"
          icon={<ArrowLeftIcon color="#94A286" size={iconSizes.xlarge} />}
          noPadding
          data-cy={dataCy}
          onClick={onClick}
        />

        {text}
      </BackButtonWrapper>
    )
  }

  return (
    <Link href={href}>
      <BackButtonWrapper css={{ marginBottom: "$40" }}>
        <Button
          as="div"
          variant="light"
          size="large"
          icon={<ArrowLeftIcon color="#94A286" size={iconSizes.xlarge} />}
          noPadding
          data-cy={dataCy}
        />

        {text}
      </BackButtonWrapper>
    </Link>
  )
}
