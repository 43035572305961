import { ChangeEvent, createRef, useCallback, useEffect, useState } from "react"
import { iconSizes } from "src/constants"
import { OVERLAY_ZINDEX } from "src/constants"
import { styled } from "src/stitches.config"

import { Close2Icon, SearchAssetsIcon } from "../assets-icons"
import { Overlay } from "../overlay"
import { P } from "../typography"

export const sizes = {
  // px
  small: 30,
  regular: 42,
}

interface Props {
  withClearButton?: boolean
  withSearchIcon?: boolean
  withFocusEnabled?: boolean
  value?: string
  onChange?: Function
  size?: keyof typeof sizes
  variant?: "secondary"
  error?: string

  [x: string]: any
}

const inputRef = createRef<HTMLInputElement>()

export const Input = ({
  withClearButton = true,
  withSearchIcon = false,
  withFocusEnabled = true,
  value = "",
  onChange,
  size,
  variant,
  error,
  ...rest
}: Props) => {
  const [isFocused, setIsFocused] = useState(false)
  const [currentValue, setCurrentValue] = useState(value)
  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(e.target.value)
  }, [])

  const handleClearClick = useCallback(() => {
    setCurrentValue("")
  }, [])

  useEffect(() => {
    onChange && onChange(currentValue)
  }, [currentValue])

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  return (
    <>
      {isFocused && withFocusEnabled && <Overlay />}

      <Container>
        {error && (
          <InputError noMargin size="extraSmall">
            {error}
          </InputError>
        )}

        <InputWrap
          withClearButton={withClearButton}
          withSearchIcon={withSearchIcon}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          isActive={isFocused && withFocusEnabled}
          size={size}
          variant={variant}
        >
          {withSearchIcon && <SearchAssetsIcon size={iconSizes.large} />}
          <StyledInput
            ref={inputRef}
            value={currentValue}
            onChange={handleInputChange}
            {...rest}
          />
          {withClearButton && (
            <CloseButton onClick={handleClearClick} hide={!currentValue}>
              <Close2Icon size={iconSizes.small} />
            </CloseButton>
          )}
        </InputWrap>
      </Container>
    </>
  )
}

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
})

const CloseButton = styled("span", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "1rem",
  cursor: "pointer",
  color: "#00378A",
  transition: "opacity .2s linear",

  variants: {
    hide: {
      true: {
        opacity: 0,
        cursor: "default",
      },
    },
  },
})

const StyledInput = styled("input", {
  padding: 0,
  paddingBottom: "4px",
  margin: 0,
  height: "100%",
  outline: "none",
  border: "none",
  borderRadius: "4px",
  flexGrow: 1,
  backgroundColor: "transparent",
  maxWidth: "100%",
  width: "100%",
  boxSizing: "border-box",
})

const InputWrap = styled("div", {
  height: `${sizes.regular}px`,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  backgroundColor: "$white",
  border: "1px solid $inputBorder",
  borderRadius: "4px",
  paddingLeft: "$10",
  color: "$black",

  "& > *:first-child": {
    marginRight: "$10",
  },

  [`${StyledInput}`]: {
    "&::placeholder": {
      fontStyle: "italic",
    },
  },

  variants: {
    isActive: {
      true: {
        zIndex: OVERLAY_ZINDEX,
      },
    },
    withClearButton: {
      false: {
        paddingRight: "10px",
      },
    },
    withSearchIcon: {
      false: {
        "& > *:first-child": {
          marginRight: 0,
        },
      },
    },
    size: {
      small: {
        height: `${sizes.small}px`,
        fontSize: "14px",

        [`${StyledInput}`]: {
          "&::placeholder": {
            fontSize: "14px",
          },
        },
      },
      regular: {
        height: `${sizes.regular}px`,
      },
    },
    variant: {
      secondary: {
        border: "1px solid #31493C",
        backgroundColor: "rgba(255,255,255,0.5)",

        [`${StyledInput}`]: {
          "&::placeholder": {
            color: "#919291",
          },
        },
        [`${CloseButton}`]: {
          color: "#31493C",
        },
      },
    },
  },
})

const InputError = styled(P, {
  color: "$red",
})
