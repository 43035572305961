import { useRouter } from "next/router"
import { useState } from "react"
import { useIntl } from "react-intl"
import { Button } from "src/components/button"
import { HorizontalFlex } from "src/components/flex"
import { IDisciplineCertificate } from "src/services"
import api from "src/services/api"
import { styled } from "src/stitches.config"
import { useHasPermissions } from "src/utils/has-permissions"

interface Props {
  token: string
  disciplineCertificate: IDisciplineCertificate
}

const DownloadButtonWrapper = styled("div", {
  display: "inline",
  marginRight: "$10",
})

const RegenerateButtonWrapper = styled("div", {
  marginLeft: "$10",
})

export function DownloadCertificateLink(props: Props) {
  const [isRegenerating, setIsRegenerating] = useState(false)
  const { formatMessage } = useIntl()
  const canRegenerate = useHasPermissions(["regenerateCertificate"])
  const router = useRouter()
  const { certificate, discipline } = props.disciplineCertificate

  if (!certificate?.url) {
    return null
  }

  async function handleRegenerateClick() {
    if (certificate) {
      try {
        setIsRegenerating(true)
        await api.admin.regenerateCertificate(certificate.id)
        router.reload()
      } catch (error) {
        console.error(error)
      }
    }
  }

  return (
    <HorizontalFlex justifyContent="spaceBetween">
      <a
        href={`${certificate.url}?${props.token}`}
        download
        target="_blank"
        className="underlined"
        data-cy="download-certificate"
      >
        <DownloadButtonWrapper>
          <Button variant="light" icon="download" iconColor="primary" />
        </DownloadButtonWrapper>
        {formatMessage({ id: "implementedSportProduct.downloadCertificate" })} -{" "}
        {discipline.sport.name}
      </a>

      {canRegenerate && (
        <RegenerateButtonWrapper>
          <Button
            variant="light"
            icon="reset"
            iconColor="primary"
            isLoading={isRegenerating}
            onClick={handleRegenerateClick}
          />
        </RegenerateButtonWrapper>
      )}
    </HorizontalFlex>
  )
}
