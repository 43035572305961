import { Fragment } from "react"
import { useFieldArray, useFormContext } from "react-hook-form"
import { IDisciplineCertificate } from "src/services"
import { styled } from "src/stitches.config"

import { ContentBox } from "../../../context-box"
import { Grid } from "../../../grid"
import { GridItem } from "../../../grid-item"
import { H3 } from "../../../typography"
import { SportClubDisciplineRelation } from "../../sport-club-discipline-relation"

const SportClubDisciplineRelationWrapper = styled("div", {
  margin: "$20 0",
  padding: "$20",
  borderLeft: "1px solid $bodyGreen",
  backgroundColor: "$grayLight",

  "&:first-child": {
    marginTop: "0",
  },

  "&:last-child": {
    marginBottom: "0",
  },
})

export const ImplementedSportProduct = ({
  implementedSportProduct,
  path,
}: any) => {
  const form = useFormContext()

  const sportClubDisciplineRelations = useFieldArray({
    keyName: "_id",
    name: `${path}.sportClubDisciplineRelations`,
    control: form.control,
  })

  const isApproved = (disciplineCode: string) => {
    return implementedSportProduct.disciplineCertificates.find(
      (certificates: IDisciplineCertificate) =>
        certificates.discipline.code === disciplineCode &&
        certificates.state === "APPROVED",
    )
  }

  return (
    <Fragment>
      <Grid>
        <GridItem col={[1, 3]} medium={[1, 12]}>
          <H3 css={{ "@mdUp": { textAlign: "right" } }} noMargin>
            {implementedSportProduct.sportProduct.label}
          </H3>
        </GridItem>

        <GridItem col={[4, 9]} medium={[1, 12]}>
          <ContentBox css={{ marginBottom: "$20" }}>
            {sportClubDisciplineRelations.fields.map(
              (sportClubDisciplineRelation: any, index: number) => {
                if (!isApproved(sportClubDisciplineRelation.discipline.id))
                  return null

                return (
                  <SportClubDisciplineRelationWrapper
                    key={sportClubDisciplineRelation._id}
                  >
                    <SportClubDisciplineRelation
                      index={index}
                      remove={sportClubDisciplineRelations.remove}
                      path={`${path}.sportClubDisciplineRelations.${index}`}
                      sportProductValue={implementedSportProduct.sportProduct}
                      isDisciplineEditable={false}
                    />
                  </SportClubDisciplineRelationWrapper>
                )
              },
            )}
          </ContentBox>
        </GridItem>
      </Grid>
    </Fragment>
  )
}
