export const SportfloorIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.00565982 50.321C0.00565982 52.3489 1.64267 54 3.6532 54H56.3525C58.363 54 60 52.3489 60 50.321V9.68045C59.9986 7.65257 58.363 6.00286 56.3525 6.00286H37.5901C37.583 6.00286 37.5774 6 37.5703 6H3.64755C1.63701 5.99857 0 7.64971 0 9.6776C0.0127339 75.8344 3.22703e-07 10.4925 0.00565982 50.321ZM2.33596 21.7736H8.31113V38.2264H2.33596V21.7736ZM2.33596 50.321V40.5783H9.47558C10.1193 40.5783 10.64 40.0517 10.64 39.4038V20.5991C10.64 19.9498 10.1193 19.4246 9.47558 19.4246H2.33596V9.68045C2.33596 8.94836 2.92737 8.35326 3.65179 8.35326H28.8306V23.175C25.5976 23.7387 23.1272 26.58 23.1272 30.0007C23.1272 33.4214 25.5962 36.2628 28.8306 36.8265V51.6496H3.65179C2.92596 51.6496 2.33596 51.0545 2.33596 50.3224V50.321ZM30.0078 34.5831C30.0078 34.5831 30.0007 34.5802 29.9965 34.5802C29.9936 34.5802 29.9894 34.5817 29.9894 34.5817C27.4893 34.5745 25.459 32.5224 25.459 29.9993C25.459 27.4762 27.4964 25.4155 30.0021 25.4155C32.5079 25.4155 34.5467 27.4719 34.5467 29.9993C34.5467 32.5267 32.5107 34.5788 30.0078 34.5817V34.5831ZM57.6683 38.2279H51.6931V21.775H57.6683V38.2279ZM57.6683 9.68045V19.4232H50.5287C49.8849 19.4232 49.3642 19.9498 49.3642 20.5977V39.4038C49.3642 40.0531 49.8849 40.5783 50.5287 40.5783H57.6683V50.321C57.6683 51.0531 57.0769 51.6482 56.3525 51.6482H31.1623V36.8279C34.4024 36.2699 36.877 33.4243 36.877 30.0007C36.877 26.5771 34.401 23.7315 31.1623 23.1735V8.35469H56.3525C57.0783 8.35469 57.6683 8.95121 57.6683 9.68188V9.68045Z"
      fill="#000000"
    />
  </svg>
)
