import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const SubheadingIcon = ({
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
    version="1.1"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <line
        x1="2"
        y1="10"
        x2="22"
        y2="10"
        stroke="#E1001A"
        strokeWidth="3"
      ></line>
      <line x1="2" y1="14" x2="22" y2="14" stroke="#919291"></line>
    </g>
  </svg>
)
