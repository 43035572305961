import type * as Stitches from "@stitches/react"
import React, { MouseEvent, PropsWithChildren } from "react"
import { iconSizes } from "src/constants"
import { keyframes, styled } from "src/stitches.config"

import {
  ArchiveIcon,
  ArrowLeftIcon,
  ArrowRightAssetsIcon,
  ArrowRightSmallOrangeIcon,
  CheckmarkIcon,
  ChevronRightIcon,
  CloseAssetsIcon,
  DownloadIcon,
  EditIcon,
  ExternalLinkIcon,
  FilterIcon,
  LocationIcon,
  NotificationIcon,
  PlusIcon,
  PlusThinIcon,
  ReplaceIcon,
  ResetIcon,
  SpinIcon,
  TagsIcon,
  TrashIcon,
  UnarchiveIcon,
  UploadIcon,
} from "../assets-icons"

export type TButtonVariants =
  | "primary"
  | "secondary"
  | "completed"
  | "light"
  | "lightInverse"
  | "darkGreen"
  | "transparent"
export type TButtonIconColors = "variant" | "primary" | "secondary" | "white" // TODO: | 'light' | 'dark'
export type TButtonSizes = "xsmall" | "small" | "regular" | "large"
export interface ButtonProps {
  icon?: keyof typeof icons | JSX.Element
  iconColor?: TButtonIconColors
  type?: "button" | "submit"
  size?: TButtonSizes
  variant: TButtonVariants
  light?: boolean
  outline?: boolean
  isLoading?: boolean
  as?: any
  noPadding?: boolean
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void
  disabled?: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >["disabled"]
  css?: Stitches.CSS
}

const rotate = keyframes({
  "0%": {
    transform: "rotate(0)",
  },
  "100%": {
    transform: "rotate(360deg)",
  },
})

const ButtonIconContainer = styled("span", {
  position: "relative",
  display: "flex",
})

const ButtonLabel = styled("span", {
  position: "relative",
  fontFamily: "$grotesk",
  alignSelf: "baseline",
  paddingBottom: "2px",
})

const ButtonContentContainer = styled("span", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  variants: {
    visible: {
      false: {
        visibility: "hidden",
      },
    },
  },
})

const SpinIconContainer = styled("span", {
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  svg: {
    animation: `${rotate} 1s infinite`,
  },
})

const StyledButton = styled("button", {
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  position: "relative",
  color: "$white",
  appearance: "none",
  "-moz-appearance": "none !important",
  "-webkit-appearance": "none !important",
  border: "none",
  borderRadius: "6px",
  background: "none",
  boxShadow: "$default",
  fontSize: "$body",
  lineHeight: "$body",

  transition: "all 0.15s ease",

  "&:hover, &:focus": {
    color: "$primary",
  },

  [`& ${ButtonIconContainer}:not(:last-child)`]: {
    marginRight: "0.625em",
  },

  compoundVariants: [
    {
      iconColor: "white",
      variant: "secondary",
      css: {
        [`${ButtonIconContainer}`]: {
          color: "$white",
        },
      },
    },
    {
      iconColor: "variant",
      variant: "primary",
      css: {
        [`${ButtonIconContainer}`]: {
          color: "$primary",
        },
      },
    },
    {
      iconColor: "variant",
      variant: "secondary",
      css: {
        [`${ButtonIconContainer}`]: {
          color: "$secondary",
        },
      },
    },
    {
      iconColor: "variant",
      variant: "light",
      css: {
        [`${ButtonIconContainer}`]: {
          color: "$white",
        },
      },
    },
    {
      iconColor: "variant",
      variant: "darkGreen",
      css: {
        [`${ButtonIconContainer}`]: {
          color: "$darkGreen",
        },
      },
    },
    {
      variant: "secondary",
      light: true,
      css: {
        backgroundColor: "$lightestGreen",
        color: "$darkGreen",
        "&:hover, &:focus": {
          backgroundColor: "$white",
          color: "$primary",
        },
      },
    },
    {
      variant: "primary",
      outline: true,
      css: {
        border: "1px solid $primary",
        color: "$primary",

        "&:hover, &:focus": {
          backgroundColor: "$primary",
          color: "$white",
          [`${ButtonIconContainer}`]: {
            color: "$white",
          },
        },
      },
    },
    {
      variant: "secondary",
      outline: true,
      css: {
        border: "1px solid $secondary",
        color: "$secondary",
        backgroundColor: "$white",

        "&:hover, &:focus": {
          backgroundColor: "$secondary",
          color: "$white",
          [`${ButtonIconContainer}`]: {
            color: "$white",
          },
        },
      },
    },
    {
      variant: "light",
      outline: true,
      css: {
        border: "1px solid $secondary",
        backgroundColor: "$white",
      },
    },
    {
      variant: "transparent",
      css: {
        border: "none",
        backgroundColor: "transparent",
        boxShadow: "none",
      },
    },
  ],

  variants: {
    disabled: {
      true: {
        opacity: "0.5 !important",
        cursor: "pointer !important",
        pointerEvents: "none",
      },
    },
    noPadding: {
      true: {
        padding: 0,
      },
    },
    variant: {
      primary: {
        backgroundColor: "$primary",

        "&:hover, &:focus": {
          backgroundColor: "$white",
        },
      },
      secondary: {
        backgroundColor: "$secondary",
        "&:hover, &:focus": {
          color: "$darkGreen",
          backgroundColor: "$white",
        },
      },
      completed: {
        backgroundColor: "$mediumGreen",
        "&:hover, &:focus": {
          color: "$mediumGreen",
          backgroundColor: "$white",
        },
      },
      light: {
        color: "$secondary",
        backgroundColor: "$white",
      },
      lightInverse: {
        color: "$primary",
        backgroundColor: "$white",

        "&:hover, &:focus": {
          color: "$orangeLight",
        },
      },
      darkGreen: {
        backgroundColor: "$darkGreen",
        color: "$darkGreen",
        [`${SpinIconContainer}`]: {
          color: "$white",
        },
      },
      transparent: {
        backgroundColor: "transparent",
      },
    },

    light: {
      true: {},
    },

    outline: {
      true: {
        backgroundColor: "transparent",
      },
    },

    size: {
      xsmall: {
        minHeight: "1.125rem",
        minWidth: "1.125rem",
        padding: "0 0.3125rem",
        borderRadius: "5px",
        fontSize: "$bodyExtraSmall",
        lineHeight: "$bodyExtraSmall",

        [`& ${ButtonIconContainer}:not(:last-child)`]: {
          marginRight: "0.325em",
        },
        [`& ${ButtonLabel}`]: {},
      },
      small: {
        minHeight: "1.5rem",
        minWidth: "1.5rem",
        padding: "0 0.375rem",
        fontSize: "$bodySmall",
        lineHeight: "$bodySmall",

        [`& ${ButtonIconContainer}:not(:last-child)`]: {
          marginRight: "0.425em",
        },
        [`& ${ButtonLabel}`]: {},
      },
      regular: {
        height: "1.875rem",
        minHeight: "1.875rem",
        minWidth: "1.875rem",
        padding: "0 .5rem",
      },
      large: {
        minHeight: "2.5rem",
        minWidth: "2.5rem",
        padding: "0 $10",
      },
    },

    iconColor: {
      white: {},
      variant: {},
      primary: {
        [`${ButtonIconContainer}`]: {
          color: "$primary",
        },
      },
      secondary: {
        [`${ButtonIconContainer}`]: {
          color: "$secondary",
        },
      },
    },
  },
})

export const icons = {
  arrowLeft: ArrowLeftIcon,
  arrowRight: ArrowRightAssetsIcon,
  arrowRightSmallOrange: ArrowRightSmallOrangeIcon,
  checkmark: CheckmarkIcon,
  chevronRight: ChevronRightIcon,
  delete: CloseAssetsIcon,
  download: DownloadIcon,
  edit: EditIcon,
  externalLink: ExternalLinkIcon,
  filter: FilterIcon,
  plus: PlusIcon,
  plusThin: PlusThinIcon,
  reset: ResetIcon,
  replace: ReplaceIcon,
  tags: TagsIcon,
  trash: TrashIcon,
  upload: UploadIcon,
  archive: ArchiveIcon,
  unarchive: UnarchiveIcon,
  location: LocationIcon,
  notification: NotificationIcon,
}

export const Button = ({
  type = "button",
  size = "regular",
  icon,
  isLoading,
  children,
  ...rest
}: PropsWithChildren<ButtonProps>) => {
  const PickedIcon =
    typeof icon === "string"
      ? React.createElement(icons[icon], { size: iconSizes[size] })
      : icon

  return (
    <StyledButton type={type} size={size} {...rest} role="button">
      <ButtonContentContainer visible={!isLoading}>
        {PickedIcon && <ButtonIconContainer>{PickedIcon}</ButtonIconContainer>}
        {children && <ButtonLabel>{children}</ButtonLabel>}
      </ButtonContentContainer>

      {isLoading && (
        <SpinIconContainer>
          <SpinIcon />
        </SpinIconContainer>
      )}
    </StyledButton>
  )
}

Button.toString = () => "Button"
