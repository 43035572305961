import {
  UseQueryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import { toast } from "react-hot-toast"
import {
  ImplementedSportProduct,
  certificationProcessService,
} from "src/services"
import { V2CertificationProcessLinkIsp } from "src/utils/v2"

export function useLinkImplementedSportProduct(
  id: string,
  options?: UseQueryOptions,
) {
  const queryClient = useQueryClient()

  async function mutateFn(data: V2CertificationProcessLinkIsp) {
    const response = await certificationProcessService.linkImplementedSportProduct(id, data) // prettier-ignore
    await queryClient.refetchQueries(["certification-processes", id])
    return response.data
  }

  return useMutation(mutateFn, {
    onSuccess: (data: ImplementedSportProduct[]) => {
      toast.success(`Sportproducten toegevoegd`)

      if (options?.onSuccess) {
        options?.onSuccess(data)
      }

      queryClient.refetchQueries([
        "certification-process",
        id,
        "implemented-sport-products",
      ])
    },
  })
}
