import React, { PropsWithChildren, useState } from "react"
import { useDetectClickOutside } from "react-detect-click-outside"
import { ThreeDotsIcon } from "src/components/assets-icons"
import { styled } from "src/stitches.config"

interface Props {
  align?: "left" | "right"
}

const OverflowMenuWrapper = styled("div", {
  position: "relative",
  display: "inline-block",
})

const OverflowMenuToggleButton = styled("button", {
  display: "block",
  width: "1em",
  height: "1em",
  padding: "0",
  margin: "0",
  cursor: "pointer",
  border: "none",
  background: "none",

  svg: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
})

const OverflowMenuItems = styled("div", {
  position: "absolute",
  bottom: "0",
  minWidth: "300px",
  padding: "$5 0",

  variants: {
    align: {
      left: {
        left: "0",
        transform: "translate(0, 100%)",
      },
      right: {
        right: "0",
        transform: "translate(0, 100%)",
      },
    },
  },
})

const OverflowMenuItemsInner = styled("div", {
  padding: "$10",
  borderRadius: "6px",
  backgroundColor: "$white",
  boxShadow: "$default",
})

export function OverflowMenu({
  align = "left",
  children,
}: PropsWithChildren<Props>) {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useDetectClickOutside({ onTriggered: handleClickOutside })

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
    setIsOpen(!isOpen)
  }

  function handleClickOutside() {
    if (isOpen) {
      setIsOpen(false)
    }
  }

  return (
    <OverflowMenuWrapper>
      <OverflowMenuToggleButton onClick={handleClick} role="button">
        <ThreeDotsIcon width="1em" height="1em" />
      </OverflowMenuToggleButton>

      {isOpen && (
        <OverflowMenuItems align={align} ref={ref} data-cy="items-wrapper">
          <OverflowMenuItemsInner>{children}</OverflowMenuItemsInner>
        </OverflowMenuItems>
      )}
    </OverflowMenuWrapper>
  )
}
