import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from "@tanstack/react-query"
import { accommodationService } from "src/services"
import { AccommodationListItems } from "src/services/v2/accommodation"

export function useAccommodations(
  options?: UseQueryOptions,
): UseQueryResult<AccommodationListItems> {
  const queryFn = async () => {
    const data = await accommodationService.getAll()
    return data
  }

  return useQuery(["accommodations"], queryFn, {
    initialData: options?.initialData,
  })
}
