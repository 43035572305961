import { styled } from "src/stitches.config"

import { HorizontalFlex } from "../../flex"

export const NavBar = styled(HorizontalFlex, {
  height: "$80",
  maxWidth: "$pageContainer",
  margin: "0 auto",
  justifyContent: "space-between",
  alignItems: "center",
})
