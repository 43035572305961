import { PropsWithChildren, useEffect, useState } from "react"
import SlidingPanel from "react-sliding-side-panel"
import "react-sliding-side-panel/lib/index.css"
import { styled } from "src/stitches.config"

import { Logo } from "../logo"

const Header = styled("div", {
  height: "$80",
  paddingLeft: "4rem",
  backgroundColor: "$white",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
})

const LogoContainer = styled("div", {
  height: "$40",
  fontSize: "$sizes$40",

  svg: {
    verticalAlign: "top",
  },
})

const Container = styled("div", {
  backgroundColor: "$shadow",
  display: "flex",
  flexDirection: "column",
  height: "100%",
})

const ContentWrap = styled("div", {})

export interface SidePanelProps {
  isOpen?: boolean
  onClose?: () => void
}

export const SidePanel = ({
  isOpen = false,
  onClose,
  children,
  ...rest
}: PropsWithChildren<SidePanelProps>) => {
  const [openPanel, setOpenPanel] = useState(isOpen)

  useEffect(() => {
    setOpenPanel(isOpen)
  }, [isOpen])

  return (
    <SlidingPanel
      type={"left"}
      isOpen={openPanel}
      onClose={onClose}
      backdropClicked={() => setOpenPanel(false)}
      size={30}
      {...rest}
    >
      <Container>
        <Header>
          <LogoContainer>
            <Logo />
          </LogoContainer>
        </Header>
        <ContentWrap>{children}</ContentWrap>
      </Container>
    </SlidingPanel>
  )
}
