import { V2Api } from "../api.v2"
import { CertificationManage } from "./types"

export class CertificationService extends V2Api {
  public async manage(
    data: Omit<CertificationManage, "validForYears"> & {
      validForYears?: string
    },
  ) {
    return super.post(`/certification/manage`, { data })
  }

  public async returnCertificationProcess(cpId: string) {
    return super.post(`/return-certification-process/${cpId}`)
  }
}
