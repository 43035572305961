import { useMemo } from "react"
import { Dropdown, DropdownProps } from "src/components"
import { OrganizationGetAllFilters, useOrganizations } from "src/services"

interface Props {
  name: string
  enabled?: boolean
  isDisabled?: boolean
  placeholder?: string
  filters: OrganizationGetAllFilters
}

export function OrganizationDropdown({
  name,
  isDisabled = false,
  enabled = true,
  placeholder,
  filters,
}: Props) {
  const { data: organizations = [], isLoading } = useOrganizations(filters, {
    enabled,
  })

  const options = useMemo(() => {
    return organizations.map((organization) => ({
      value: organization.id,
      label: organization.name,
    }))
  }, [organizations])

  return (
    <Dropdown
      name={name}
      options={options}
      isDisabled={!enabled || isDisabled}
      isLoading={isLoading}
      placeholder={placeholder}
    />
  )
}
