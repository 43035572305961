import { IQuote } from "src/services"
import { styled } from "src/stitches.config"

import { H4, P } from "../typography"

const HomepageQuoteWrapper = styled("div", {
  position: "relative",
  backgroundColor: "$white",

  [H4.toString()]: {
    marginBottom: "$10",
  },

  "@mdDown": {
    padding: "$40 0 0",
  },

  "@mdUp": {
    padding: "$30 110px",
  },
})

const QuoteLeft = styled("div", {
  fontWeight: "$bold",
  fontSize: "7rem",
  lineHeight: "0.55",
  color: "$blue",

  "@mdUp": {
    position: "absolute",
    top: "$40",
    left: "$30",
  },
})

const QuoteRight = styled("div", {
  position: "absolute",
  right: "$30",
  bottom: "$70",
  fontWeight: "$bold",
  fontSize: "7rem",
  lineHeight: "0",
  color: "$blue",

  "@mdDown": {
    display: "none",
  },
})

export const HomepageQuote = (props: IQuote) => (
  <HomepageQuoteWrapper>
    <QuoteLeft>&ldquo;</QuoteLeft>
    <QuoteRight>&rdquo;</QuoteRight>

    <H4 as="p" data-cy="content">
      {props.content.trim()}
    </H4>

    <P size="small" noMargin data-cy="title">
      {props?.title}
    </P>
  </HomepageQuoteWrapper>
)
