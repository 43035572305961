import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const TextIcon = ({
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
    version="1.1"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <g
        strokeLinecap="round"
        transform="translate(1.500000, 11.000000)"
        stroke="#919291"
        strokeWidth="1.19112595"
      >
        <line x1="7.2" y1="0.571428571" x2="21" y2="0.571428571"></line>
        <line x1="0" y1="4" x2="21" y2="4"></line>
        <line x1="0" y1="7.42857143" x2="21" y2="7.42857143"></line>
      </g>
      <polygon
        fill="#E1001A"
        fillRule="nonzero"
        points="4.56267806 12 4.56267806 5.11458333 6.5 5.11458333 6.5 4 1.5 4 1.5 5.11458333 3.44207028 5.11458333 3.44207028 12"
      ></polygon>
    </g>
  </svg>
)
