import { PropsWithChildren } from "react"
import { FieldValues, FormProvider, UseFormReturn } from "react-hook-form"

interface FormProps {
  form: UseFormReturn<FieldValues>
  onSubmit: any
}

export const Form = ({
  children,
  onSubmit,
  form,
}: PropsWithChildren<FormProps>) => (
  <FormProvider {...form}>
    <form onSubmit={form.handleSubmit(onSubmit)}>{children}</form>
  </FormProvider>
)
