import Link from "next/link"
import React, { ReactElement } from "react"
import { IParentLink } from "src/services"
import { styled } from "src/stitches.config"

import { ArrowLeftIcon } from "../assets-icons"

const BackLinkWrapper = styled("div", {
  marginBottom: "$40",

  "@mdDown": {
    marginBottom: "$20",
  },

  a: {
    display: "inline-flex",
    alignItems: "center",

    span: {
      marginLeft: "$20",
    },
  },

  "&:hover, &:focus": {
    svg: {
      opacity: 0.75,
    },
  },

  variants: {
    light: {
      true: {
        color: "$white",

        "&:hover, &:focus": {
          a: {
            color: "$white",
          },
        },
      },
      false: {
        color: "$blue",
      },
    },
  },
})

const StyledIcon = styled("div", {
  borderRadius: "6px",
  boxShadow: "0 2px 8px 0 rgba(74, 74, 74, 0.1)",
  display: "inline-flex",
  justifyContent: "center",
  backgroundColor: "white",
  alignItems: "center",
  padding: "4px",
  cursor: "pointer",
  height: "$40",
  width: "$40",

  "@mdDown": {
    height: "$30",
    width: "$30",
  },
})

export const BackLink = (props: IParentLink): ReactElement => (
  <BackLinkWrapper light={props.light || false} data-cy="parent-link">
    <Link href={props.route}>
      <StyledIcon>
        <ArrowLeftIcon
          width="1.5em"
          height="1.5em"
          color="var(--colors-orange)"
        />
      </StyledIcon>
      <span data-cy="parent-link-title">{props.title}</span>
    </Link>
  </BackLinkWrapper>
)
