import { IAssetsIconProps } from "@types"
import { iconSizes } from "src/constants"

export const DrawingWithPencilColoredIcon = ({
  size = iconSizes.regular,
  width,
  height,
}: IAssetsIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || size}
    height={height || size}
    viewBox="0 0 24 24"
  >
    <g>
      <g opacity="1">
        <g opacity="1">
          <path
            stroke="#94a286"
            strokeWidth="1.0119"
            d="M2+18.6914L4.55773+16.5914C4.90108+16.3095+5.38816+16.2845+5.75856+16.5298L8.43571+18.3027L8.43571+18.3027L12.1543+14.3229C12.4637+13.9918+12.9554+13.9067+13.358+14.1146L16.7917+15.8876L16.7917+15.8876"
            fill="none"
            strokeLinecap="round"
            opacity="1"
            strokeLinejoin="round"
          />
          <path
            stroke="#31493c"
            strokeWidth="1.0119"
            d="M2+9.5C2+8.39543+2.83947+7.5+3.875+7.5L15.125+7.5C16.1605+7.5+17+8.39543+17+9.5L17+19.5C17+20.6046+16.1605+21.5+15.125+21.5L3.875+21.5C2.83947+21.5+2+20.6046+2+19.5L2+9.5Z"
            fill="none"
            strokeLinecap="butt"
            opacity="1"
            strokeLinejoin="miter"
          />
          <path
            d="M6.43452+11.5411C6.43452+10.9371+5.96815+10.4474+5.39286+10.4474C4.81756+10.4474+4.35119+10.9371+4.35119+11.5411C4.35119+12.1452+4.81756+12.6349+5.39286+12.6349C5.96815+12.6349+6.43452+12.1452+6.43452+11.5411Z"
            opacity="1"
            fill="#94a286"
          />
        </g>
        <path
          d="M20.9793+3.75L21.0508+3.75125L21.0997+3.75561L21.1475+3.7627L21.1919+3.77183L21.2428+3.78542L21.2833+3.79878L21.3204+3.81321L21.3749+3.83795L21.3989+3.85059C21.4686+3.88818+21.5342+3.9366+21.5931+3.99565L22.7544+5.15677C22.9845+5.3875+23.0532+5.71873+22.9593+6.00872L22.9491+6.03849C22.9152+6.13096+22.8645+6.21865+22.797+6.2968L22.7544+6.34252L21.464+7.63265L20.0448+9.05138L18.7345+10.3611L13.599+15.4961L11.4923+15.7471C11.3584+15.7625+11.2248+15.716+11.1294+15.6209C11.0341+15.5257+10.9875+15.3924+11.0029+15.2588L11.2348+13.1659L20.4068+3.99565L20.444+3.96057L20.4882+3.92397L20.5391+3.8879L20.5776+3.86377L20.6204+3.84043L20.6726+3.81628L20.7175+3.79875L20.7383+3.79152L20.7852+3.77762L20.8326+3.76656L20.885+3.75764L20.9286+3.75278L20.9793+3.75ZM19.1165+5.28578L17.6972+6.70452L20.0448+9.05138L21.464+7.63265L19.1165+5.28578Z"
          opacity="1"
          fill="#31493c"
        />
      </g>
    </g>
  </svg>
)
