import { styled } from "src/stitches.config"

import { HDefault } from "./h-default"

export const H2 = styled("h2", {
  ...HDefault,

  fontSize: "32px",
  lineHeight: "1.25",

  "@mdDown": {
    fontSize: "24px",
  },
})
