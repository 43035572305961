import { Table } from "@tanstack/react-table"
import { Button } from "src/components/button"
import { P } from "src/components/typography"
import { styled } from "src/stitches.config"

interface Props {
  table: Table<any>
}

const PaginationWrapper = styled("div", {
  paddingTop: "$20",
})

const PaginationButtons = styled("div", {
  display: "flex",
  justifyContent: "center",
  gap: "$10",
  padding: "$10",
  margin: "0 auto",
})

export function Pagination({ table }: Props) {
  return (
    <PaginationWrapper>
      <PaginationButtons>
        <Button
          variant="secondary"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {"<<"}
        </Button>
        <Button
          variant="secondary"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {"<"}
        </Button>
        <Button
          variant="secondary"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {">"}
        </Button>
        <Button
          variant="secondary"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {">>"}
        </Button>
      </PaginationButtons>

      <P textAlign="center" light>
        Pagina&nbsp;
        {table.getState().pagination.pageIndex + 1} van {table.getPageCount()}
      </P>
    </PaginationWrapper>
  )
}
