import React from "react"
import { IInfrastructureRuleAttachment } from "src/services"
import { styled } from "src/stitches.config"

import { Markdown } from "../../markdown"
import { H4 } from "../../typography"

const RuleAttachmentTableWrapper = styled("div", {
  marginTop: "$20",

  h4: {
    width: "calc(100% + $sizes$30)",
    margin: "0",
    padding: "$40 $20 $10",
    borderRadius: "4px 4px 0 0",
    backgroundColor: "$background",

    "@lgUp": {
      width: "calc(180%)",
    },

    "@xlUp": {
      width: "calc(186%)",
    },
  },

  figure: {
    margin: "0",

    div: {
      width: "calc(100% + $sizes$30)",
      padding: "0 $20 $40",
      borderRadius: "0 0 4px 4px",
      backgroundColor: "$background",

      "@lgUp": {
        width: "calc(180%)",
      },

      "@xlUp": {
        width: "calc(186%)",
      },
    },

    figcaption: {
      paddingTop: "$10",
      fontFamily: "$grotesk",
      fontSize: "$bodySmall",
      lineHeight: "1.25",
    },
  },
})

export const RuleAttachmentTable = (props: IInfrastructureRuleAttachment) => (
  <RuleAttachmentTableWrapper data-cy="table-attachment">
    <H4>{props.title}</H4>

    <figure>
      <div>
        <Markdown>{props.content}</Markdown>
      </div>

      {props.caption && <figcaption>{props.caption}</figcaption>}
    </figure>
  </RuleAttachmentTableWrapper>
)
