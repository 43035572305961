import {
  IInfrastructureSection,
  IInfrastructureSubsection,
  IKnowledgeBaseSection,
  IKnowledgeBaseSubSection,
} from "src/services"

import isMandatoryRule from "./is-mandatory-rule"

// TODO Yulia: type for section
export default function hasMandatoryRules(
  section:
    | IInfrastructureSection
    | IInfrastructureSubsection
    | IKnowledgeBaseSection
    | IKnowledgeBaseSubSection,
): boolean {
  const rules =
    "knowledgeBaseRules" in section ? section.knowledgeBaseRules : section.rules
  if (rules.some(isMandatoryRule)) {
    return true
  }

  // For top level sections we should also check the subsections
  if ("knowledgeBaseSections" in section || "sections" in section) {
    const subsections =
      "sections" in section ? section.sections : section.knowledgeBaseSections
    for (const subsection of subsections) {
      if (hasMandatoryRules(subsection)) {
        return true
      }
    }
  }

  return false
}
