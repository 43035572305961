import { useMutation, useQueryClient } from "@tanstack/react-query"
import { toast } from "react-hot-toast"
import { certificationProcessService } from "src/services"

export function useDeleteCertificationProcess(id: string) {
  const queryClient = useQueryClient()

  function mutateFn() {
    return certificationProcessService.delete(id)
  }

  return useMutation(mutateFn, {
    onSuccess: () => {
      toast.success("Certificeringsproject verwijderd")

      queryClient.refetchQueries([
        "accommodation",
        id,
        "certification-processes",
      ])
    },
  })
}
