import { useRouter } from "next/router"
import React, { useCallback } from "react"
import api from "src/services/api"

import { PageHeaderInactive } from "../page-header-inactive"

interface Props {
  code: string
  hideRestore?: boolean
  onUnarchiveFail?: () => void
}

export const InfrastructurePageHeaderInactive = ({
  code,
  hideRestore,
  onUnarchiveFail,
}: Props) => {
  const router = useRouter()

  const onClickHandler = useCallback(() => {
    return api.admin.updateDocumentStatusById(code, { active: true })
  }, [])

  const onSuccessHandler = useCallback(() => {
    router.push(`/kwaliteitszorgsysteem/voorschriften/${code}`)
  }, [])

  return (
    <PageHeaderInactive
      messageTitle={code}
      onClick={onClickHandler}
      onSuccess={onSuccessHandler}
      onFail={onUnarchiveFail}
      hideRestore={hideRestore}
    />
  )
}
