import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useWindowScroll } from "react-use"
import { keyframes, styled } from "src/stitches.config"

import { ArrowUpTailedIcon } from "../assets-icons"
import { Message } from "../message"

const Wrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  zIndex: "2",
  color: "$blue",
  position: "fixed",
  bottom: "2.5rem",
  left: "95%",
  width: "40px",
  cursor: "pointer",

  "@mdDown": {
    left: "93%",
    visibility: "hidden",
  },

  "@smDown": {
    left: "88%",
  },

  variants: {
    isVisible: {
      true: {
        "@mdDown": {
          visibility: "visible",
        },
      },
    },
  },
})

const BounceAnimation = keyframes({
  "50%": {
    transform: "translateY(-4px)",
  },
})

const Button = styled("div", {
  width: "40px",
  height: "40px",
  border: "1px solid $blue",
  backgroundColor: "white",
  boxShadow: "0 2px rgba(74,74,74,0.1)",
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "& > *": {
    animation: `${BounceAnimation} 1000ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95)`,
    width: "18px",
    height: "18px",
  },
})

const Text = styled("p", {
  marginTop: "10px",
  textAlign: "center",
  fontSize: "12px",
  lineHeight: "15px",
})

// TODO Yulia: use only in the wrapper of all pages, not on each page

/** Button to scroll to the top of the content
 */
export const TopScrollButton = () => {
  const { formatMessage } = useIntl()
  const [scrollDirection, setScrollDirection] = useState("down")
  const { y } = useWindowScroll()

  const onClickHandler = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.pageYOffset
      setScrollDirection(scrollY > y ? "down" : "up")
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [y])

  if (y < 400) return null

  return (
    <Wrapper
      onClick={onClickHandler}
      isVisible={scrollDirection === "up"}
      data-cy="scroll-to-top"
    >
      <Button aria-label={formatMessage({ id: "scrollToTopButton.label" })}>
        <ArrowUpTailedIcon />
      </Button>
      <Text>
        <Message id="scrollToTopButton.label" />
      </Text>
    </Wrapper>
  )
}
