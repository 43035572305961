import type * as Stitches from "@stitches/react"
import Link from "next/link"
import { useIntl } from "react-intl"
import { styled } from "src/stitches.config"

import { ArrowRightSmallOrangeIcon } from "../assets-icons"
import { Button } from "../button"
import { LinkContainer } from "../link-container"
import { Message } from "../message"
import { H4, P } from "../typography"

const RowContainer = styled("a", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 85px 0 95px",

  "&:hover, &:focus": {
    cursor: "pointer",
    textDecoration: "none",

    [`${LinkContainer} > ${P}`]: {
      textDecoration: "underline",
    },
  },

  variants: {
    variant: {
      current: {
        height: "86px",
        background: "$white",

        [`${H4}`]: {
          color: "$blue",
        },
        [`${LinkContainer} > ${P}`]: {
          color: "$blue",
        },
      },
      old: {
        height: "70px",
        background: "linear-gradient(90deg, $redCherry 0%, $red 100%)",

        [`${H4}`]: {
          color: "$white",
        },
        [`${LinkContainer} > ${P}`]: {
          color: "$white",
        },
      },
    },
  },

  "@lgDown": {
    padding: "0 30px 0 80px",
  },

  "@mdDown": {
    padding: "0 15px 0 15px",
    height: "64px",
  },
})

const DateContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
})

export interface IDocumentVersionRowProps {
  date: string
  url: string
  variant: "old" | "current"
  css?: Stitches.CSS
}

export const DocumentVersionRow = ({
  date,
  url,
  variant,
  ...rest
}: IDocumentVersionRowProps) => {
  const { formatMessage } = useIntl()
  const buttonLabel =
    variant === "current"
      ? formatMessage({
          id: "infrastructureDetails.archive.olderVersions.viewCurrent",
        })
      : formatMessage({
          id: "infrastructureDetails.archive.olderVersions.viewOlder",
        })

  return (
    <Link href={url} passHref legacyBehavior>
      <RowContainer variant={variant} {...rest}>
        {variant === "old" ? (
          <H4>{date}</H4>
        ) : (
          <DateContainer>
            <H4>
              <Message id="infrastructureDetails.archive.currentVersion.title" />
            </H4>
            <P noMargin size="extraSmall" css={{ color: "black" }}>
              {date}
            </P>
          </DateContainer>
        )}

        <LinkContainer>
          <P css={{ margin: 0 }}>{buttonLabel}</P>

          <Button
            size="small"
            variant="light"
            icon={<ArrowRightSmallOrangeIcon />}
            aria-label={buttonLabel}
          />
        </LinkContainer>
      </RowContainer>
    </Link>
  )
}
