import { styled } from "src/stitches.config"

export const NonMobileNavigation = styled("div", {
  display: "flex",
  alignItems: "center",
  alignSelf: "flex-end",

  [`& > *:not(:first-child)`]: {
    marginLeft: "$40",
  },

  "@mdDown": {
    display: "none",
  },
})
