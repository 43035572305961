import { IIcon } from "@types"
import { ReactElement, ReactSVG } from "react"

export const ArrowTailedIcon = ({
  fill = "#EB5E00",
  height = "80px",
  width = "80px",
}: IIcon): ReactElement<ReactSVG> => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 80 80"
    version="1.1"
  >
    <title>Arrow_right</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M41.9073736,23.4693355 C44.1645373,25.7264992 46.0240768,27.5860387 47.485992,29.0479539 C48.9479072,30.5098691 51.14078,32.7027419 54.0646104,35.6265723 L15.3750684,35.6265723 C12.9600306,35.6265723 11,37.5866029 11,40.0016407 C11,42.4166784 12.9600306,44.376709 15.3750684,44.376709 L54.0646104,44.376709 L41.9073736,56.5339458 C40.1967218,58.2445976 40.1967218,61.0140158 41.9073736,62.7202925 C43.6180253,64.4265692 46.3874436,64.4309442 48.0937202,62.7202925 L67.7191987,43.094814 C68.121705,42.6923077 68.4454601,42.2110502 68.6685886,41.6772918 C69.1104705,40.6054001 69.1104705,39.3978812 68.6685886,38.3259895 C68.4542102,37.8097314 68.1392053,37.3284739 67.7191987,36.9084673 L48.0937202,17.2829888 C46.3830685,15.5723371 43.6136502,15.5723371 41.9073736,17.2829888 C40.2010969,18.9936405 40.1967218,21.7630588 41.9073736,23.4693355 Z"
        fill={fill}
      />
    </g>
  </svg>
)
