import React from "react"
import { styled } from "src/stitches.config"

import { FilterReset, FilterResetProps } from "../filter-reset"
import { NoResultsGlyph } from "../glyphs"
import { Message } from "../message"
import { H2 } from "../typography"

interface Props {
  onChange?: FilterResetProps["onChange"]
}

export const NoResults = ({ onChange }: Props) => (
  <NoResultsWrap>
    <GlyphWrap>
      <NoResultsGlyph />
    </GlyphWrap>
    <TextWrap>
      <H2>
        <Message id="filters.page.noResults" />
      </H2>
      {onChange && <FilterReset onChange={onChange} noIcon />}
    </TextWrap>
  </NoResultsWrap>
)

const NoResultsWrap = styled("div", {
  display: "flex",
  margin: "0 auto",
  marginTop: 84,
  maxWidth: "550px",

  "& > *:not(:last-child)": {
    marginRight: "$30",
  },
})

const GlyphWrap = styled("span", {
  width: 160,
})

const TextWrap = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",

  [`${H2}`]: {
    margin: 0,
  },
})
