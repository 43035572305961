import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { MappedSport, Status, useManageCertification } from "src/services"
import { v2CertificationSchema } from "src/services/v2/certification/types"
import { styled } from "src/stitches.config"
import formatDate from "src/utils/format-date"

import { Button } from "../button"
import { Form, FormInput, FormItemLabel } from "../forms"
import { Loader } from "../loader"
import { Message } from "../message"
import { PopoverConfirmation } from "../pop-over-confirmation"
import { P } from "../typography"

interface Props {
  sport: MappedSport
  accommodationName: string
  implementedSportProductId: string
  accommodationId: string
  certificationProcessId?: string
  validForYears?: number
  formDisabled?: boolean
}

const CertificationItem = styled("div", {
  display: "flex",
  marginBottom: "$10",
})

const CertificationItemDiscipline = styled("div", {
  flexGrow: 2,
})

const CertificationItemDisciplineName = styled("div", {
  fontWeight: "$bold",
})

const CertificationSportClubs = styled("div", {
  fontStyle: "italic",
})

const CertificationItemState = styled("div", {
  display: "flex",
  margin: "0 0 auto 0",
})
const DateWrapper = styled("div", {
  height: "100%",
  fontSize: "$12",
  marginRight: "$10",
  marginLeft: "$10",
  fontWeight: "$light",
  marginTop: "0.1em",
  minWidth: "85px",
})

const StateToggleButtonWrapper = styled("div", {
  overflow: "hidden",
  borderRadius: "4px",
  boxShadow: "$default",
  display: "flex",
})

const StateToggleButton = styled("button", {
  padding: "$5 $10",
  fontSize: "$body",
  color: "$darkGreen",
  border: "none",
  backgroundColor: "$white",

  variants: {
    variant: {
      approve: {
        borderRight: "1px solid $colors$grayLight",
      },
      reject: {},
    },
    selected: {
      true: {},
    },
  },
  compoundVariants: [
    {
      variant: "approve",
      selected: true,
      css: {
        color: "$white",
        backgroundColor: "$darkGreen",
      },
    },
    {
      variant: "reject",
      selected: true,
      css: {
        color: "$white",
        backgroundColor: "$orange",
      },
    },
  ],
})

const NoteInputWrapper = styled("div", {
  paddingTop: "$10",
  textarea: {
    backgroundColor: "$white",
  },
})

const StatusWrapper = styled("span", {
  color: "$green",
  backgroundColor: "$white",
  padding: "0 6px",
  borderRadius: "4px",
  variants: {
    variant: {
      approved: {
        border: "1px solid $lightGreen",
      },
      rejected: {
        border: "1px solid $orangeLight",
      },
    },
  },
})

const Footer = styled("div", {
  marginTop: "$20",
  marginRight: "-$20",
  marginLeft: "-$20",
  padding: "$20 $20 0",
  borderTop: "1px solid $white",
})

enum State {
  LOADING = "loading",
  DONE = "done",
  READY = "ready",
}

/** Form for certifying accommodation
 * @param {MappedSport} sport - sport info
 * @param {string} accommodationName
 * @param {string} implementedSportProductId
 * @param {string} accommodationId
 */
export const ReviewCertificationsForm = ({
  sport,
  accommodationName,
  implementedSportProductId,
  accommodationId,
  certificationProcessId = "",
  validForYears,
  formDisabled,
}: Props) => {
  const { formatMessage } = useIntl()
  const state = useMemo(() => {
    return sport.disciplineCertificates[0].state !== Status.CREATED
      ? State.DONE
      : State.READY
  }, [sport.disciplineCertificates[0].state])

  const { mutateAsync: onSubmit, isLoading } = useManageCertification(
    certificationProcessId,
  )

  const defaultValues = {
    sports: sport.disciplineCertificates.map((certificate) => ({
      disciplineCertificateId: certificate.id,
      state: certificate.state,
    })),
    validForYears,
    note: sport.disciplineCertificates[0].certificate?.note,
    accommodationName:
      sport.disciplineCertificates[0].certificate?.accommodationName ||
      accommodationName,
    implementedSportProductId,
    accommodationId,
    certificationProcessId,
  }

  const form: any = useForm({
    resolver: zodResolver(v2CertificationSchema),
    reValidateMode: "onChange",
    defaultValues,
  })

  useEffect(() => {
    if (formDisabled) {
      form.reset()
    }
  }, [formDisabled])

  const values = form.watch()
  const disabled = formDisabled || !form.formState.isValid

  return (
    <Form onSubmit={onSubmit} form={form}>
      <input
        type="hidden"
        name="implementedSportProductId"
        value={implementedSportProductId}
        data-cy="certification-accommodation-name-1"
      />
      {sport.disciplineCertificates.map((certification, index) => {
        const isApproved = values.sports[index].state === Status.APPROVED
        const isRejected = values.sports[index].state === Status.REJECTED

        function handleApproveClick() {
          if (state === State.DONE) {
            return
          }
          const newValue = isApproved ? "PENDING" : "APPROVED"
          form.setValue(`sports.${index}.state`, newValue, {
            shouldValidate: true,
            shouldDirty: true,
          })
        }

        function handleRejectClick() {
          if (state === State.DONE) {
            return
          }
          const newValue = isRejected ? "PENDING" : "REJECTED"
          form.setValue(`sports.${index}.state`, newValue, {
            shouldValidate: true,
            shouldDirty: true,
          })
        }

        return (
          <CertificationItem key={certification.id}>
            <CertificationItemDiscipline>
              <CertificationItemDisciplineName data-cy="certification-discipline">
                {certification.discipline.name}
              </CertificationItemDisciplineName>
              <CertificationSportClubs data-cy="certification-sportclub">
                {certification.sportClubs
                  .map((sportClub) => sportClub.name)
                  .join(", ")}
              </CertificationSportClubs>
            </CertificationItemDiscipline>

            <CertificationItemState data-cy="state">
              <input
                type="hidden"
                {...form.register(`sports.${index}.disciplineCertificateId`)}
              />
              {state === State.DONE && (
                <DateWrapper data-cy="certification-date">
                  {formatDate(
                    certification?.certificate?.createdAt || new Date(),
                    "dd-MM-yyyy",
                  )}
                </DateWrapper>
              )}

              {state === State.DONE ? (
                isApproved ? (
                  <StatusWrapper
                    data-cy="certification-approved"
                    variant="approved"
                  >
                    <Message id="reviewCertificationForm.approve.label" />
                  </StatusWrapper>
                ) : (
                  <StatusWrapper
                    data-cy="certification-rejected"
                    variant="rejected"
                  >
                    <Message id="reviewCertificationForm.reject.label" />
                  </StatusWrapper>
                )
              ) : (
                <StateToggleButtonWrapper>
                  <StateToggleButton
                    type="button"
                    variant="approve"
                    selected={isApproved}
                    onClick={handleApproveClick}
                    data-cy="certification-approve-button"
                    disabled={formDisabled}
                  >
                    <Message id="reviewCertificationForm.approve.label" />
                  </StateToggleButton>
                  <StateToggleButton
                    type="button"
                    variant="reject"
                    selected={isRejected}
                    onClick={handleRejectClick}
                    data-cy="certification-reject-button"
                    disabled={formDisabled}
                  >
                    <Message id="reviewCertificationForm.reject.label" />
                  </StateToggleButton>
                </StateToggleButtonWrapper>
              )}
            </CertificationItemState>
          </CertificationItem>
        )
      })}
      <NoteInputWrapper>
        <FormItemLabel>Accommodatienaam voor certificaat</FormItemLabel>
        <FormInput
          isDisabled={formDisabled || state === State.DONE}
          displayAs={P}
          name="accommodationName"
          data-cy="certification-accommodation-name-2"
        />
      </NoteInputWrapper>
      <NoteInputWrapper>
        <FormItemLabel>Opmerking</FormItemLabel>
        <FormInput
          isDisabled={formDisabled || state === State.DONE}
          name="note"
          displayAs={P}
          italic
          maxLength={255}
          showMaxLengthCounter={!!values?.note?.length && state !== State.DONE}
          data-cy="certification-note"
        />
      </NoteInputWrapper>
      <NoteInputWrapper>
        <FormItemLabel>Aantal jaren geldig</FormItemLabel>
        <FormInput
          displayAs={P}
          isDisabled={formDisabled || state === State.DONE}
          placeholder="Kies aantal jaren"
          name="validForYears"
          type="number"
        />
      </NoteInputWrapper>
      {state !== State.DONE && (
        <Footer>
          {isLoading ? (
            <Loader css={{ margin: "20px auto" }} />
          ) : (
            <PopoverConfirmation
              message={formatMessage({
                id: "reviewCertificationForm.confirmation.popover.message",
              })}
              onConfirm={form.handleSubmit(onSubmit)}
              confirmIcon="checkmark"
              confirmButtonLabel={formatMessage({
                id: "reviewCertificationForm.confirm.label",
              })}
              confirmButtonVariant="secondary"
              confirmIconColor="white"
              disabled={disabled}
            >
              <Button
                icon={"checkmark"}
                variant="secondary"
                disabled={disabled}
                as="div"
                data-cy="confirm-certification-button"
              >
                <Message id="reviewCertificationForm.confirm.label" />
              </Button>
            </PopoverConfirmation>
          )}
        </Footer>
      )}
    </Form>
  )
}
