import { AxiosResponse } from "axios"
import {
  V2CreateInspectionReport,
  V2UpdateInspectionReport,
} from "src/utils/v2/form-schemas/create-inspection-report"

import { V2Api } from "../api.v2"
import { InspectionReport } from "./types"

export class InspectionReportService extends V2Api {
  public async getReport(
    cpId: string,
    ispId: string,
  ): Promise<AxiosResponse<InspectionReport>> {
    return super.get(
      `/certification-process/${cpId}/implemented-sport-product/${ispId}/inspection-report`,
    )
  }

  public async create(
    cpId: string,
    ispId: string,
    data: Partial<V2CreateInspectionReport>,
  ): Promise<InspectionReport> {
    return super.post(
      `/certification-process/${cpId}/implemented-sport-product/${ispId}/inspection-report`,
      { data },
    )
  }

  public async update(
    cpId: string,
    ispId: string,
    data: Partial<V2UpdateInspectionReport>,
  ): Promise<InspectionReport> {
    return super.put(
      `/certification-process/${cpId}/implemented-sport-product/${ispId}/inspection-report`,
      { data },
    )
  }

  public async delete(id: string): Promise<AxiosResponse<InspectionReport>> {
    return super.delete(`/inspection-report/${id}`)
  }
}
