import Link from "next/link"
import { ReactElement } from "react-markdown"
import { INewsItem } from "src/services"
import { styled } from "src/stitches.config"

import { Button } from "../button"
import { Grid } from "../grid"
import { GridItem } from "../grid-item"
import { LinkContainer } from "../link-container"
import { NewsItemGridCard } from "../news-item-grid-card"
import { PageWidthContainer } from "../page-width-container"
import { H2, P } from "../typography"

interface Props {
  newsItems: INewsItem[]
  title: string | ReactElement
  subtitle: string | ReactElement
  linkText: string | ReactElement
  linkHref: string
}

const StyledLinkContainer = styled(LinkContainer, {
  justifyContent: "flex-start",

  "&:hover": {
    cursor: "pointer",
  },

  [`${P}`]: {
    color: "$secondary",
  },

  [`${Button} svg`]: {
    color: "$orange",
  },
})

const Content = styled("div", {
  margin: "0 -$40",
  padding: "$20 $40",
  backgroundColor: "$white",

  "@mdUp": {
    margin: "0 -$40",
    padding: "$40 $40",
  },

  "@lgUp": {
    margin: "0 -$40",
    padding: "$80 $40",
  },
})

const GridItemNewsHeader = styled(GridItem, {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
})

const NewsItemGridCardWrapper = styled("div", {
  "@mdDown": {
    marginBottom: "$20",
  },
})

export const NewsItems = ({
  newsItems,
  title,
  subtitle,
  linkText,
  linkHref,
}: Props) => (
  <PageWidthContainer>
    <Content data-cy="page-content">
      <Grid>
        <GridItemNewsHeader col={[1, 5]} medium={[1, 4]} small={[1, 12]}>
          <H2>{title}</H2>
          <P>{subtitle}</P>

          <Link href={linkHref} legacyBehavior>
            <StyledLinkContainer data-cy="agenda-page-link">
              <P css={{ margin: 0 }}>{linkText}</P>
              <Button
                size="regular"
                variant="light"
                icon="arrowRight"
                aria-label={linkText}
              />
            </StyledLinkContainer>
          </Link>
        </GridItemNewsHeader>

        {newsItems.map((newsItem, index) => {
          const len = newsItems.length
          const colStart = len > 1 ? 7 : 10
          const col = colStart + index * 3

          const mdColStart = len > 1 ? 5 : 9
          const mdCol = mdColStart + index * 4

          return (
            <GridItem
              col={[col, 3]}
              medium={[mdCol, 4]}
              small={[1, 12]}
              key={newsItem.id}
            >
              <NewsItemGridCardWrapper>
                <NewsItemGridCard newsItem={newsItem} />
              </NewsItemGridCardWrapper>
            </GridItem>
          )
        })}
      </Grid>
    </Content>
  </PageWidthContainer>
)
