import React from "react"
import { iconSizes } from "src/constants"
import { styled } from "src/stitches.config"

import {
  DrawingVariantDepthIcon,
  DrawingVariantFlatIcon,
} from "../assets-icons"
import { Button } from "../button"
import { IconShadower } from "../icon-shadower"
import { Message } from "../message"
import { H2 } from "../typography"

interface Props {
  title?: string
  onCancelClick: () => void
  pdfUrl: string
  cadUrl: string
}

const HeaderWrapper = styled("div", {
  display: "flex",
  flexWrap: "wrap",
  width: "100%",
  backgroundColor: "$grayLightest",
  padding: "$40",
  justifyContent: "space-between",
  marginBottom: "$40",

  "@smDown": {
    padding: "$20 15px",
  },
})

const ModalClose = styled("a", {
  order: 1,
  display: "flex",
  alignItems: "center",
  color: "$blue",
  cursor: "pointer",

  span: {
    paddingRight: "10px",
  },
  "@smDown": {
    order: 0,
    width: "100%",
    justifyContent: "flex-end",
  },
})

const Title = styled(H2, {
  order: 0,
  color: "$blue",
  paddingRight: "20px",
  "@smDown": {
    order: 1,
  },
})

const DrawingButtonWrapper = styled("div", {
  order: 2,
  display: "flex",
  alignItems: "left",
  width: "100%",
  marginTop: "$10",

  "& > a:first-child": {
    borderRight: "1px solid $black",
    marginRight: "$20",
  },
})

const DrawingButton = styled("a", {
  flex: "0 1 auto",
  display: "flex",
  alignItems: "center",
  paddingRight: "20px",
  color: "$blue",

  Button: {
    marginRight: "$10",
  },
})

export const DrawingModalTitle = ({
  title,
  onCancelClick,
  pdfUrl,
  cadUrl,
}: Props) => (
  <HeaderWrapper>
    <Title as="p">{title}</Title>
    <ModalClose onClick={onCancelClick}>
      <span data-cy="modal-close-title">
        <Message id="drawingModal.closeButton.label" />
      </span>
      <IconShadower name="close" width="1.5em" height="1.5em" />
    </ModalClose>
    <DrawingButtonWrapper>
      <DrawingButton href={pdfUrl} target="_blank">
        <Button
          icon={<DrawingVariantFlatIcon size={iconSizes.large} />}
          type="button"
          variant="lightInverse"
          noPadding
        />
        Download PDF
      </DrawingButton>
      <DrawingButton href={cadUrl}>
        <Button
          icon={<DrawingVariantDepthIcon size={iconSizes.large} />}
          type="button"
          variant="lightInverse"
          noPadding
        />
        Download CAD
      </DrawingButton>
    </DrawingButtonWrapper>
  </HeaderWrapper>
)
