import { IDropdownOption } from "@types"
import React, { useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useAdminContext } from "src/context/admin-context"
import useKVKSearch from "src/hooks/useKVKSearch"
import { styled } from "src/stitches.config"
import alphabeticalSort from "src/utils/alphabetical-sort"
import { filters, transformers } from "src/utils/mappers"

import { WrappedDropdownSelect } from "../../dropdown-select"
import {
  FormItem,
  FormItemInput,
  FormItemLabel,
  RemoveWithConfirmationButton,
} from "../../forms"
import { WrappedMultiselectDropdown } from "../../multiselect-dropdown"

interface Props {
  remove: Function
  index: number
  path: string
  sportProductValue?: IDropdownOption
  hasBackground?: boolean
  isDisciplineEditable?: boolean
  isSportClubsEditable?: boolean
}

const Wrapper = styled("div", {
  position: "relative",

  variants: {
    hasBackground: {
      true: {
        margin: "0 -$40 $20 -$10",
        padding: "$10 $40 $10 $10",
        borderRadius: "4px",
        backgroundColor: "$lightGreen",
      },
      false: {
        padding: "0 $40 0 0",
      },
    },
  },
})

const RemoveWrapper = styled("div", {
  position: "absolute",

  variants: {
    offset: {
      true: {
        top: "$10",
        right: "$10",
      },
      false: {
        top: "0",
        right: "0",
      },
    },
  },
})

export const SportClubDisciplineRelation = ({
  sportProductValue,
  remove,
  index,
  path,
  hasBackground = false,
  isDisciplineEditable = true,
  isSportClubsEditable = true,
}: Props) => {
  const adminContext = useAdminContext()
  const { formatMessage } = useIntl()
  const { filter: kvkOptionsFilter } = useKVKSearch()

  const mappedDisciplines = useMemo(() => {
    if (!sportProductValue) return []
    return (
      adminContext.sportProducts
        ?.find(filters.sportProduct(sportProductValue))
        ?.disciplines.map(transformers.discipline)
        ?.sort(alphabeticalSort) || []
    )
  }, [sportProductValue])

  return (
    <Wrapper hasBackground={hasBackground}>
      <RemoveWrapper offset={hasBackground}>
        {(isDisciplineEditable || isSportClubsEditable) && (
          <RemoveWithConfirmationButton onConfirm={() => remove(index)} />
        )}
      </RemoveWrapper>

      <FormItem horizontal>
        <FormItemLabel htmlFor={`${path}.discipline`}>
          <FormattedMessage id="form.disciplines.label" />
        </FormItemLabel>
        <FormItemInput data-cy="form-input-disciplines" noMargin>
          <WrappedDropdownSelect
            name={`${path}.discipline`}
            placeholder={formatMessage({ id: "form.select.placeholder" })}
            options={mappedDisciplines}
            isDisabled={!isDisciplineEditable}
            noDefault
          />
        </FormItemInput>
      </FormItem>

      <FormItem horizontal noMargin>
        <FormItemLabel htmlFor={`${path}.sportClubs`}>
          <FormattedMessage id="form.sportClubs.label" />
        </FormItemLabel>
        <FormItemInput noMargin data-cy="form-input-sportclubs">
          <WrappedMultiselectDropdown
            name={`${path}.sportClubs`}
            placeholder={formatMessage({ id: "form.select.placeholder" })}
            filterOptions={kvkOptionsFilter}
            disabled={!isSportClubsEditable}
          />
        </FormItemInput>
      </FormItem>
    </Wrapper>
  )
}
